import { runOutsideAngular } from '@app/srv/di.service'
import { _Memo } from '@naturalcycles/js-lib'
import { env } from '@src/environments/environment'
import { initializeApp } from 'firebase/app'
import { FirebasePerformance, getPerformance, PerformanceTrace, trace } from 'firebase/performance'

class FirebaseService {
  init(): void {
    this.perf()
  }

  @_Memo()
  perf(): FirebasePerformance | undefined {
    try {
      if (!env.firebasePerfEnabled || !env.firebaseCfg) return

      const firebaseApp = initializeApp(env.firebaseCfg, 'NaturalCycles')
      // firebase.setLogLevel('debug') // use for debugging

      // This line initializes Firebase Perf Monitoring
      const perf = getPerformance(firebaseApp)
      perf.instrumentationEnabled = true // Kirill: trying if this has any effect on our iOS issues
      perf.dataCollectionEnabled = true
      return perf
    } catch {
      // ignore
    }
  }

  trace(traceName: string): PerformanceTrace | undefined {
    try {
      if (!this.perf()) return
      return trace(this.perf()!, traceName)
    } catch {
      // ignore
    }
  }

  /**
   * You're limited to 5 custom attributes per trace.
   * Names for custom attributes must meet the following requirements:
   * no leading or trailing whitespace, no leading underscore (_) character, and max length is 32 characters.
   */
  recordTrace(
    traceName: string,
    startTime: number,
    duration: number,
    options?: {
      metrics?: { [key: string]: number }
      attributes?: { [key: string]: string }
    },
  ): void {
    try {
      if (!this.perf()) return

      runOutsideAngular(() => {
        trace(this.perf()!, traceName).record(startTime, duration, options)
      })
    } catch {
      // ignore
    }
  }
}

export const firebaseService = new FirebaseService()
