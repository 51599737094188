import { inject, Injectable } from '@angular/core'
import { EVENT } from '@app/analytics/analytics.cnst'
import { AnalyticsService } from '@app/analytics/analytics.service'
import { isIOSApp } from '@app/cnst/userDevice.cnst'
import { tryCatch } from '@app/decorators/tryCatch.decorator'
import {
  FERTILITY_DATA,
  HK_TO_LH,
  HK_TO_MENS,
  HK_TO_MENS_QUANTITY,
  HK_TO_MUCUS,
  HK_TO_MUCUS_QUANTITY,
  HKCategoryValueNotApplicable,
  HKCategoryWriteInput,
  HKDataIdentifier,
  HKDataIdentifierInput,
  HKExportableDailyEntry,
  HKIdentifierDataType,
  HKQuantityWriteInput,
  HKUnit,
  HW_ID_TO_EXPORTABLE_ENTRY_PROPS,
  HW_ID_TO_IDENTIFIER_INPUTS,
  isCategoryWriteInput,
  LH_TO_HK,
  MENS_QUANTITY_TO_HK,
  MUCUS_QUANTITY_TO_HK,
  MUCUS_TO_HK,
} from '@app/model/healthKit.model'
import { UFDay } from '@app/model/uf.model'
import { api } from '@app/srv/api.service'
import { DailyEntryService } from '@app/srv/dailyentry.service'
import { di } from '@app/srv/di.service'
import { dispatch, getState } from '@app/srv/store.service'
import { UnitsService } from '@app/srv/units.service'
import { logUtil } from '@app/util/log.util'
import { prf } from '@app/util/perf.util'
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem'
import {
  _deepEquals,
  _difference,
  _filterUndefinedValues,
  _groupBy,
  _lastOrUndefined,
  _mapValues,
  _Memo,
  _objectAssign,
  _objectKeys,
  _sortBy,
  _stringMapEntries,
  _stringMapValues,
  hashCode64,
  IsoDateString,
  localDate,
  localTime,
  NumberOfSeconds,
  pMap,
  StringMap,
  UnixTimestampNumber,
} from '@naturalcycles/js-lib'
import {
  AccountDataFM,
  DailyEntryBM,
  DailyEntrySaveBatchInput,
  dailyEntrySharedUtil,
  DataQuantity,
  DEVIATION_REASON_FLAGS,
  HadSex,
  HKCategoryCervicalMucusValue,
  HKCategoryMensValue,
  HKCategorySample,
  HKExtraDataTypesResp,
  HKMetadata,
  HKPostExtraDataInput,
  HKQuantitySample,
  HKSample,
  HKType,
  Mens,
  NCHKSample,
  OnboardingData,
  RecentlyUsedHormones,
  TestResult,
} from '@naturalcycles/shared'
import { dayjs } from '@naturalcycles/time-lib'
import { NCHealthKit } from '@src/typings/capacitor'
import { Subject } from 'rxjs'

const CACHE_FOLDER = 'health_kit'
const CACHE_FILE = 'health_kit_import_cache'
const directory = Directory.Data
const encoding = Encoding.UTF8

@Injectable({ providedIn: 'root' })
export class HealthKitService {
  private unitsService = inject(UnitsService)
  private analyticsService = inject(AnalyticsService)

  public permissionsRequested$ = new Subject<void>()

  public async init(): Promise<void> {
    const available = await this.isAvailable()
    if (!available) return

    void this.processScienceData()
  }

  @_Memo()
  public async isAvailable(): Promise<boolean> {
    if (!isIOSApp) return false
    const { isAvailable } = await NCHealthKit.isAvailable()
    return isAvailable
  }

  public async canRequestPermissionForIdentifier(
    readDataIdentifiers: HKDataIdentifierInput[],
    writeDataIdentifiers: HKDataIdentifierInput[],
    trackEvent = false,
  ): Promise<boolean> {
    const isAvailable = await this.isAvailable()
    if (!isAvailable) return false

    const { result } = await NCHealthKit.canRequestPermissionForIdentifier({
      readDataIdentifiers,
      writeDataIdentifiers,
    })

    if (trackEvent) {
      void this.analyticsService.trackEvent(EVENT.HK_CAN_REQUEST_PERMISSION, {
        ...readDataIdentifiers,
        ...writeDataIdentifiers,
        result,
      })
    }

    return !!result
  }

  @tryCatch({
    alert: false,
    onError: error => {
      if (error?.message?.startsWith('NCHealthKit error') && error?.message?.includes('Code=6')) {
        // Swallow 'Protected health data is inaccessible' (i.e., app trying to read data from HK when device is locked) errors
        return false
      }
    },
  })
  public async requestPermissions(
    readDataIdentifiers: HKDataIdentifierInput[],
    writeDataIdentifiers: HKDataIdentifierInput[],
  ): Promise<boolean> {
    const { result } = await NCHealthKit.requestPermissions({
      readDataIdentifiers,
      writeDataIdentifiers,
    })
    this.permissionsRequested$.next()
    return !!result
  }

  /**
   * Helper function to make mocking in unit tests easier
   */
  private async writeDataForIdentifier<T extends HKCategoryWriteInput | HKQuantityWriteInput>(
    data: T,
  ): Promise<void> {
    await NCHealthKit.writeDataForIdentifier({ data })
  }

  /**
   * Helper function to make mocking in unit tests easier
   */
  @tryCatch({
    alert: false,
    onError: error => {
      if (error?.message?.startsWith('NCHealthKit error') && error?.message?.includes('Code=6')) {
        // Swallow 'Protected health data is inaccessible' (i.e., app trying to read data from HK when device is locked) errors
        return false
      }
    },
  })
  public async readDataForIdentifier<T extends HKCategorySample | HKQuantitySample = NCHKSample>(
    dataIdentifier: HKDataIdentifierInput,
    startTimestamp?: UnixTimestampNumber,
    endTimestamp?: UnixTimestampNumber,
    limit?: number,
    ascending?: boolean,
    checkCanRequestPermission = true,
  ): Promise<{ data: T[] } | undefined> {
    if (
      checkCanRequestPermission &&
      (await this.canRequestPermissionForIdentifier([dataIdentifier], []))
    ) {
      return { data: [] }
    }

    return await NCHealthKit.readDataForIdentifier<T>({
      dataIdentifier,
      startTimestamp,
      endTimestamp,
      limit,
      ascending,
    })
  }

  @tryCatch({
    alert: false,
    onError: error => {
      if (
        error?.message?.includes('Protected health data is inaccessible') ||
        error?.message?.includes('Unable to invalidate interval: no data source available')
      ) {
        // Swallow 'Protected health data is inaccessible' (i.e., app trying to read data from HK when device is locked) errors
        // Swallow 'Unable to invalidate interval: no data source available' errors. HKStatisticsCollectionQuery bug with no fix
        return false
      }
    },
  })
  private async readAggregatedDataForIdentifier<
    T extends HKCategorySample | HKQuantitySample = NCHKSample,
  >(
    dataIdentifier: HKDataIdentifierInput,
    postPeriod: NumberOfSeconds,
    startTimestamp?: UnixTimestampNumber,
  ): Promise<{ data: T[] } | undefined> {
    if (await this.canRequestPermissionForIdentifier([dataIdentifier], [])) return { data: [] }

    return await NCHealthKit.readAggregatedDataForIdentifier<T>({
      dataIdentifier,
      postPeriod,
      startTimestamp,
    })
  }

  /**
   * Helper function to make mocking in unit tests easier
   */
  private async checkPermissions(
    dataIdentifiers: HKDataIdentifierInput[],
  ): Promise<{ result: { [k in HKDataIdentifier]: boolean } }> {
    return await NCHealthKit.checkPermissions({ dataIdentifiers })
  }

  public async exportFertilityData(
    startDateIncl: IsoDateString | undefined,
    ufDays: UFDay[],
  ): Promise<void> {
    const exportFertilityDataStarted = Date.now()

    const {
      account: { hwId },
      userFertility: { entryMap },
    } = getState()

    const exportableProps = HW_ID_TO_EXPORTABLE_ENTRY_PROPS[hwId]

    let entries = _stringMapValues(entryMap)
    if (startDateIncl) {
      entries = entries.filter(de => de.date >= startDateIncl)
    }

    const writtenDates = new Set()

    const { result } = await this.checkPermissions(FERTILITY_DATA)
    const identifiersWithWritePermissions = _objectKeys(result).filter(k => result[k])

    await pMap(entries, async de => {
      const ufDay = ufDays.find(d => d.date === de.date)
      const inputs = this._dailyEntryToHKWriteInputs(de, exportableProps, ufDay)

      const startTimestamp = localDate(de.date).startOf('day').unix
      const endTimestamp = startTimestamp + 86399

      return await Promise.all(
        inputs.map(async data => {
          if (!identifiersWithWritePermissions.includes(data.identifier)) return

          const { data: samples } =
            (await this.readDataForIdentifier(
              { dataType: data.dataType, identifier: data.identifier, unit: data.unit },
              startTimestamp,
              endTimestamp,
              undefined,
              undefined,
              false,
            )) || {}

          const sampleExists = samples?.some(s =>
            isCategoryWriteInput(data) ? data.value === s.value : data.quantity === s.quantity,
          )

          if (sampleExists) return
          writtenDates.add(de.date)
          void this.writeDataForIdentifier(data)
        }),
      )
    })

    prf('Healthkit.ExportFertilityData', exportFertilityDataStarted, undefined, false)

    if (!writtenDates.size) return

    void this.analyticsService.trackEvent(EVENT.HK_DATA_EXPORT, {
      daysCount: writtenDates.size,
    })
  }

  public async importFertilityData(): Promise<void> {
    const importFertilityDataStarted = Date.now()

    const {
      account: { hwId, onboardingData },
      accountData,
      userFertility: { startDate },
    } = getState()
    const calculatedStartDate = this._calculateStartDate(onboardingData!, accountData)
    const startTimestamp = calculatedStartDate ? dayjs(calculatedStartDate).unix() : undefined
    const endTimestamp = dayjs().endOf('d').unix()

    const samples = await pMap(
      HW_ID_TO_IDENTIFIER_INPUTS[hwId],
      async (input: HKDataIdentifierInput) => {
        const { data } = (await this.readDataForIdentifier(
          input,
          startTimestamp,
          endTimestamp,
          undefined,
          undefined,
          false,
        )) || { data: [] }

        return data
      },
    )

    const newEntries = await this.changeDetection(
      samples.filter(Boolean),
      calculatedStartDate || startDate,
    )
    // Save only new entries
    if (!newEntries) return

    const entries = newEntries.map(de => dailyEntrySharedUtil.dailyEntryToSaveInput(de))

    const input: DailyEntrySaveBatchInput = {
      merge: true,
      entries,
      origin: 'HK',
    }

    prf('Healthkit.ImportFertilityData', importFertilityDataStarted, undefined, false)

    await di.get(DailyEntryService).saveBatch(input)
  }

  public async processScienceData(): Promise<void> {
    const processScienceDataStarted = Date.now()
    const { hkConsent } = getState().account
    if (!hkConsent) return

    const { userSettings, remoteConfig } = getState()
    const scienceDataIdentifierInputs = await this.getScienceDataIdentifierInputs()
    await this.requestPermissions(scienceDataIdentifierInputs, [])

    await pMap(scienceDataIdentifierInputs, async input => {
      const { identifier, aggregationPeriodSeconds } = input
      const { hkDataReadPeriodSeconds, hkExtraDataSampleLimit } = remoteConfig
      let startTimestamp = userSettings.hkScienceDataPosted?.[identifier]
      startTimestamp &&= startTimestamp + 1

      if (aggregationPeriodSeconds) {
        return await this._readAndPostHKAggregationData(
          input,
          hkDataReadPeriodSeconds,
          hkExtraDataSampleLimit,
          startTimestamp,
        )
      }

      await this.readAndPostHKData(input, hkExtraDataSampleLimit, startTimestamp)
      prf('Healthkit.ProcessScienceData', processScienceDataStarted, undefined, true)
    })
  }

  @tryCatch({
    alert: false,
    onError: error => {
      logUtil.error(error)
    },
  })
  private async postScienceData(data: NCHKSample[], identifier: HKDataIdentifier): Promise<void> {
    // Timestamp of the last sample we sent
    const lastEndTimestamp = _lastOrUndefined(data)?.endTimestamp
    if (!lastEndTimestamp) return
    dispatch('extendHKScienceDataPosted', {
      [identifier]: lastEndTimestamp,
    })

    const utcOffset = dayjs().utcOffset()

    data = data.map(sample => ({
      ...sample,
      metadata: {
        ...sample.metadata,
        NCUTCOffsetMinutes: utcOffset,
      } satisfies HKMetadata,
    }))

    await api.post<void>('healthkit/extraData', {
      json: {
        data,
      } satisfies HKPostExtraDataInput,
    })
  }

  private async getScienceDataIdentifiers(): Promise<HKType[]> {
    const { types } = await api.get<HKExtraDataTypesResp>('healthkit/extraDataTypes')
    return types
  }

  private async getScienceDataIdentifierInputs(): Promise<HKDataIdentifierInput[]> {
    const scienceDataIdentifiers = await this.getScienceDataIdentifiers()
    return scienceDataIdentifiers
      .map(d => ({
        identifier: d.identifier as HKDataIdentifier,
        dataType: this.getHKDataTypeFromIdentifier(d.identifier)!,
        unit: d.unit as HKUnit,
        aggregationPeriodSeconds: d.aggregationPeriodSeconds,
      }))
      .filter(i => i.dataType)
  }

  private async readAndPostHKData(
    input: HKDataIdentifierInput,
    hkExtraDataSampleLimit: number,
    startTimestamp?: number,
  ): Promise<void> {
    // whole read of data starting from the startTimestamp
    const readAndPostHKDataStarted = Date.now()
    const { identifier } = input
    let samples: NCHKSample[]

    do {
      // each cycle of reading the data from the HK without data sending
      const readDataForIdentifierStarted = Date.now()
      const { data } = (await this.readDataForIdentifier(
        input,
        startTimestamp,
        undefined,
        hkExtraDataSampleLimit,
        true,
      )) || { data: [] }

      samples = data

      const lastEndTimestamp = _lastOrUndefined(data)?.endTimestamp
      if (lastEndTimestamp) {
        startTimestamp = lastEndTimestamp + 1
        prf('Healthkit.ReadDataForIdentifier', readDataForIdentifierStarted, undefined, true)
        void this.postScienceData(data, identifier)
      }
    } while (samples.length === hkExtraDataSampleLimit)

    prf('Healthkit.ReadAndPostHKData', readAndPostHKDataStarted, undefined, true)
  }

  public async _readAndPostHKAggregationData(
    input: HKDataIdentifierInput,
    hkDataReadPeriodSeconds: number,
    hkExtraDataSampleLimit: number,
    startTimestamp: number | undefined,
  ): Promise<void> {
    // whole read of aggregation data starting from the startTimestamp (or first sample startTimestamp) till now
    const readAndPostHKAggregationDataStarted = Date.now()
    const { identifier } = input
    const dataToSend: NCHKSample[] = []
    const now = localTime.nowUnix()

    if (!startTimestamp) {
      const { data } = (await this.readDataForIdentifier(
        input,
        startTimestamp,
        undefined,
        1,
        true,
      )) || { data: [] }

      startTimestamp = data[0]?.startTimestamp
    }

    do {
      // each cycle of reading the aggregation data from the HK without data sending
      const readAggregatedDataForIdentifierStarted = Date.now()
      // aggregate samples by aggregationPeriodSeconds from hkDataReadPeriodSeconds chunk
      const { data } = (await this.readAggregatedDataForIdentifier(
        input,
        hkDataReadPeriodSeconds,
        startTimestamp,
      )) || { data: [] }

      dataToSend.push(...data)

      // Here we get the endTimestamp of the aggregation period and assign it to the startTimestamp for future calculations
      // as it is now the new startTimestamp
      startTimestamp = startTimestamp! + hkDataReadPeriodSeconds

      prf(
        'Healthkit.ReadAggregatedDataForIdentifier',
        readAggregatedDataForIdentifierStarted,
        undefined,
        true,
      )

      // Send the data by chunks with the hkExtraDataSampleLimit length
      while (dataToSend.length >= hkExtraDataSampleLimit) {
        const chunkToSend = dataToSend.splice(0, hkExtraDataSampleLimit)
        void this.postScienceData(chunkToSend, identifier)
      }
    } while (startTimestamp && startTimestamp < now)

    prf(
      'Healthkit.ReadAndPostHKAggregationData',
      readAndPostHKAggregationDataStarted,
      undefined,
      true,
    )

    // Post any remaining data
    if (dataToSend.length > 0) {
      void this.postScienceData(dataToSend, identifier)
    }
  }

  public _hkSamplesToDailyEntries(
    samples: HKSample[],
    entryMap: StringMap<DailyEntryBM>,
  ): DailyEntryBM[] {
    const samplesByDate = _groupBy(samples, s => dayjs.unix(s.endTimestamp).toISODate())

    return _stringMapEntries(samplesByDate)
      .map(([date, samples]) => this.hkSamplesToDailyEntry(date, samples, entryMap[date]))
      .filter(Boolean) as DailyEntryBM[]
  }

  private hkSamplesToDailyEntry(
    date: IsoDateString,
    samples: HKSample[],
    de: DailyEntryBM | undefined,
  ): DailyEntryBM | undefined {
    const hkDailyEntry: Partial<DailyEntryBM> = {}

    // Don't import data previously exported from NC
    const nonNCSamples = samples.filter(
      s => !s.source.bundleId.startsWith('com.naturalcycles.cordova'),
    )
    if (!nonNCSamples.length) return

    // Sex and LH can return several samples per day
    const sexSamples = nonNCSamples.filter(s => s.identifier === HKDataIdentifier.SEX)
    const lhSamples = nonNCSamples.filter(s => s.identifier === HKDataIdentifier.LHTEST)

    // TODO: should we do something 'smart'?
    // We only expect one sample per day for all remaining identifiers
    const uniqueSamples = _difference(nonNCSamples, sexSamples, lhSamples)

    if (sexSamples.length) {
      const unprotectedSexSample = sexSamples.find(
        s => s.metadata && s.metadata.HKSexualActivityProtectionUsed === '0',
      )
      _objectAssign(
        hkDailyEntry,
        this.hkSampleToDailyEntryProp(unprotectedSexSample || sexSamples[0]!, de),
      )
    }

    if (lhSamples.length) {
      const positiveLHSample = lhSamples.find(
        data => (data as HKCategorySample).value === LH_TO_HK[TestResult.YES],
      )
      _objectAssign(
        hkDailyEntry,
        this.hkSampleToDailyEntryProp(positiveLHSample || lhSamples[0]!, de),
      )
    }

    for (const sample of uniqueSamples) {
      _objectAssign(hkDailyEntry, this.hkSampleToDailyEntryProp(sample, de, hkDailyEntry))
    }

    return _filterUndefinedValues({
      date,
      dataFlags: [],
      ...hkDailyEntry,
      ...de,
      // source if some hk data
      source: _objectKeys(hkDailyEntry).length ? nonNCSamples[0]?.source.name : undefined,
    })
  }

  private hkSampleToDailyEntryProp(
    sample: HKSample,
    de: DailyEntryBM | undefined,
    hkDailyEntry?: Partial<DailyEntryBM>,
  ): Partial<DailyEntryBM> {
    // TODO: type safety without 'as'? (Category vs Quantity) 🤔
    switch (sample.identifier) {
      case HKDataIdentifier.BASAL_BODY_TEMPERATURE:
        return {
          temperature: (sample as HKQuantitySample).quantity,
        }
      case HKDataIdentifier.MENS: {
        const _sample = sample as HKCategorySample
        return {
          mens: HK_TO_MENS[_sample.value as Mens],
          // only add mensQuantity if no mens in de (so it'll be taken from HK) or de.mens === Mens.MENSTRUATION
          mensQuantity:
            (!de?.mens || de.mens === Mens.MENSTRUATION) && _sample.value
              ? HK_TO_MENS_QUANTITY[_sample.value as Mens]
              : undefined,
        }
      }
      case HKDataIdentifier.SPOTTING:
        return hkDailyEntry?.mens || de?.mens ? {} : { mens: Mens.SPOTTING }
      case HKDataIdentifier.CERVICAL_MUCUS: {
        const _sample = sample as HKCategorySample
        return {
          cervicalMucusConsistency: HK_TO_MUCUS[_sample.value as Mens],
          cervicalMucusQuantity: HK_TO_MUCUS_QUANTITY[_sample.value as Mens],
        }
      }
      case HKDataIdentifier.SEX:
        return {
          sex:
            sample.metadata?.HKSexualActivityProtectionUsed === '1'
              ? HadSex.YES_PROTECTED
              : HadSex.YES,
        }
      case HKDataIdentifier.LHTEST:
        return {
          lhTest: HK_TO_LH[(sample as HKCategorySample).value as TestResult],
        }
    }

    return {}
  }

  public _dailyEntryToHKWriteInputs(
    de: DailyEntryBM,
    exportableProps: (keyof DailyEntryBM)[],
    ufDay: UFDay | undefined,
  ): (HKCategoryWriteInput | HKQuantityWriteInput)[] {
    return _objectKeys(de)
      .map(k => {
        if (!exportableProps.includes(k)) return

        return this.dailyEntryPropToHKWriteInput(de, k as keyof HKExportableDailyEntry, ufDay)
      })
      .filter(Boolean) as (HKCategoryWriteInput | HKQuantityWriteInput)[]
  }

  private dailyEntryPropToHKWriteInput(
    de: DailyEntryBM,
    key: keyof HKExportableDailyEntry,
    ufDay: UFDay | undefined,
  ): HKCategoryWriteInput | HKQuantityWriteInput | undefined {
    const timestamp = dayjs(de.date).hour(7).unix()
    const writeInput = {
      startTimestamp: timestamp,
      endTimestamp: timestamp,
      metadata: {
        HKExternalUUID: `NC-${de.date}-${key}`,
      },
      // TODO: HKDevice?
    }

    switch (key) {
      case 'mens':
        if (
          !de.mens ||
          de.mens === Mens.MISCARRIAGE_BLEEDING ||
          de.mens === Mens.POSTPARTUM_BLEEDING ||
          de.mens === Mens.WITHDRAWAL
        ) {
          return
        }

        if (de.mens === Mens.SPOTTING) {
          return {
            ...writeInput,
            dataType: HKIdentifierDataType.CATEGORY,
            identifier: HKDataIdentifier.SPOTTING,
            value: 0,
          } satisfies HKCategoryWriteInput<HKCategoryValueNotApplicable>
        }
        return {
          ...writeInput,
          dataType: HKIdentifierDataType.CATEGORY,
          identifier: HKDataIdentifier.MENS,
          value: MENS_QUANTITY_TO_HK[de.mensQuantity!] || HKCategoryMensValue.UNSPECIFIED,
          metadata: {
            ...writeInput.metadata,
            HKMenstrualCycleStart: de.date === ufDay?.cycleStartDate,
          },
        } satisfies HKCategoryWriteInput<HKCategoryMensValue>
      case 'sex':
        if (!de.sex || de.sex === HadSex.NO) return

        return {
          ...writeInput,
          dataType: HKIdentifierDataType.CATEGORY,
          identifier: HKDataIdentifier.SEX,
          value: 0,
          metadata: {
            ...writeInput.metadata,
            HKSexualActivityProtectionUsed: de.sex === HadSex.YES_PROTECTED,
          },
        } satisfies HKCategoryWriteInput<HKCategoryValueNotApplicable>
      case 'lhTest':
        if (!de.lhTest) return

        return {
          ...writeInput,
          dataType: HKIdentifierDataType.CATEGORY,
          identifier: HKDataIdentifier.LHTEST,
          value: LH_TO_HK[de.lhTest],
        }
      case 'temperature':
        if (!de.temperature || de?.dataFlags?.some(flag => DEVIATION_REASON_FLAGS.has(flag))) return

        return {
          ...writeInput,
          dataType: HKIdentifierDataType.QUANTITY,
          identifier: HKDataIdentifier.BASAL_BODY_TEMPERATURE,
          unit: HKUnit.CELSIUS,
          quantity: this.unitsService.convertTempToCelsius(de.temperature),
        } satisfies HKQuantityWriteInput
      case 'cervicalMucusQuantity':
        if (de?.cervicalMucusQuantity !== DataQuantity.NONE) return

        return {
          ...writeInput,
          dataType: HKIdentifierDataType.CATEGORY,
          identifier: HKDataIdentifier.CERVICAL_MUCUS,
          value: MUCUS_QUANTITY_TO_HK[de.cervicalMucusQuantity]!,
        } satisfies HKCategoryWriteInput<HKCategoryCervicalMucusValue>
      case 'cervicalMucusConsistency':
        if (!de.cervicalMucusConsistency) return

        return {
          ...writeInput,
          dataType: HKIdentifierDataType.CATEGORY,
          identifier: HKDataIdentifier.CERVICAL_MUCUS,
          value: MUCUS_TO_HK[de.cervicalMucusConsistency],
        } satisfies HKCategoryWriteInput<HKCategoryCervicalMucusValue>
    }
  }

  private getHKDataTypeFromIdentifier(identifier: string): HKIdentifierDataType | undefined {
    return identifier.includes('HKCategoryTypeIdentifier')
      ? HKIdentifierDataType.CATEGORY
      : HKIdentifierDataType.QUANTITY
  }

  public _calculateStartDate(
    onboardingData: OnboardingData,
    accountData: AccountDataFM,
  ): IsoDateString | undefined {
    if (onboardingData.recentlyUsedHormones === RecentlyUsedHormones.YES_LAST12M) {
      return accountData.withdrawalBleedEndDate || onboardingData.hormonesQuitDate
    }

    if (onboardingData.recentlyPregnant && onboardingData.birthDate) {
      return onboardingData.birthDate
    }
  }

  public async checkWritePermissionsForFertilityData(): Promise<boolean> {
    const { result } = await NCHealthKit.checkPermissions({
      dataIdentifiers: FERTILITY_DATA,
    })

    return Object.values(result).includes(true)
  }

  private async saveHashFile(map: StringMap): Promise<void> {
    const data = JSON.stringify(map)
    await Filesystem.writeFile({
      path: `${CACHE_FOLDER}/${CACHE_FILE}`,
      data,
      directory,
      encoding,
    })
  }

  public async deleteHashFile(): Promise<void> {
    try {
      await Filesystem.deleteFile({
        path: `${CACHE_FOLDER}/${CACHE_FILE}`,
        directory,
      })
    } catch {
      console.warn("Can't remove HealthKit hash file (probably it doesn't exist)")
    }
  }

  private async getHashCache(): Promise<StringMap | undefined> {
    const { files } = await Filesystem.readdir({
      path: CACHE_FOLDER,
      directory,
    }).catch(() => {
      // create folder if does not exist
      void Filesystem.mkdir({
        directory,
        path: CACHE_FOLDER,
      })

      return { files: [] }
    })

    // no such file
    if (!files.some(f => f.name === CACHE_FILE)) return

    const { data } = await Filesystem.readFile({
      directory,
      path: `${CACHE_FOLDER}/${CACHE_FILE}`,
      encoding,
    })

    const oldHashMap = JSON.parse(data as string) as StringMap
    return oldHashMap
  }

  public _createMonthToHashMap(
    samples: NCHKSample[],
    startDate: string,
  ): {
    monthMap: StringMap<NCHKSample[]>
    monthToHashMap: StringMap
  } {
    const startMonthBeginning = localDate(startDate).startOf('month')
    const twoYearsAgoMonthBeginning = localDate.today().minus(2, 'year').startOf('month')

    const earliestEntryDate =
      startMonthBeginning < twoYearsAgoMonthBeginning
        ? startMonthBeginning
        : twoYearsAgoMonthBeginning

    // Grouping entries by month chunks, filtering those which are older than the beginning of the start month
    const monthMap = _groupBy(
      _sortBy(samples, s => s.endTimestamp),
      e => {
        const sampleDate = localTime(e.endTimestamp).toLocalDate()
        if (sampleDate < earliestEntryDate) return
        return sampleDate.startOf('month')
      },
    )

    const monthToHashMap = _mapValues(monthMap, (_date, entry) =>
      hashCode64(entry.toString()),
    ) satisfies StringMap
    return { monthMap, monthToHashMap }
  }

  public _findChangedEntries(
    monthToHashMap: StringMap,
    oldHashMap: StringMap,
    monthMap: StringMap<NCHKSample[]>,
    entryMap: StringMap<DailyEntryBM>,
  ): DailyEntryBM[] | undefined {
    const changedSamplesDates = _stringMapEntries(monthToHashMap)
      .filter(([date, hash]) => hash !== oldHashMap[date])
      .map(([date, _hash]) => date) satisfies IsoDateString[]

    // Nothing has changed and we return
    if (!changedSamplesDates.length) return

    const changedSamples = changedSamplesDates.flatMap(date => monthMap[date]!)

    const newEntries: DailyEntryBM[] = []
    const changedEntries = this._hkSamplesToDailyEntries(changedSamples, entryMap)
    changedEntries.forEach(changedEntry => {
      const originalEntry = entryMap[changedEntry.date]
      if (!_deepEquals(originalEntry, changedEntry)) {
        newEntries.push(changedEntry)
      }
    })

    return newEntries
  }

  private async changeDetection(
    samples: NCHKSample[][],
    startDate: string,
  ): Promise<DailyEntryBM[] | undefined> {
    const changeDetectionStarted = Date.now()
    const { entryMap } = getState().userFertility
    const hkSamples = samples.flat()
    const { monthMap, monthToHashMap } = this._createMonthToHashMap(hkSamples, startDate)

    const oldHashMap = (await this.getHashCache()) || {}
    const newEntries = this._findChangedEntries(monthToHashMap, oldHashMap, monthMap, entryMap)
    if (!newEntries) return

    await this.saveHashFile(monthToHashMap)
    prf('Healthkit.ChangeDetection', changeDetectionStarted, undefined, true)
    return newEntries
  }
}
