<ion-icon class="introSlides__logo" [src]="ICON.NC_LOGO" [color]="'white'" />

<swiper #swiper [config]="config" [autoplay]="false">
  @for (slide of SLIDES; track 'introSlide' + $index) {
    <ng-template swiperSlide>
      <div class="introSlide">
        <div
          class="introSlide__mediaWrapper"
          [class.introSlide__mediaWrapper--gradient1]="slide.gradient === 'gradient-1'"
          [class.introSlide__mediaWrapper--gradient2]="slide.gradient === 'gradient-2'"
        >
          @if (slide.mediaType === 'image') {
            <img class="introSlide__img" [src]="slide.mediaSrc" />
          } @else {
            <video
              [src]="slide.mediaSrc"
              [id]="VIDEO_ID_PREFIX + $index"
              tappable
              playsinline
              [controls]="false"
              [muted]="true"
              preload="metadata"
              (ended)="videoEnd()"
            ></video>
          }
          <app-intro-slides-pagination-ab250
            [activeIndex]="$index"
            [numberOfSlides]="SLIDES.length"
          />
        </div>

        <div class="introSlide__content">
          <div
            class="introSlide__title"
            [attr.uid]="'introAb250Slide__title_' + $index"
            [innerHTML]="slide.title | md"
          ></div>
          <div class="introSlide__text" [innerHTML]="slide.text | md"></div>
        </div>
      </div>
    </ng-template>
  }
</swiper>
