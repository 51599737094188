<ion-header>
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-back-button icon="close" mode="md" />
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content scrollable>
  <div class="content">
    <ion-img src="/assets/img/oura-ring.png" style="width: 160px; margin: 0 auto" />

    <div>
      <h2 class="ion-text-center">{{ 'oura-onboarding-success-title' | tr }} ✅</h2>

      <p class="ion-text-center">{{ 'oura-onboarding-success-body' | tr }}</p>
    </div>
  </div>
</ion-content>

<ion-footer>
  <ion-button
    expand="block"
    shape="round"
    uid="ouraAuthSuccess__buttonNext"
    (click)="dismissModal()"
  >
    <ion-label>{{ ctaTitle | tr }}</ion-label>
  </ion-button>
</ion-footer>
