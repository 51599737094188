import { CommonModule } from '@angular/common'
import { Component, Input, OnChanges, OnInit, output, SimpleChanges } from '@angular/core'
import { ICON } from '@app/cnst/icons.cnst'
import { SharedModule } from '@app/shared.module'
import { IonicModule } from '@ionic/angular'

@Component({
  selector: 'app-pagination',
  templateUrl: 'pagination.component.html',
  styleUrls: ['pagination.component.scss'],
  standalone: true,
  imports: [IonicModule, SharedModule, CommonModule],
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input()
  public pages!: number

  @Input()
  public activeIndex!: number

  @Input()
  public color?: string

  @Input()
  public navButtons = false

  @Input()
  public startCta = 'txt-start'

  @Input()
  public endCta = 'txt-done-only'

  public previous = output<void>()

  public next = output<void>()

  public done = output<void>()

  public dots: any[] = []
  public ICON = ICON

  public canGoBack = false
  public canGoNext = true

  public ngOnInit(): void {
    // eslint-disable-next-line unicorn/no-new-array
    this.dots = new Array(this.pages)
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const { activeIndex } = changes

    if (!activeIndex) return

    this.canGoBack = activeIndex.currentValue !== 0
    this.canGoNext = activeIndex.currentValue !== this.pages - 1
  }

  public goPrevious(): void {
    this.previous.emit()
  }

  public goNext(): void {
    this.next.emit()
  }

  public onDone(): void {
    this.done.emit()
  }
}
