import { Component, inject, OnInit, ViewChild } from '@angular/core'
import { decorate, ErrorHandlerType, LoaderType } from '@app/decorators/decorators'
import { BaseModal } from '@app/pages/base.modal'
import { ConsentService } from '@app/srv/consent.service'
import { IonContent } from '@ionic/angular'
import { ConsentKey, ConsentTermsItem } from '@naturalcycles/shared'

@Component({
  selector: 'app-science-consent-modal',
  templateUrl: './science-consent.modal.html',
  styleUrls: ['./science-consent.modal.scss'],
})
export class ScienceConsentModal extends BaseModal implements OnInit {
  private consentService = inject(ConsentService)
  className = 'ScienceConsentModal'

  public scienceConsentTerm?: ConsentTermsItem

  @ViewChild(IonContent, { static: true })
  content!: IonContent

  ngOnInit(): void {
    this.subscriptions.push(
      this.consentService.consents$.subscribe(c => {
        if (c) {
          this.scienceConsentTerm = c[ConsentKey.SCIENCE]
        }
      }),
    )
    void this.getConsents()
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  async getConsents(): Promise<void> {
    await this.consentService.fetchConsents()
  }

  public closeModal(accept = false): void {
    void this.dismissModal({ scienceConsent: accept })
  }
}
