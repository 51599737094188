import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, inject } from '@angular/core'
import { HubPage } from '@app/pages/hub/hub.page'
import { SharedModule } from '@app/shared.module'
import { PopupController, Priority } from '@app/srv/popup.controller'
import { select2 } from '@app/srv/store.service'
import { IonicModule } from '@ionic/angular'

@Component({
  selector: 'app-red-dot',
  standalone: true,
  imports: [IonicModule, SharedModule, CommonModule],
  templateUrl: './red-dot.component.html',
  styleUrls: ['./red-dot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RedDotComponent {
  private popupController = inject(PopupController)

  public redDot$ = select2(s => s?.ui.admin)

  public onClick(): void {
    void this.popupController.presentModal(
      { component: HubPage },
      'modal-hubPage',
      Priority.IMMEDIATE,
    )
  }
}
