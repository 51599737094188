import { _omit } from '@naturalcycles/js-lib'
import { AppSettings, AppTracker, Goal } from '@naturalcycles/shared'
import { CheckboxGroupValue } from '../cmp/checkbox-group/checkbox-group.component'
import { ICON } from '../cnst/icons.cnst'

export interface AppSettingsFM
  extends Omit<AppSettings, 'enabledTrackers' | 'enabledPartnerTrackers'> {
  trackers: TrackerSettings
  partnerTrackers: PartnerTrackerSettings
}

const periodFlow: CheckboxGroupValue = {
  title: 'txt-period-flow',
  name: `${AppTracker.MENS_QUANTITY}`,
  uid: 'onboarding__trackers__periodFlow',
  icon: ICON.MENSTRUATION_HEAVY,
}

const cervicalMucus = {
  title: 'txt-cervical-mucus',
  name: `${AppTracker.CM_QUANTITY}`,
  uid: 'onboarding__trackers__cervicalMucus',
  icon: ICON.CM_QUANTITY_LIGHT,
}

const discharge = {
  title: 'txt-cervical-mucus-follow',
  name: `${AppTracker.CM_QUANTITY}`,
  uid: 'onboarding__trackers__cervicalMucus',
  icon: ICON.CM_QUANTITY_LIGHT,
}

const libido = {
  title: 'txt-sex-drive',
  name: `${AppTracker.LIBIDO}`,
  uid: 'onboarding__trackers__libido',
  icon: ICON.LIBIDO_HIGH,
}

const sexInfo = {
  title: 'txt-additional-sex-info',
  name: `${AppTracker.MORE_SEX}`,
  uid: 'onboarding__trackers__moreSex',
  icon: ICON.SEX_OTHER,
}

const skin = {
  title: 'txt-skin',
  name: `${AppTracker.SKIN}`,
  uid: 'onboarding__trackers__skin',
  icon: ICON.SKIN_OTHER,
}

const sleep = {
  title: 'txt-sleep',
  name: `${AppTracker.SLEEP}`,
  uid: 'onboarding__trackers__sleep',
  icon: ICON.SLEEP_OKAY,
}

const pain = {
  title: 'txt-pain-symptoms',
  name: `${AppTracker.PAIN}`,
  uid: 'onboarding__trackers__pain',
  icon: ICON.PAIN_CRAMPS,
}

const moods = {
  title: 'txt-emotions',
  name: `${AppTracker.MOOD}`,
  uid: 'onboarding__trackers__mood',
  icon: ICON.MOOD_CALM,
}

export const TRACKERS_BY_GOAL: Record<Goal, CheckboxGroupValue[]> = {
  [Goal.PREVENT]: [periodFlow, pain, moods, skin, sleep, libido, sexInfo, cervicalMucus],
  [Goal.PLAN]: [periodFlow, pain, moods, skin, sleep, libido, sexInfo, cervicalMucus],
  [Goal.PREGNANT]: [pain, moods, skin, sleep, libido, sexInfo, discharge],
  [Goal.RECOVERY]: [periodFlow, pain, moods, skin, sleep, libido, sexInfo, cervicalMucus],
  [Goal.POSTPARTUM]: [periodFlow, pain, moods, skin, sleep, libido, sexInfo, cervicalMucus],
}

export type TrackerSettings = Record<
  Exclude<
    AppTracker,
    AppTracker.NOTES | AppTracker.SEX | AppTracker.LH_TEST | AppTracker.PREG_TEST
  >,
  boolean
>
export type PartnerTrackerSettings = Record<
  Exclude<
    AppTracker,
    | AppTracker.MENS_QUANTITY
    | AppTracker.CM_CONSISTENCY
    | AppTracker.CM_QUANTITY
    | AppTracker.MORE_SEX
  >,
  boolean
>

export enum AddDataSettingsToAppSettings {
  MENS_FLOW_ENABLED = AppTracker.MENS_QUANTITY,
  CM_CONSISTENCY_ENABLED = AppTracker.CM_CONSISTENCY,
  CM_QUANTITY_ENABLED = AppTracker.CM_QUANTITY,
  LIBIDO_ENABLED = AppTracker.LIBIDO,
  SLEEP = AppTracker.SLEEP,
  PAIN_ENABLED = AppTracker.PAIN,
  SKIN_ENABLED = AppTracker.SKIN,
  MOODS_ENABLED = AppTracker.MOOD,
  MORE_SEX_ENABLED = AppTracker.MORE_SEX,
}

const excludedTrackersForPartnerSettings = [
  AppTracker.MENS_QUANTITY,
  AppTracker.CM_CONSISTENCY,
  AppTracker.CM_QUANTITY,
  AppTracker.MORE_SEX,
]
const trackersForPartnerSettingsOnly = [
  AppTracker.NOTES,
  AppTracker.SEX,
  AppTracker.LH_TEST,
  AppTracker.PREG_TEST,
]

export const allTrackersOn = Object.fromEntries(
  Object.keys(AppTracker)
    .filter(Number)
    .filter(k => !trackersForPartnerSettingsOnly.some(key => Number(key) === Number(k)))
    .map(k => [k, true]),
) as TrackerSettings

export const allTrackersOff = Object.fromEntries(
  Object.keys(AppTracker)
    .filter(Number)
    .filter(k => !trackersForPartnerSettingsOnly.some(key => Number(key) === Number(k)))
    .map(k => [k, false]),
) as TrackerSettings

export const allPartnerTrackersOn = Object.fromEntries(
  Object.keys(AppTracker)
    .filter(Number)
    .filter(k => !excludedTrackersForPartnerSettings.some(key => Number(key) === Number(k)))
    .map(k => [k, true]),
) as PartnerTrackerSettings

export const allPartnerTrackersOff = Object.fromEntries(
  Object.keys(AppTracker)
    .filter(Number)
    .filter(k => !excludedTrackersForPartnerSettings.some(key => Number(key) === Number(k)))
    .map(k => [k, false]),
) as PartnerTrackerSettings

export function appSettingsTMtoFM(settings?: AppSettings | null): AppSettingsFM {
  settings ||= { enabledTrackers: [], enabledPartnerTrackers: [] }

  const { enabledTrackers } = settings
  const enabledPartnerTrackers = settings.enabledPartnerTrackers || []

  const trackers = Object.fromEntries(enabledTrackers.map(v => [v, v]))
  const partnerTrackers = Object.fromEntries(enabledPartnerTrackers.map(v => [v, v]))

  return {
    ..._omit(settings, ['enabledTrackers', 'enabledPartnerTrackers']),
    trackers: {
      [AppTracker.MENS_QUANTITY]: !!trackers[AppTracker.MENS_QUANTITY],
      [AppTracker.CM_CONSISTENCY]: !!trackers[AppTracker.CM_CONSISTENCY],
      [AppTracker.CM_QUANTITY]: !!trackers[AppTracker.CM_QUANTITY],
      [AppTracker.LIBIDO]: !!trackers[AppTracker.LIBIDO],
      [AppTracker.PAIN]: !!trackers[AppTracker.PAIN],
      [AppTracker.SKIN]: !!trackers[AppTracker.SKIN],
      [AppTracker.MOOD]: !!trackers[AppTracker.MOOD],
      [AppTracker.MORE_SEX]: !!trackers[AppTracker.MORE_SEX],
      [AppTracker.SLEEP]: !!trackers[AppTracker.SLEEP],
    },
    partnerTrackers: {
      [AppTracker.LIBIDO]: !!partnerTrackers[AppTracker.LIBIDO],
      [AppTracker.PAIN]: !!partnerTrackers[AppTracker.PAIN],
      [AppTracker.SKIN]: !!partnerTrackers[AppTracker.SKIN],
      [AppTracker.MOOD]: !!partnerTrackers[AppTracker.MOOD],
      [AppTracker.NOTES]: !!partnerTrackers[AppTracker.NOTES],
      [AppTracker.SEX]: !!partnerTrackers[AppTracker.SEX],
      [AppTracker.LH_TEST]: !!partnerTrackers[AppTracker.LH_TEST],
      [AppTracker.PREG_TEST]: !!partnerTrackers[AppTracker.PREG_TEST],
      [AppTracker.SLEEP]: !!partnerTrackers[AppTracker.SLEEP],
    },
  }
}
