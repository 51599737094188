import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AppPreloadingStrategy } from '@app/app.preloading.strategy'
import { QuizPage } from './cnst/quiz-pages.cnst'
import { canActivateQuiz } from './guards/can.activate.quiz'
import { BestTimeInCyclePage } from './pages/quiz/best-time-in-cycle/best-time-in-cycle.page.component'
import { KnowPregnantDaysPage } from './pages/quiz/know-pregnant-days/know-pregnant-days.page.component'
import { KnowingYourSexDrivePage } from './pages/quiz/knowing-your-sex-drive/knowing-your-sex-drive.page.component'
import { LearnAboutSexInfoPage } from './pages/quiz/learn-about-sex-info/learn-about-sex-info.page.component'
import { MenstrualCycleInfoPage } from './pages/quiz/menstrual-cycle-info/menstrual-cycle-info.page.component'
import { SexDriveInfoPage } from './pages/quiz/sex-drive-info/sex-drive-info.page.component'
import { SexFrequencyPage } from './pages/quiz/sex-frequency/sex-frequency.page.component'
import { TestimonialInfoPage } from './pages/quiz/testimonial-info/testimonial-info.page.component'

const routes: Routes = [
  {
    path: 'quiz',
    canActivateChild: [canActivateQuiz],
    children: [
      {
        path: '',
        redirectTo: QuizPage.privacy,
        pathMatch: 'full',
      },
      {
        path: QuizPage.privacy,
        loadComponent: () =>
          import('./pages/quiz/privacy/privacy.page').then(m => m.QuizPrivacyPage),
        data: { page: QuizPage.privacy },
      },
      {
        path: QuizPage.name,
        loadComponent: () =>
          import('./pages/quiz/questions/name/name.page').then(m => m.QuizNamePage),
        data: { page: QuizPage.name },
      },
      {
        path: QuizPage.usps,
        loadComponent: () => import('./pages/quiz/usps/usps.page').then(m => m.QuizUspsPage),
        data: { page: QuizPage.usps },
      },
      {
        path: QuizPage.birthday,
        loadComponent: () =>
          import('./pages/quiz/questions/birthday/birthday.page').then(m => m.QuizBirthdayPage),
        data: { page: QuizPage.birthday },
      },
      // section 2
      {
        path: QuizPage.fertilityGoal,
        loadComponent: () =>
          import('./pages/quiz/questions/fertility-goal/fertility-goal.page').then(
            m => m.FertilityGoalPage,
          ),
        data: { page: QuizPage.fertilityGoal },
      },
      {
        path: QuizPage.fertilityGoalInfo,
        loadComponent: () =>
          import('./pages/quiz/fertility-goal-info/fertility-goal-info.page').then(
            m => m.FertilityGoalInfoPage,
          ),
        data: { page: QuizPage.fertilityGoalInfo },
      },
      // section 2 -> prevent
      {
        path: QuizPage.birthControlMethod,
        loadComponent: () =>
          import('./pages/quiz/questions/birth-control-method/birth-control-method.page').then(
            m => m.BirthControlMethodPage,
          ),
        data: { page: QuizPage.birthControlMethod },
      },
      {
        path: QuizPage.birthControlMethodInfo,
        loadComponent: () =>
          import('./pages/quiz/birth-control-method-info/birth-control-method-info.page').then(
            m => m.BirthControlMethodInformationPage,
          ),
        data: { page: QuizPage.birthControlMethodInfo },
      },
      {
        path: QuizPage.sideEffects,
        loadComponent: () =>
          import('./pages/quiz/questions/side-effects/side-effects.page').then(
            m => m.SideEffectsPage,
          ),
        data: { page: QuizPage.sideEffects },
      },
      {
        path: QuizPage.sideEffectsInfo,
        loadComponent: () =>
          import('./pages/quiz/side-effects-info/side-effects-info.page').then(
            m => m.SideEffectsInfoPage,
          ),
        data: { page: QuizPage.sideEffectsInfo },
      },

      // // section 2 -> plan
      {
        path: QuizPage.plannedPregnancyTiming,
        loadComponent: () =>
          import(
            './pages/quiz/questions/planned-pregnancy-timing/planned-pregnancy-timing.page'
          ).then(m => m.PlannedPregnancyTimingPage),
        data: { page: QuizPage.plannedPregnancyTiming },
      },
      {
        path: QuizPage.learnAboutSexInfo,
        component: LearnAboutSexInfoPage,
        data: { page: QuizPage.learnAboutSexInfo },
      },
      {
        path: QuizPage.bestTimeInCycle,
        component: BestTimeInCyclePage,
        data: { page: QuizPage.bestTimeInCycle },
      },
      {
        path: QuizPage.knowingYourSexDrive,
        component: KnowingYourSexDrivePage,
        data: { page: QuizPage.knowingYourSexDrive },
      },
      {
        path: QuizPage.sexDriveInfo,
        component: SexDriveInfoPage,
        data: { page: QuizPage.sexDriveInfo },
      },
      {
        path: QuizPage.sexFrequency,
        component: SexFrequencyPage,
        data: { page: QuizPage.sexFrequency },
      },
      {
        path: QuizPage.knowPregnantDays,
        component: KnowPregnantDaysPage,
        data: { page: QuizPage.knowPregnantDays },
      },
      {
        path: QuizPage.menstrualCycleInfo,
        component: MenstrualCycleInfoPage,
        data: { page: QuizPage.menstrualCycleInfo },
      },
      {
        path: QuizPage.testimonialInfo,
        component: TestimonialInfoPage,
        data: { page: QuizPage.testimonialInfo },
      },

      // section 3 ( cycle)
      {
        path: QuizPage.hormonalBirthControlUsage,
        loadComponent: () =>
          import(
            './pages/quiz/questions/hormonal-birth-control-usage/hormonal-birth-control-usage.page'
          ).then(m => m.HormonalBirthControlUsagePage),
        data: { page: QuizPage.hormonalBirthControlUsage },
      },
      {
        path: QuizPage.hormonalBirthControlHowLongPlan,
        loadComponent: () =>
          import('./pages/quiz/questions/hormonal-bc-how-long/hormonal-bc-how-long.page').then(
            m => m.HowLongHormonalBcPage,
          ),
        data: { page: QuizPage.hormonalBirthControlHowLongPlan },
      },
      {
        path: QuizPage.hormonalBirthControlHowLongPrevent,
        loadComponent: () =>
          import('./pages/quiz/questions/hormonal-bc-how-long/hormonal-bc-how-long.page').then(
            m => m.HowLongHormonalBcPage,
          ),
        data: { page: QuizPage.hormonalBirthControlHowLongPrevent },
      },
      {
        path: QuizPage.plannedPregnancyInfo,
        loadComponent: () =>
          import('./pages/quiz/planned-pregnancy-info/planned-pregnancy-info.page').then(
            m => m.PlannedPregnancyInfoPage,
          ),
        data: { page: QuizPage.plannedPregnancyInfo },
      },
      {
        path: QuizPage.cycleLength,
        loadComponent: () =>
          import('./pages/quiz/questions/cycle-length/cycle-length.page').then(
            m => m.CycleLengthPage,
          ),
        data: { page: QuizPage.cycleLength },
      },
      {
        path: QuizPage.periodRegularity,
        loadComponent: () =>
          import('./pages/quiz/questions/period-regularity/period-regularity.page').then(
            m => m.PeriodRegularityPage,
          ),
        data: { page: QuizPage.periodRegularity },
      },
      {
        path: QuizPage.periodRegularityInfo,
        loadComponent: () =>
          import('./pages/quiz/period-regularity-info/period-regularity-info.page').then(
            m => m.PeriodRegularityInfoPage,
          ),
        data: { page: QuizPage.periodRegularityInfo },
      },
      {
        path: QuizPage.cycleStartDate,
        loadComponent: () =>
          import('./pages/quiz/questions/cycle-start-date/cycle-start-date.page').then(
            m => m.CycleStartDatePage,
          ),
        data: { page: QuizPage.cycleStartDate },
      },
      {
        path: QuizPage.medicalConditionsPlan,
        loadComponent: () =>
          import('./pages/quiz/questions/medical-conditions/medical-conditions.page').then(
            m => m.MedicalConditionsPage,
          ),
        data: { page: QuizPage.medicalConditionsPlan },
      },
      {
        path: QuizPage.medicalConditionsPrevent,
        loadComponent: () =>
          import('./pages/quiz/questions/medical-conditions/medical-conditions.page').then(
            m => m.MedicalConditionsPage,
          ),
        data: { page: QuizPage.medicalConditionsPrevent },
      },
      {
        path: QuizPage.medicalConditionsInfo,
        loadComponent: () =>
          import('./pages/quiz/medical-conditions-info/medical-conditions-info.page').then(
            m => m.MedicalConditionsInfoPage,
          ),
        data: { page: QuizPage.medicalConditionsInfo },
      },
      {
        path: QuizPage.doctorInvolvement,
        loadComponent: () =>
          import('./pages/quiz/questions/doctor-involvement/doctor-involvement.page').then(
            m => m.DoctorInvolvementPage,
          ),
        data: { page: QuizPage.doctorInvolvement },
      },
      {
        path: QuizPage.partnerInvolvement,
        loadComponent: () =>
          import('./pages/quiz/questions/partner-involvement/partner-involvement.page').then(
            m => m.PartnerInvolvementPage,
          ),
        data: { page: QuizPage.partnerInvolvement },
      },
      {
        path: QuizPage.partnerInvolvementInfo,
        loadComponent: () =>
          import('./pages/quiz/partner-involvement-info/partner-involvement-info.page').then(
            m => m.PartnerInvolvementInfoPage,
          ),
        data: { page: QuizPage.partnerInvolvementInfo },
      },
      {
        path: QuizPage.followPregnancyInfo,
        loadComponent: () =>
          import('./pages/quiz/follow-pregnancy-info/follow-pregnancy-info.page').then(
            m => m.FollowPregnancyInfoPage,
          ),
        data: { page: QuizPage.followPregnancyInfo },
      },
      // // section 4 (cycleChanges )
      {
        path: QuizPage.emotionalChangesInfo,
        loadComponent: () =>
          import('./pages/quiz/emotional-changes-info/emotional-changes-info.page').then(
            m => m.EmotionalChangesInfoPage,
          ),
        data: { page: QuizPage.emotionalChangesInfo },
      },
      {
        path: QuizPage.cycleChanges,
        loadComponent: () =>
          import('./pages/quiz/questions/cycle-changes/main/cycle-changes.page').then(
            m => m.CycleChangesPage,
          ),
        data: { page: QuizPage.cycleChanges },
      },
      {
        path: QuizPage.cycleChangesEmotions,
        loadComponent: () =>
          import('./pages/quiz/questions/cycle-changes/sub/cycle-changes-emotions.page').then(
            m => m.CycleChangesEmotionsPage,
          ),
        data: { page: QuizPage.cycleChangesEmotions },
      },
      {
        path: QuizPage.cycleChangesBleeding,
        loadComponent: () =>
          import('./pages/quiz/questions/cycle-changes/sub/cycle-changes-bleeding.page').then(
            m => m.CycleChangesBleedingPage,
          ),
        data: { page: QuizPage.cycleChangesBleeding },
      },
      {
        path: QuizPage.cycleChangesSkin,
        loadComponent: () =>
          import('./pages/quiz/questions/cycle-changes/sub/cycle-changes-skin.page').then(
            m => m.CycleChangesSkinPage,
          ),
        data: { page: QuizPage.cycleChangesSkin },
      },
      {
        path: QuizPage.cycleChangesSexAndLibido,
        loadComponent: () =>
          import('./pages/quiz/questions/cycle-changes/sub/cycle-changes-sex-and-libido.page').then(
            m => m.CycleChangesSexAndLibidoPage,
          ),
        data: { page: QuizPage.cycleChangesSexAndLibido },
      },
      {
        path: QuizPage.cycleChangesCervicalMucus,
        loadComponent: () =>
          import('./pages/quiz/questions/cycle-changes/sub/cycle-changes-cervical-mucus.page').then(
            m => m.CycleChangesCervicalMucusPage,
          ),
        data: { page: QuizPage.cycleChangesCervicalMucus },
      },
      {
        path: QuizPage.cycleChangesPain,
        loadComponent: () =>
          import('./pages/quiz/questions/cycle-changes/sub/cycle-changes-pain.page').then(
            m => m.CycleChangesPainPage,
          ),
        data: { page: QuizPage.cycleChangesPain },
      },
      {
        path: QuizPage.trackingInfo,
        loadComponent: () =>
          import('./pages/quiz/tracking-info/tracking-info.page').then(m => m.TrackingInfoPage),
        data: { page: QuizPage.trackingInfo },
      },
      // section 5 (routine )
      {
        path: QuizPage.naturalMethod,
        loadComponent: () =>
          import('./pages/quiz/natural-method/natural-method.page').then(m => m.NaturalMethodPage),
        data: { page: QuizPage.naturalMethod },
      },
      {
        path: QuizPage.yourMeasuringDevice,
        loadComponent: () =>
          import('./pages/quiz/measuring-device/measuring-device.page').then(
            m => m.MeasuringDeviceInfoPage,
          ),
        data: { page: QuizPage.yourMeasuringDevice },
      },
      {
        path: QuizPage.yourDescription,
        loadComponent: () =>
          import('./pages/quiz/questions/your-description/your-description.page').then(
            m => m.YourDescriptionPage,
          ),
        data: { page: QuizPage.yourDescription },
      },
      {
        path: QuizPage.howDoYouSleep,
        loadComponent: () =>
          import('./pages/quiz/questions/how-do-you-sleep/how-do-you-sleep.page').then(
            m => m.HowDoYouSleepPage,
          ),
        data: { page: QuizPage.howDoYouSleep },
      },
      {
        path: QuizPage.sleepWearable,
        loadComponent: () =>
          import('./pages/quiz/questions/sleep-wearable/sleep-wearable.page').then(
            m => m.SleepWearablePage,
          ),
        data: { page: QuizPage.sleepWearable },
      },
      {
        path: QuizPage.whichWearable,
        loadComponent: () =>
          import('./pages/quiz/questions/which-wearable/which-wearable.page').then(
            m => m.WhichWearablePage,
          ),
        data: { page: QuizPage.whichWearable },
      },
      {
        path: QuizPage.consideringPurchase,
        loadComponent: () =>
          import('./pages/quiz/questions/considering-purchase/considering-purchase.page').then(
            m => m.ConsideringPurchasePage,
          ),
        data: { page: QuizPage.consideringPurchase },
      },
      {
        path: QuizPage.eligibleAppleWatch,
        loadComponent: () =>
          import('./pages/quiz/questions/eligible-apple-watch/eligible-apple-watch.page').then(
            m => m.EligibleAppleWatchPage,
          ),
        data: { page: QuizPage.eligibleAppleWatch },
      },
      {
        path: QuizPage.purchaseNewWatch,
        loadComponent: () =>
          import('./pages/quiz/questions/purchase-new-watch/purchase-new-watch.page').then(
            m => m.PurchaseNewWatchPage,
          ),
        data: { page: QuizPage.purchaseNewWatch },
      },
      {
        path: QuizPage.wearYourWatchToBed,
        loadComponent: () =>
          import(
            './pages/quiz/questions/wear-your-watch-to-bed/wear-your-watch-to-bed.page.component'
          ).then(m => m.WearYourWatchToBedPage),
        data: { page: QuizPage.wearYourWatchToBed },
      },
      {
        path: QuizPage.fitResult,
        loadComponent: () =>
          import('./pages/quiz/fit-result-info/fit-result-info.page').then(
            m => m.FitResultInfoPage,
          ),
        data: { page: QuizPage.fitResult },
      },
      {
        path: QuizPage.deviceResult,
        loadComponent: () =>
          import('./pages/quiz/device-result-info/device-result-info.page').then(
            m => m.DeviceResultInfoPage,
          ),
        data: { page: QuizPage.deviceResult },
      },
    ],
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: AppPreloadingStrategy }),
    // RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class QuizRoutingModule {}
