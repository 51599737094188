import { inject, Injectable } from '@angular/core'
import { NavController } from '@ionic/angular'
import { _objectAssign, _objectKeys, StringMap } from '@naturalcycles/js-lib'
import { getState, StoreService } from '@src/app/srv/store.service'
import { InfoTemplate } from './_templates/info/flow-info.page'
import {
  FlowBasePageTemplate,
  FlowConfig,
  FlowId,
  FlowInputData,
  FlowPage,
  FlowTemplate,
} from './flow.cnst'

@Injectable({ providedIn: 'root' })
export abstract class FlowService<T = StringMap> {
  protected abstract CONFIG: Map<keyof T, (input: FlowInputData<T>) => FlowBasePageTemplate>
  protected abstract INFO_SCREENS: Map<
    keyof T,
    (input: FlowInputData<T>) => InfoTemplate | undefined
  >
  protected abstract FLOW_ID: FlowId

  protected navController = inject(NavController)
  private storeService = inject(StoreService)

  public resetKey(key: keyof T): void {
    void this.saveData({ [key]: undefined } as any) // TODOOOO
  }

  public async saveAndGetNextConfig(
    data: FlowInputData<T>,
    key?: keyof T,
    override = false,
  ): Promise<FlowPage | undefined> {
    await this.saveData(data, override)

    const infoScreen = key && this.getInfoScreen(key)

    if (infoScreen) {
      return {
        route: `flow/${this.FLOW_ID}/${FlowTemplate.INFO}/${infoScreen.analyticsPageName}`,
        config: infoScreen,
      }
    }

    const config = this.getNextConfig()

    if (config) return config
  }

  protected async saveData(data: FlowInputData<T>, override = false): Promise<void> {
    const { flowData } = getState()

    const sideEffects: FlowInputData<T> = {}

    // run side effects for each key in inputted data
    _objectKeys(data).forEach(k => {
      const input = override ? data : { ...flowData, ...data }
      _objectAssign(sideEffects, this._processSideEffects(k, input))
    })

    const update = {
      ...data,
      ...sideEffects,
      flowId: this.FLOW_ID,
    }

    if (override) {
      this.storeService.dispatch('setFlowData', update)
    } else {
      this.storeService.dispatch('extendFlowData', update)
    }
  }

  public _processSideEffects(key: keyof T, input: FlowInputData<T>): FlowInputData<T> {
    const getConfigFn = key && this.CONFIG.get(key)

    const sideEffects = getConfigFn?.(input).sideEffects?.(input) || {}

    return sideEffects
  }

  /**
   * Returns an info screen to show based on the input.
   */
  private getInfoScreen(key: keyof T): FlowConfig | undefined {
    const flowData = getState().flowData as T

    return this.INFO_SCREENS.get(key)?.(flowData)
  }

  protected getNextConfig(): FlowPage | undefined {
    const flowData = getState().flowData as T

    const [key, getConfigFn] =
      [...this.CONFIG.entries()].find(([key]) => {
        if (flowData[key] === undefined) return true
      }) || []

    if (!key || !getConfigFn) return

    const config = getConfigFn(flowData)

    return {
      route: `flow/${this.FLOW_ID}/${config.template}/${String(key)}`,
      key: String(key),
      config,
    }
  }
}
