import { Router } from '@angular/router'
import { GATTCharacteristicUuid, GATTServiceUuid } from '@app/cnst/hardware.cnst'
import { ROUTES } from '@app/cnst/nav.cnst'
import { BluetoothService } from '@app/srv/bluetooth.service'
import { di } from '@app/srv/di.service'
import { bootstrapDone } from '@app/srv/milestones'
import { dispatch, getState, select2 } from '@app/srv/store.service'
import { T3_CHARACTERISTIC_UUID, T3_SERVICE_UUID } from '@app/srv/t3.cnst'
import {
  BleClientInterface,
  BleDevice,
  InitializeOptions,
  numbersToDataView,
  RequestBleDeviceOptions,
  ScanResult,
  textToDataView,
  TimeoutOptions,
} from '@capacitor-community/bluetooth-le'
import { localTime, LocalTimeInput, pDelay } from '@naturalcycles/js-lib'
import { HardwareId } from '@naturalcycles/shared'
import { env } from '@src/environments/environment'
import { BehaviorSubject, Subject } from 'rxjs'
import { BluetoothPluginMockState } from './bluetoothPluginMock.reducer'

/* eslint-disable no-bitwise */

const BLUETOOTH_DEVICE_MOCK: Record<
  HardwareId.UEBE_THERMOMETER | HardwareId.T3_THERMOMETER,
  BleDevice
> = {
  [HardwareId.UEBE_THERMOMETER]: {
    deviceId: '12:34:56:78:90:AB',
    name: 'NC Thermometer1',
  },
  [HardwareId.T3_THERMOMETER]: {
    deviceId: '12:34:56:78:90:AB',
    name: 'TC_1234567890AB',
  },
}

enum TemperatureSyncState {
  BEGIN = 1,
  TEMPERATURE = 2,
  END = 3,
}

export const getBleDeviceMock = (): BleDevice => {
  const url = di.get(Router).url
  if (url === ROUTES.T3PairingPage) {
    return BLUETOOTH_DEVICE_MOCK[HardwareId.T3_THERMOMETER]
  }
  if (url === ROUTES.UebePairingPage) {
    return BLUETOOTH_DEVICE_MOCK[HardwareId.UEBE_THERMOMETER]
  }

  const {
    account: { hwId },
    hwDevice,
  } = getState()
  if (!hwDevice) return BLUETOOTH_DEVICE_MOCK[hwId]

  const deviceId = hwDevice.mac
  const name = hwDevice.name || BLUETOOTH_DEVICE_MOCK[hwId]?.name
  return { deviceId, name }
}

/**
 * Mock implementation of BleClientInterface.
 * Intended to be used for T3 testing as of now but can be extended to T2 if needed.
 */
// TODO should we add the rest of the methods?
// class BleClient implements BleClientInterface {
class BleClientMock implements Partial<BleClientInterface> {
  private _disconnected$ = new Subject<void>()
  private _disconnectCallback: ((...params: any) => void) | undefined

  private _temperatureSyncState$ = new BehaviorSubject<TemperatureSyncState>(
    TemperatureSyncState.BEGIN,
  )

  // Data controls
  private bluetoothMock: BluetoothPluginMockState | undefined

  constructor() {
    if (!env.mockBluetooth) return

    this._disconnected$.subscribe(() => {
      this._disconnectCallback?.()
      console.warn('BleClientMock called onDisconnect callback!')
      this._disconnectCallback = undefined
      console.warn('BleClientMock reset onDisconnect callback')
    })

    void bootstrapDone.then(() => {
      select2(s => s.bluetoothPluginMock).subscribe(bluetoothMock => {
        this.bluetoothMock = bluetoothMock
      })

      select2(s => s.bluetoothPluginMock?.thermometerAdvertising).subscribe(
        thermometerAdvertising => {
          if (thermometerAdvertising) return

          this._disconnected$.next()
          this.log('disconnected$.next() as thermometerAdvertising was set to false!')
        },
      )
    })
  }

  public async initialize(options?: InitializeOptions): Promise<void> {
    this.log('initialize called!\n', 'options:', options)
    this.log('initialize returned nothing')
    return
  }

  public async isEnabled(): Promise<boolean> {
    this.log('isEnabled called!')

    const value = true
    this.log('isEnabled resolved with value:', value)
    return await Promise.resolve(value)
  }

  public async startEnabledNotifications(callback: (value: boolean) => void): Promise<void> {
    this.log('startEnabledNotifications called!')

    const value = true
    this.log('startEnabledNotifications calling callback with value:', value)
    callback(value)
  }

  public async requestLEScan(
    options: RequestBleDeviceOptions,
    callback: (result: ScanResult) => void,
  ): Promise<void> {
    this.log('requestLEScan called!\n', 'options:', options)

    const timeout = 5000
    this.log(`requestLEScan waiting ${timeout}ms`)
    await pDelay(timeout)

    // Return as thermometer is not advertising
    if (!this.bluetoothMock?.thermometerAdvertising) {
      this.log('requestLEScan recurrently called itself as thermometer is not advertising')
      return await this.requestLEScan(options, callback)
    }
    const mockedDevice: ScanResult = { device: getBleDeviceMock() }

    this.log('requestLEScan calling callback with value:', mockedDevice)
    callback(mockedDevice)
  }

  public async stopLEScan(): Promise<void> {
    this.log('stopLEScan called!')
    this.log('stopLEScan returned nothing')
    return
  }

  public async getConnectedDevices(services: string[]): Promise<BleDevice[]> {
    if (!this.bluetoothMock) throw new Error('[BleClientMock] BluetoothMockState not set!')
    this.log('getConnectedDevices called!\n', 'services:', services)
    if (!services.length) {
      this.log('getConnectedDevices returned empty array as no services provided')
      return []
    }

    if (!this.bluetoothMock.thermometerAdvertising) {
      this.log('getConnectedDevices returned empty array as thermometer is not advertising')
      return []
    }

    const mockedBleDevice = getBleDeviceMock()
    this.log('getConnectedDevices returned with value:', [mockedBleDevice])
    return [mockedBleDevice]
  }

  public async connect(
    deviceId: string,
    onDisconnect?: (deviceId: string) => void,
    options?: TimeoutOptions,
  ): Promise<void> {
    this.log('connect called!\n', `deviceId: ${deviceId}, `, 'options:', options)
    console.warn('BleClientMock saved onDisconnect callback')
    this._disconnectCallback = onDisconnect

    return
  }

  public async disconnect(deviceId: string): Promise<void> {
    this.log('disconnect called!\n', `deviceId: ${deviceId}`)
    dispatch('extendBluetoothPluginMock', { thermometerAdvertising: false })

    this.log('disconnect returned nothing')
    return
  }

  public async read(
    deviceId: string,
    service: string,
    characteristic: string,
    options?: TimeoutOptions,
  ): Promise<DataView> {
    if (!this.bluetoothMock) throw new Error('[BleClientMock] BluetoothMockState not set!')
    this.log(
      'read called!\n',
      `deviceId: ${deviceId}, `,
      `service: ${service}, `,
      `characteristic: ${characteristic}, `,
      'options:',
      options,
    )

    const timeout = Math.min(100, (options?.timeout || 200) * 0.5)
    this.log(`read waiting ${timeout}ms`)
    await pDelay(timeout)

    const { account } = getState()

    switch (service) {
      case T3_SERVICE_UUID.DEVICE: {
        switch (characteristic) {
          case T3_CHARACTERISTIC_UUID.LOGS: {
            const timeout = 500
            this.log(`requestLEScan waiting ${timeout}ms`)
            await pDelay(timeout)

            const { value } = this._temperatureSyncState$

            if (value === TemperatureSyncState.BEGIN) {
              this._temperatureSyncState$.next(value + this.bluetoothMock.hasUnsyncedTemp)
            } else if (value === TemperatureSyncState.TEMPERATURE) {
              this._temperatureSyncState$.next(TemperatureSyncState.END)
            } else dispatch('extendBluetoothPluginMock', { hasUnsyncedTemp: 2 })

            if (value !== TemperatureSyncState.TEMPERATURE) {
              return numbersToDataView([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
            }

            // TODO should we ask user for number of measurements and temperature values?
            // TODO
            /**
             * 0-3: time
             * 4-5: temperature
             * 6: algorithm
             * 7-8: wait for rise duration
             * 9-10: analysis duration
             * 11-12: prediction duration
             * 13-14: predicted temperature
             * 15: algorithm fail reason
             * 16: sync-success screen
             */
            const timeNow = this.getT3Timestamp()
            return numbersToDataView([
              ...timeNow,
              160,
              145,
              4,
              30,
              0,
              239,
              0,
              239,
              0,
              59,
              112,
              6,
              4,
              0,
            ])
          }

          case T3_CHARACTERISTIC_UUID.MEASUREMENT_STATUS: {
            // TODO
            /**
             * 0: state
             * 1-2: latest temperature
             */
            return numbersToDataView([])
          }

          case T3_CHARACTERISTIC_UUID.DIAGNOSTIC_DATA: {
            // TODO
            /**
             * 0-1: Successful power on counter
             * 2-3: RTC sync counter
             * 4-5: User config changed counter
             * 6-7: Predictive measurement success counter
             * 8-9: MaxHold measurement success counter
             * 10-11: Measurement failed counter(Too low)
             * 12-13: Measurement failed counter(Too high)
             * 14-15: FW error counter
             * 16-17: HW error counter
             * 18-19: Calibration offset
             * 20-21: Calibration gain * 30’000
             * 22: NTC group
             * 23: Algorithm ID
             * 24-25: 4.12.0+ Latest wake up voltage
             * 26-27: 4.12.0+ Average battery voltage
             * 28-49: RFU - ignored
             */
            return numbersToDataView([
              204, 0, 52, 1, 226, 4, 185, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 236, 250, 213, 120, 0, 4,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ])
          }

          case T3_CHARACTERISTIC_UUID.SERIAL_NUMBER: {
            return textToDataView('serialNumberMock')
          }

          case T3_CHARACTERISTIC_UUID.DEVICE_STATUS: {
            // TODO
            const timeNow = this.getT3Timestamp()
            return numbersToDataView([
              ...timeNow,
              5,
              224,
              2,
              this.bluetoothMock.hasUnsyncedTemp,
              0,
              5,
            ])
          }

          case T3_CHARACTERISTIC_UUID.COMMANDS: {
            throw new Error('TODO') // TODO
          }

          default: {
            throw new Error(
              `[BleClientMock] read: no mocked value found for service: ${service}, characteristic: ${characteristic} (${T3_CHARACTERISTIC_UUID[characteristic]})`,
            )
          }
        }
      }

      case GATTServiceUuid.DEVICE_INFORMATION:
      case T3_SERVICE_UUID.DEVICE_INFORMATION: {
        if (
          [
            T3_CHARACTERISTIC_UUID.FIRMWARE_REVISION,
            GATTCharacteristicUuid.FIRMWARE_REVISION,
          ].includes(characteristic as GATTCharacteristicUuid)
        ) {
          const { hwDevice } = getState()
          let fwVersion: string
          // Use existing hwDevice.fwVersion if available and not forced to be mocked
          if (!this.bluetoothMock.mockFWVersion && hwDevice?.fwVersion) {
            fwVersion = hwDevice.fwVersion
          } else fwVersion = this.bluetoothMock.fwVersion
          return textToDataView(fwVersion)
        }

        throw new Error(
          `[BleClientMock] read: no mocked value found for service: ${service}, characteristic: ${characteristic} (${T3_CHARACTERISTIC_UUID[characteristic]})`,
        )
      }

      case T3_SERVICE_UUID.USER_CONFIG: {
        switch (characteristic) {
          case T3_CHARACTERISTIC_UUID.BRIGHTNESS: {
            return numbersToDataView([128, 100, 0, 0]) // TODO or?
          }

          case T3_CHARACTERISTIC_UUID.UI_CONFIG: {
            // TODO [settings, language] check settings format as it's complicated
            // TODO how to match lang/units?
            return numbersToDataView([14, 0])
          }

          case T3_CHARACTERISTIC_UUID.USER_NAME: {
            return textToDataView(account.name1 ?? '')
          }

          default: {
            throw new Error(
              `[BleClientMock] read: no mocked value found for service: ${service}, characteristic: ${characteristic} (${T3_CHARACTERISTIC_UUID[characteristic]})`,
            )
          }
        }
      }

      default: {
        throw new Error(`[BleClientMock] read: service not supported: ${service}`)
      }
    }
  }

  public async write(
    deviceId: string,
    service: string,
    characteristic: string,
    value: DataView,
    options?: TimeoutOptions,
  ): Promise<void> {
    this.log(
      'write called!\n',
      `deviceId: ${deviceId}, `,
      `service: ${service}, `,
      `characteristic: ${characteristic}, `,
      'value:',
      value,
      'options:',
      options,
    )
    this.log('write returned nothing')
    return
  }

  public async writeWithoutResponse(
    deviceId: string,
    service: string,
    characteristic: string,
    value: DataView,
    options?: TimeoutOptions,
  ): Promise<void> {
    this.log(
      'writeWithoutResponse called!\n',
      `deviceId: ${deviceId}, `,
      `service: ${service}, `,
      `characteristic: ${characteristic}, `,
      'value:',
      value,
      'options:',
      options,
    )
    this.log('writeWithoutResponse returned nothing')
    return
  }

  public async startNotifications(
    deviceId: string,
    service: string,
    characteristic: string,
    _callback: (value: DataView) => void,
  ): Promise<void> {
    this.log(
      'startNotifications called!\n',
      `deviceId: ${deviceId}, `,
      `service: ${service}, `,
      `characteristic: ${characteristic}`,
    )

    // TODO: what should we do with the callback?
    return
  }

  public async stopNotifications(
    deviceId: string,
    service: string,
    characteristic: string,
  ): Promise<void> {
    this.log(
      'stopNotifications called!\n',
      `deviceId: ${deviceId}`,
      `service: ${service}`,
      `characteristic: ${characteristic}`,
    )
    this.log('stopNotifications returned nothing')
    return
  }

  private log(...args: any[]): void {
    const prettyString = args
      .map(a => (typeof a === 'string' ? a : JSON.stringify(a, null, 2) + ', '))
      .join('')
    di.get(BluetoothService).log('[BleClientMock] ' + prettyString)
  }

  private getT3Timestamp(timestamp?: LocalTimeInput): number[] {
    const localTimestamp = localTime
      .orNow(timestamp)
      .plus(localTime.now().getUTCOffsetMinutes(), 'minute').unix

    return [
      localTimestamp & 255,
      (localTimestamp >> 8) & 255,
      (localTimestamp >> 16) & 255,
      (localTimestamp >> 24) & 255,
    ]
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const BluetoothClientMock = new BleClientMock()
