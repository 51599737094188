import {
  FormatWidth,
  FormStyle,
  getLocaleDateFormat,
  getLocaleDayNames,
  getLocaleMonthNames,
  getLocaleTimeFormat,
  TranslationWidth,
} from '@angular/common'
import { Injectable } from '@angular/core'
import { getState } from '@app/srv/store.service'
import { _range, StringMap, UnixTimestampNumber } from '@naturalcycles/js-lib'
import { LANG } from '@naturalcycles/shared'
import { dayjs, IDayjs } from '@naturalcycles/time-lib'
import { AndroidDatePicker } from '@src/typings/capacitor'

export interface Weekday {
  fullname: string
  shortname: string
  isoNumber: number
}

export enum DateFormat {
  SHORT = 'SHORT',
  MEDIUM = 'MEDIUM',
  MEDIUM_WITH_TIME = 'MEDIUM_WITH_TIME',
  LONG = 'LONG',
  FULL = 'FULL',
  MONTH_YEAR = 'MONTH_YEAR',
  WEEKDAY = 'WEEKDAY',
  WEEKDAY_SHORT = 'WEEKDAY_SHORT',
  WEEKDAY_DATE = 'WEEKDAY_DATE',
  WEEKDAY_DATE_LONG = 'WEEKDAY_DATE_LONG',
  DAY_MONTH = 'DAY_MONTH',
  DAY_MONTH_SHORT = 'DAY_MONTH_SHORT',
  DAY_MONTH_TIME = 'DAY_MONTH_TIME',
  TIME = 'TIME',
}

const dateFormatMap: Record<DateFormat, Intl.DateTimeFormatOptions> = {
  [DateFormat.SHORT]: {
    day: 'numeric',
    month: 'numeric',
    year: '2-digit',
  },
  [DateFormat.MEDIUM]: {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  },
  [DateFormat.MEDIUM_WITH_TIME]: {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  [DateFormat.LONG]: {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  },
  [DateFormat.FULL]: {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  [DateFormat.MONTH_YEAR]: {
    year: 'numeric',
    month: 'long',
  },
  [DateFormat.WEEKDAY]: {
    weekday: 'long',
  },
  [DateFormat.WEEKDAY_SHORT]: {
    weekday: 'short',
  },
  [DateFormat.WEEKDAY_DATE]: {
    weekday: 'long',
    day: 'numeric',
    month: 'short',
  },
  [DateFormat.WEEKDAY_DATE_LONG]: {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
  },
  [DateFormat.DAY_MONTH]: {
    day: 'numeric',
    month: 'long',
  },
  [DateFormat.DAY_MONTH_SHORT]: {
    day: 'numeric',
    month: 'short',
  },
  [DateFormat.DAY_MONTH_TIME]: {
    day: 'numeric',
    month: 'short',
    hour: 'numeric',
    minute: 'numeric',
  },
  [DateFormat.TIME]: {
    hour: 'numeric',
    minute: 'numeric',
  },
}

let dateService: DateService

// shortcut
export function localizeDate(date: IDayjs, format = DateFormat.MEDIUM, locale?: string): string {
  return dateService.localizeDate(date, format, locale)
}

// [localeName + DateFormat_ID] => DateTimeFormat
const dateTimeFormatCache: StringMap<Intl.DateTimeFormat> = {}

@Injectable({ providedIn: 'root' })
export class DateService {
  constructor() {
    dateService = this
  }

  public getWeekdays(locale: string, firstDayOfWeek?: number): Weekday[] {
    const longNames = getLocaleDayNames(locale, FormStyle.Standalone, TranslationWidth.Wide)
    const shortNames = getLocaleDayNames(locale, FormStyle.Standalone, TranslationWidth.Abbreviated)

    const weekdays: Weekday[] = longNames.map((value, index) => {
      return {
        fullname: value,
        shortname: shortNames[index]!,
        isoNumber: index === 0 ? 7 : index,
      }
    })

    if (firstDayOfWeek === 7) {
      // Sunday, do nothing
    } else if (firstDayOfWeek === 6) {
      // Saturday, move saturday to beginning
      weekdays.unshift(weekdays.pop()!)
    } else {
      // Monday, move sunday to end
      weekdays.push(weekdays.shift()!)
    }

    return weekdays
  }

  public getAbbreviatedMonths(): string[] {
    return this._getMonths(TranslationWidth.Abbreviated)
  }

  public getMonths(): string[] {
    return this._getMonths(TranslationWidth.Wide)
  }

  /**
   * When you want to localize a date that doesnt depend on timezone (calendar dates)
   */
  public localizeDate(date: IDayjs, format = DateFormat.MEDIUM, locale?: string): string {
    const offset = date.utcOffset()
    const _date = dayjs.utc(date).add(offset, 'minute')

    return this._localizeDate(_date, format, true, locale)
  }

  /**
   * When you want to localize a time dependant date
   */
  public localizeDateTime(date: IDayjs, format = DateFormat.MEDIUM, locale?: string): string {
    return this._localizeDate(date, format, false, locale)
  }

  private _localizeDate(
    _date: IDayjs,
    format = DateFormat.MEDIUM,
    timezoneless = true,
    locale?: string,
  ): string {
    locale ||= getState().ui.locale

    const date = _date.toDate()

    // es-US date locales dont support short month formats for some reason
    // example:
    // new Date().toLocaleDateString('es-US', { day: 'numeric', month: 'short', year: 'numeric' })
    // outputs: 13 de diciembre de 2019
    // new Date().toLocaleDateString('es-ES', { day: 'numeric', month: 'short', year: 'numeric' })
    // outputs: 13 dic. 2019
    if (locale === LANG.es_US) locale = 'es-ES'

    // return dateObj.toLocaleDateString(locale, format)
    const output = (dateTimeFormatCache[locale + format] ||= new Intl.DateTimeFormat(locale, {
      ...dateFormatMap[format],
      timeZone: timezoneless ? 'UTC' : undefined,
      calendar: 'gregory',
    })).format(date)

    return output
  }

  /**
   *  `'M/D/YY'` (e.g. `6/15/15`)
   */
  public getShortDateFormat(): string {
    return this.getDateFormat(FormatWidth.Short)
  }

  /**
   *  `'MMM D, YYYY'` (e.g. `Jun 15, 2015`)
   */
  public getMediumDateFormat(): string {
    return this.getDateFormat(FormatWidth.Medium)
  }

  /**
   *  `'MMMM D, YYYY'` (e.g. `June 15, 2015`)
   */
  public getLongDateFormat(): string {
    return this.getDateFormat(FormatWidth.Long)
  }

  /**
   *  `'DDDD, MMMM D, YYYY'` (e.g. `Monday, June 15, 2015`)
   */
  public getFullDateFormat(): string {
    return this.getDateFormat(FormatWidth.Full)
  }

  public getTimeFormat(width: FormatWidth = FormatWidth.Short): string {
    const { locale } = getState().ui
    return getLocaleTimeFormat(locale, width)
  }

  public async getTimeOnAndroid(time: string): Promise<string> {
    return await new Promise(resolve => {
      const currentTime = this.getDateFromTime(time)
      void AndroidDatePicker.showTimePicker({
        hour: currentTime.hour(),
        minute: currentTime.minute(),
      }).then(date => {
        if (!date) return resolve(time)

        const dayjsDate = dayjs().set('hour', date.hour).set('minute', date.minute)
        const selectedTime = dayjsDate.format('HH:mm')

        resolve(selectedTime)
      })
    })
  }

  public getDateFromTime(time: string): IDayjs {
    const [hour, minute] = time.split(':')
    const date = dayjs().set('hour', parseInt(hour!)).set('minute', parseInt(minute!))
    return date
  }

  private _getMonths(translationWidth: TranslationWidth): string[] {
    const { locale } = getState().ui
    return [...getLocaleMonthNames(locale, FormStyle.Standalone, translationWidth)]
  }

  private getDateFormat(width: FormatWidth): string {
    const { locale } = getState().ui
    let format = getLocaleDateFormat(locale, width)

    format = format.replace(/EEEE/g, 'DDDD') // Fix Weekday
    format = format.replace(/d/g, 'D') // Fix Date

    if (format.includes('yy')) {
      format = format.replace(/yy/g, 'YY') // Fix short year
    } else if (format.includes('y')) {
      format = format.replace(/y/g, 'YYYY') // Fix long year
    }

    format = format.replace(/'De'/g, 'de')

    return format
  }

  public appTimestampToT3Timestamp(): UnixTimestampNumber {
    return dayjs().add(dayjs().utcOffset(), 'minute').unix()
  }

  public t3TimestampToAppTimestamp(timestamp: UnixTimestampNumber): UnixTimestampNumber {
    return dayjs.unix(timestamp).subtract(dayjs().utcOffset(), 'minute').unix()
  }

  public getDaysRange(start: IDayjs, end: IDayjs): IDayjs[] {
    const diff = end.diff(start, 'day')
    return _range(0, diff + 1).map(i => start.add(i, 'day'))
  }
}
