import { CommonModule } from '@angular/common'
import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { loader, LoaderType } from '@app/decorators/decorators'
import { BaseModal } from '@app/pages/base.modal'
import { HardwareDevice } from '@app/reducers/hardwareDevice.reducer'
import { SharedModule } from '@app/shared.module'
import { BluetoothService, SyncedTemperature } from '@app/srv/bluetooth.service'
import { HardwareDeviceService } from '@app/srv/hardwareDevice.service'
import { PopupController, Priority } from '@app/srv/popup.controller'
import { dispatch, getState, select2 } from '@app/srv/store.service'
import {
  T3_SCREEN_BRIGHTNESS_MODIFIER,
  T3Algo,
  T3BatteryLevel,
  T3DeviceState,
  T3DeviceStatus,
  T3MeasurementState,
} from '@app/srv/t3.cnst'
import { T3Service } from '@app/srv/t3.service'
import { Clipboard } from '@capacitor/clipboard'
import { AlertInput, IonicModule, IonRange } from '@ionic/angular'
import { _numberEnumKeys, _range, IsoDateString, localDate, localTime } from '@naturalcycles/js-lib'
import {
  BatteryStatus,
  FWVersion,
  FWVersionSeverity,
  HardwareId,
  LANG,
} from '@naturalcycles/shared'
import { env } from '@src/environments/environment'
import { T3 } from '@src/typings/capacitor'
import { map } from 'rxjs'

@Component({
  selector: 'page-debug-t3',
  standalone: true,
  imports: [IonicModule, SharedModule, CommonModule],
  templateUrl: './debug-t3.page.html',
})
export class DebugT3Page extends BaseModal implements OnInit, OnDestroy {
  className = 'DebugT3Page'

  protected override blockPopups = false

  private bluetoothService = inject(BluetoothService)
  private hardwareDeviceService = inject(HardwareDeviceService)
  private popupController = inject(PopupController)
  private t3Service = inject(T3Service)

  @ViewChild(IonRange)
  private brightnessRange!: IonRange

  public backlight$ = select2(s => s.hwDevice?.backlight)
  public buzzer$ = select2(s => s.hwDevice?.buzzer)
  public bluetoothMock$ = select2(s => s.bluetoothPluginMock).pipe(
    map(mock => (env.mockBluetooth ? mock : undefined)),
  )

  public T3DeviceState = T3DeviceState
  public T3BatteryLevel = T3BatteryLevel
  public temperatures: SyncedTemperature[] = []
  public bluetoothEnabled$ = this.bluetoothService.bluetoothEnabled$
  public connected$ = this.bluetoothService.connected$
  public hardwareDevice$ = this.hardwareDeviceService.hardwareDevice$
  public logs$ = this.bluetoothService.logs$

  public native = window.Capacitor.isNativePlatform()
  public initialized = false
  public brightness = 0
  public rangeChanged = false
  public deviceStatus?: T3DeviceStatus

  public LANG = LANG

  public async ngOnInit(): Promise<void> {
    this.subscriptions.push(
      this.bluetoothService.temperatures$.subscribe(temperatures => {
        this.temperatures.unshift(...temperatures)
      }),

      this.connected$.subscribe(async connected => {
        if (!connected) {
          this.deviceStatus = undefined
          return
        }

        const { mac } = getState().hwDevice || {}
        if (!mac) return

        void this.getDeviceStatus(mac)
        void this.getBrightnessConfig(mac)
      }),
    )
  }

  public enableBluetooth(): void {
    void this.bluetoothService.enableBluetooth(true)
  }

  @loader(LoaderType.BUTTON)
  public async scanAndPair(): Promise<void> {
    const initialized = await this.bluetoothService.initBluetooth('T3Debug')

    if (!initialized) return

    await this.bluetoothService.ensureBluetoothEnabled()

    const id = await this.bluetoothService.scanAndPair(HardwareId.T3_THERMOMETER)

    if (!id) return
    const serialNumber = await this.t3Service.getSerialNumber(id)

    const settings: HardwareDevice = {
      mac: id,
      serialNumber,
      buzzer: true,
      backlight: false,
      testMeasurementDone: false,
      nfcMode: false,
      screenBrightness: 75,
      leftHandMode: false,
      created: localTime.now().unix,
    }

    await this.hardwareDeviceService.saveHardwareDevice(settings, HardwareId.T3_THERMOMETER)

    await this.t3Service.setAllSettings(settings)
  }

  public fakePair(): void {
    void this.hardwareDeviceService.saveHardwareDevice(
      {
        mac: '00:00:00:00:00:00',
        serialNumber: 'fakeSerialNumber',
        buzzer: true,
        backlight: false,
        testMeasurementDone: false,
        fwVersion: '4.10.2',
        created: localTime.now().unix,
      },
      HardwareId.T3_THERMOMETER,
    )
  }

  public clearLogs(): void {
    this.temperatures = []
    this.logs$.next([])
  }

  public async unpair(mac: string): Promise<void> {
    await this.hardwareDeviceService.clearHardwareDevice(mac)
  }

  public mockTemperature(): void {
    const temp = prompt('Enter temperature or leave empty to get random')
    const value = temp ? Number(temp) : undefined
    const date: IsoDateString =
      prompt("Enter date as YYYY-mm-dd or leave empty to use today's date") ||
      localDate.todayString()

    this.t3Service.mockTemperatures([{ value, date }])
    void this.dismissModal()
  }

  public mockMulti(): void {
    const counter = _range(Math.floor(Math.random() * 6) + 4)
    const temperatures = counter.map(index => {
      const date = localDate.today().minus(index, 'day').toISODate()
      return { date }
    })

    this.t3Service.mockTemperatures(temperatures)
    void this.dismissModal()
  }

  public mockMultiSameDay(): void {
    const counter = _range(Math.floor(Math.random() * 6) + 2)
    const temperatures = counter.flatMap(index => {
      const date = localDate.today().minus(index, 'day').toISODate()
      const dateCounter = _range(Math.floor(Math.random() * 3) + 1)
      return dateCounter.map(() => ({ date }))
    })

    this.t3Service.mockTemperatures(temperatures)
    void this.dismissModal()
  }

  public async setBatteryStatus(): Promise<void> {
    const inputs = _numberEnumKeys(BatteryStatus)
      .filter(label => label !== 'MEDIUM')
      .map(label => {
        return {
          type: 'radio',
          label,
          value: BatteryStatus[label],
        }
      }) as AlertInput[]

    await this.popupController.presentAlert(
      {
        header: 'Set battery status',
        inputs,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: 'Save',
            handler: value => {
              this.hardwareDeviceService.saveBatteryStatus(value)
            },
          },
        ],
      },
      'alert-t3-algo',
      Priority.IMMEDIATE,
    )
  }

  @loader(LoaderType.BUTTON)
  public async setUsername(id: string): Promise<void> {
    const alert = await this.popupController.presentAlert(
      {
        header: 'Change username',
        inputs: [
          {
            name: 'username',
            placeholder: 'Username',
          },
        ],
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: 'Save',
            handler: ev => {
              void this.t3Service.setUsername(id, ev.username)
            },
          },
        ],
      },
      'alert-t3-username',
      Priority.IMMEDIATE,
    )

    await alert.onDidDismiss()
  }

  @loader(LoaderType.BUTTON)
  public async factoryReset(id: string): Promise<void> {
    await this.t3Service.factoryReset(id)
  }

  public onRangeChange(event: any): void {
    this.rangeChanged = event.detail.value !== this.brightness
  }

  public async setAlgo(id: string): Promise<void> {
    const inputs = _numberEnumKeys(T3Algo).map(label => {
      return {
        type: 'radio',
        label,
        value: T3Algo[label],
      }
    }) as AlertInput[]

    await this.popupController.presentAlert(
      {
        header: 'Select algorithm',
        inputs,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: 'Save',
            handler: value => {
              void this.t3Service.setAlgo(id, value)
            },
          },
        ],
      },
      'alert-t3-algo',
      Priority.IMMEDIATE,
    )
  }

  public async setBacklight(id: string, event: CustomEvent): Promise<void> {
    const { checked } = event.detail

    await this.t3Service.setBacklight(id, checked)

    await this.hardwareDeviceService.extendHardwareDevice({ backlight: checked })
  }

  public async setSound(id: string, event: CustomEvent): Promise<void> {
    const { checked } = event.detail

    await this.t3Service.setSound(id, checked)

    await this.hardwareDeviceService.extendHardwareDevice({ buzzer: checked })
  }

  public async setDateFormat(id: string, event: CustomEvent): Promise<void> {
    const { value } = event.detail
    await this.t3Service.setDateFormat(id, value)
  }

  public async setTemperatureUnits(id: string, event: CustomEvent): Promise<void> {
    const { value } = event.detail
    await this.t3Service.setTempUnits(id, !!value)
  }

  public async setLanguage(id: string, event: CustomEvent): Promise<void> {
    const { value } = event.detail
    await this.t3Service.setLanguage(id, value)
  }

  public async setBrightnessConfig(id: string): Promise<void> {
    const value = Number(this.brightnessRange.value)

    await this.t3Service.setScreenBrightness(id, value / T3_SCREEN_BRIGHTNESS_MODIFIER)

    this.brightness = value
  }

  private async getBrightnessConfig(id: string): Promise<void> {
    const config = await this.t3Service.getBrightnessConfig(id)

    this.brightness = config.screen
  }

  public async getMeasurementStatus(id: string): Promise<void> {
    const status = await this.t3Service.getMeasurementStatus(id)

    if (!status) return

    alert(`Status: ${T3MeasurementState[status.state]}\nTemperature: ${status.temperature}`)
  }

  public async getFirmwareVersion(id: string): Promise<void> {
    await this.t3Service.getFirmwareVersion(id)
  }

  public async getLogs(id: string): Promise<void> {
    await this.t3Service.getTemperatureLogs(id)
  }

  private async getDeviceStatus(id: string): Promise<void> {
    this.deviceStatus = await this.t3Service.getDeviceStatus(id)
  }

  public async performFOTA(id: string): Promise<void> {
    await this.t3Service.performFOTA(id)
  }

  public async cancelFOTA(): Promise<void> {
    await T3.abortFOTA()
  }

  public mockFirmwareVersion(): void {
    const fwVersion = prompt('Enter mock FW version (X.XX.X)', '4.12.1')
    dispatch('setHardwareDevice', { ...getState().hwDevice, fwVersion })
  }

  public mockCriticalFWUpdate(): void {
    const releaseDate =
      prompt('Enter mock FW version release date (YYYY-mm-dd)') || localDate.todayString()
    dispatch('mockAvailableFWVersion', {
      version: '4.11.1',
      releaseDate,
      severity: FWVersionSeverity.CRITICAL,
    } as FWVersion)
    void this.dismissModal()
  }

  public async copyLogs(): Promise<void> {
    await Clipboard.write({
      // eslint-disable-next-line id-blacklist
      string: this.bluetoothService.logs$.value.join('\n'),
    })
  }

  /**
   * Bluetooth Mock section
   */
  public toggleThermometerAdvertisingBleMock(): void {
    dispatch('extendBluetoothPluginMock', {
      thermometerAdvertising: !getState().bluetoothPluginMock?.thermometerAdvertising,
    })
  }

  public toggleUnsyncedTempBleMock(): void {
    dispatch('extendBluetoothPluginMock', { hasUnsyncedTemp: 1 })
  }

  public toggleFWVersionMockBleMock(): void {
    dispatch('extendBluetoothPluginMock', {
      mockFWVersion: !getState().bluetoothPluginMock?.mockFWVersion,
    })
  }

  public setFWVersionBleMock(): void {
    const fwVersion = prompt('Enter firmware version (X.XX.X)', '4.12.1') || '4.12.1'
    dispatch('extendBluetoothPluginMock', { fwVersion })
  }
  // =========================================================================
}
