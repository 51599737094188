import { Pipe, PipeTransform } from '@angular/core'
import { di } from '@app/srv/di.service'
import { getState } from '@app/srv/store.service'
import { UnitsService } from '@app/srv/units.service'
import { TemperatureC } from '../cmp/temperature-numpad/temperature-numpad.service'

@Pipe({
  name: 'temperature',
})
export class TemperaturePipe implements PipeTransform {
  transform(temperature: number | undefined, showUnit = true, decimals = 2): string {
    const { fahrenheit } = getState().account
    const unit = fahrenheit ? '°F' : '°C'

    if (!temperature) {
      const separator = di.get(UnitsService).getNumberSeparator()
      return `––${separator}––${showUnit ? unit : ''}`
    }

    if (fahrenheit && temperature >= TemperatureC.MIN && temperature <= TemperatureC.MAX) {
      temperature = this.convertTempToFahrenheit(temperature)
    }

    const { locale } = getState().ui

    const tempString = temperature.toLocaleString(locale, {
      style: 'decimal',
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    })

    return `${tempString}${showUnit ? unit : ''}`
  }

  private convertTempToFahrenheit(v: number): number {
    if (v > 50) return v // already F
    return v * 1.8 + 32
  }
}
