import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { SharedModule } from '@app/shared.module'
import { IonicModule } from '@ionic/angular'
import { FeedbackModal } from './feedback.modal'

@NgModule({
  declarations: [FeedbackModal],
  imports: [IonicModule, CommonModule, SharedModule, ReactiveFormsModule],
  exports: [FeedbackModal],
})
export class FeedbackModalModule {}
