import { Pipe, PipeTransform } from '@angular/core'
import { tr } from '@app/srv/translation.util'

@Pipe({
  name: 'tr',
  pure: true,
})
export class TrPipe implements PipeTransform {
  transform(key: string): string {
    return tr(key)
  }
}
