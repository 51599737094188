<ion-header>
  <app-quiz-header />
</ion-header>

<ion-content>
  <div class="infoPageContent">
    <video
      [class.infoPageContent__video___visible]="isVideoVisible"
      class="infoPageContent__video"
      #trackerVideo
      autoplay
      playsinline
      muted
      loop
      (loadedmetadata)="onVideoLoaded()"
    >
      <source
        src="https://assets.naturalcycles.com/videos/menstrual-cycle-video-crop.mp4"
        type="video/mp4"
      />
    </video>
  </div>
</ion-content>

<ion-footer>
  <div class="infoPageFooter">
    <h1 class="infoPageFooter__title">
      {{ 'txt-quiz-menstrual-cycle' | tr }}
    </h1>
    <ion-button
      color="purple"
      expand="block"
      shape="round"
      [attr.aria-label]="'get-pregnant-faster' | tr"
      [attr.uid]="page + '__footerButton'"
      (click)="nextPage()"
    >
      <ion-label>
        {{ 'get-pregnant-faster' | tr }}
      </ion-label>
    </ion-button>
  </div>
</ion-footer>
