import { storage3Service } from '@app/srv/storage3/storage3.service'
import { DeviceUserSettings, dispatch, USER_SETTINGS } from '@app/srv/store.service'
import { StringMap } from '@naturalcycles/js-lib'
import { AccountTM, BackendResponseFM } from '@naturalcycles/shared'
import { Action, Reducer } from 'redux'
import { Subject } from 'rxjs'
import { mergeMap } from 'rxjs/operators'

type State = Partial<AccountTM>

const initialState: State = {}

const actions: any = {
  extendAccount(state: State, action: { type: string; payload: Partial<AccountTM> }): State {
    return {
      ...state,
      ...action.payload,
    }
  },

  onBackendResponse(state: State, action: { type: string; payload: BackendResponseFM }): State {
    const { account } = action.payload

    // replace!
    if (account) {
      // account changed
      if (state.id !== account.id) {
        void storage3Service
          .getItem<StringMap<DeviceUserSettings>>(USER_SETTINGS, true, true)
          .then(userSettings => {
            if (userSettings?.[account.id]) {
              updateUserSettings.next(userSettings[account.id]!)
            }
          })
      }

      return {
        ...action.payload.account,
      }
    }

    return state
  },
}

// biome-ignore lint/style/useDefaultParameterLast: ok
export const accountReducer: Reducer<State> = (state = initialState, action: Action) => {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}

const updateUserSettings = new Subject<DeviceUserSettings>()

updateUserSettings
  .pipe(
    mergeMap(async deviceUserSettings => {
      const { userSettings, notifications } = deviceUserSettings

      dispatch('extendUserSettings', userSettings)
      dispatch('extendNotifications', notifications)
    }, 1),
  )
  .subscribe()
