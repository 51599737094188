import { Component } from '@angular/core'
import { IllustrationComponent } from '@app/cmp/illustration/illustration.component'
import { BaseModal } from '@app/pages/base.modal'
import { SharedModule } from '@app/shared.module'
import { IonicModule } from '@ionic/angular'

@Component({
  selector: 'app-fresh-battery-modal',
  templateUrl: './fresh-battery.modal.html',
  styleUrls: ['./fresh-battery.modal.scss'],
  standalone: true,
  imports: [IonicModule, SharedModule, IllustrationComponent],
})
export class FreshBatteryModal extends BaseModal {
  public className = 'FreshBatteryModal'
}
