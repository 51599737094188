import { Component, forwardRef, inject } from '@angular/core'
import { BaseModal } from '@app/pages/base.modal'
import { SharedModule } from '@app/shared.module'
import { IonicModule } from '@ionic/angular'
import { BiometricAuthService, biometryType } from '@src/app/srv/biometricAuth.service'

@Component({
  standalone: true,
  imports: [forwardRef(() => SharedModule), IonicModule],
  selector: 'app-biometric-auth-permission-revoked-modal',
  templateUrl: './biometric-auth-permission-revoked.modal.html',
})
export class BiometricAuthPermissionRevokedModal extends BaseModal {
  public className = 'BiometricAuthPermissionRevokedModal'
  public biometryType = biometryType

  private biometricAuthService = inject(BiometricAuthService)

  public async continue(): Promise<void> {
    await this.dismissModal(true)
    this.biometricAuthService.onResume$.next()
  }
}
