<!-- TODO: 2024-06-01 remove uid once we got rid of TestCafe as this value is not unique in the DOM -->
<ion-button
  uid="addedTempDisplay__btn"
  class="addedTempDisplay addedTempDisplay__btn"
  size="small"
  color="layer"
  (click)="onClick()"
>
  <!-- TODO: 2024-06-01 remove uid too -->
  <ion-label
    uid="addedTempDisplay__label"
    class="addedTempDisplay__label"
    [class.deviated]="deviated"
    >{{ temperature | temperature }}</ion-label
  >
</ion-button>
