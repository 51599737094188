import { PercentPipe } from '@angular/common'
import { inject, Injectable, Renderer2 } from '@angular/core'
import { promiseNoZone, setTimeoutNoZone } from '@app/util/zone.util'
import { IonContent } from '@ionic/angular'
import { StringMap } from '@naturalcycles/js-lib'
import { isE2e } from '@src/environments/env.util'
import { EVENT } from '../analytics/analytics.cnst'
import { AnalyticsService } from '../analytics/analytics.service'
import { TemperaturePipe } from '../pipes/temperature.pipe'
import { StatisticsService } from './statistics/statistics.service'
import { getState, select2 } from './store.service'
import { tr } from './translation.util'

export interface Tooltip {
  linkElement: HTMLElement
  targetElement: IonContent
  text: string
  key: string
  posY: number
  arrowPosition: number
  alignment: 'left' | 'right'
  position: 'top' | 'bottom'
  size?: 'small' | 'large'
  color?: string
}

// these tooltips are only referred to from other strings, adding them here to make the unused translations script detect them!
// 'tooltip-avg-cycle-length',
// 'tooltip-avg-cycle-stats',
// 'tooltip-cycle-summary-ovulation-detected',
// 'tooltip-cycle-summary-ovulation-not-detected',
// 'tooltip-follicular-phase-length',
// 'tooltip-luteal-phase-length',
// 'tooltip-my-cycle-common-trackers',
// 'tooltip-pregnancy-end-date',
// 'tooltip-protected-sex',
// 'tooltip-unprotected-sex',

@Injectable({ providedIn: 'root' })
export class TooltipService {
  private analyticsService = inject(AnalyticsService)
  private statisticsService = inject(StatisticsService)

  private measureStreak$ = select2(s => s.measureStreak)

  private renderedTooltips: StringMap<{ tooltip: any; parent: any }> = {}

  private tooltipParams: StringMap<any> = {
    'tooltip-average-follicular-temperature': { temperature: 36.23 },
    'tooltip-average-luteal-temperature': { temperature: 36.58 },
    'tooltip-pregnancy-temperature': { temperature: 36.64 },
    // eslint-disable-next-line id-blacklist
    'tooltip-cycle-length': { number: 30 },
    // eslint-disable-next-line id-blacklist
    'tooltip-period-length-old': { number: 4 },
    // eslint-disable-next-line id-blacklist
    'tooltip-ovulation-average': { number: 18 },
  }

  public init(): void {
    this.statisticsService.statisticsData$.subscribe(data => {
      const { min, max } = data.minMaxCycleLength || {}
      const { earliest, latest } = data.earliestLatestOvulationDay || {}
      const myAverage = data.averageValues.averageDaysPerCycle?.percentageValue

      this.tooltipParams = {
        ...this.tooltipParams,
        'tooltip-shortest-cycle-6-cycles': { days: min },
        'tooltip-longest-cycle-6-cycles': { days: max },
        // eslint-disable-next-line id-blacklist
        'tooltip-earliest-ovulation': { number: earliest },
        // eslint-disable-next-line id-blacklist
        'tooltip-latest-ovulation': { number: latest },
        'tooltip-average-green-days-old': { myAverage, allAverage: 0.61 },
        'tooltip-average-red-days-old': { myAverage, allAverage: 0.32 },
      }
    })

    this.measureStreak$.subscribe(({ current, longest, lastStreakLength }) => {
      this.tooltipParams = {
        ...this.tooltipParams,
        'tooltip-longest-streak-days-in-row': {
          days: Math.max(current, longest, lastStreakLength),
        },
        'tooltip-measuring-streak-days-in-row': { days: current },
      }
    })
  }

  public getContainer(element: any): IonContent {
    let last = element

    const elements = []

    do {
      elements.push(last)
      last = last.parentNode as HTMLElement
    } while (last)

    const scrollElement: IonContent = elements.find(el => {
      return el.classList && el.localName === 'ion-content'
    })

    return scrollElement
  }

  public async createTooltip(
    target: IonContent,
    link: HTMLElement,
    positionX: number,
    key: string,
    _params?: StringMap,
    position?: 'top' | 'bottom',
    size?: 'small' | 'large',
    color?: string,
  ): Promise<Tooltip> {
    const scrollElement = await target.getScrollElement()
    const targetRect = this.getRect(scrollElement)
    const clickableRect = this.getRect(link)

    position ||= clickableRect.top - targetRect.top < 100 ? 'bottom' : 'top'

    const offsetLeft = Math.max(positionX, 24)
    const offsetRight = Math.max(targetRect.width - offsetLeft, 24)
    const alignment = offsetLeft < offsetRight ? 'left' : 'right'
    const arrowPosition = alignment === 'left' ? offsetLeft : offsetRight

    const params = _params || this.getPipedParams(key)

    // todo: position correct in ncFrame

    const tooltip: Tooltip = {
      linkElement: link,
      targetElement: target,
      text: tr(key, params),
      posY: clickableRect.top + scrollElement.scrollTop - targetRect.top,
      position,
      alignment,
      arrowPosition,
      key,
      size,
      color,
    }

    return tooltip
  }

  private getPipedParams(key: string): StringMap {
    const temperaturePipe = new TemperaturePipe()
    const percentPipe = new PercentPipe(getState().ui.locale)

    Object.keys(this.tooltipParams).forEach(key => {
      const tooltipParams = this.tooltipParams[key]

      Object.keys(tooltipParams).forEach(param => {
        if (typeof tooltipParams[param] !== 'number') return

        if (param === 'temperature') {
          this.tooltipParams[key][param] = temperaturePipe.transform(tooltipParams[param], true)
        }

        if (param === 'myAverage' || param === 'allAverage') {
          this.tooltipParams[key][param] = percentPipe.transform(tooltipParams[param])
        }
      })
    })

    return { ...this.tooltipParams[key] }
  }

  private getRect(el: HTMLElement): {
    top: number
    width: number
    height: number
  } {
    const rect = el.getBoundingClientRect()
    const scrollTop = window.pageYOffset || (document as any).documentElement.scrollTop
    return {
      top: rect.top + scrollTop,
      width: rect.width,
      height: rect.height,
    }
  }

  public async renderTooltip(tTip: Tooltip, renderer: Renderer2, closeTimeout = 0): Promise<void> {
    if (isE2e) return

    const tooltipParent = tTip.targetElement

    // create elements
    const tooltip = renderer.createElement('div')
    const inner = renderer.createElement('div')
    const arrow = renderer.createElement('span')
    const textEl = renderer.createText(tTip.text)

    // add class names
    renderer.addClass(tooltip, 'tooltip')
    renderer.addClass(tooltip, `tooltip--${tTip.position}${tTip.alignment}`)
    renderer.addClass(tooltip, `tooltip--${tTip.size}`)
    renderer.addClass(tooltip, `tooltip--${tTip.color}`)
    renderer.addClass(tooltip, 'tooltip--forceOpen')
    renderer.addClass(inner, 'tooltip__inner')
    renderer.addClass(arrow, 'tooltip__arrow')
    renderer.addClass(arrow, `tooltip__arrow--${tTip.position}`)

    // append elements
    renderer.appendChild(inner, textEl)
    renderer.appendChild(inner, arrow)
    renderer.appendChild(tooltip, inner)
    renderer.appendChild(tooltipParent, tooltip)

    let tTipTop = tTip.posY - 14

    if (tTip.position === 'bottom') {
      tTipTop = tTip.posY + this.getRect(tTip.linkElement).height + 14
    }

    renderer.setStyle(tooltip, 'top', `${tTipTop}px`)
    renderer.setStyle(tooltip, tTip.alignment, `${tTip.arrowPosition}px`)

    const tooltipRect = this.getRect(tooltip)
    let arrowPosition = tTip.arrowPosition

    if (tooltipRect.width / 2 > tTip.arrowPosition) {
      renderer.setStyle(tooltip, tTip.alignment, `${tooltipRect.width / 2}px`)
    } else {
      arrowPosition = tooltipRect.width / 2
    }
    renderer.setStyle(arrow, `margin-${tTip.alignment}`, '-5px')
    renderer.setStyle(arrow, tTip.alignment, `${arrowPosition}px`)

    renderer.addClass(tooltip, `open`)

    const tTipAndParent = {
      tooltip,
      parent: tooltipParent,
    }

    this.renderedTooltips[Date.now()] = tTipAndParent

    void this.analyticsService.trackEvent(EVENT.READ_TOOLTIP, {
      tooltip: tTip.key,
    })

    await new promiseNoZone<void>(resolve => {
      setTimeoutNoZone(() => {
        renderer.removeClass(tooltip, 'tooltip--forceOpen')
        resolve()
      }, closeTimeout)
    })
  }

  /**
   * Does not need CD (ChangeDetection).
   */
  public async removeAllTooltips(renderer: Renderer2, forceRemove = false): Promise<void> {
    const promises: Promise<any>[] = []

    Object.keys(this.renderedTooltips).forEach(key => {
      const tooltip = this.renderedTooltips[key]
      if (!tooltip) return
      if (tooltip.tooltip.classList.contains('tooltip--forceOpen') && !forceRemove) {
        return
      }

      const promise = new promiseNoZone<void>(resolve => {
        renderer.removeClass(tooltip.tooltip, 'open')
        setTimeoutNoZone(() => {
          // this.zone.run(() => {
          renderer.removeChild(tooltip.parent, tooltip.tooltip)
          delete this.renderedTooltips[key]
          resolve()
          // })
        }, 250)
      })
      promises.push(promise)
    })

    await Promise.all(promises)

    return
  }
}
