<ion-radio-group
  #radio
  class="superSegment"
  [allowEmptySelection]="true"
  (ionChange)="segmentChanged($any($event))"
>
  @if (
    {
      currentOffsets: currentOffsets$ | async,
      itemSizes: itemSizes$ | async,
    };
    as observables
  ) {
    @for (item of items; track trackByItem($index, item)) {
      <span
        #superSegmentItem
        class="superSegment__container"
        [attr.itemvalue]="item.value"
        [ngClass]="{
          'superSegment__container--checked': item.selected,
          'superSegment__container--options':
            segmentState[item.value] === SuperSegmentState.OPTIONS,
        }"
        [style.transform]="'translateX(' + observables.currentOffsets?.[item.value] + 'px)'"
        [style.transitionDuration]="initDone ? '0.2s' : '0s'"
        [style.marginBottom.px]="
          (segmentState[item.value] === SuperSegmentState.OPTIONS &&
            observables.itemSizes?.[item.value]?.optionHeight) ||
          0
        "
      >
        @if (prefixIcon && item.selected) {
          <ion-chip [color]="color" class="superSegment__prefixIcon">
            <ion-icon [src]="prefixIcon" />
          </ion-chip>
        }
        <ion-item
          [attr.uid]="item.uid"
          class="superSegment__item"
          [class.superSegment__item--checked]="item.selected"
          [class.superSegment__item--hidden]="
            segmentState[item.value] === SuperSegmentState.MIDDLE && !item.selected
          "
          [ngClass]="item.styles"
          lines="none"
          (click)="onItemClick(item)"
          [color]="color"
          [disabled]="readonly"
          haptic
        >
          <ion-radio
            [disabled]="segmentState[item.value] === SuperSegmentState.MIDDLE && !item.selected"
            class="superSegment__radio"
            slot="start"
            [value]="item.value"
          />
          @if (item.value === SuperSegmentSpecialButton.LH_SCANNER_BUTTON) {
            <ion-icon [src]="item.icon" class="superSegment__lhScannerButton" />
          }
          @if (
            selectedIcon && item.selected && segmentState[item.value] === SuperSegmentState.MIDDLE
          ) {
            <ion-icon
              class="superSegment__icon superSegment__icon--{{ item.value }}"
              slot="start"
              [src]="selectedIcon"
            />
          }
          <div #itemLabel class="superSegment__title" [attr.uid]="item.uid + '--label'">
            @if (item.selected && item.options && item.selectedOption; as option) {
              <span>{{ item.title + '--' + item.options.type[option] | tr }}</span>
            } @else {
              {{ item.title | tr }}
            }
          </div>
        </ion-item>
        @if (item.options; as options) {
          <span
            #options
            class="superSegment__options"
            [attr.itemUid]="item.uid"
            [attr.itemValue]="item.value"
            [style.maxWidth.px]="maxWidth + 1"
            [ngClass]="
              'superSegment__options--' + observables.itemSizes?.[item.value]?.optionPosition!
            "
          >
            @for (option of options.items; track trackByItem(i, option); let i = $index) {
              <ion-item
                class="superSegment__item"
                lines="none"
                [color]="color"
                [style.transform]="
                  'translateX(' +
                  (observables.itemSizes?.[item.value]?.offsets?.optionsItems || 0) +
                  'px)'
                "
                haptic
              >
                <ion-radio
                  [attr.uid]="option.uid"
                  class="superSegment__radio"
                  slot="start"
                  [value]="item.value + '--' + option.value"
                />
                <div class="superSegment__title">{{ option.title | tr }}</div>
              </ion-item>
            }
            <div class="superSegment__triangle"></div>
          </span>
        }
      </span>
    }
  }
</ion-radio-group>
