import { HardwareId } from '@naturalcycles/shared'
import { env } from '@src/environments/environment'
import { getState } from '../srv/store.service'
import { ROUTES } from './nav.cnst'

export type HardwareType = 'thermometer' | 'wearable'

export const PRODUCT_IMAGE_BY_HWID = {
  [HardwareId.ORAL_THERMOMETER]: '/assets/img/nc-thermometer-1.png',
  [HardwareId.UEBE_THERMOMETER]: '/assets/img/nc-thermometer-2.png',
  [HardwareId.T3_THERMOMETER]: '/assets/img/nc-thermometer-3.png',
}

interface HardwareDeviceConfig {
  type: HardwareType
  bluetooth?: boolean
  walkthroughRoute?: string
  pairingRoute?: string
}

export const HW_CONFIG_BY_HWID: Record<HardwareId, HardwareDeviceConfig> = {
  [HardwareId.ORAL_THERMOMETER]: {
    type: 'thermometer',
  },
  [HardwareId.UEBE_THERMOMETER]: {
    type: 'thermometer',
    bluetooth: true,
    walkthroughRoute: ROUTES.UebeWalkthroughPage,
    pairingRoute: ROUTES.UebePairingPage,
  },
  [HardwareId.T3_THERMOMETER]: {
    type: 'thermometer',
    bluetooth: true,
    walkthroughRoute: ROUTES.T3WalkthroughPage,
    pairingRoute: ROUTES.T3PairingPage,
  },
  [HardwareId.OURA]: {
    type: 'wearable',
  },
  [HardwareId.APPLE_WATCH]: {
    type: 'wearable',
  },
  [HardwareId.B1]: {
    type: 'wearable',
    bluetooth: true,
    // pairingRoute: ROUTES.B1PairingPage,
  },
}

export enum FirmwareStatus {
  LATEST = 1,
  UPDATE_AVAILABLE = 2,
  UPDATE_CRITICAL = 3,
  UPDATE_FAILED = 4,
  UPDATING = 5,
}

export const isHardwareSupportedPlatform = !env.prod || window.Capacitor.isNativePlatform()

export function usesConnectedThermometer(hwId: HardwareId = getState().account.hwId): boolean {
  if (!hwId) return false

  const config = HW_CONFIG_BY_HWID[hwId]

  return !!config.bluetooth && config.type === 'thermometer'
}

export function usesBluetoothDevice(hwId: HardwareId = getState().account.hwId): boolean {
  if (!hwId) return false

  return !!HW_CONFIG_BY_HWID[hwId].bluetooth && isHardwareSupportedPlatform
}

export enum GATTServiceUuid {
  DEVICE_INFORMATION = '0000180a-0000-1000-8000-00805f9b34fb',
}

export enum GATTCharacteristicUuid {
  MODEL_NUMBER = '00002a24-0000-1000-8000-00805f9b34fb',
  SERIAL_NUMBER = '00002a25-0000-1000-8000-00805f9b34fb',
  FIRMWARE_REVISION = '00002a26-0000-1000-8000-00805f9b34fb',
  HARDWARE_REVISION = '00002a27-0000-1000-8000-00805f9b34fb',
  MANUFACTURER = '00002a29-0000-1000-8000-00805f9b34fb',
}
