import { Pipe, PipeTransform } from '@angular/core'
import { PricePipe } from '@app/pipes/price.pipe'
import { DateFormat, DateService } from '@app/srv/date.service'
import { di } from '@app/srv/di.service'
import { markdownService } from '@app/srv/markdown.service'
import { tr } from '@app/srv/translation.util'
import { StringMap } from '@naturalcycles/js-lib'
import { Price } from '@naturalcycles/shared'
import { dayjs } from '@naturalcycles/time-lib'

@Pipe({
  name: 'tmd',
  pure: true,
})
export class MarkdownPipe implements PipeTransform {
  transform(key?: string | null, tmdParams?: StringMap<any> | null): string {
    const params: any = {}

    if (tmdParams) {
      Object.keys(tmdParams).forEach(key => {
        const value: any = tmdParams[key]

        // Localize Price and Dates
        if (value instanceof Price) {
          params[key] = new PricePipe().transform(value)
        } else if (value instanceof Date) {
          params[key] = di.get(DateService).localizeDate(dayjs(value), DateFormat.LONG)
        } else {
          params[key] = value
        }
      })
    }

    const value = tr(key!, params)
    const valueWithoutPlaceholders = value?.replace(/{{\w+}}/g, '')
    if (!valueWithoutPlaceholders) return ''
    return markdownService.parse(valueWithoutPlaceholders)
  }
}
