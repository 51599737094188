import { Component, Input, ViewChild } from '@angular/core'
import { BaseModal } from '@app/pages/base.modal'
import { IonContent, IonicModule } from '@ionic/angular'
import { ICON } from '@src/app/cnst/icons.cnst'
import { SharedModule } from '@src/app/shared.module'

export interface CtaModalInput {
  title: string
  body: string
  ctas: CtaModalButton[]
}

interface CtaModalButton {
  title: string
  id: string
  outline?: boolean
}

@Component({
  standalone: true,
  selector: 'page-cta-modal',
  imports: [IonicModule, SharedModule],
  templateUrl: './cta.modal.html',
  styleUrls: ['./cta.modal.scss'],
})
export class CtaModal extends BaseModal {
  className = 'CtaModal'

  @Input()
  public title?: string

  @Input()
  public body?: string

  @Input()
  public ctas?: CtaModalButton[]

  @ViewChild(IonContent, { static: true })
  content!: IonContent

  public ICON = ICON
}
