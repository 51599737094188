import { Component, Input } from '@angular/core'
import { BaseModal } from '@app/pages/base.modal'
import { RadioGroupValue } from '@src/app/cmp/radio-group/radio-group.component'

@Component({
  selector: 'multiple-watches-detected-modal',
  templateUrl: 'multiple-watches-detected.modal.html',
  styleUrls: ['multiple-watches-detected.modal.scss'],
})
export class MultipleWatchesDetectedModal extends BaseModal {
  className = 'MultipleWatchesDetectedModal'

  @Input()
  public deviceOptions: RadioGroupValue<string>[] = []

  public selectedHKDeviceId?: string

  public confirm(): void {
    void this.dismissModal({ preferredHKDeviceId: this.selectedHKDeviceId })
  }

  public selectPreferredHKDevice(value: string): void {
    this.selectedHKDeviceId = value
  }
}
