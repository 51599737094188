<!-- Use mode="ios" to prevent flicker when updating slides -->
@if (ufDays$ | async; as ufDays) {
  <swiper #swiper [config]="config" uid="addDataPreview__slides" mode="ios">
    @for (day of previewDays$ | async; track trackByDate(i, day); let i = $index) {
      <ng-template swiperSlide>
        <div class="addDataPreview__slide">
          <div class="addDataPreview__backdrop" (click)="dismissModal()"></div>
          <div
            class="addDataPreview__container"
            [class.addDataPreview__container--prediction]="day.isPrediction"
            on-scroll="onScroll($event)"
          >
            @if (ufDays[i]; as ufDay) {
              <div
                uid="addData__preview__content"
                class="addDataPreview__content"
                [class.addDataPreview__content--prediction]="day.isPrediction"
                [class.addDataPreview__content--past]="!day.isPrediction"
              >
                <h2>
                  <span uid="addDataPreview__date">{{ day.date }}</span>
                  @if (ufDay.cd; as cd) {
                    <span
                      >,
                      <span uid="addDataPreview__cycleDay" class="ion-text-nowrap"
                        >{{ 'txt-cycle-day' | tr }} {{ cd }}</span
                      ></span
                    >
                  }
                </h2>
                <!-- Fertility status -->
                @if (ufDay.fertilityStatus && showFertilityStatus$ | async) {
                  <div class="addDataPreview__fertilityStatus">
                    @if (!ufDay.today && ufDay.prediction) {
                      <div class="addDataPreview__contextLabel">
                        {{ 'txt-prediction-title' | tr }}
                      </div>
                    }
                    <div
                      uid="addDataPreview__fertilityStatusCircle"
                      class="addDataPreview__fertilityCircle fertColor--{{ ufDay.colorClass }}"
                      [class.addDataPreview__fertilityCircle--past]="
                        !ufDay.today && !ufDay.prediction
                      "
                      [class.addDataPreview__fertilityCircle--today]="ufDay.today"
                      [class.addDataPreview__fertilityCircle--future]="
                        !ufDay.today && ufDay.prediction
                      "
                    ></div>
                    @if (ufDay.fertilityStatus; as status) {
                      <span
                        uid="addDataPreview__fertilityStatusLabel"
                        class="addDataPreview__fertilityStatusLabel"
                        >{{ status.key | tmd: { num: status.num } | stripTags }}</span
                      >
                    }
                    @if (!ufDay.today && ufDay.prediction) {
                      <div class="addDataPreview__fertilityStatusBody">
                        {{ 'txt-prediction-body' | tr }}
                      </div>
                    }
                    @if ((!ufDay.prediction || ufDay.today) && ufDay.color === UFColor.YELLOW) {
                      <div class="addDataPreview__fertilityStatusBody">
                        {{ 'txt-fertility-uncertainty-body' | tr }}
                      </div>
                    }
                  </div>
                }
                @for (type of day.pregnancyEndCare; track type) {
                  <p class="addDataPreview__careType">
                    {{ 'txt-you-logged' | tr }}
                    {{ 'txt-pregnancy-end-care-type--' + PregnancyEndCareType[type] | tr }}
                  </p>
                }
                <!-- HwId change -->
                @if (ufDay.hwIdChange; as newHwId) {
                  <div>
                    <div class="addDataPreview__info">
                      <div class="addDataPreview__infoButton ion-activatable">
                        <ion-chip class="chip--iconOnly hwIdChangeIcon" size="small" color="white">
                          @if (newHwId !== HardwareId.OURA && newHwId !== HardwareId.APPLE_WATCH) {
                            <ion-icon [src]="ICON.THERMOMETER" color="purple" />
                          }
                          @if (newHwId === HardwareId.OURA) {
                            <ion-icon [src]="ICON.OURA" color="purple" />
                          }
                          @if (newHwId === HardwareId.APPLE_WATCH) {
                            <ion-icon [src]="ICON.APPLE_WATCH" color="purple" />
                          }
                        </ion-chip>
                        <p
                          class="ion-text-wrap"
                          [innerHTML]="
                            ('txt-hwid-change-title' | tr) +
                            ' - ' +
                            ('txt-hardware-name--' + newHwId | tr)
                          "
                        ></p>
                      </div>
                      <p>
                        <span
                          [innerHTML]="
                            'txt-hwid-change-body'
                              | tmd: { hardwareName: ('txt-hardware-name--' + newHwId | tr) }
                              | stripTags
                          "
                        ></span
                        >&nbsp;
                        @if (newHwId !== HardwareId.OURA) {
                          {{ 'txt-do-not-alternate-therm' | tr }}
                        }
                      </p>
                    </div>
                  </div>
                }
                <!-- Insights -->
                @if (day.insights?.length) {
                  <div uid="addDataPreview__insights">
                    @for (insight of day.insights; track $index) {
                      <div>
                        @if (insight.tag; as tag) {
                          <div class="addDataPreview__contextLabel">
                            {{ tag | tr }}
                          </div>
                        }
                        <div class="addDataPreview__info">
                          <div
                            class="addDataPreview__infoButton ion-activatable"
                            [class.addDataPreview__infoButton--disabled]="!insight.action"
                            (click)="onActionClick(insight.action)"
                          >
                            @if (insight.icon; as icon) {
                              <ion-icon
                                slot="start"
                                [src]="icon"
                                [class.ovulationPrediction]="icon === ICON.OVULATION && ufDay.today"
                                color="prediction"
                              />
                            }
                            @if (insight.title) {
                              <p class="ion-text-wrap">{{ insight.title | tr }}</p>
                            }
                            @if (insight.action) {
                              <ion-icon slot="end" [src]="ICON.ARROW_RIGHT" />
                            }
                            <ion-ripple-effect />
                          </div>
                          <p>{{ insight.body | tr }}</p>
                        </div>
                      </div>
                    }
                  </div>
                }
                @if (ufDay) {
                  <div (click)="dismissModal(this.date)">
                    <div class="addDataPreview__data">
                      <!-- Temperature -->
                      @if (ufDay.temperature; as temp) {
                        <ion-chip
                          outline
                          color="temperature"
                          class="addDataPreview__temperature"
                          [class.addDataPreview__temperature--deviating]="day.isDeviating"
                        >
                          <ion-label uid="addDataPreview__temperatureLabel">{{
                            temp | temperature
                          }}</ion-label>
                        </ion-chip>
                      }
                      <!-- Deviation flags -->
                      @for (reason of day.deviationFlags; track reason) {
                        <ion-chip
                          [color]="ColorBySegment.DEVIATION"
                          class="chip--iconOnly"
                          [attr.uid]="'addDataPreview__' + DataFlag[reason]"
                        >
                          @if (ICON_BY_DATAFLAG[reason]; as icon) {
                            <ion-icon [src]="icon" />
                          }
                        </ion-chip>
                      }
                      <!-- Bleeding -->
                      @if (ufDay.mens; as mens) {
                        <ion-chip
                          [color]="ColorBySegment.BLEEDING"
                          [attr.uid]="
                            'preview__chip--mens--' +
                            (ufDay.mensQuantity ? DataQuantity[ufDay.mensQuantity] : Mens[mens])
                          "
                        >
                          @if (mens === Mens.SPOTTING) {
                            <ion-icon [src]="ICON.SPOTTING" />
                            <ion-label>{{ 'txt-spotting' | tr }}</ion-label>
                          }
                          @if (mens === Mens.WITHDRAWAL) {
                            @if (!ufDay.mensQuantity) {
                              <ion-icon [src]="ICON_BY_MENS[Mens.WITHDRAWAL]" />
                              @if (ufDay.mensQuantity; as mensQuantity) {
                                <ion-label>{{
                                  'txt-menstruation-withdrawal--' + DataQuantity[mensQuantity] | tr
                                }}</ion-label>
                              }
                              <ion-label>{{ 'txt-menstruation-withdrawal' | tr }}</ion-label>
                            }
                            @if (ufDay.mensQuantity; as mensQuantity) {
                              <ion-icon [src]="ICON_BY_WITHDRAWAL_QUANTITY[mensQuantity]" />
                              <ion-label>{{
                                'txt-menstruation-withdrawal--' + DataQuantity[mensQuantity] | tr
                              }}</ion-label>
                            }
                          }
                          @if (mens === Mens.MISCARRIAGE_BLEEDING) {
                            @if (!ufDay.mensQuantity) {
                              <ion-icon [src]="ICON_BY_MENS[Mens.MISCARRIAGE_BLEEDING]" />
                              @if (ufDay.mensQuantity; as mensQuantity) {
                                <ion-label>{{
                                  'txt-menstruation-miscarriage--' + DataQuantity[mensQuantity] | tr
                                }}</ion-label>
                              }
                              <ion-label>{{ 'txt-menstruation-miscarriage' | tr }}</ion-label>
                            }
                            @if (ufDay.mensQuantity; as mensQuantity) {
                              <ion-icon [src]="ICON_BY_MISCARRIAGE_QUANTITY[mensQuantity]" />
                              <ion-label>{{
                                'txt-menstruation-miscarriage--' + DataQuantity[mensQuantity] | tr
                              }}</ion-label>
                            }
                          }
                          @if (mens === Mens.POSTPARTUM_BLEEDING) {
                            @if (!ufDay.mensQuantity) {
                              <ion-icon [src]="ICON_BY_MENS[Mens.POSTPARTUM_BLEEDING]" />
                              @if (ufDay.mensQuantity; as mensQuantity) {
                                <ion-label>{{
                                  'txt-menstruation-postpartum--' + DataQuantity[mensQuantity] | tr
                                }}</ion-label>
                              }
                              <ion-label>{{ 'txt-menstruation-postpartum' | tr }}</ion-label>
                            }
                            @if (ufDay.mensQuantity; as mensQuantity) {
                              <ion-icon [src]="ICON_BY_POSTPARTUM_QUANTITY[mensQuantity]" />
                              <ion-label>{{
                                'txt-menstruation-postpartum--' + DataQuantity[mensQuantity] | tr
                              }}</ion-label>
                            }
                          }
                          @if (mens === Mens.MENSTRUATION) {
                            @if (!ufDay.mensQuantity) {
                              <ion-icon [src]="ICON.MENSTRUATION" />
                              @if (ufDay.mensQuantity; as mensQuantity) {
                                <ion-label>{{
                                  'txt-menstruation--' + DataQuantity[mensQuantity] | tr
                                }}</ion-label>
                              }
                              <ion-label>{{ 'txt-menstruation' | tr }}</ion-label>
                            }
                            @if (ufDay.mensQuantity; as mensQuantity) {
                              <ion-icon [src]="ICON_BY_MENS_QUANTITY[mensQuantity]" />
                              <ion-label>{{
                                'txt-menstruation--' + DataQuantity[mensQuantity] | tr
                              }}</ion-label>
                            }
                          }
                        </ion-chip>
                      }
                      <!-- LH test -->
                      @if (!ufDay.lhTestImageIds?.length && ufDay.lhTest; as lhTest) {
                        <ion-chip
                          [color]="ColorBySegment.LH_TEST"
                          [attr.uid]="'preview__chip--LH--' + TestResult[lhTest]"
                        >
                          <ion-icon [src]="lhTest === TestResult.YES ? ICON.LH_POS : ICON.LH_NEG" />
                          <ion-label>{{
                            (lhTest === TestResult.YES ? 'txt-positive' : 'txt-negative') | tr
                          }}</ion-label>
                        </ion-chip>
                      }
                      <!-- Preg test -->
                      @if (ufDay.pregTest; as pregTest) {
                        <ion-chip
                          [color]="
                            (goal$ | async) === Goal.PREVENT
                              ? ColorBySegment.SENSITIVE_LIGHT
                              : ColorBySegment.PREG_TEST
                          "
                          [attr.uid]="'preview__chip--pregTest--' + TestResult[pregTest]"
                        >
                          <ion-icon
                            [src]="
                              pregTest === TestResult.YES ? ICON.PREG_TEST_POS : ICON.PREG_TEST_NEG
                            "
                          />
                          <ion-label>{{
                            (pregTest === TestResult.YES ? 'txt-positive' : 'txt-negative') | tr
                          }}</ion-label>
                        </ion-chip>
                      }
                      <!-- Sex -->
                      @if (ufDay.sex !== HadSex.NO && ufDay.sex; as sex) {
                        <ion-chip
                          [color]="ColorBySegment.SEX"
                          [attr.uid]="
                            'preview__chip--sex--' +
                            (ufDay.sexType ? SexType[ufDay.sexType] : HadSex[sex])
                          "
                        >
                          <ion-icon
                            [src]="sex === HadSex.YES_PROTECTED ? ICON.SEX_SAFE : ICON.SEX"
                          />
                          <ion-label>
                            @if (ufDay.sexType; as sexType) {
                              {{
                                (sex === HadSex.YES_PROTECTED
                                  ? 'txt-protected--'
                                  : 'txt-unprotected--') + SexType[sexType] | tr
                              }}
                            } @else if (sex === HadSex.YES_PROTECTED) {
                              {{ 'txt-protected' | tr }}
                            } @else if (
                              ufDay.goal === Goal.PREVENT || ufDay.goal === Goal.POSTPARTUM
                            ) {
                              {{ 'txt-unprotected' | tr }}
                            } @else {
                              {{ ((moreSexEnabled$ | async) ? 'txt-sex-vaginal' : 'txt-yes') | tr }}
                            }
                          </ion-label>
                        </ion-chip>
                      }
                      <!-- Other sex -->
                      @for (sex of day.sexFlags; track sex) {
                        <ion-chip
                          [color]="ColorBySegment.SEX"
                          [attr.uid]="'addDataPreview__' + DataFlag[sex]"
                        >
                          @if (ICON_BY_DATAFLAG[sex]; as icon) {
                            <ion-icon [src]="icon" />
                          }
                          <ion-label>{{ 'txt-dataflag--' + DataFlag[sex] | tr }}</ion-label>
                        </ion-chip>
                      }
                      <!-- Cervical mucus -->
                      @if (day.cervicalMucusLabel) {
                        <ion-chip
                          [color]="ColorBySegment.CERVICAL_MUCUS"
                          [attr.uid]="
                            'cervicalMucus' +
                            (ufDay.cervicalMucusQuantity
                              ? '--' + DataQuantity[ufDay.cervicalMucusQuantity]
                              : '') +
                            (ufDay.cervicalMucusConsistency
                              ? '--' + CervicalMucusConsistency[ufDay.cervicalMucusConsistency]
                              : '')
                          "
                        >
                          @if (ufDay.cervicalMucusQuantity) {
                            <ion-icon [src]="ICON_BY_CM_QUANTITY[ufDay.cervicalMucusQuantity]" />
                          }
                          @if (ufDay.cervicalMucusConsistency) {
                            <ion-icon
                              [src]="ICON_BY_CM_CONSISTENCY[ufDay.cervicalMucusConsistency]"
                            />
                          }
                          <ion-label>{{ day.cervicalMucusLabel | tr }}</ion-label>
                        </ion-chip>
                      }
                      <!-- Libido -->
                      @if (day.libido; as libido) {
                        <ion-chip
                          [color]="ColorBySegment.SEX"
                          [attr.uid]="'libido--' + Libido[libido]"
                        >
                          <ion-icon [src]="ICON_BY_LIBIDO[libido]" />
                          <ion-label>{{ 'txt-libido--' + Libido[libido] | tr }}</ion-label>
                        </ion-chip>
                      }
                      <!-- Skin -->
                      @for (skin of day.skinFlags; track skin) {
                        <ion-chip
                          [color]="ColorBySegment.SKIN"
                          [attr.uid]="'addDataPreview__' + DataFlag[skin]"
                        >
                          @if (day.dataFlagIntensity && day.dataFlagIntensity[skin]) {
                            <div class="innerIntensity">
                              @for (intensity of intensities; track intensity) {
                                <span
                                  class="innerIntensity__element"
                                  ngClass="{{
                                    day.dataFlagIntensity[skin]! >= intensity &&
                                      'innerIntensity__element--active'
                                  }}"
                                ></span>
                              }
                            </div>
                          }
                          @if (ICON_BY_DATAFLAG[skin]; as icon) {
                            <ion-icon [src]="icon" />
                          }
                          <ion-label>{{ 'txt-skin--' + DataFlag[skin] | tr }}</ion-label>
                        </ion-chip>
                      }
                      <!-- Sleep -->
                      @if (day.sleep; as sleep) {
                        <ion-chip
                          [color]="ColorBySegment.SLEEP"
                          [attr.uid]="'sleep--' + Sleep[sleep]"
                        >
                          <ion-icon [src]="ICON_BY_SLEEP[sleep]" />
                          <ion-label>{{ 'txt-sleep--' + Sleep[sleep] | tr }}</ion-label>
                        </ion-chip>
                      }
                      <!-- Pain -->
                      @for (pain of day.painFlags; track pain) {
                        <ion-chip
                          [color]="
                            pain === DataFlag.PAIN_MOVEMENTS
                              ? ColorBySegment.PREGNANT
                              : ColorBySegment.PAIN
                          "
                          [attr.uid]="'addDataPreview__' + DataFlag[pain]"
                        >
                          @if (day.dataFlagIntensity && day.dataFlagIntensity[pain]) {
                            <div class="innerIntensity">
                              @for (intensity of intensities; track intensity) {
                                <span
                                  class="innerIntensity__element"
                                  ngClass="{{
                                    day.dataFlagIntensity[pain]! >= intensity &&
                                      'innerIntensity__element--active'
                                  }}"
                                ></span>
                              }
                            </div>
                          }
                          @if (ICON_BY_DATAFLAG[pain]; as icon) {
                            <ion-icon [src]="icon" />
                          }
                          <ion-label>{{ 'txt-dataflag--' + DataFlag[pain] | tr }}</ion-label>
                        </ion-chip>
                      }
                      <!-- COVID test -->
                      @if (ufDay.covidTest; as covidTest) {
                        <ion-chip
                          [color]="ColorBySegment.PAIN"
                          [attr.uid]="'preview__chip--covid--' + TestResult[covidTest]"
                        >
                          <ion-icon
                            [src]="covidTest === TestResult.YES ? ICON.COVID_POS : ICON.COVID_NEG"
                          />
                          <ion-label>{{
                            (covidTest === TestResult.YES ? 'txt-positive' : 'txt-negative') | tr
                          }}</ion-label>
                        </ion-chip>
                      }
                      <!-- COVID symptoms -->
                      @for (symptom of day.covidSymptomFlags; track symptom) {
                        <ion-chip
                          [color]="ColorBySegment.PAIN"
                          [attr.uid]="'addDataPreview__' + DataFlag[symptom]"
                        >
                          @if (ICON_BY_DATAFLAG[symptom]; as icon) {
                            <ion-icon [src]="icon" />
                          }
                          <ion-label>{{ 'txt-dataflag--' + DataFlag[symptom] | tr }}</ion-label>
                        </ion-chip>
                      }
                      <!-- Moods -->
                      @for (mood of day.moodFlags; track mood) {
                        <ion-chip
                          [color]="ColorBySegment.MOOD"
                          class="chip--iconOnly"
                          [attr.uid]="'addDataPreview__' + DataFlag[mood]"
                          [title]="'txt-dataflag--' + DataFlag[mood] | tr"
                        >
                          <ion-icon [src]="ICON_BY_DATAFLAG[mood]" />
                        </ion-chip>
                      }
                      <!-- Emergency flags -->
                      @for (symptom of day.emergencyFlags; track symptom) {
                        <ion-chip [color]="ColorBySegment.SENSITIVE_LIGHT">
                          @if (ICON_BY_DATAFLAG[symptom]; as icon) {
                            <ion-icon [src]="icon" />
                          }
                          <ion-label>{{ 'txt-dataflag--' + DataFlag[symptom] | tr }}</ion-label>
                        </ion-chip>
                      }
                      @for (id of ufDay.lhTestImageIds; track id) {
                        <app-lh-image [id]="id" />
                      }
                    </div>
                    <!-- Notes -->
                    @if (ufDay.notes; as notes) {
                      <app-notes
                        [notes]="notes"
                        [disabled]="true"
                        [attr.uid]="'addDataPreview__notes'"
                      />
                    }
                  </div>
                }
              </div>
            }
            <ion-footer class="addDataPreview__footer">
              <ion-toolbar color="transparent">
                <ion-buttons slot="end">
                  @if (!day.isPrediction && !isPartner) {
                    <ion-button
                      uid="addData__preview__editButton"
                      (click)="dismissModal(this.date)"
                      color="pink"
                    >
                      <ion-label>{{
                        (ufDays[i]?.updated ? 'btn-edit' : 'txt-add-data') | tr
                      }}</ion-label>
                    </ion-button>
                  }
                  <ion-button
                    (click)="dismissModal()"
                    [color]="day.isPrediction ? 'primary' : 'pink'"
                    uid="addData__preview__closeButton"
                  >
                    <ion-label>{{ 'txt-close' | tr }}</ion-label>
                  </ion-button>
                </ion-buttons>
              </ion-toolbar>
            </ion-footer>
          </div>
        </div>
      </ng-template>
    }
  </swiper>
}
