import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { IonicModule } from '@ionic/angular'
import { BigCircleContainerComponent } from './big-circle-container.component'

@NgModule({
  declarations: [BigCircleContainerComponent],
  imports: [IonicModule, CommonModule],
  exports: [BigCircleContainerComponent],
})
export class BigCircleContainerComponentModule {}
