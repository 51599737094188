import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { DataFlagWithIntensityToggleComponent } from '@app/cmp/data-flag-with-intensity-toggle/data-flag-with-intensity-toggle.component'
import { InfoButtonComponent } from '@app/cmp/info-button/info-button.component'
import { NotesComponent } from '@app/cmp/notes/notes.component'
import { SuperSegmentComponentModule } from '@app/cmp/super-segment/super-segment.component.module'
import { AddDataLhPreviewModalModule } from '@app/modals/add-data-lh-preview/add-data-lh-preview.modal.module'
import { SharedModule } from '@app/shared.module'
import { IonicModule } from '@ionic/angular'
import { AdditionalInfoComponent } from './add-data-additional-info.component'

@NgModule({
  declarations: [AdditionalInfoComponent],
  imports: [
    IonicModule,
    SharedModule,
    CommonModule,
    ReactiveFormsModule,
    NotesComponent,
    SuperSegmentComponentModule,
    InfoButtonComponent,
    AddDataLhPreviewModalModule,
    DataFlagWithIntensityToggleComponent,
  ],
  exports: [AdditionalInfoComponent],
})
export class AdditionalInfoComponentModule {}
