import { Pipe, PipeTransform } from '@angular/core'
import { getState } from '@app/srv/store.service'
import { Price } from '@naturalcycles/shared'

@Pipe({
  name: 'price',
})
export class PricePipe implements PipeTransform {
  transform(obj: Price | null | undefined): string {
    if (obj === null || obj === undefined) return '' // safely pipe after `| async` which is typed as ` | null` in Angular
    const { locale } = getState().ui
    return obj.toLocaleString(locale)
  }
}
