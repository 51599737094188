@if (navButtons) {
  <div>
    <ion-grid class="ion-no-padding">
      <ion-row>
        <ion-col class="pagination__col">
          <ion-button
            fill="clear"
            color="text"
            class="pagination__btn"
            [disabled]="!canGoBack"
            (click)="goPrevious()"
          >
            <ion-icon slot="icon-only" [src]="ICON.ARROW_LEFT" />
          </ion-button>
        </ion-col>

        <ion-col class="pagination__col--middle">
          <div class="pagination pagination--{{ color }}">
            <div
              class="pagination__container"
              [ngStyle]="
                dots.length > 3
                  ? { transform: 'translateX(' + -activeIndex * 16 + 'px)' }
                  : { transform: 'translateX(-16px)' }
              "
            >
              @for (dot of [1, 2, 3, 4]; track $index) {
                <div class="pagination__item pagination__item--mock"></div>
              }

              @for (dot of dots; track $index; let i = $index) {
                <div class="pagination__item pagination__item--offset{{ activeIndex - i }}"></div>
              }

              @for (dot of [1, 2, 3, 4]; track $index) {
                <div class="pagination__item pagination__item--mock"></div>
              }
            </div>
          </div>
        </ion-col>

        <ion-col class="pagination__col">
          @if (!canGoBack) {
            <ion-button class="pagination__done" shape="round" size="small" (click)="goNext()">
              <ion-label>{{ startCta || 'txt-start' | tr }}</ion-label>
            </ion-button>
          }
          @if (canGoNext && canGoBack) {
            <ion-button
              fill="clear"
              color="text"
              class="pagination__btn pagination__btn--next"
              (click)="goNext()"
            >
              <ion-icon slot="icon-only" [src]="ICON.ARROW_RIGHT" />
            </ion-button>
          }
          @if (!canGoNext) {
            <ion-button class="pagination__done" shape="round" size="small" (click)="onDone()">
              <ion-label>{{ endCta || 'txt-done-only' | tr }}</ion-label>
            </ion-button>
          }
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
} @else {
  <div class="pagination pagination--{{ color }}">
    <div
      class="pagination__container"
      [ngStyle]="
        dots.length > 3
          ? { transform: 'translateX(' + -activeIndex * 16 + 'px)' }
          : { transform: 'translateX(-16px)' }
      "
    >
      @for (dot of [1, 2, 3, 4]; track dot) {
        <div class="pagination__item pagination__item--mock"></div>
      }
      @for (dot of dots; track dot; let i = $index) {
        <div class="pagination__item pagination__item--offset{{ activeIndex - i }}"></div>
      }
      @for (dot of [1, 2, 3, 4]; track dot) {
        <div class="pagination__item pagination__item--mock"></div>
      }
    </div>
  </div>
}
