<ion-item lines="none" #snackbar>
  @if (imgSrc) {
    <ion-img slot="start" class="snackbar__image" [src]="imgSrc" />
  }
  <div class="text" [ngClass]="imgSrc && 'text--column'">
    <ion-label class="ion-no-margin ion-text-wrap">{{ text }}</ion-label>
    <ion-button size="small" fill="clear" (click)="onButtonClick()" [attr.uid]="uid">
      {{ buttonLabel }}
    </ion-button>
  </div>
</ion-item>
