import { Injectable } from '@angular/core'
import { api } from '@app/srv/api.service'
import { getState } from '@app/srv/store.service'
import {
  ApplyDiscountInput,
  BackendResponseFMResp,
  CartDiscount,
  CartInput,
  CartItem,
  CartItemInput,
  CartState,
  HardwareId,
  Price,
  ProductKey,
  ProductType,
} from '@naturalcycles/shared'

@Injectable({ providedIn: 'root' })
export class CartService {
  private rollingTrialKeys = [ProductKey.ROLLING_TRIAL, ProductKey.ROLLING_TRIAL_7DAYS]

  public async replace(
    productKeys: ProductKey[],
    discount?: CartDiscount,
    hwId?: HardwareId,
  ): Promise<CartState> {
    const newItems: CartItemInput[] = productKeys.map(key => ({
      key,
      quantity: 1,
    }))

    return await api
      .post<BackendResponseFMResp>('carts', {
        json: {
          items: newItems,
          discount,
          hwId,
        } satisfies CartInput,
      })
      .then(r => r.backendResponse.cart!)
  }

  public async update(
    items: CartItemInput[],
    discount?: CartDiscount,
    hwId?: HardwareId,
    validateShippingCountry?: boolean,
  ): Promise<CartState> {
    return await api
      .post<BackendResponseFMResp>(
        `carts${validateShippingCountry ? '?validateShippingCountry=true' : ''}`,
        {
          json: {
            items,
            discount,
            hwId,
          } satisfies CartInput,
        },
      )
      .then(r => r.backendResponse.cart!)
  }

  public async getItem(key: ProductKey): Promise<CartState> {
    return await api.get<CartState>(`carts/item/${key}`)
  }

  public async updateCartItemQuantity(key: ProductKey, quantity: number): Promise<void> {
    const items = this.getCartItemInputs()
    const selectedItem = items.find(i => i.key === key)
    if (selectedItem) {
      items.forEach(item => {
        if (item.key === selectedItem.key) item.quantity = quantity
      })
    } else {
      items.push({ key, quantity })
    }
    const offer = this.getProductOffer(items)
    await this.update(items, offer)
  }

  public async removeItemFromCart(key: ProductKey): Promise<void> {
    const items = this.getCartItemInputs()
    const updated = items.filter(i => i.key !== key)
    const offer = this.getProductOffer(updated)
    await this.update(updated, offer)
  }

  public async removeDiscountFromCart(): Promise<void> {
    const { cart } = getState()
    await this.update(cart.items, undefined, cart.hwId)
  }

  private getCartItems(): CartItem[] {
    return getState().cart.items
  }

  public getCartItemInputs(): CartItemInput[] {
    return this.getCartItems().map(item => ({
      key: item.key,
      quantity: item.quantity,
      variant: item.variant,
    }))
  }

  public getSubscriptionItem(): CartItem | undefined {
    // assuming cart only contains one subscription item
    return this.getCartItems().find(i => i.type === ProductType.SUBSCRIPTION)
  }

  public hasShopItems(items?: CartItem[]): boolean {
    const _items = items || this.getCartItems()
    return _items.some(i => i.type === ProductType.SHOP)
  }

  public hasOnlySubscription(items?: CartItem[]): boolean {
    const _items = items || this.getCartItems()
    return _items.every(i => i.type === ProductType.SUBSCRIPTION)
  }

  public getTotal(): Price {
    return getState().cart.totalPrice
  }

  public getCartDiscount(): CartDiscount | undefined {
    return getState().cart.discount
  }

  private getProductOffer(cartItems: CartItemInput[]): CartDiscount | undefined {
    const { product } = getState()
    const productOffer = product.items.find(productItem => {
      return cartItems.find(item => item.key === productItem.key && productItem.offer)
    })
    if (productOffer?.offer?.code) {
      return { code: productOffer.offer.code } as CartDiscount
    }
  }

  public getSubscriptionType(): ProductKey | undefined {
    const { cart } = getState()
    const subscription = cart.items.find((item: CartItem) => item.type === ProductType.SUBSCRIPTION)

    return subscription?.key
  }

  /**
   * @description
   * We use this method at CHECKOUT to validate a discount code before applying it to the cart.
   *
   * @note
   * - If the discount code's conditions aren't met, an exception will be thrown with the code DISCOUNT_CODE_VALIDATION_FAILURE.
   * - If zero discounts can be applied, the {@link CartState.discountStatus} will be {@link CartDiscountStatus.Mismatch}.
   * - If at least one discount can be applied, the {@link CartState.discountStatus} will be {@link CartDiscountStatus.Applied}.
   *
   * In the case that there's a 'Mismatch' between the user's product selection and the discount code's product offering
   * We ask the user to replace their cart with the products that the discount code is intended for.
   *
   */
  async validateDiscount(input: ApplyDiscountInput): Promise<CartState> {
    return await api.put(`carts/discount/validate`, {
      json: input,
    })
  }

  isOuraRollingTrial(cart: CartState): boolean {
    return (
      cart.hwId === HardwareId.OURA &&
      !cart.items.some(i => i.type === ProductType.SHOP) &&
      cart.items.some(i => this.rollingTrialKeys.includes(i.key))
    )
  }

  isRecurringSubscription(cart: CartState): boolean {
    return cart.items.some(i => i.type === ProductType.SUBSCRIPTION && i.recurring)
  }

  hasValidThermometerSelection(): boolean {
    const therm = getState().cart.items.find(item => item.key === ProductKey.THERMOMETER)
    return !therm || !!therm.variant
  }
}
