<ion-header>
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-button mode="md" (click)="dismissModal()">
        <ion-icon slot="icon-only" name="close" />
      </ion-button>
    </ion-buttons>
    <ion-title>Bluetooth Debug</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list class="ion-no-padding">
    @if (!(bluetoothEnabled$ | async)) {
      <ion-item (click)="enableBluetooth()" button>
        <ion-label>Enable bluetooth</ion-label>
      </ion-item>
    }

    @if ((hardwareDevice$ | async)?.mac; as id) {
      <ion-item-group class="ion-no-padding">
        <ion-item (click)="unpair(id)">
          <ion-label>Connected</ion-label>
          <ion-note style="font-size: 12px" slot="end">{{ id }}</ion-note>
        </ion-item>
        @if (bluetoothEnabled$ | async) {
          <ion-item>
            <ion-label>Scanning...</ion-label>
            <ion-spinner slot="end" />
          </ion-item>
        }
      </ion-item-group>
    } @else {
      <ion-item-group class="ion-no-padding">
        <ion-item (click)="scanAndPair()" button loader>
          <ion-label>Pair</ion-label>
        </ion-item>
        <ion-item (click)="fakePair()" button loader>
          <ion-label>Pair with fake thermometer</ion-label>
        </ion-item>
      </ion-item-group>
    }

    <ion-item-group>
      <ion-list-header>
        <ion-label>Temperatures</ion-label>
      </ion-list-header>

      <ion-item (click)="mockTemperature()" button>
        <ion-label>Mock temperature</ion-label>
      </ion-item>

      <ion-item (click)="mockMulti()" button>
        <ion-label>Mock multiple days</ion-label>
      </ion-item>

      <ion-item (click)="mockMultiSameDay()" button>
        <ion-label>Mock multiple temperatures same day</ion-label>
      </ion-item>
    </ion-item-group>

    @if (temperatures.length) {
      <ion-item-group>
        @for (temperature of temperatures; track temperature) {
          <ion-item>
            <ion-label>{{ temperature.temperature | temperature }}</ion-label>
            <ion-note style="font-size: 12px" slot="end">
              {{ temperature.timestamp | nc_date: 'FULL' }}
            </ion-note>
          </ion-item>
        }
      </ion-item-group>
    }

    <ion-item-group>
      <ion-list-header>
        <ion-label>Other</ion-label>
      </ion-list-header>

      <ion-item (click)="setBatteryStatus()" button>
        <ion-label>Set battery status</ion-label>
      </ion-item>
    </ion-item-group>

    <ion-item-group class="ion-padding-horizontal">
      <ion-list-header>
        <ion-label>Logs</ion-label>
      </ion-list-header>

      @for (log of logs$ | async; track log) {
        <span style="display: block">{{ log }}</span>
      }
    </ion-item-group>
  </ion-list>
</ion-content>

<ion-footer>
  <ion-button expand="block" shape="round" size="small" (click)="copyLogs()">
    <ion-label>Copy logs</ion-label>
  </ion-button>

  <ion-button expand="block" shape="round" fill="outline" size="small" (click)="clearLogs()">
    <ion-label>Clear logs</ion-label>
  </ion-button>
</ion-footer>
