import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { LhImageComponent } from '@app/cmp/lh-image/lh-image.component'
import { SharedModule } from '@app/shared.module'
import { IonicModule } from '@ionic/angular'
import { AddDataLhPreviewModal } from './add-data-lh-preview.modal'

@NgModule({
  declarations: [AddDataLhPreviewModal],
  imports: [IonicModule, CommonModule, SharedModule, LhImageComponent],
  exports: [AddDataLhPreviewModal],
})
export class AddDataLhPreviewModalModule {}
