import { CommonModule } from '@angular/common'
import { Component, inject, OnDestroy, OnInit } from '@angular/core'
import { loader, LoaderType } from '@app/decorators/decorators'
import { BaseModal } from '@app/pages/base.modal'
import { SharedModule } from '@app/shared.module'
import { BluetoothService, SyncedTemperature } from '@app/srv/bluetooth.service'
import { HardwareDeviceService } from '@app/srv/hardwareDevice.service'
import { PopupController, Priority } from '@app/srv/popup.controller'
import { UebeService } from '@app/srv/uebe.service'
import { Clipboard } from '@capacitor/clipboard'
import { AlertInput, IonicModule } from '@ionic/angular'
import { _numberEnumKeys, _range, localDate, localTime } from '@naturalcycles/js-lib'
import { BatteryStatus, HardwareId } from '@naturalcycles/shared'

@Component({
  selector: 'page-debug-uebe',
  standalone: true,
  imports: [IonicModule, SharedModule, CommonModule],
  templateUrl: './debug-uebe.page.html',
})
export class DebugUebePage extends BaseModal implements OnInit, OnDestroy {
  className = 'DebugUebePage'
  protected override blockPopups = false

  private bluetoothService = inject(BluetoothService)
  private hardwareDeviceService = inject(HardwareDeviceService)
  private popupController = inject(PopupController)
  private uebeService = inject(UebeService)

  public temperatures: SyncedTemperature[] = []
  public bluetoothEnabled$ = this.bluetoothService.bluetoothEnabled$
  public hardwareDevice$ = this.hardwareDeviceService.hardwareDevice$
  public logs$ = this.bluetoothService.logs$

  public native = window.Capacitor.isNativePlatform()
  public initialized = false

  public async ngOnInit(): Promise<void> {
    this.subscriptions.push(
      this.bluetoothService.temperatures$.subscribe(temperatures => {
        this.temperatures.unshift(...temperatures)
      }),
    )
  }

  public enableBluetooth(): void {
    void this.bluetoothService.enableBluetooth(true)
  }

  @loader(LoaderType.BUTTON)
  public async scanAndPair(): Promise<void> {
    const initialized = await this.bluetoothService.initBluetooth('UebeDebug')

    if (!initialized) return

    await this.bluetoothService.ensureBluetoothEnabled()

    const id = await this.bluetoothService.scanAndPair(HardwareId.UEBE_THERMOMETER)

    if (!id) return

    await this.hardwareDeviceService.saveHardwareDevice(
      {
        mac: id,
        buzzer: true,
        backlight: true,
        testMeasurementDone: false,
        created: localTime.now().unix,
      },
      HardwareId.UEBE_THERMOMETER,
    )
  }

  public fakePair(): void {
    void this.hardwareDeviceService.saveHardwareDevice(
      {
        mac: '00:00:00:00:00:00',
        buzzer: true,
        backlight: true,
        testMeasurementDone: false,
        created: localTime.now().unix,
      },
      HardwareId.UEBE_THERMOMETER,
    )
  }

  public async unpair(mac: string): Promise<void> {
    const unpair = confirm('Do you want to unpair?')

    if (!unpair) return

    await this.hardwareDeviceService.clearHardwareDevice(mac)
  }

  public mockTemperature(): void {
    const temp = prompt('Enter temperature or leave empty to get random')
    const value = temp ? Number(temp) : undefined
    const date =
      prompt("Enter date as YYYY-mm-dd or leave empty to use today's date") ||
      localDate.todayString()

    this.uebeService.mockTemperatures([{ value, date }])
    void this.dismissModal()
  }

  public mockMulti(): void {
    const counter = _range(Math.floor(Math.random() * 6) + 4)
    const temperatures = counter.map(index => {
      const date = localDate.today().minus(index, 'day').toISODate()
      return { date }
    })

    this.uebeService.mockTemperatures(temperatures)
    void this.dismissModal()
  }

  public mockMultiSameDay(): void {
    const counter = _range(Math.floor(Math.random() * 6) + 2)
    const temperatures = counter.flatMap(index => {
      const date = localDate.today().minus(index, 'day').toISODate()
      const dateCounter = _range(Math.floor(Math.random() * 3) + 1)
      return dateCounter.map(() => ({ date }))
    })

    this.uebeService.mockTemperatures(temperatures)
    void this.dismissModal()
  }

  public async setBatteryStatus(): Promise<void> {
    const inputs = _numberEnumKeys(BatteryStatus)
      .filter(label => label !== 'CRITICALLY_LOW')
      .map(label => {
        return {
          type: 'radio',
          label,
          value: BatteryStatus[label],
        }
      }) as AlertInput[]

    await this.popupController.presentAlert(
      {
        header: 'Set battery status',
        inputs,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: 'Save',
            handler: value => {
              this.hardwareDeviceService.saveBatteryStatus(value)
            },
          },
        ],
      },
      'alert-t3-algo',
      Priority.IMMEDIATE,
    )
  }

  public async copyLogs(): Promise<void> {
    await Clipboard.write({
      // eslint-disable-next-line id-blacklist
      string: this.bluetoothService.logs$.value.join('\n'),
    })
  }

  public clearLogs(): void {
    this.temperatures = []
    this.logs$.next([])
  }
}
