import { AfterViewInit, Component, Input, ViewChild } from '@angular/core'
import { BaseModal } from '@app/pages/base.modal'
import { select } from '@app/srv/store.service'
import { IonDatetime } from '@ionic/angular'
import { Observable } from 'rxjs'
import { DatetimePickerData, DatetimePickerValue, IonDatetimeConfig } from './datetime-picker.model'

@Component({
  selector: 'app-datetime-picker',
  templateUrl: './datetime-picker.modal.html',
  styleUrls: ['./datetime-picker.modal.scss'],
})
export class DatetimePickerModal extends BaseModal implements AfterViewInit {
  className = 'DatetimePickerModal'

  @Input()
  public config?: IonDatetimeConfig

  @select(['userLocale', 'supportedLanguage'])
  public locale$!: Observable<string>

  @ViewChild('datetime')
  public datetime?: IonDatetime

  public ngAfterViewInit(): void {
    Object.assign(this.datetime!, this.config!)
  }

  public onCancel(): void {
    this.closeModal({ cancelled: true })
  }

  public async onConfirm(datetime: IonDatetime): Promise<void> {
    await datetime.confirm()
    const value = datetime.value as DatetimePickerValue
    this.closeModal({ value })
  }

  public closeModal(data?: DatetimePickerData): void {
    void this.dismissModal(data)
  }
}
