import { BackendResponseFM, BillingAddress } from '@naturalcycles/shared'
import { Action } from 'redux'

type State = BillingAddress

const initialState: State = {} as BillingAddress

const actions: any = {
  extendBillingAddress(
    state: State,
    action: { type: string; payload: Partial<BillingAddress> },
  ): State {
    return {
      ...state,
      ...action.payload,
    }
  },

  onBackendResponse(state: State, action: { type: string; payload: BackendResponseFM }): State {
    // replace!
    if (action.payload.billingAddress) {
      return {
        ...action.payload.billingAddress,
      }
    }

    return state
  },
}

// biome-ignore lint/style/useDefaultParameterLast: ok
export function billingAddressReducer(state = initialState, action: Action): State {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
