import { Injectable } from '@angular/core'
import { PageGuard } from '@app/guards/page.guard'
import { getState } from '@app/srv/store.service'
import { AppId, HardwareId } from '@naturalcycles/shared'

/**
 * Indicates whether user uses a thermometer which can be calibrated and used to measure absolute values
 */
@Injectable({
  providedIn: 'root',
})
export class CalibratePageGuard extends PageGuard {
  className = 'CalibratePageGuard'

  get requirements(): boolean[] {
    const { hwId, appId } = getState().account

    if (appId === AppId.CLEARBLUE && hwId === HardwareId.ORAL_THERMOMETER) return [false]

    return [[HardwareId.ORAL_THERMOMETER, HardwareId.UEBE_THERMOMETER].includes(hwId)]
  }
}
