<ion-datetime
  #datetime
  [preferWheel]="true"
  [showDefaultButtons]="false"
  [locale]="locale$ | async"
  (ionCancel)="onCancel()"
>
  <ion-buttons slot="buttons">
    <ion-button
      id="cancel-button"
      color="primary"
      uid="dateTimePickerModal__cancelButton"
      (click)="datetime.cancel()"
      >{{ 'btn-cancel' | tr }}</ion-button
    >
    <ion-button
      id="confirm-button"
      color="primary"
      role="dateTimePickerModal__confirmButton"
      (click)="onConfirm(datetime)"
      >{{ 'txt-done-only' | tr }}</ion-button
    >
  </ion-buttons>
</ion-datetime>
