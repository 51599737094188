import { inject, Injectable } from '@angular/core'
import { EVENT } from '@app/analytics/analytics.cnst'
import { AnalyticsService } from '@app/analytics/analytics.service'
import { decorate, ErrorHandlerType } from '@app/decorators/decorators'
import { MeasureReminder } from '@app/reducers/notification.reducer'
import { AccountService } from '@app/srv/account.service'
import { AppSettingsFM } from '@app/srv/appSettings.cnst'
import { di } from '@app/srv/di.service'
import { ExperimentService } from '@app/srv/experiment.service'
import { NotificationService } from '@app/srv/notification.service'
import { PartnerService } from '@app/srv/partner.service'
import { dispatch, getState, select2 } from '@app/srv/store.service'
import { tr, trIfExists } from '@app/srv/translation.util'
import { distinctUntilDeeplyChanged } from '@app/util/distinctUntilDeeplyChanged'
import {
  _isEmptyObject,
  _objectAssign,
  _objectKeys,
  _range,
  _shuffle,
  _stringMapValues,
  localDate,
} from '@naturalcycles/js-lib'
import { AccountTM, Goal, HardwareId, reminderByAppTracker, Reminders } from '@naturalcycles/shared'
import { dayjs, IDayjs } from '@naturalcycles/time-lib'
import { BehaviorSubject } from 'rxjs'
import { combineLatestWith, debounceTime, map, tap } from 'rxjs/operators'

export enum ReminderType {
  MEASURING = 'MEASURING',
  PREPARATION = 'PREPARATION',
}

export interface ReminderKeys {
  title: string
  text: string
}

const numberOfReminderCopys: Record<HardwareId, Record<ReminderType, number>> = {
  [HardwareId.ORAL_THERMOMETER]: {
    [ReminderType.MEASURING]: 14,
    [ReminderType.PREPARATION]: 19,
  },
  [HardwareId.UEBE_THERMOMETER]: {
    [ReminderType.MEASURING]: 14,
    [ReminderType.PREPARATION]: 19,
  },
  [HardwareId.T3_THERMOMETER]: {
    [ReminderType.MEASURING]: 14,
    [ReminderType.PREPARATION]: 19,
  },
  [HardwareId.OURA]: {
    [ReminderType.MEASURING]: 5,
    [ReminderType.PREPARATION]: 5,
  },
  [HardwareId.APPLE_WATCH]: {
    [ReminderType.MEASURING]: 0,
    [ReminderType.PREPARATION]: 5,
  },
  [HardwareId.B1]: {
    [ReminderType.MEASURING]: 0,
    [ReminderType.PREPARATION]: 0,
  },
}

@Injectable({ providedIn: 'root' })
export class RemindersService {
  public remindersOn$ = new BehaviorSubject<boolean>(false)

  private pushNotifications$ = select2(s => s.notifications.push)
  private localNotifications$ = select2(s => s.notifications.local.items)
  private account$ = select2(s => s.account)
  private partnerAccount$ = select2(s => s.partnerAccount)

  public showMeasuringReminder$ = this.account$.pipe(
    map(account => !!numberOfReminderCopys[account.hwId].MEASURING),
  )
  public showPreparationReminder$ = this.account$.pipe(
    map(account => !!numberOfReminderCopys[account.hwId].PREPARATION),
  )

  private localNotifications: MeasureReminder[] = []
  private pushNotifications!: Reminders

  readonly TIME_FORMAT = 'HH:mm'

  private accountService = inject(AccountService)
  private partnerService = inject(PartnerService)
  public notificationService = inject(NotificationService)
  private experimentService = inject(ExperimentService)

  public async init(): Promise<void> {
    const available = await this.notificationService.available()

    if (!available) return

    this.setupSubscriptions()
  }

  public toggleLocalNotification(isEnabled: boolean, notificationId: number): void {
    dispatch('enableLocalNotification', {
      isEnabled,
      notificationId,
    })
  }

  public toggleLocalNotificationDay(day: number, enabled: boolean, notificationId: number): void {
    dispatch('toggleLocalNotificationDay', {
      notificationId,
      weekday: day,
      enabled,
    })
  }

  public toggleLocalNotificationsAllDays(enabled: boolean, notificationId: number): void {
    const notification = getState().notifications.local.items.find(
      notification => notification.id === notificationId,
    )!

    const days = {} as MeasureReminder['days']

    _objectKeys(notification.days).forEach((_, index) => {
      _objectAssign(days, {
        [index + 1]: {
          ...notification.days[index + 1],
          enabled,
        },
      })
    })

    dispatch('updateNotification', {
      ...notification,
      days,
    })
  }

  public updateLocalNotificationTime(dayjs: IDayjs, notificationId: number): void {
    const time = dayjs.format(this.TIME_FORMAT)
    const notification = this.getLocalNotificationById(notificationId)

    if (!notification) return

    dispatch('updateLocalNotificationTime', {
      notificationId,
      time,
    })
  }

  public togglePushNotification(isEnabled: boolean, notification: string): void {
    dispatch('extendPushNotificationSettings', {
      [notification]: isEnabled,
    })
  }

  public removeNotificationsOfType(type: ReminderType): void {
    const { notifications } = getState()
    const newNotifications = notifications.local.items.filter(
      notification => notification.type !== type,
    )
    dispatch('extendNotifications', {
      ...notifications,
      local: {
        ...notifications.local,
        items: newNotifications,
      },
    })
  }

  public async savePushNotifications(): Promise<void> {
    await this.putRemindersSettings(this.pushNotifications)
  }

  public getCycleReminders(
    account: AccountTM,
    partner?: boolean,
    appSettings?: AppSettingsFM,
  ): (keyof Reminders)[] {
    let keys: (keyof Reminders)[] = []
    const { goal, hwId } = account

    switch (goal) {
      case Goal.PLAN:
      case Goal.RECOVERY:
        keys = partner
          ? ['fertileDays', 'startTesting', 'checkLH', 'pms', 'breastSelfCheck']
          : ['fertileDays', 'checkLH', 'pms', 'startTesting', 'breastSelfCheck']
        break
      case Goal.PREVENT:
      case Goal.POSTPARTUM:
        keys = partner
          ? ['firstGreenDay', 'firstRedDay', 'period', 'checkLH', 'pms', 'breastSelfCheck']
          : ['period', 'firstRedDay', 'checkLH', 'pms', 'breastSelfCheck']
        break
      case Goal.PREGNANT:
        keys = ['newPregWeek', 'newTrimester']
    }

    const isRelevantGoal = goal === Goal.PLAN || goal === Goal.PREVENT

    const isRelevantHardware = [HardwareId.APPLE_WATCH, HardwareId.OURA].includes(hwId)

    if (isRelevantGoal && isRelevantHardware) {
      keys.unshift('fertilityStatus')
    }

    const reminders = new Set<keyof Reminders>(keys)

    if (partner && appSettings?.partnerTrackers) {
      Object.keys(appSettings.partnerTrackers).forEach(k => {
        // remove reminder if appSetting is turned off
        if (!appSettings.partnerTrackers[k]) {
          reminders.delete(reminderByAppTracker[k])
        }
      })
    }

    return Array.from(reminders)
  }

  public addOneMoreReminder(
    type: ReminderType,
    enabled = false,
    time?: string,
    id?: number,
  ): MeasureReminder {
    const reminder = this.createNewReminder(type, enabled, time, id)

    dispatch('addReminder', reminder)
    return reminder
  }

  public removeReminder(notificationId: number): void {
    dispatch('removeReminder', { notificationId })
  }

  public async checkPermission(): Promise<boolean> {
    const state = await this.notificationService.getPermissionState()

    if (state === 'granted') return true

    if (state === 'denied') {
      const accepted = await this.notificationService.showNotificationsDisabledAlert()

      return accepted
    }

    if (state === 'prompt' || state === 'prompt-with-rationale') {
      const permission = await this.notificationService.showEnableNotificationsAlert()

      return permission
    }

    return false
  }

  public _checkIfAnyNotificationIsEnabled(
    pushNotifications: Reminders,
    localNotifications: MeasureReminder[],
    account: AccountTM,
    partner?: boolean,
  ): boolean {
    if (localNotifications.some(n => n.enabled)) return true

    const remindersByGoal = this.getCycleReminders(account, partner, undefined)

    return Object.keys(pushNotifications)
      .filter(key => remindersByGoal.includes(key as keyof Reminders))
      .some(key => pushNotifications[key])
  }

  private updateNotificationCopy(reminder: MeasureReminder): void {
    const {
      account: { hwId, lang },
      notifications,
    } = getState()

    const notificationId = reminder.id
    let type = reminder.type

    if (!ReminderType[type]) {
      type =
        (reminder.type as string) === 'MORNING' ? ReminderType.MEASURING : ReminderType.PREPARATION
    }

    const copy = this._getRandomReminderTexts(type, hwId)

    const notification = notifications.local.items.find(
      notification => notification.id === notificationId,
    )

    const days = {} as MeasureReminder['days']

    _range(7).forEach(index => {
      const reminderKeys = copy[index]

      const day = notification?.days[index + 1]

      _objectAssign(days, {
        [index + 1]: {
          title: reminderKeys?.title || '',
          text: reminderKeys?.text || '',
          enabled: reminderKeys && day ? day.enabled : false,
        },
      })
    })

    dispatch('updateNotification', {
      ...notification,
      days,
      lastUpdate: localDate.todayString(),
      lang,
      utcOffset: dayjs().utcOffset(),
      hwId,
      type,
    })
  }

  public checkForOldCopy(
    measureReminders: MeasureReminder[],
    lang: string,
    hwId: HardwareId,
    forceUpdate?: boolean,
  ): void {
    for (const reminder of measureReminders) {
      const lastUpdate = dayjs(reminder.lastUpdate)
      const now = dayjs()

      if (
        !reminder.lastUpdate || // notification was never updated
        now.diff(lastUpdate, 'day') > 6 || // If is has gone more than 6 days
        now.utcOffset() !== reminder.utcOffset || // If utcOffset changed
        reminder.lang !== lang || // If language changed
        reminder.hwId !== hwId || // If hardware changes
        !ReminderType[reminder.type] || // if old types
        forceUpdate // if param is passed
      ) {
        this.updateNotificationCopy(reminder)
      }
    }
  }

  private createNewReminder(
    type: ReminderType,
    enabled = false,
    time?: string,
    id?: number,
  ): MeasureReminder {
    let newId = 1

    const { hwId, lang } = getState().account

    if (this.localNotifications.length) {
      newId = this.localNotifications[this.localNotifications.length - 1]!.id + 1
    }

    const notification: MeasureReminder = {
      id: id || newId,
      enabled,
      days: {
        1: { enabled },
        2: { enabled },
        3: { enabled },
        4: { enabled },
        5: { enabled },
        6: { enabled },
        7: { enabled },
      },
      time,
      type,
      hwId,
      lastUpdate: dayjs().toISODate(),
      lang,
      utcOffset: dayjs().utcOffset(),
    }

    const copy = this._getRandomReminderTexts(type, hwId)

    const days = {} as MeasureReminder['days']

    copy.forEach((copy, index) => {
      _objectAssign(days, {
        [index + 1]: {
          title: copy.title,
          text: copy.text,
          enabled,
        },
      })
    })

    return { ...notification, days }
  }

  public trackReminderSettings(onboarding = false): void {
    const enabledNotifications = this.localNotifications.filter(({ enabled }) => enabled)

    // parse local notifications and build reminderToMeasure1: 18:35, reminderToMeasure2: 09:45, etc
    if (!enabledNotifications.length) return

    const remindersEventProperties = { onboarding }

    enabledNotifications.forEach((notification, index) => {
      const reminderIndex = index + 1
      const reminderPropertyName = `reminderToMeasure${reminderIndex}`
      const reminderTypePropertyName = `reminderToMeasure${reminderIndex}Type`
      const reminderDaysPropertyName = `reminderToMeasure${reminderIndex}Weekdays`
      const days: number[] = []

      remindersEventProperties[reminderPropertyName] = notification.time
      remindersEventProperties[reminderTypePropertyName] = notification.type

      _stringMapValues(notification.days).forEach(day => days.push(day.enabled ? 1 : 0))
      remindersEventProperties[reminderDaysPropertyName] = days
    })

    void di
      .get(AnalyticsService)
      .trackEvent(EVENT.UPDATE_MEASURING_REMINDERS, remindersEventProperties)
  }

  public trackCycleReminders(availableNotifications: string[]): void {
    const remindersEventProperties = {}

    availableNotifications.forEach(key => {
      remindersEventProperties[key] = !!this.pushNotifications[key]
    })

    di.get(AnalyticsService).trackEvent(EVENT.UPDATE_CYCLE_REMINDERS, remindersEventProperties)
  }

  private usesInnerWrist(hwId: HardwareId): boolean | undefined {
    if (hwId !== HardwareId.APPLE_WATCH) return

    return getState()
      .messages.messages.find(m => m.msgKey === 'msg-QA-watchWrist-Apple')
      ?.selectedAnswers?.some(m => m.key === 'msg-QA-watchWrist-Apple-ans2' && m.selected)
  }

  public _getRandomReminderTexts(type: ReminderType, hwId: HardwareId): ReminderKeys[] {
    const copyArr = _range(numberOfReminderCopys[hwId][type])

    if (copyArr.length === 0) {
      return []
    }

    // ideally we should have minimum 7 different copies, but oura doesnt right now, so we'll use the same keys twice
    do {
      copyArr.push(...copyArr)
    } while (copyArr.length < 7)

    const keys: ReminderKeys[] = []
    let titleKey = `reminders-measure--${HardwareId[hwId]}--${type}--title`
    let textKey = `reminders-measure--${HardwareId[hwId]}--${type}--text`
    if (this.usesInnerWrist(hwId)) {
      titleKey = `${titleKey}--innerWrist`
      textKey = `${textKey}--innerWrist`
    }

    _shuffle(copyArr)
      .slice(0, 7)
      .forEach(num => {
        const item = {
          title:
            trIfExists(hwId === HardwareId.APPLE_WATCH ? `${titleKey}--${num + 1}` : titleKey) ||
            tr(`reminders-measure--ORAL_THERMOMETER--${type}--title`),
          text:
            trIfExists(`${textKey}--${num + 1}`) ||
            tr(`reminders-measure--ORAL_THERMOMETER--${type}--text--${num + 1}`),
        }
        keys.push(item)
      })

    return keys
  }

  private setupSubscriptions(): void {
    this.account$
      .pipe(
        distinctUntilDeeplyChanged(),
        combineLatestWith(
          this.partnerAccount$.pipe(distinctUntilDeeplyChanged()),
          this.pushNotifications$.pipe(
            distinctUntilDeeplyChanged(),
            tap(items => (this.pushNotifications = items)),
          ),
          this.localNotifications$.pipe(
            distinctUntilDeeplyChanged(),
            tap(items => (this.localNotifications = items)),
          ),
        ),
        debounceTime(500),
      )
      .subscribe(async ([account, partnerAccount, pushNotifications, localNotifications]) => {
        const { goal, lang, hwId } = account
        if (!goal) return

        await this.enableFertilityStatusNotification(account, pushNotifications)
        this.checkForOldCopy(localNotifications, lang, hwId)

        const remindersOn = this._checkIfAnyNotificationIsEnabled(
          pushNotifications,
          localNotifications,
          account,
          !!partnerAccount,
        )

        this.remindersOn$.next(remindersOn)

        // enable cycle reminder for new users
        if (_isEmptyObject(pushNotifications)) {
          const pushNotifications = this.getDefaultReminders(account, !!partnerAccount)

          await this.putRemindersSettings(pushNotifications)
          return
        }
      })
  }

  private getDefaultReminders(account: AccountTM, partner?: boolean): Reminders {
    if (partner) {
      return {
        firstGreenDay: true,
        firstRedDay: true,
        checkLH: true,
        startTesting: true,
        pms: true,
        period: true,
        breastSelfCheck: true,
        fertileDays: true,
        newPregWeek: true,
        newTrimester: true,
      }
    }

    const reminders: Reminders = {
      firstRedDay: true,
      checkLH: true,
      startTesting: true,
      pms: true,
      period: true,
      breastSelfCheck: true,
      fertileDays: true,
      newPregWeek: true,
      newTrimester: true,
    }

    if ([HardwareId.APPLE_WATCH, HardwareId.OURA].includes(account.hwId)) {
      reminders.fertilityStatus = true
    }

    return reminders
  }

  public async disableAllNotifications(): Promise<void> {
    this.localNotifications.forEach((item: MeasureReminder) => {
      if (item.enabled) {
        this.toggleLocalNotification(false, item.id)
      }
    })

    const pushNotifications: Reminders = {
      fertilityStatus: false,
      firstRedDay: false,
      firstGreenDay: false,
      checkLH: false,
      startTesting: false,
      pms: false,
      period: false,
      breastSelfCheck: false,
      fertileDays: false,
      newPregWeek: false,
      newTrimester: false,
    }

    dispatch('extendPushNotificationSettings', pushNotifications)

    await this.putRemindersSettings(pushNotifications)
  }

  public getLocalNotificationById(id: number): MeasureReminder | undefined {
    return this.localNotifications.find(item => {
      return item.id === id
    })
  }

  public getFirstEnabledMorningReminderTime(): string | undefined {
    const { items } = getState().notifications.local

    if (!items.length) return

    const enabledReminders = items.filter(
      item => item.enabled && item.type === ReminderType.MEASURING,
    )

    if (!enabledReminders.length) return

    return enabledReminders[0]!.time
  }

  @decorate({
    errorHandlerType: ErrorHandlerType.LOG,
  })
  private async putRemindersSettings(reminders: Reminders): Promise<void> {
    const {
      partnerAccount,
      userDevice: { uuid },
    } = getState()
    if (!uuid) return

    if (partnerAccount) {
      // I'm a Partner!
      await this.partnerService.patchPartner({
        reminders,
      })
    } else {
      // I'm a User!
      await this.accountService.patch({
        reminders,
      })
    }
  }

  public async enableFertilityStatusNotification(
    account: AccountTM,
    pushNotifications: Reminders,
  ): Promise<void> {
    if (
      ![HardwareId.APPLE_WATCH, HardwareId.OURA].includes(account.hwId) ||
      pushNotifications.fertilityStatus !== undefined ||
      _isEmptyObject(pushNotifications)
    ) {
      return
    }

    await this.accountService.patch({
      reminders: {
        ...pushNotifications,
        fertilityStatus: true,
      },
    })
  }
}
