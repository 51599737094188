<ion-header>
  <app-quiz-header />
</ion-header>

<ion-content>
  <div class="infoPageContent">
    <img src="assets/img/quiz/ellipse3.svg" alt="" class="ellipse" />
    <app-quiz-testimonal-box />
  </div>
</ion-content>

<ion-footer>
  <div class="infoPageFooter">
    <h1 class="infoPageFooter__title">
      {{ 'txt-quiz-testimonial' | tr }}
    </h1>
    <ion-button
      color="purple"
      expand="block"
      shape="round"
      [attr.aria-label]="'txt-quiz-next' | tr"
      [attr.uid]="page + '__footerButton'"
      (click)="nextPage()"
    >
      <ion-label>
        {{ 'txt-quiz-next' | tr }}
      </ion-label>
    </ion-button>
  </div>
</ion-footer>

<app-quiz-overlay
  (animationDoneEmitter)="animationDone()"
  [isVisible]="isModalVisible"
  [attr.uid]="page + '__overlay'"
>
  <ion-header>
    <ion-toolbar mode="ios">
      <ion-title>
        <div class="quizLogo">
          <ion-icon class="quizLogo__img" [src]="ICON.NC_LOGO" color="logo" />
        </div>
      </ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <div class="overlayContent">
      <h1 class="quizTitle" style="text-align: center">
        {{ 'txt-quiz-thank-you-wonderful' | tmd: { name: userName } | stripTags }}
      </h1>
      <img width="28" height="28" [src]="baseImgLink + 'heart.png'" alt="heart" />
    </div>
  </ion-content>
</app-quiz-overlay>
