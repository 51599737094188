import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core'
import { select } from '@app/srv/store.service'
import { IonicModule } from '@ionic/angular'
import { _numberEnumValues } from '@naturalcycles/js-lib'
import { AppQuizFlow, Section } from '@src/app/cnst/quiz.cnst'
import { QuizPage } from '@src/app/cnst/quiz-pages.cnst'
import { SharedModule } from '@src/app/shared.module'
import { QuizService } from '@src/app/srv/quiz/quiz.service'
import { Observable, Subscription } from 'rxjs'

@Component({
  standalone: true,
  imports: [IonicModule, SharedModule],
  selector: 'app-quiz-progress-indicator',
  templateUrl: './progress-indicator.component.html',
  styleUrls: ['./progress-indicator.component.scss'],
})
export class QuizProgressIndicatorComponent implements OnInit, OnDestroy {
  @Input()
  page!: QuizPage

  @select(['quiz', 'flow'])
  flow$!: Observable<AppQuizFlow>

  private quizService = inject(QuizService)
  private subscriptions: Subscription[] = []
  public sections: Section[] = []

  public ngOnInit(): void {
    this.subscriptions.push(
      this.flow$.subscribe(flow => {
        const sectionsNames = _numberEnumValues(Section)
        this.sections = sectionsNames.map(section => {
          return this.getFill(flow, section, this.page)
        })
      }),
    )
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe())
  }

  private getFill(flow: AppQuizFlow, section: Section, page: QuizPage): number {
    return this.quizService.getEngine().getCompletedPercentage(flow, section, page)
  }
}
