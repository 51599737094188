<ion-header>
  <ion-toolbar mode="ios" color="darkPurple">
    <ion-buttons slot="start">
      <ion-button mode="md" (click)="dismissModal()" uid="lhScanner__closeButton">
        <ion-icon slot="icon-only" name="close" />
      </ion-button>
    </ion-buttons>

    <ion-title>{{ 'lh-scanner-title' | tr }}</ion-title>

    <ion-buttons slot="end">
      <app-info-button
        #infoButton
        iconColor="white"
        title="info-lh-scanner-title"
        body="info-lh-scanner-body"
        (infoModalOpened)="stopCamera()"
        (infoModalDismissed)="!result && startCamera()"
      />
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="lhScanner">
    <div class="lhScanner__top">
      <app-big-circle-container>
        <img
          #scannerContainer
          class="lhScanner__preview"
          [class.lhScanner__preview--result]="result"
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
          [ngStyle]="{ 'background-image': 'url(' + previewImage + ')' }"
        />

        @if (entered) {
          <svg class="lhScanner__radar">
            <rect [class.success]="previewImage" [class.result]="result" />
          </svg>
        }
      </app-big-circle-container>
    </div>

    <div class="lhScanner__bottom">
      @if (result?.testResult; as result) {
        <div>
          <p>{{ 'lh-scanner-result' | tr }}</p>
          <ion-button class="lhScanner__add" shape="round" size="block" (click)="save()" loader>
            <ion-label>
              <ion-icon [src]="ICON_BY_LH[result]" />
              <span>{{ 'txt-lh--' + TestResult[result] | tr }}</span>
            </ion-label>
            <ion-label>{{ 'btn-add' | tr }}</ion-label>
          </ion-button>
          <ion-button
            class="lhScanner__report"
            shape="round"
            color="white"
            fill="clear"
            (click)="report()"
          >
            <ion-label>{{ 'lh-scanner-report-txt' | tr }}</ion-label>
          </ion-button>
        </div>
      } @else {
        @if (!previewImage) {
          <div tmd="lh-scanner-status-scanning"></div>
        }
        @if (previewImage) {
          <p>{{ 'lh-scanner-status-preview' | tr }}</p>
        }
      }
    </div>
  </div>
</ion-content>

<ion-footer>
  <ion-toolbar class="lhScanner__toolbar" mode="ios" color="transparent">
    @if (!previewImage) {
      <ion-button
        class="lhScanner__gallery"
        uid="lhScanner__galleryButton"
        slot="end"
        fill="clear"
        (click)="openGallery()"
      >
        <ion-icon [src]="ICON.GALLERY" />
      </ion-button>
    }

    @if (!previewImage || result) {
      <ion-button slot="start" fill="clear" uid="lhScanner__cancelButton" (click)="cancel()">
        <ion-label>{{ 'btn-cancel' | tr }}</ion-label>
      </ion-button>
    }

    @if (result) {
      <ion-button uid="lhScanner__retakeButton" fill="clear" slot="end" (click)="retake()">
        <ion-label>{{ 'lh-scanner-btn-retake' | tr }}</ion-label>
      </ion-button>
    }
  </ion-toolbar>
</ion-footer>
