import { Directive, HostListener, inject, Input, output } from '@angular/core'
import { DatetimePickerModal } from '@app/modals/datetime-picker/datetime-picker.modal'
import {
  DatetimePickerValue,
  IonDatetimeConfig,
} from '@app/modals/datetime-picker/datetime-picker.model'
import { AppearanceSettings } from '@app/srv/appearance.service'
import { PopupController, Priority } from '@app/srv/popup.controller'
import { ModalOptions } from '@ionic/angular'

@Directive({
  selector: '[datetimePicker]',
})
export class DatetimePickerDirective {
  private popupController = inject(PopupController)
  @Input()
  public datetimePicker?: IonDatetimeConfig

  @Input()
  public forceDarkPicker = false

  public selectedDatetime = output<DatetimePickerValue>()

  public cancelled = output<void>()

  @HostListener('click', ['$event'])
  public onClick(): void {
    void this.showDatetimePicker()
  }

  public async showDatetimePicker(): Promise<void> {
    const config = this.datetimePicker

    const options: ModalOptions = {
      component: DatetimePickerModal,
      componentProps: { config },
      cssClass: 'modal--bottom',
    }
    if (this.forceDarkPicker) {
      options.htmlAttributes = { appearance: AppearanceSettings[AppearanceSettings.DARK] }
    }
    const modal = await this.popupController.presentModal(
      options,
      'modal-datetimePicker',
      Priority.IMMEDIATE,
    )

    const { data } = await modal.onDidDismiss()

    if (!data || data?.cancelled) return this.cancelled.emit()

    this.selectedDatetime.emit(data?.value)
  }
}
