import { DataFlag } from '@naturalcycles/shared'

export const MOOD_FLAGS = [
  DataFlag.MOOD_HAPPY,
  DataFlag.MOOD_CONFIDENT,
  DataFlag.MOOD_CALM,
  DataFlag.MOOD_ENERGETIC,
  DataFlag.MOOD_EXCITED,
  DataFlag.MOOD_PMS,
  DataFlag.MOOD_SWINGS,
  DataFlag.MOOD_IRRITABLE,
  DataFlag.MOOD_ANXIOUS,
  DataFlag.MOOD_STRESSED,
  DataFlag.MOOD_TIRED,
  DataFlag.MOOD_SENSITIVE,
  DataFlag.MOOD_NUMB,
  DataFlag.MOOD_SAD,
  DataFlag.MOOD_ANGRY,
  DataFlag.MOOD_UNFOCUSED,
  DataFlag.MOOD_SELF_CRITICAL,
  DataFlag.MOOD_FEELING_GUILTY,
  DataFlag.MOOD_OBSESSIVE_THOUGHTS,
  DataFlag.MOOD_CONFUSED,
]

export const MOOD_FLAGS_FOR_PREGNANT = [
  ...MOOD_FLAGS,
  DataFlag.MOOD_EXHAUSTED,
  DataFlag.MOOD_SENSITIVE_TO_SMELL,
  DataFlag.MOOD_SICK,
]
const pmsIndex = MOOD_FLAGS_FOR_PREGNANT.indexOf(DataFlag.MOOD_PMS)
if (pmsIndex !== -1) {
  MOOD_FLAGS_FOR_PREGNANT.splice(pmsIndex, 1, DataFlag.MOOD_FORGETFUL)
}

const painFlags = [
  DataFlag.PAIN_CRAMPS,
  DataFlag.PAIN_BACKACHE,
  DataFlag.PAIN_SORE_BREASTS,
  DataFlag.PAIN_OVULATION,
  DataFlag.PAIN_HEADACHE,
  DataFlag.PAIN_NAUSEOUS,
  DataFlag.PAIN_DIARRHEA,
  DataFlag.PAIN_CONSTIPATION,
  DataFlag.PAIN_BLOATING,
  DataFlag.PAIN_CRAVINGS,
]

export const PAIN_FLAGS = [...painFlags, DataFlag.PAIN_OTHER]

export const PAIN_FLAGS_FOR_PREGNANT = [
  DataFlag.PAIN_CRAMPS,
  DataFlag.PAIN_BACKACHE,
  DataFlag.PAIN_SORE_BREASTS,
  DataFlag.PAIN_HEADACHE,
  DataFlag.PAIN_NAUSEOUS,
  DataFlag.PAIN_DIARRHEA,
  DataFlag.PAIN_CONSTIPATION,
  DataFlag.PAIN_BLOATING,
  DataFlag.PAIN_CRAVINGS,
  DataFlag.PAIN_PELVIC,
  DataFlag.PAIN_SWELLING,
  DataFlag.PAIN_HEARTBURN,
  DataFlag.PAIN_MOVEMENTS,
  DataFlag.PAIN_CONTRACTIONS,
  DataFlag.PAIN_OTHER,
]

export const PAIN_FLAGS_POSTPARTUM = [
  ...painFlags,
  DataFlag.PAIN_SWELLING,
  DataFlag.PAIN_SORE_NIPPLES,
  DataFlag.PAIN_VAGINAL,
  DataFlag.PAIN_STITCHES,
  DataFlag.PAIN_HEMORRHOIDS,
  DataFlag.PAIN_OTHER,
]

const skinFlags = [
  DataFlag.SKIN_DRY,
  DataFlag.SKIN_OILY,
  DataFlag.SKIN_PUFFY,
  DataFlag.SKIN_ACNE,
  DataFlag.SKIN_GLOWING,
]

export const SKIN_FLAGS = [...skinFlags, DataFlag.SKIN_OTHER]

export const SKIN_FLAGS_PREGNANT = [
  DataFlag.SKIN_DRY,
  DataFlag.SKIN_OILY,
  DataFlag.SKIN_PUFFY,
  DataFlag.SKIN_ACNE,
  DataFlag.SKIN_GLOWING,
  DataFlag.SKIN_CHLOASMA,
  DataFlag.SKIN_OTHER,
]
export const SKIN_FLAGS_POSTPARTUM = [
  ...skinFlags,
  DataFlag.SKIN_CHLOASMA,
  DataFlag.SKIN_HAIR_LOSS,
  DataFlag.SKIN_STRETCH_MARKS,
  DataFlag.SKIN_OTHER,
]
