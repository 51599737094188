import { waitForOutsideAngular } from '@app/util/wait.util'
import { pDelay } from '@naturalcycles/js-lib'

export async function clickUid(uid: string): Promise<void> {
  const button = await waitForOutsideAngular(
    uid,
    () => document.querySelectorAll(`[uid="${uid}"]`)[0] as HTMLButtonElement,
  )
  if (!button) {
    throw new Error(`clickUid(${uid}) cannot find the element`)
  }
  button.click()
}

export async function numpadTemp(temp: string): Promise<void> {
  for await (const digit of temp.split('')) {
    await clickUid(`numpad__${digit}`)
    await pDelay(300) // simulate user input
  }
}
