import { Component } from '@angular/core'
import { TreeLayerComponent } from '@app/cmp/tree-layer/tree-layer.component'
import { loader, LoaderType } from '@app/decorators/decorators'
import { BaseModal } from '@app/pages/base.modal'
import { PipesModule } from '@app/pipes/pipes.module'
import { Clipboard } from '@capacitor/clipboard'
import { IonicModule } from '@ionic/angular'
import { StringMap } from '@naturalcycles/js-lib'

@Component({
  selector: 'app-tree-view-modal',
  templateUrl: './tree-view.modal.html',
  styleUrls: ['./tree-view.modal.scss'],
  standalone: true,
  imports: [IonicModule, TreeLayerComponent, PipesModule],
})
export class TreeViewModal extends BaseModal {
  className = 'TreeViewModal'

  public data: StringMap = {}

  @loader(LoaderType.BUTTON)
  public async copyToClipboard(): Promise<void> {
    // eslint-disable-next-line id-blacklist
    await Clipboard.write({ string: JSON.stringify(this.data, null, 2) })
  }
}
