import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { SharedModule } from '@app/shared.module'
import { IonicModule } from '@ionic/angular'
import { DirectivesModule } from '../../dir/directives.module'
import { TemperatureDisplayComponent } from './temperature-display.component'

@NgModule({
  declarations: [TemperatureDisplayComponent],
  imports: [IonicModule, DirectivesModule, CommonModule, SharedModule],
  exports: [TemperatureDisplayComponent],
})
export class TemperatureDisplayComponentModule {}
