import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnDestroy,
  OnInit,
  output,
} from '@angular/core'
import { ICON } from '@app/cnst/icons.cnst'
import { Subscription } from 'rxjs'
import { skip } from 'rxjs/operators'
import { NumpadDigit, TemperatureNumpadService } from './temperature-numpad.service'

const allowedKeys: string[] = [
  'Backspace',
  'Enter',
  'Delete',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
]

@Component({
  selector: 'app-temperature-numpad',
  templateUrl: './temperature-numpad.component.html',
  styleUrls: ['./temperature-numpad.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemperatureNumpadComponent implements OnInit, OnDestroy {
  private temperatureService = inject(TemperatureNumpadService)
  private subscriptions: Subscription[] = []
  public dismissDisabled = false
  ICON = ICON

  @Input()
  digits?: number[] = []
  @Input()
  dismissTitle = 'txt-ok'
  @Input()
  disableDismissWithoutTemperature = false
  @Input()
  showDismisser = true

  digitsChange = output<number[]>()

  temperatureChange = output<number | undefined>()

  dismiss = output<void>()
  public numpadDigits$ = this.temperatureService.numpadDigits$
  public selectedDigits$ = this.temperatureService.selectedDigits$
  public temperature$ = this.temperatureService.temperature$

  ngOnInit(): void {
    this.enableOnKeyup(true)

    this.subscriptions.push(
      this.selectedDigits$.subscribe(digits => this.digitsChange.emit(digits)),

      this.temperature$.pipe(skip(1)).subscribe(temperature => {
        this.temperatureChange.emit(temperature)

        if (this.disableDismissWithoutTemperature) {
          this.dismissDisabled = !temperature
        }
      }),
    )

    this.temperatureService.resetDigits(this.digits)
  }

  ngOnDestroy(): void {
    this.enableOnKeyup(false)
    for (const sub of this.subscriptions) {
      sub.unsubscribe()
    }
  }

  public enableOnKeyup(enable: boolean): void {
    if (enable) {
      document.onkeyup = e => {
        this.keyHandler(e)
      }
    } else {
      document.onkeyup = () => {}
    }
  }

  public resetDigits(digits: number[] = []): void {
    this.temperatureService.resetDigits(digits)
  }

  public dismissClicked(): void {
    this.dismiss.emit()
  }

  pressBtn(value: number): void {
    this.temperatureService.updateNumpadDigitsByPressingDigit(value)
  }

  trackByDigitValue(_n: number, i: NumpadDigit): number {
    return i.value
  }

  private keyHandler(e: KeyboardEvent): void {
    if (allowedKeys.includes(e.key)) {
      if (e.keyCode === 13) {
        this.dismissClicked()
      } else if (e.keyCode === 8 || e.keyCode === 46) {
        this.temperatureService.updateNumpadDigitsByPressingDigit(-1)
      } else {
        this.numpadDigits$.value.map(digit => {
          if (Number(e.key) === digit.value && !digit.disabled) {
            this.temperatureService.updateNumpadDigitsByPressingDigit(Number(e.key))
          }
        })
      }
    }
  }
}
