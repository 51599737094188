import { Component, ElementRef, ViewChild } from '@angular/core'
import { IonicModule } from '@ionic/angular'
import { QuizProgressIndicatorComponent } from '@src/app/cmp/quiz/progress-indicator/progress-indicator.component'
import { QuizHeaderComponent } from '@src/app/cmp/quiz/quiz-header/quiz-header.component'
import { quizAnimation } from '@src/app/cnst/quiz.cnst'
import { QuizPage } from '@src/app/cnst/quiz-pages.cnst'
import { SharedModule } from '@src/app/shared.module'
import { sentryService } from '@src/app/srv/sentry.service'
import { QuizBasePage } from '../quiz.base.page'

@Component({
  standalone: true,
  imports: [QuizProgressIndicatorComponent, SharedModule, QuizHeaderComponent, IonicModule],
  selector: `page-quiz-${QuizPage.menstrualCycleInfo}`,
  templateUrl: 'menstrual-cycle-info.page.component.html',
  styleUrls: ['menstrual-cycle-info.page.component.scss', '../quiz.base.scss'],
  animations: [quizAnimation],
})
export class MenstrualCycleInfoPage extends QuizBasePage {
  @ViewChild('trackerVideo')
  trackerVideo!: ElementRef<HTMLVideoElement>

  public isVideoVisible = false

  public onVideoLoaded(): void {
    if (this.trackerVideo?.nativeElement) {
      this.trackerVideo.nativeElement.muted = true // This ensures auto play
      this.trackerVideo.nativeElement.play().catch(err => {
        console.error('Video play failed:', err)
        sentryService.captureException(err)
      })
      this.isVideoVisible = true
    }
  }

  public nextPage(): void {
    void super.navigateForwardInQuiz()
  }
}
