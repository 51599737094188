import { Component, OnInit } from '@angular/core'
import { NavigationEnd } from '@angular/router'
import { IonicModule } from '@ionic/angular'
import { EVENT } from '@src/app/analytics/analytics.cnst'
import { QuizOverlayComponent } from '@src/app/cmp/quiz/overlay/overlay.component'
import { QuizProgressIndicatorComponent } from '@src/app/cmp/quiz/progress-indicator/progress-indicator.component'
import { QuizHeaderComponent } from '@src/app/cmp/quiz/quiz-header/quiz-header.component'
import { QuizTestimonalBoxComponent } from '@src/app/cmp/quiz/testimonal-box/testimonial-box.component'
import { ICON } from '@src/app/cnst/icons.cnst'
import { Breakpoint, quizAnimation } from '@src/app/cnst/quiz.cnst'
import { QuizPage } from '@src/app/cnst/quiz-pages.cnst'
import { SharedModule } from '@src/app/shared.module'
import { dispatch, getState } from '@src/app/srv/store.service'
import { filter } from 'rxjs'
import { QuizBasePage } from '../quiz.base.page'

@Component({
  standalone: true,
  imports: [
    QuizProgressIndicatorComponent,
    SharedModule,
    QuizHeaderComponent,
    QuizOverlayComponent,
    IonicModule,
    QuizTestimonalBoxComponent,
  ],
  selector: `page-quiz-${QuizPage.testimonialInfo}`,
  templateUrl: 'testimonial-info.page.component.html',
  styleUrls: ['testimonial-info.page.component.scss', '../quiz.base.scss'],
  animations: [quizAnimation],
})
export class TestimonialInfoPage extends QuizBasePage implements OnInit {
  public isModalVisible = false
  public userName?: string
  public ICON = ICON
  private timeouts: ReturnType<typeof setTimeout>[] = []

  override ngOnInit(): void {
    super.ngOnInit()

    const { quiz } = getState()
    this.userName = quiz.data!.name

    this.subscriptions.push(
      this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
        this.isModalVisible = false
        this.timeouts.forEach(t => clearTimeout(t))
      }),
    )
  }

  public nextPage(): void {
    const { quiz } = getState()
    const conceiveTiming = quiz.data!.conceiveTiming!

    const updatedFlow = this.quizService
      .getEngine()
      .processBreakpointAction(Breakpoint.conceiveTiming, conceiveTiming, quiz.flow)

    dispatch('replaceQuizFlow', updatedFlow)

    this.isModalVisible = true
  }

  public animationDone(): void {
    if (!this.page) {
      return
    }

    this.analyticsService.trackEvent(EVENT.QUIZ_AUTO_MODAL, {
      page: this.page,
    })

    this.timeouts.push(
      setTimeout(() => {
        void this.navigateForwardInQuiz()
      }, this.selfHidingModalTimeout),
    )
  }
}
