import { Injectable } from '@angular/core'
import { PageGuard } from '@src/app/guards/page.guard'
import { isAb250Test } from '../ab250'

@Injectable({
  providedIn: 'root',
})
export class IntroAb250PageGuard extends PageGuard {
  className = 'IntroPageGuard'

  get requirements(): boolean[] {
    const requirements = [!this.hasAccountId(), isAb250Test()]

    return requirements
  }
}
