import { Injectable } from '@angular/core'
import { api } from '@app/srv/api.service'
import {
  BackendResponseFMResp,
  ConsentKey,
  ConsentTerms,
  ConsentTermsItem,
} from '@naturalcycles/shared'
import { BehaviorSubject } from 'rxjs'
import { isIOSApp } from '../cnst/userDevice.cnst'

@Injectable({ providedIn: 'root' })
export class ConsentService {
  public consents$ = new BehaviorSubject<Record<ConsentKey, ConsentTermsItem> | undefined>(
    undefined,
  )

  public partnerConsents$ = new BehaviorSubject<Record<ConsentKey, ConsentTermsItem> | undefined>(
    undefined,
  )

  async fetchConsents(partner?: boolean): Promise<void> {
    if (!this.consents$.value) {
      const searchParams = {}

      if (partner) {
        searchParams['partner'] = 'true'
      }

      const consents = await api.get<Record<ConsentKey, ConsentTermsItem>>(`consent`, {
        searchParams,
      })

      if (partner) {
        this.partnerConsents$.next(consents)
      } else {
        this.consents$.next(consents)
      }
    }
  }

  async fetchTerms(lang: string, partner?: boolean): Promise<ConsentTerms> {
    const { backendResponse } = await api.get<BackendResponseFMResp>(`consent/terms`, {
      searchParams: {
        lang,
        partner,
      },
    })

    const consentTerms: ConsentTerms = backendResponse.consentTerms!

    // remove social marketing terms from AuthTerms modal on iOS app
    // consent will be asked by AppTrackingTransparency
    // keep it for partners
    if (isIOSApp && !partner) {
      consentTerms.items = consentTerms.items.filter(i => i.toggleInfo?.key !== 'socialMarketing')
    }

    return consentTerms
  }
}
