import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { EVENT } from '@app/analytics/analytics.cnst'
import { AnalyticsService } from '@app/analytics/analytics.service'
import { slideOptions } from '@app/cnst'
import { ColorBySegment } from '@app/cnst/add-data.cnst'
import {
  ICON,
  ICON_BY_CM_CONSISTENCY,
  ICON_BY_CM_QUANTITY,
  ICON_BY_DATAFLAG,
  ICON_BY_LH_STATUS,
  ICON_BY_LIBIDO,
  ICON_BY_MENS,
  ICON_BY_MENS_QUANTITY,
  ICON_BY_MISCARRIAGE_QUANTITY,
  ICON_BY_OVULATION_STATUS,
  ICON_BY_POSTPARTUM_QUANTITY,
  ICON_BY_PREG_TEST_STATUS,
  ICON_BY_SLEEP,
  ICON_BY_WITHDRAWAL_QUANTITY,
} from '@app/cnst/icons.cnst'
import { ROUTES } from '@app/cnst/nav.cnst'
import { InfoModal } from '@app/modals/info/info.modal'
import { UFEntry } from '@app/model/uf.model'
import { BaseModal } from '@app/pages/base.modal'
import { GraphService } from '@app/pages/graph/graph.service'
import { DateFormat, DateService } from '@app/srv/date.service'
import { di } from '@app/srv/di.service'
import { MarkdownService } from '@app/srv/markdown.service'
import { Orientation } from '@app/srv/orientation.model'
import { OrientationService } from '@app/srv/orientation.service'
import { PopupController, Priority } from '@app/srv/popup.controller'
import { getState, select } from '@app/srv/store.service'
import { tr } from '@app/srv/translation.util'
import { UFService } from '@app/srv/uf.service'
import { DomController } from '@ionic/angular'
import { _Debounce, _numberEnumValues, StringMap } from '@naturalcycles/js-lib'
import {
  AppTracker,
  CervicalMucusConsistency,
  DataFlag,
  DataIntensity,
  DataQuantity,
  Goal,
  GuideId,
  HadSex,
  HardwareId,
  LANG,
  LHStatus,
  Libido,
  Mens,
  OvulationStatus,
  PregnancyEndCareType,
  PregnancyTestStatus,
  SexType,
  Sleep,
  TestResult,
  UFColor,
} from '@naturalcycles/shared'
import { dayjs, IDayjs } from '@naturalcycles/time-lib'
import { BehaviorSubject, Observable } from 'rxjs'
import Swiper, { SwiperOptions } from 'swiper'
import { SwiperComponent } from 'swiper/angular'

export type PreviewInfoAction = 'ovulation' | 'lh' | 'pms' | 'period' | 'pregnancyTest'

interface PreviewInfo {
  icon?: string
  title?: string
  body: string
  tag?: string
  action?: PreviewInfoAction
}

interface PreviewDay {
  date: string
  insights?: PreviewInfo[]
  ovulation?: PreviewInfo
  pmsPrediction?: PreviewInfo
  mensPrediction?: PreviewInfo
  lhStatus?: PreviewInfo
  isPrediction: boolean
  isDeviating: boolean
  deviationFlags?: DataFlag[]
  painFlags?: DataFlag[]
  dataFlagIntensity?: StringMap<DataIntensity>
  skinFlags?: DataFlag[]
  emergencyFlags?: DataFlag[]
  cervicalMucusLabel?: string
  moodFlags?: DataFlag[]
  libido?: Libido
  covidSymptomFlags?: DataFlag[]
  sexFlags?: DataFlag[]
  pregnancyEndCare?: PregnancyEndCareType[]
  sleep?: Sleep
}

enum SwipeDirection {
  FORWARD = 'FORWARD',
  BACK = 'BACK',
}

@Component({
  selector: 'app-add-data-preview-modal',
  templateUrl: './add-data-preview.modal.html',
  styleUrls: ['./add-data-preview.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddDataPreviewModal extends BaseModal implements OnInit, AfterViewInit, OnDestroy {
  private ufService = inject(UFService)
  private orientationService = inject(OrientationService)
  private markdownService = inject(MarkdownService)
  private popupController = inject(PopupController)
  private dom = inject(DomController)
  private cdr = inject(ChangeDetectorRef)
  private dateService = inject(DateService)
  className = 'AddDataPreviewModal'

  public HardwareId = HardwareId
  public intensities = _numberEnumValues(DataIntensity).reverse()

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent): void {
    switch (event.key) {
      case 'ArrowRight':
        void this.swiperRef?.slideNext()
        break
      case 'ArrowLeft':
        void this.swiperRef?.slidePrev()
        break
    }
  }

  @ViewChild('swiper')
  swiper?: SwiperComponent

  @Input({ required: true })
  public date!: string

  @select(['account', 'goal'])
  public goal$!: Observable<Goal>

  @select(['appSettings', 'trackers', AppTracker.MORE_SEX])
  public moreSexEnabled$!: Observable<boolean | undefined>

  private landscape = this.orientationService.orientation$.getValue() === Orientation.landscape

  public ICON = ICON
  public ICON_BY_DATAFLAG = ICON_BY_DATAFLAG
  public ICON_BY_MENS = ICON_BY_MENS
  public ICON_BY_MENS_QUANTITY = ICON_BY_MENS_QUANTITY
  public ICON_BY_WITHDRAWAL_QUANTITY = ICON_BY_WITHDRAWAL_QUANTITY
  public ICON_BY_MISCARRIAGE_QUANTITY = ICON_BY_MISCARRIAGE_QUANTITY
  public ICON_BY_POSTPARTUM_QUANTITY = ICON_BY_POSTPARTUM_QUANTITY
  public ICON_BY_CM_QUANTITY = ICON_BY_CM_QUANTITY
  public ICON_BY_CM_CONSISTENCY = ICON_BY_CM_CONSISTENCY
  public ICON_BY_LIBIDO = ICON_BY_LIBIDO
  public ICON_BY_SLEEP = ICON_BY_SLEEP

  public DataFlag = DataFlag
  public Mens = Mens
  public DataQuantity = DataQuantity
  public TestResult = TestResult
  public HadSex = HadSex
  public SexType = SexType
  public Libido = Libido
  public Sleep = Sleep
  public CervicalMucusConsistency = CervicalMucusConsistency
  public ColorBySegment = ColorBySegment
  public Goal = Goal
  public LANG = LANG
  public UFColor = UFColor
  public PregnancyEndCareType = PregnancyEndCareType
  public config: SwiperOptions & { initialSlide: number } = {
    ...slideOptions,
    centeredSlides: true,
    spaceBetween: this.landscape ? 48 : 16,
    slidesPerView: this.landscape ? 1.6 : 1.2,
    initialSlide: 2,
    runCallbacksOnInit: false,
    speed: 250,
    slideToClickedSlide: true,
  }
  private activeIndex: number = this.config.initialSlide
  private swiperRef?: Swiper

  public ufDays$ = new BehaviorSubject<Partial<UFEntry>[]>([])
  public previewDays$ = new BehaviorSubject<PreviewDay[]>([])
  public showFertilityStatus$ = this.ufService.showFertilityStatus$

  public isPartner = !!getState().partnerAccount

  ngOnInit(): void {
    this.updateData()
  }

  ngAfterViewInit(): void {
    this.swiperRef = this.swiper?.swiperRef
    this.swiperRef?.on('slideChangeTransitionEnd', () => this.onSlideDidChange())
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy()
    this.swiperRef?.off('slideChangeTransitionEnd')
  }

  override ionViewDidEnter(): void {
    setTimeout(() => this.checkContainersOverflow())
  }

  private getOvulationInfo(status?: OvulationStatus, goal?: Goal): PreviewInfo | undefined {
    if (!status) return

    const key = Object.keys(OvulationStatus).find(i => OvulationStatus[i] === status)

    let body = `alert-ovulation--${key}--message`

    if (status === OvulationStatus.OVU_WAITING && goal === Goal.PLAN) {
      body = `alert-ovulation--OVU_WAITING_PLAN--message`
    }

    const tag = status === OvulationStatus.OVU_PREDICTION ? 'txt-prediction-title' : undefined

    return {
      action: 'ovulation',
      icon: ICON_BY_OVULATION_STATUS[status],
      title: `alert-ovulation--${key}--title`,
      body,
      tag,
    }
  }

  private getLhStatus(lhStatus?: LHStatus, today?: boolean): PreviewInfo | undefined {
    if (!lhStatus) return

    const i = Object.values(LHStatus).indexOf(lhStatus)
    const key = Object.keys(LHStatus)[i]

    if (lhStatus === LHStatus.LH_NEG_OVU_DAY) {
      // this one has only body
      return {
        body: `txt-lhtest--${key}--body`,
      }
    }
    const tag = lhStatus === LHStatus.LH_PREDICTION ? 'txt-recommendation-title' : undefined
    const body =
      lhStatus === LHStatus.LH_PREDICTION && today
        ? `txt-lhtest--${key}-today--body`
        : `txt-lhtest--${key}--body`

    return {
      action: 'lh',
      icon: ICON_BY_LH_STATUS[lhStatus],
      title: `txt-lhtest--${key}--title`,
      body,
      tag,
    }
  }

  private getPregTestStatus(
    pregTestStatus?: PregnancyTestStatus,
    today?: boolean,
    goal?: Goal,
  ): PreviewInfo | undefined {
    if (!pregTestStatus) return

    const i = Object.values(PregnancyTestStatus).indexOf(pregTestStatus)
    const key = Object.keys(PregnancyTestStatus)[i]

    let tag: string | undefined
    let title = `txt-pregtest--${key}--title`
    let body = `txt-pregtest--${key}--body`

    if (pregTestStatus === PregnancyTestStatus.PREG_PREDICTION) {
      tag = 'txt-recommendation-title'
      title = 'txt-pregnancy-test'
      body = today ? `txt-pregtest--${key}-today--body` : body
    }

    return {
      action: goal === Goal.PLAN ? 'pregnancyTest' : undefined,
      icon: ICON_BY_PREG_TEST_STATUS[pregTestStatus],
      title,
      body,
      tag,
    }
  }

  private getPmsPrediction(entry?: Partial<UFEntry>): PreviewInfo | undefined {
    if (!entry?.dataFlags?.includes(DataFlag.MOOD_PMS)) return

    return {
      action: 'pms',
      title: 'txt-pmsPrediction-title',
      body: 'txt-pmsPrediction-body',
      tag: 'txt-prediction-title',
    }
  }

  private getMensPrediction(entry?: Partial<UFEntry>): PreviewInfo | undefined {
    if ((!entry?.mensQuantity && !entry?.code?.mens) || entry?.mens) return

    const title = entry?.mensQuantity
      ? 'txt-menstruation--' + DataQuantity[entry?.mensQuantity]
      : 'txt-menstruation'
    const icon = entry?.mensQuantity ? ICON_BY_MENS_QUANTITY[entry.mensQuantity] : ICON.MENSTRUATION

    return {
      action: 'period',
      icon,
      title,
      body: 'prediction-mens-body',
      tag: 'txt-prediction-title',
    }
  }

  private onSlideDidChange(): void {
    const { initialSlide } = this.config
    if (!this.swiperRef) return

    const active = this.swiperRef.activeIndex
    const indexDifference = Math.abs(active - this.activeIndex)
    const newDate = dayjs(this.date).add(
      active < initialSlide ? -indexDifference : indexDifference,
      'day',
    )

    this.date = newDate.toISODate()

    // Detach cdr while updating slides to avoid flicker
    this.cdr.detach()

    this.updateData()

    this.cdr.reattach()
    this.cdr.detectChanges()

    const direction = active - initialSlide < 0 ? SwipeDirection.BACK : SwipeDirection.FORWARD
    void di.get(AnalyticsService).trackEvent(EVENT.ADD_DATA_PREVIEW_SWIPE, { direction })
  }

  public onActionClick(action: PreviewInfoAction | undefined): void {
    if (!action) return

    switch (action) {
      case 'ovulation': {
        void this.openGuide(GuideId.OVULATION)
        return
      }
      case 'lh': {
        void this.openGuide(GuideId.LH)
        return
      }
      case 'pms': {
        void this.openGlossaryPage('pms')
        return
      }
      case 'period': {
        void this.openGlossaryPage('period')
        return
      }
      case 'pregnancyTest': {
        void this.openGlossaryPage('pregTest')
        return
      }
    }
  }

  private async openGuide(guideId: string): Promise<void> {
    await this.dismissModal()

    void this.navigateForward(`${ROUTES.Guide}/${guideId}`, {
      analytics: { source: 'AddDataPreview' },
    })
  }

  private async openGlossaryPage(pageId: string): Promise<void> {
    const item = getState().glossary.items.find(item => item.id === pageId)
    if (!item) return undefined

    const html = this.markdownService.parse(`## ${item.title}\n\n ${item.body}`)

    await this.popupController.presentModal(
      {
        component: InfoModal,
        componentProps: {
          html,
        },
      },
      `modal-info-glossary-${pageId}`,
      Priority.IMMEDIATE,
    )
  }

  private updateData(): void {
    const twoDaysBefore = dayjs(this.date).subtract(2, 'day')
    const dayBefore = dayjs(this.date).subtract(1, 'day')

    const dayAfter = dayjs(this.date).add(1, 'day')
    const twoDaysAfter = dayjs(this.date).add(2, 'day')

    const ufDays = []
    ufDays[0] = this.getUFEntry(twoDaysBefore)
    ufDays[1] = this.getUFEntry(dayBefore)
    ufDays[2] = this.getUFEntry(dayjs(this.date))
    ufDays[3] = this.getUFEntry(dayAfter)
    ufDays[4] = this.getUFEntry(twoDaysAfter)

    this.updatePreviewDays(ufDays)
    this.ufDays$.next(ufDays)

    if (!this.swiperRef) return

    this.swiperRef.slideTo(this.config.initialSlide, 0, false)
    setTimeout(() => this.checkContainersOverflow(), 0)
  }

  private getUFEntry(entryDate: IDayjs): UFEntry | Partial<UFEntry> {
    const date = entryDate.toISODate()

    return this.ufService.getUfEntry(date) || { date }
  }

  private updatePreviewDays(ufEntries: (UFEntry | Partial<UFEntry>)[]): void {
    const previewDays: PreviewDay[] = []

    for (const day of ufEntries) {
      if (!day) continue
      previewDays.push(this.getPreviewDayData(day))
    }

    this.previewDays$.next(previewDays)
  }

  private getPreviewDayData(entry: Partial<UFEntry>): PreviewDay {
    const insights = [
      this.getOvulationInfo(entry.ovulationStatus, entry.goal)!,
      this.getPmsPrediction(entry)!,
      this.getMensPrediction(entry)!,
      this.getLhStatus(entry.lhStatus, entry.today)!,
      this.getPregTestStatus(entry.pregnancyTestStatus, entry.today, entry.goal)!,
    ].filter(Boolean)

    const todayDate = dayjs().startOf('day')
    const ufDate = dayjs(entry.date)
    const diff = ufDate.diff(todayDate, 'day')

    const deviationFlags = [
      ...(entry.deviationReasons ?? []),
      ...(entry.ouraFlags?.filter(f => f === DataFlag.OURA_ADJUSTED_TEMPERATURE) ?? []),
    ]

    return {
      date: this._getDisplayDate(diff, entry.date!),
      isPrediction: dayjs(entry.date).isAfter(dayjs()),
      isDeviating: !!entry.deviationReasons?.length,
      deviationFlags,
      painFlags: entry.pains,
      dataFlagIntensity: entry.dataFlagIntensity,
      skinFlags: entry.skinFlags,
      emergencyFlags: entry.emergencyFlags,
      cervicalMucusLabel: this.ufService.getCervicalMucusLabel(entry as UFEntry),
      moodFlags: entry.moods,
      libido: entry.libido,
      sleep: entry.sleep,
      covidSymptomFlags: entry.covidFlags,
      sexFlags: entry.sexFlags,
      insights,
      pregnancyEndCare: entry.pregnancyEndCare,
    }
  }

  private _getDisplayDate(diff: number, date: string): string {
    if (diff === 0) {
      return tr('txt-today')
    }

    if (diff === 1) {
      return tr('txt-tomorrow')
    }

    if (diff === -1) {
      return tr('txt-yesterday')
    }

    return this.dateService.localizeDate(dayjs(date), DateFormat.DAY_MONTH)
  }

  private checkContainersOverflow(): void {
    this.dom.read(() => {
      const containers = document.querySelectorAll('.addDataPreview__container')
      for (const container of containers) {
        this.showOverflowShadow(container)
      }
    })
  }

  private showOverflowShadow(element: any): void {
    this.dom.read(() => {
      const scrollHeight = element.scrollHeight
      const offsetHeight = element.offsetHeight

      this.dom.write(() => {
        if (
          scrollHeight > offsetHeight &&
          Math.ceil(element.scrollTop) < scrollHeight - offsetHeight
        ) {
          if (element.classList.contains('shadow')) return
          element.parentNode.classList.add('addDataPreview__slide--overflow')
          element.classList.add('shadow')
        } else {
          element.classList.remove('shadow')
        }
      })
    })
  }

  @_Debounce(20)
  public onScroll(event: Event): void {
    this.dom.read(() => {
      const container = event.target
      this.showOverflowShadow(container)
    })
  }

  public trackByDate(_i: number, item: PreviewDay): string | undefined {
    return item?.date
  }

  public override async dismissModal(options?: any): Promise<void> {
    // Fix for DEV-13833
    // TODO revisit and check for better solution
    if (options) di.get(GraphService).enableGraphOpening$.next()

    return await super.dismissModal(options)
  }
}
