import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { LhImageComponent } from '@app/cmp/lh-image/lh-image.component'
import { NotesComponent } from '@app/cmp/notes/notes.component'
import { PipesModule } from '@app/pipes/pipes.module'
import { SharedModule } from '@app/shared.module'
import { IonicModule } from '@ionic/angular'
import { SwiperModule } from 'swiper/angular'
import { AddDataPreviewModal } from './add-data-preview.modal'

@NgModule({
  declarations: [AddDataPreviewModal],
  imports: [
    IonicModule,
    CommonModule,
    PipesModule,
    SharedModule,
    NotesComponent,
    LhImageComponent,
    SwiperModule,
  ],
  exports: [AddDataPreviewModal],
})
export class AddDataPreviewModalModule {}
