import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { SharedModule } from '@app/shared.module'
import { IonicModule } from '@ionic/angular'
import { DatetimePickerModal } from './datetime-picker.modal'

@NgModule({
  declarations: [DatetimePickerModal],
  imports: [CommonModule, IonicModule, SharedModule],
  exports: [DatetimePickerModal],
})
export class DatetimePickerModalModule {}
