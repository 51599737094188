import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AppPreloadingStrategy } from '@app/app.preloading.strategy'
import { FlowTemplate } from './flow.cnst'
import { canDeactivateFlowGuard } from './flow.deactivate.guard'
import { FlowGuard } from './flow.guard'

const routes: Routes = [
  {
    path: 'flow/:flow', // :flow for FlowId
    canActivate: [FlowGuard],
    canDeactivate: [canDeactivateFlowGuard],
    children: [
      {
        path: `${FlowTemplate.CALENDAR}/:key`,
        loadComponent: () =>
          import('./_templates/calendar/flow-calendar.page').then(m => m.FlowCalendarPage),
      },
      {
        path: `${FlowTemplate.CALENDAR}/:key/:periodIndex`,
        loadComponent: () =>
          import('./_templates/calendar/flow-calendar.page').then(m => m.FlowCalendarPage),
      },
      {
        path: `${FlowTemplate.SINGLE_SELECT}/:key`,
        loadComponent: () =>
          import('./_templates/single-select/flow-single-select.page').then(
            m => m.FlowSingleSelectPage,
          ),
      },
      {
        path: `${FlowTemplate.TEXT_INPUT}/:key`,
        loadComponent: () =>
          import('./_templates/text-input/flow-text-input.page').then(m => m.FlowTextInputPage),
      },
      {
        path: `${FlowTemplate.DATE_INPUT}/:key`,
        loadComponent: () =>
          import('./_templates/date-input/flow-date-input.page').then(m => m.FlowDateInputPage),
      },
      {
        path: `${FlowTemplate.RADIO}/:key`,
        loadComponent: () =>
          import('./_templates/radio-group/flow-radio-group.page').then(m => m.FlowRadioGroupPage),
      },
      {
        path: `${FlowTemplate.CHECKBOX}/:key`,
        loadComponent: () =>
          import('./_templates/checkbox-group/flow-checkbox-group.page').then(
            m => m.FlowCheckboxGroupPage,
          ),
      },
      {
        path: `${FlowTemplate.PICKER}/:key`,
        loadComponent: () =>
          import('./_templates/picker/flow-picker.page').then(m => m.FlowPickerPage),
      },
      {
        path: `${FlowTemplate.STEPPER}/:key`,
        loadComponent: () =>
          import('./_templates/stepper/flow-stepper.page').then(m => m.FlowStepperPage),
      },
      {
        path: `${FlowTemplate.HEIGHT_AND_WEIGHT}/:key`,
        loadComponent: () =>
          import('./_templates/height-and-weight/flow-height-and-weight.page').then(
            m => m.FlowHeightAndWeightPage,
          ),
      },
      {
        path: `${FlowTemplate.INFO}/:key`,
        loadComponent: () => import('./_templates/info/flow-info.page').then(m => m.FlowInfoPage),
      },
      {
        path: `${FlowTemplate.REMINDERS}/:key`,
        loadComponent: () =>
          import('./_templates/reminders/flow-reminders.page').then(m => m.FlowRemindersPage),
      },
      {
        path: `${FlowTemplate.NOTIFICATIONS}/:key`,
        loadComponent: () =>
          import('./_templates/notifications/flow-notifications.page').then(
            m => m.FlowNotificationsPage,
          ),
      },
      {
        path: `${FlowTemplate.NOTES}/:key`,
        loadComponent: () =>
          import('./_templates/notes/flow-notes.page').then(m => m.FlowNotesPage),
      },

      /**
       * Onboarding flow
       */
      {
        path: 'start',
        loadComponent: () => import('./onboarding/reg-done/reg-done.page').then(m => m.RegDonePage),
      },
      {
        path: 'oura-auth',
        loadComponent: () => import('../oura/oura-auth/oura-auth.page').then(m => m.OuraAuthPage),
      },
      {
        path: 'summary',
        loadComponent: () =>
          import('./onboarding/summary/summary.page').then(m => m.OnboardingSummaryPage),
      },
      {
        path: 'demo-exit',
        loadComponent: () =>
          import('./onboarding/demo-exit/demo-exit.page').then(m => m.OnboardingDemoExitPage),
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: AppPreloadingStrategy })],
  exports: [RouterModule],
})
export class FlowRoutingModule {}
