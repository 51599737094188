import { Component } from '@angular/core'
import { IonicModule } from '@ionic/angular'
import { QuizHeaderComponent } from '@src/app/cmp/quiz/quiz-header/quiz-header.component'
import { quizAnimation } from '@src/app/cnst/quiz.cnst'
import { QuizPage } from '@src/app/cnst/quiz-pages.cnst'
import { SharedModule } from '@src/app/shared.module'
import { QuizBasePage } from '../quiz.base.page'

@Component({
  standalone: true,
  imports: [SharedModule, QuizHeaderComponent, IonicModule],
  selector: `page-quiz-${QuizPage.sexDriveInfo}`,
  templateUrl: 'sex-drive-info.page.component.html',
  styleUrls: ['sex-drive-info.page.component.scss', '../quiz.base.scss'],
  animations: [quizAnimation],
})
export class SexDriveInfoPage extends QuizBasePage {
  public nextPage(): void {
    void super.navigateForwardInQuiz()
  }
}
