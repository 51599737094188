import { sentryService } from '@app/srv/sentry.service'
import { _stringify } from '@naturalcycles/js-lib'
import { env } from '@src/environments/environment'

const { prod } = env

class LogUtil {
  /**
   * Log to console or add breadcrumb to Sentry
   */
  log(...args: any[]): void {
    if (prod) {
      args.forEach(a =>
        sentryService.addBreadcrumb({
          message: _stringify(a, { includeErrorData: true }),
        }),
      )
    } else {
      console.log(...args)
    }
  }

  /**
   * Log to console or capture message to Sentry
   */
  warn(message: string): void {
    if (prod) {
      sentryService.captureMessage(message, 'warning')
    } else {
      console.warn(message)
    }
  }

  /**
   * Log the error (to console or Breadcrumb) and capture exception
   */
  error(err: any): string | undefined {
    return sentryService.captureException(err)
  }
}

export const logUtil = new LogUtil()
