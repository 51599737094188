@if (testResult) {
  <h2>{{ 'alert-lhimage-remove--' + TestResult[testResult] + '--title' | tr }}</h2>
}

<p>{{ 'alert-lhimage-remove-body' | tr }}</p>

@for (id of imageIds; track id) {
  <app-lh-image [id]="id" />
}

<ion-toolbar color="transparent">
  <ion-buttons slot="end">
    <ion-button
      uid="lh__preview__remove__button"
      color="primary"
      (click)="dismissModal({ id: imageIds![0] })"
    >
      <ion-label>{{ 'txt-remove' | tr }}</ion-label>
    </ion-button>

    <ion-button uid="lh__preview__cancel__button" color="primary" (click)="dismissModal()">
      <ion-label>{{ 'btn-cancel' | tr }}</ion-label>
    </ion-button>
  </ion-buttons>
</ion-toolbar>
