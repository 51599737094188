<ion-header>
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-button mode="md" (click)="dismissModal()">
        <ion-icon slot="icon-only" name="close" />
      </ion-button>
    </ion-buttons>
    <ion-title>T3 Debug</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list class="ion-no-padding">
    @if (!(bluetoothEnabled$ | async)) {
      <ion-item (click)="enableBluetooth()" button>
        <ion-label>Enable bluetooth</ion-label>
      </ion-item>
    }

    @if ((hardwareDevice$ | async)?.mac; as id) {
      <ion-item-group class="ion-no-padding">
        <ion-item>
          <ion-label>{{ (connected$ | async) ? 'Connected' : 'Disconnected' }}</ion-label>
          <ion-note style="font-size: 12px" slot="end">{{ id }}</ion-note>
        </ion-item>
        <ion-item>
          <ion-button slot="end" (click)="unpair(id)" fill="outline" shape="round">
            <ion-label>Unpair</ion-label>
          </ion-button>
        </ion-item>
        @if (deviceStatus) {
          <ion-item>
            <ion-label> Device status </ion-label>
            <small slot="end" style="padding: 8px 0">
              Time: {{ deviceStatus.time | nc_date: 'MEDIUM_WITH_TIME' }}<br />
              State: {{ T3DeviceState[deviceStatus.state] }}<br />
              Battery: {{ deviceStatus.batteryVoltage }}v ({{
                T3BatteryLevel[deviceStatus.batteryLevel]
              }})<br />
            </small>
          </ion-item>
        }
      </ion-item-group>
    } @else {
      <ion-item-group class="ion-no-padding">
        <ion-item (click)="scanAndPair()" button loader>
          <ion-label>Pair</ion-label>
        </ion-item>
        <ion-item (click)="fakePair()" button>
          <ion-label>Pair with fake thermometer</ion-label>
        </ion-item>
      </ion-item-group>
    }

    <ion-item-group>
      <ion-list-header>
        <ion-label>Temperatures</ion-label>
      </ion-list-header>

      <ion-item (click)="mockTemperature()" button>
        <ion-label>Mock temperature</ion-label>
      </ion-item>

      <ion-item (click)="mockMulti()" button>
        <ion-label>Mock multiple days</ion-label>
      </ion-item>

      <ion-item (click)="mockMultiSameDay()" button>
        <ion-label>Mock multiple temperatures same day</ion-label>
      </ion-item>
    </ion-item-group>

    @if ((hardwareDevice$ | async)?.mac; as id) {
      <span>
        <ion-item-group>
          <ion-item-divider>
            <ion-label>Bluetooth</ion-label>
          </ion-item-divider>
          <ion-item (click)="getMeasurementStatus(id)" button>
            <ion-label>Get measurement status</ion-label>
          </ion-item>
          <ion-item (click)="getFirmwareVersion(id)" button>
            <ion-label>Get firmware version</ion-label>
          </ion-item>
          <ion-item (click)="getLogs(id)" button>
            <ion-label>Get logs</ion-label>
          </ion-item>
        </ion-item-group>
        <ion-item-group>
          <ion-item-divider>
            <ion-label>UI Settings</ion-label>
          </ion-item-divider>
          <ion-item (click)="setUsername(id)" button loader>
            <ion-label>Set username</ion-label>
          </ion-item>
          <ion-item>
            <ion-label>Light</ion-label>
            <ion-toggle
              slot="end"
              mode="ios"
              value="uiConfig__light"
              [checked]="backlight$ | async"
              (ionChange)="setBacklight(id, $any($event))"
            />
          </ion-item>
          <ion-item>
            <ion-label>Sound</ion-label>
            <ion-toggle
              slot="end"
              mode="ios"
              value="uiConfig__sound"
              [checked]="buzzer$ | async"
              (ionChange)="setSound(id, $any($event))"
            />
          </ion-item>
          <ion-item>
            <ion-select label="Date format" (ionChange)="setDateFormat(id, $any($event))">
              <ion-select-option [value]="LANG.sv_SE">ddMMYY</ion-select-option>
              <ion-select-option [value]="LANG.en_US">MMddYY</ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item>
            <ion-select
              label="Temperature units"
              (ionChange)="setTemperatureUnits(id, $any($event))"
            >
              <ion-select-option [value]="1">Fahrenheit</ion-select-option>
              <ion-select-option [value]="0">Celsius</ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item>
            <ion-select label="Language" (ionChange)="setLanguage(id, $any($event))">
              <ion-select-option [value]="LANG.en_US">English</ion-select-option>
              <ion-select-option [value]="LANG.sv_SE">Swedish</ion-select-option>
              <ion-select-option [value]="LANG.es_US">Spanish</ion-select-option>
              <ion-select-option [value]="LANG.pt_BR">Portuguese</ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item>
            <ion-range
              style="padding: 24px 0 16px"
              pin="true"
              min="0"
              max="100"
              [value]="brightness"
              debounce="200"
              (ionChange)="onRangeChange($event)"
            >
              <ion-label style="font-size: 16px" slot="start">Brightness</ion-label>
              <ion-button
                slot="end"
                size="small"
                shape="round"
                [disabled]="!rangeChanged"
                (click)="setBrightnessConfig(id)"
              >
                <ion-label>Save</ion-label>
              </ion-button>
            </ion-range>
          </ion-item>
        </ion-item-group>
        <ion-item-group class="ion-no-padding">
          <ion-item (click)="setAlgo(id)">
            <ion-label>Set algorithm</ion-label>
          </ion-item>
          <ion-item (click)="factoryReset(id)" button loader>
            <ion-label>Factory reset</ion-label>
          </ion-item>
          <ion-item (click)="performFOTA(id)" button>
            <ion-label>Perform FW update</ion-label>
          </ion-item>
          <ion-item (click)="cancelFOTA()" button>
            <ion-label>Cancel FW update</ion-label>
          </ion-item>
        </ion-item-group>
      </span>
    }

    <ion-item-group>
      <ion-list-header>
        <ion-label>Other</ion-label>
      </ion-list-header>

      <ion-item (click)="setBatteryStatus()" button>
        <ion-label>Set battery status</ion-label>
      </ion-item>

      <ion-item (click)="mockFirmwareVersion()" button>
        <ion-label>Mock firmware version in state</ion-label>
      </ion-item>

      <ion-item (click)="mockCriticalFWUpdate()" button>
        <ion-label>Mock critical FW update</ion-label>
      </ion-item>
    </ion-item-group>

    @if (bluetoothMock$ | async; as bluetoothMock) {
      <ion-item-group>
        <ion-list-header>
          <ion-label>🛜 Bluetooth Plugin Mock Controls</ion-label>
        </ion-list-header>

        <ion-item>
          <ion-label>Toggle thermometer advertising</ion-label>

          <ion-toggle
            slot="end"
            mode="ios"
            [value]="bluetoothMock.thermometerAdvertising"
            [checked]="bluetoothMock.thermometerAdvertising"
            (ionChange)="toggleThermometerAdvertisingBleMock()"
          />
        </ion-item>

        <ion-item>
          <ion-label>Toggle unsynced temperature</ion-label>

          <ion-toggle
            slot="end"
            mode="ios"
            [value]="bluetoothMock.hasUnsyncedTemp"
            [checked]="bluetoothMock.hasUnsyncedTemp === 1"
            (ionChange)="toggleUnsyncedTempBleMock()"
          />
        </ion-item>

        <ion-item>
          <ion-label>Toggle FW version mock</ion-label>

          <ion-toggle
            slot="end"
            mode="ios"
            [value]="bluetoothMock.mockFWVersion"
            [checked]="bluetoothMock.mockFWVersion"
            (ionChange)="toggleFWVersionMockBleMock()"
          />
        </ion-item>

        <ion-item [disabled]="!bluetoothMock.mockFWVersion" (click)="setFWVersionBleMock()" button>
          <ion-label>Set firmware version</ion-label>

          <ion-note slot="end">{{ bluetoothMock.fwVersion }}</ion-note>
        </ion-item>
      </ion-item-group>
    }

    <ion-item-group class="ion-padding-horizontal">
      <ion-list-header>
        <ion-label>Logs</ion-label>
      </ion-list-header>

      @for (log of logs$ | async; track log) {
        <span style="display: block">{{ log }}</span>
      }
    </ion-item-group>
  </ion-list>
</ion-content>

<ion-footer>
  <ion-button expand="block" shape="round" size="small" (click)="copyLogs()">
    <ion-label>Copy logs</ion-label>
  </ion-button>

  <ion-button expand="block" shape="round" fill="outline" size="small" (click)="clearLogs()">
    <ion-label>Clear logs</ion-label>
  </ion-button>
</ion-footer>
