import { CommonModule } from '@angular/common'
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnInit,
  output,
} from '@angular/core'
import { RouterModule } from '@angular/router'
import { ICON, ICON_BY_DATAFLAG } from '@app/cnst/icons.cnst'
import { SharedModule } from '@app/shared.module'
import { IonicModule } from '@ionic/angular'
import { _numberEnumValues } from '@naturalcycles/js-lib'
import { DataFlag, DataIntensity } from '@naturalcycles/shared'
import { NCHaptics } from '@src/typings/capacitor'
import { BehaviorSubject } from 'rxjs'

export interface DataFlagChangeEvent {
  checked: boolean
  intensity?: DataIntensity | undefined
}

@Component({
  standalone: true,
  imports: [IonicModule, SharedModule, CommonModule, RouterModule],
  selector: 'app-data-flag-with-intensity-toggle',
  templateUrl: './data-flag-with-intensity-toggle.component.html',
  styleUrls: ['./data-flag-with-intensity-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataFlagWithIntensityToggleComponent implements OnInit {
  private cdr = inject(ChangeDetectorRef)
  @Input({ required: true })
  public dataFlag!: DataFlag

  @Input({ required: true })
  public dataFlagType!: 'pain' | 'skin'

  @Input()
  public checked = false

  @Input({ required: true })
  public dataIntensity: DataIntensity | undefined = undefined

  public dataFlagChange = output<DataFlagChangeEvent>()

  public scrollLock = output<boolean>()

  public ICON = ICON
  public DataFlag = DataFlag
  public DataIntensity = DataIntensity
  public ICON_BY_DATAFLAG = ICON_BY_DATAFLAG

  public showIntensityBar$ = new BehaviorSubject<boolean>(false)
  public intensityValue$ = new BehaviorSubject<DataIntensity | undefined>(undefined)

  private lastIntensity = this.dataIntensity

  public intensities = _numberEnumValues(DataIntensity).reverse()

  public ngOnInit(): void {
    this.intensityValue$.next(this.dataIntensity)
  }

  public onShortClick(): void {
    this.checked = !this.checked
    this.dataFlagChange.emit({ checked: this.checked, intensity: undefined })

    if (!this.checked) {
      const dataFlagChange = { checked: this.checked, intensity: undefined }
      this.intensityValue$.next(dataFlagChange.intensity)
    }
  }

  public onLongPressStart(): void {
    this.scrollLock.emit(true)
    this.showIntensityBar$.next(true)

    if (window.Capacitor.isNativePlatform()) void NCHaptics.impact()
  }

  public onLongPressMove(position: { deltaX: number; deltaY: number }): void {
    const { deltaY } = position
    const intensity = Math.ceil(Math.min(Math.max(-deltaY, 0), 90) / 30)
    if (intensity !== this.lastIntensity) {
      // only emit distinct values to prevent too many re-renders
      this.lastIntensity = intensity || undefined
      this.intensityValue$.next(intensity)
      if (intensity && window.Capacitor.isNativePlatform()) void NCHaptics.impact()
      this.cdr.detectChanges()
    }
  }

  public onLongPressEnd(): void {
    this.showIntensityBar$.next(false)
    this.checked = true
    this.dataFlagChange.emit({ checked: this.checked, intensity: this.lastIntensity })
    this.scrollLock.emit(false)

    this.cdr.detectChanges()
  }
}
