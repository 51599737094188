import { inject, Injectable } from '@angular/core'
import { select } from '@app/srv/store.service'
import { _objectAssign } from '@naturalcycles/js-lib'
import {
  AccountTM,
  CurrentPhase,
  CurrentPhaseId,
  DataFlag,
  Goal,
  HardwareId,
  SubscriptionStateFM,
  TestResult,
} from '@naturalcycles/shared'
import { dayjs } from '@naturalcycles/time-lib'
import { env } from '@src/environments/environment'
import { NCWatch } from '@src/typings/capacitor'
import { BehaviorSubject, combineLatestWith, debounceTime, Observable, startWith } from 'rxjs'
import { COLOR } from '../cnst/color.cnst'
import { HW_CONFIG_BY_HWID } from '../cnst/hardware.cnst'
import { isIOSApp } from '../cnst/userDevice.cnst'
import { PregnancyInfo, UFEntry } from '../model/uf.model'
import { UI } from '../reducers/ui.reducer'
import {
  iconByLHResult,
  IOS_ICON_BY_DATAFLAG,
  PredictionItem,
  PregnancyData,
  TodayData,
  WatchInput,
  WatchStatus,
} from './companionWatch.cnst'
import { DateFormat, DateService } from './date.service'
import { EventService } from './event.service'
import { getState } from './store.service'
import { tr } from './translation.util'
import { UFService } from './uf.service'

@Injectable({ providedIn: 'root' })
export class CompanionWatchService {
  private ufService = inject(UFService)
  private eventService = inject(EventService)
  private dateService = inject(DateService)

  @select(['account'])
  private account$!: Observable<AccountTM>

  @select(['subscriptions'])
  private subscriptions$!: Observable<SubscriptionStateFM>

  @select(['ui'])
  private ui$!: Observable<UI>

  public watchStatus$ = new BehaviorSubject<WatchStatus>({})

  public async init(): Promise<void> {
    if (!window.Capacitor.isNativePlatform() || !isIOSApp) return
    const { appVer } = getState()

    this.account$.subscribe(account => {
      void NCWatch.initWatch({
        input: {
          loadingState: {
            headline: tr('txt-today'),
            color: COLOR.GHOST,
            title: tr('txt-syncing'),
            unit: account.fahrenheit ? 'F' : 'C',
          },
          notConnectedState: {
            title: tr('aw-companion-not-connected-title'),
            text: tr('aw-companion-not-connected-text'),
            buttonTitle: tr('txt-retry'),
            analyticsId: 'noPhoneConnection',
          },
          outdatedState: {
            headline: tr('txt-today'),
            title: tr('aw-companion-outdated-text'),
            color: COLOR.GRAY,
            unit: account.fahrenheit ? 'F' : 'C',
          },
          analytics: {
            distinctId: account.externalAccountId,
            mixpanelToken: env.mixpanelServiceConf.mixpanelId,
            loggingEnabled: true, // env.mixpanelEnabled
            globalProperties: {
              appVersion: appVer,
              complete: !!account.completeDate,
              demoMode: !!account.demoMode,
              goal: Goal[account.goal!],
              plan: account.plan,
              hwId: HardwareId[account.hwId],
            },
          },
        },
      })
    })

    this.setupListeners()

    this.ufService.todayEntry$
      .pipe(
        startWith(undefined),
        combineLatestWith(
          this.account$,
          this.subscriptions$,
          this.ui$,
          this.ufService.showFertilityStatus$.pipe(startWith(undefined)),
          this.ufService.pregnancyInfo$.pipe(startWith(undefined)),
          this.ufService.currentPhase$.pipe(startWith(undefined)),
          this.eventService.onResume$.pipe(startWith(undefined)),
        ),
        debounceTime(100),
      )
      .subscribe(
        async ([
          todayEntry,
          account,
          subscriptions,
          ui,
          showFertilityStatus,
          pregnancyInfo,
          currentPhase,
        ]) => {
          await this.update(
            account,
            subscriptions,
            ui,
            todayEntry,
            showFertilityStatus,
            pregnancyInfo,
            currentPhase,
          )
        },
      )
  }

  private setupListeners(): void {
    void NCWatch.addListener('NCWatchStatus', data => {
      this.watchStatus$.next(data)
    })

    this.eventService.onResume$.subscribe(async () => {
      const sessionDetails = await NCWatch.getSessionDetails()
      this.watchStatus$.next(sessionDetails)
    })
  }

  private async update(
    account: AccountTM,
    subscriptions: SubscriptionStateFM,
    ui: UI,
    today?: UFEntry,
    showFertilityStatus?: boolean,
    pregnancyInfo?: PregnancyInfo,
    currentPhase?: CurrentPhase,
  ): Promise<void> {
    if (ui.ghostLoader) return // don't update watch with ghost state

    const input = this.getTheWatchState(
      account,
      subscriptions,
      ui,
      today,
      showFertilityStatus,
      pregnancyInfo,
      currentPhase,
    )

    await NCWatch.updateWatch({
      input,
    })
  }

  private getTheWatchState(
    account: AccountTM,
    subscriptions: SubscriptionStateFM,
    ui: UI,
    today?: UFEntry,
    showFertilityStatus?: boolean,
    pregnancyInfo?: PregnancyInfo,
    currentPhase?: CurrentPhase,
  ): WatchInput {
    const isPartner = !!getState().partnerAccount

    if (!account.id && !isPartner) {
      return {
        message: {
          title: tr('aw-companion-not-logged-in-title'),
          text: tr('aw-companion-not-logged-in'),
          analyticsId: 'loggedOut',
        },
      }
    }

    if (account.demoMode) {
      return {
        message: {
          text: tr('aw-companion-demo-mode'),
          analyticsId: 'demoMode',
        },
      }
    }

    if (!subscriptions.current && !isPartner) {
      return {
        message: {
          text: tr('aw-companion-no-subscription'),
          analyticsId: 'noSubscription',
        },
      }
    }

    if (!ui.online) {
      return {
        message: {
          text: tr('aw-companion-offline'),
          analyticsId: 'offline',
          iconName: 'exclamationmark.triangle',
          buttonTitle: tr('txt-retry'),
        },
      }
    }

    if (!today || !showFertilityStatus || (!account.completeDate && !isPartner)) {
      return {
        message: {
          text: tr('aw-companion-open-app'),
          analyticsId: 'noUFForToday',
        },
      }
    }

    return {
      app: {
        today: this.getTodayData(account, today, currentPhase),
        ui: {
          today: tr('txt-today'),
          footnote: tr('aw-companion-add-more-data'),
          cycleDay: tr('txt-cycle-day-short'),
          predictions: tr('txt-predictions'),
        },
        pregnancy: this.getPregnancyData(account, pregnancyInfo),
      },
    }
  }

  private getPregnancyData(
    account: AccountTM,
    pregnancyInfo?: PregnancyInfo,
  ): PregnancyData | undefined {
    if (!pregnancyInfo || account.goal !== Goal.PREGNANT) return

    const { conceptionDate, dueDate, weekString } = pregnancyInfo

    return {
      week: `${tr('txt-week')} ${weekString}`,
      title: tr('txt-pregnancy-dates'),
      conceptionTitle: tr('txt-conception'),
      conceptionDate: dayjs(conceptionDate).unix(),
      dueDateTitle: tr('txt-due-date'),
      dueDate: dayjs(dueDate).unix(),
    }
  }

  private getTodayData(account: AccountTM, today: UFEntry, currentPhase?: CurrentPhase): TodayData {
    const {
      fertilityStatus,
      temperature,
      deviationReasons,
      cd,
      date,
      colorClass,
      code: { defPreg },
      goal,
    } = today

    const todayData: TodayData = {
      date,
      weekday: this.dateService.localizeDate(dayjs(date), DateFormat.WEEKDAY),
      cd,
      dateLabel: this.getTodayLabel(date, cd, goal, today),
      fertilityStatus: tr(fertilityStatus?.key, { num: fertilityStatus?.num }),
      fertilityColor: this.ufService.hexFromColor(colorClass),
      unit: account.fahrenheit ? 'F' : 'C',
      temperature,
      temperatureInfo: this.getTemperatureViewData(today, account.hwId).subtitle,
      deviated: !!deviationReasons?.length,
      deviationIcons: this.getTemperatureViewData(today, account.hwId).icons,
    }

    // pregnant, recovery or postpartum users should not see cycle phase & today predictions
    if (defPreg || account.goal === Goal.RECOVERY || account.goal === Goal.POSTPARTUM) {
      return todayData
    }

    if (currentPhase) {
      _objectAssign(todayData, {
        phaseTitle: tr('txt-cycle-phase'),
        phaseDescription: tr('today-cycle-phase--' + CurrentPhaseId[currentPhase.id]),
      })
    }

    const predictions = this.getPredictions(today)

    return {
      ...todayData,
      predictions,
    }
  }

  private getTodayLabel(date: string, cd: number, goal: Goal, todayData: UFEntry): string {
    const dateLong = this.dateService.localizeDate(dayjs(date), DateFormat.DAY_MONTH)
    const dateShort = this.dateService.localizeDate(dayjs(date), DateFormat.DAY_MONTH_SHORT)
    const weekday = this.dateService.localizeDate(dayjs(date), DateFormat.WEEKDAY)
    const weekdayShort = this.dateService.localizeDate(dayjs(date), DateFormat.WEEKDAY_SHORT)
    const cycleDayLong = tr(`txt-cycle-day`)

    if (goal === Goal.POSTPARTUM) {
      if (todayData.isCycleAfterBirth) {
        const ppWeeks = Math.floor(cd / 7)
        const ppLabel = ppWeeks >= 4 ? `- ${ppWeeks} ${tr('txt-weeks')}` : ''

        return `${dateShort} - ${weekdayShort} ${ppLabel}`
      }
      return `${dateShort} - ${weekdayShort} - ${cycleDayLong} ${cd}`
    }

    if (goal === Goal.PREGNANT) {
      return `${dateLong} - ${weekday}`
    }

    return `${weekday} - ${cycleDayLong} ${cd}`
  }

  private getPredictions(entry: UFEntry): PredictionItem[] | undefined {
    const predictions: PredictionItem[] = []

    const {
      lhTest,
      code: { checkLh, ovulation },
    } = entry

    // predicted ovulation
    if (ovulation) {
      predictions.push({
        text: tr(`txt-ovulation--PREDICTION`),
        icon: 'ovulation_prediction',
        prediction: true,
      })
    }

    // added LH
    if (lhTest) {
      predictions.push({
        text: tr(`txt-testResult-LH--${TestResult[lhTest]}`),
        icon: iconByLHResult[lhTest],
      })

      // predicted LH
    } else if (checkLh) {
      predictions.push({
        text: tr('txt-lh-recommended'),
        icon: `lh_prediction`,
        prediction: true,
      })
    }

    return predictions.length ? predictions : undefined
  }

  private getTemperatureViewData(
    today: UFEntry,
    hwId: HardwareId,
  ): { subtitle?: string; icons: string[] } {
    const deviationReasons = [...(today.deviationReasons || []), ...(today.ouraFlags || [])]

    if (!deviationReasons?.length) {
      const timestamp =
        today.temperatureMeasuredTimestamp || today.temperatureUpdatedTimestamp || today.updated
      const formattedTimestamp =
        timestamp &&
        today.temperature &&
        this.dateService.localizeDateTime(dayjs.unix(timestamp), DateFormat.TIME)

      if (HW_CONFIG_BY_HWID[hwId].type === 'wearable' && !formattedTimestamp) {
        return { subtitle: tr('txt-not-enough-data'), icons: [] }
      }

      return {
        subtitle: formattedTimestamp ? `${tr('txt-added')} ${formattedTimestamp}` : undefined,
        icons: [],
      }
    }

    if (deviationReasons?.length === 1) {
      const deviationReason = deviationReasons[0]!
      const subtitle =
        deviationReason === DataFlag.DEVIATION_REASON_ALGO
          ? tr('oura-flag-excluded')
          : tr(`txt-dataflag--${DataFlag[deviationReason]}`)

      return {
        subtitle,
        icons: this.getDeviationIcons(deviationReasons),
      }
    }

    return {
      icons: this.getDeviationIcons(deviationReasons),
    }
  }

  private getDeviationIcons(deviations: DataFlag[]): string[] {
    return deviations.map(deviation => IOS_ICON_BY_DATAFLAG[deviation]!).filter(Boolean)
  }
}
