import { Component, Input } from '@angular/core'
import { confettiService } from '@app/srv/confetti.service'
import { runOutsideAngular } from '@app/srv/di.service'
import { IonicModule } from '@ionic/angular'
import { SharedModule } from '@src/app/shared.module'
import { BaseModal } from '../../base.modal'

@Component({
  standalone: true,
  selector: 'page-oura-auth-success',
  imports: [IonicModule, SharedModule],
  templateUrl: 'oura-auth-success.modal.html',
  styleUrls: ['../../welcome/default-content.base.scss'],
  styles: [
    '.content { height: 100%; display: flex; flex-direction: column; justify-content: space-evenly; }',
  ],
})
export class OuraAuthSuccessModal extends BaseModal {
  className = 'OuraAuthSuccessPage'

  @Input()
  private confetti?: boolean

  @Input()
  public ctaTitle = 'txt-done-only'

  public override async ionViewWillEnter(): Promise<void> {
    if (this.confetti) {
      const { confettiService } = await import('@app/srv/confetti.service')
      runOutsideAngular(() => void confettiService.addConfetti())
    }
  }

  public override ionViewWillLeave(): void {
    confettiService.reset()
  }
}
