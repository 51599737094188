import { Component, inject, Input, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { ColorBySegment, SuperSegmentValue } from '@app/cnst/add-data.cnst'
import { NavigationParams } from '@app/cnst/nav.params.cnst'
import { AddDataDay, AddDataService } from '@app/pages/add-data/add-data.service'
import { BaseModal } from '@app/pages/base.modal'
import { MarkdownService } from '@app/srv/markdown.service'
import { addNavParams } from '@app/srv/nav.service'
import { getState, select } from '@app/srv/store.service'
import { DailyEntryBM, DataFlag, GlossaryItem, Goal, TestResult } from '@naturalcycles/shared'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

enum SensitiveTrackerControls {
  EMERGENCY = 'emergency',
  PREG_TEST = 'pregTest',
}

export enum SensitiveTrackersDismissReason {
  SAVED_END_PREG = 1,
  OPEN_END_PREG_NEGATIVE_TEST = 2,
  OPEN_END_PREG_NO_LONGER_PREGNANT = 3,
  OPEN_UNPLANNED_PREG_POSITIVE_TEST = 4,
}

@Component({
  selector: 'app-sensitive-trackers-modal',
  templateUrl: './sensitive-trackers.modal.html',
  styleUrls: ['./sensitive-trackers.modal.scss'],
})
export class SensitiveTrackersModal extends BaseModal implements OnInit {
  private addDataService = inject(AddDataService)
  private fb = inject(FormBuilder)
  private markdownService = inject(MarkdownService)
  className = 'SensitiveTrackersModal'

  @Input()
  private entry!: DailyEntryBM

  @Input()
  private dayParams!: AddDataDay

  @select(['account', 'goal'])
  public goal$!: Observable<Goal>

  @select(['userFertility', 'pregnantNow'])
  public pregnantNow$!: Observable<boolean>

  @select(['glossary', 'items'])
  private items$!: Observable<GlossaryItem[]>

  public emergencyContraceptivesGlossary$ = this.items$.pipe(
    map(() => this.markdownService.getGlossaryItem('emergencyContraceptives')),
  )

  public pregTestGlossary$ = this.items$.pipe(
    map(() => this.markdownService.getGlossaryItem('pregTestPrevent')),
  )

  public form!: FormGroup
  public Goal = Goal
  public ColorBySegment = ColorBySegment
  public SensitiveTrackerControls = SensitiveTrackerControls

  private account = getState().account

  public emergencyContraceptives$ = this.goal$.pipe(
    map(goal => this.addDataService.getEmergencyContraceptiveSegments(goal)),
  )

  public pregTestSegments$ = this.goal$.pipe(
    map(goal => this.addDataService.getPregSegments(goal, true)),
  )

  public noLongerPregnantButton$ = this.pregnantNow$.pipe(
    map(pregnantNow => {
      return this.addDataService.shouldShowNoLongerPregnantButton(
        pregnantNow,
        this.dayParams.isPregnantDay,
      )
    }),
  )

  public ready = false

  ngOnInit(): void {
    this.form = this.fb.group({
      [SensitiveTrackerControls.EMERGENCY]: new FormControl<DataFlag | undefined>(
        this.entry.dataFlags.includes(DataFlag.MORE_EMERGENCY_PILL)
          ? DataFlag.MORE_EMERGENCY_PILL
          : this.entry.dataFlags.includes(DataFlag.MORE_EMERGENCY_IUD)
            ? DataFlag.MORE_EMERGENCY_IUD
            : undefined,
      ),
      [SensitiveTrackerControls.PREG_TEST]: new FormControl<TestResult | undefined>(
        this.entry.pregTest,
      ),
    })
  }

  override ionViewDidEnter(): void {
    this.ready = true
  }

  public onControlChange(control: SensitiveTrackerControls, value: SuperSegmentValue): void {
    if (!this.form.controls[control]) return
    const { goal, pregnantNow } = this.account

    if (
      control === SensitiveTrackerControls.PREG_TEST &&
      value.value === TestResult.NO &&
      pregnantNow
    ) {
      void this.dismissModal(SensitiveTrackersDismissReason.OPEN_END_PREG_NEGATIVE_TEST)

      return
    }

    this.form.controls[control]!.setValue(value.value)
    addNavParams({ [NavigationParams.TRACKERS]: this.form.value })

    if (
      control === SensitiveTrackerControls.PREG_TEST &&
      value.value === TestResult.YES &&
      !pregnantNow &&
      (goal === Goal.PREVENT || goal === Goal.POSTPARTUM)
    ) {
      void this.dismissModal(SensitiveTrackersDismissReason.OPEN_UNPLANNED_PREG_POSITIVE_TEST)

      return
    }
  }

  public noLongerPregnantClicked(): void {
    void this.dismissModal(SensitiveTrackersDismissReason.OPEN_END_PREG_NO_LONGER_PREGNANT)
  }
}
