import { Component, Input } from '@angular/core'

@Component({
  standalone: true,
  selector: 'app-intro-slides-pagination-ab250',
  templateUrl: 'intro-slides-pagination-ab250.component.html',
  styleUrls: ['intro-slides-pagination-ab250.component.scss'],
})
export class IntroSlidesPaginationAb250Component {
  @Input()
  protected numberOfSlides = 0

  @Input()
  protected activeIndex = 0

  protected get slides(): number[] {
    return Array.from({ length: this.numberOfSlides })
  }
}
