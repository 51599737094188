import { Component, inject, Input, ViewChild } from '@angular/core'
import { EVENT } from '@app/analytics/analytics.cnst'
import { AnalyticsService } from '@app/analytics/analytics.service'
import { ICON } from '@app/cnst/icons.cnst'
import { BaseModal } from '@app/pages/base.modal'
import { Keyboard } from '@capacitor/keyboard'
import { DomController, IonTextarea } from '@ionic/angular'
import { _filterUndefinedValues, StringMap } from '@naturalcycles/js-lib'
import { FeedbackItem, FeedbackType } from '@src/app/cnst/feedback.cnst'
import { isWebApp } from '@src/app/cnst/userDevice.cnst'
import { fromEvent } from 'rxjs'

@Component({
  selector: 'app-feedback-modal',
  templateUrl: './feedback.modal.html',
  styleUrls: ['./feedback.modal.scss'],
})
export class FeedbackModal extends BaseModal {
  private analyticsService = inject(AnalyticsService)
  private dom = inject(DomController)
  className = 'FeedbackModal'

  @Input()
  public id!: string

  @Input()
  public title!: string

  @Input()
  public type!: FeedbackType

  @Input()
  public feedbackItems?: FeedbackItem[]

  @ViewChild(IonTextarea)
  public textarea?: IonTextarea

  public ICON = ICON
  public maxLength = 255

  private results: StringMap<boolean> = {}

  override ionViewWillEnter(): void {
    if (this.type !== 'input') return
    if (isWebApp) return

    this.dom.write(() => {
      document.documentElement.classList.add('keyboard-open')
      document.documentElement.style.setProperty(
        '--keyboardHeight',
        '250px', // estimation of keyboard height
      )
    })

    this.subscriptions.push(
      fromEvent(window, 'keyboardWillShow').subscribe((e: any) => {
        this.dom.write(() => {
          document.documentElement.style.setProperty('--keyboardHeight', `${e.keyboardHeight}px`)
        })
      }),
    )
  }

  public override ionViewDidEnter(): void {
    void this.textarea?.setFocus()
  }

  override ionViewWillLeave(): void {
    if (this.type !== 'input') return
    if (isWebApp) return

    void Keyboard.hide()

    this.dom.write(() => {
      document.documentElement.classList.remove('keyboard-open')
      document.documentElement.style.setProperty('--keyboardHeight', '0px')
    })
  }

  public itemChanged(event: CustomEvent): void {
    const [id, value] = event.detail?.value?.split('--') || []
    if (!id || !value) return

    this.results[id] = !!Number(value)
  }

  public skip(): void {
    void this.analyticsService.trackEvent(EVENT.FEEDBACK_SKIPPED, {
      id: this.id,
    })

    void this.dismissModal('skip')
  }

  public submit(): void {
    void this.analyticsService.trackEvent(
      EVENT.FEEDBACK_ANSWER,
      _filterUndefinedValues({
        id: this.id,
        input: this.type === 'input' ? this.textarea?.value : undefined,
        ...this.results,
      }),
    )

    void this.dismissModal(this.results)
  }

  public trackByUid(_index: number, item: FeedbackItem): string {
    return item.uid
  }
}
