import { BackendResponseFM, RemoteConfigApp } from '@naturalcycles/shared'
import { Action, Reducer } from 'redux'

type State = RemoteConfigApp

const initialState = {
  medicalDeviceVersion: '',
  sentrySampleRate: 1,
  recaptchaEnabled: true,
} as State

const actions: any = {
  onBackendResponse(state: State, action: { type: string; payload: BackendResponseFM }): State {
    // replace!
    if (action.payload.remoteConfigApp) {
      return {
        ...action.payload.remoteConfigApp,
      }
    }

    return state
  },
}

// biome-ignore lint/style/useDefaultParameterLast: ok
export const remoteConfigReducer: Reducer<State> = (state = initialState, action: Action) => {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
