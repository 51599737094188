import { Component, ElementRef, inject, Input, ViewChild } from '@angular/core'
import { EVENT } from '@app/analytics/analytics.cnst'
import { AnalyticsService } from '@app/analytics/analytics.service'
import { ICON, ICON_BY_LH } from '@app/cnst/icons.cnst'
import { SNACKBAR } from '@app/cnst/snackbars.cnst'
import { decorate, loader, LoaderType } from '@app/decorators/decorators'
import { BaseModal } from '@app/pages/base.modal'
import { LhService } from '@app/srv/lh.service'
import { PopupController, Priority } from '@app/srv/popup.controller'
import { SnackbarService } from '@app/srv/snackbar.service'
import { dispatch, getState } from '@app/srv/store.service'
import { tr } from '@app/srv/translation.util'
import { logUtil } from '@app/util/log.util'
import { prf } from '@app/util/perf.util'
import { CameraPreview } from '@awesome-cordova-plugins/camera-preview/ngx'
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'
import { ActionSheetController } from '@ionic/angular'
import { DeferredPromise, pDefer, pDelay } from '@naturalcycles/js-lib'
import { TestResult } from '@naturalcycles/shared'
import { NativeSettings, NCHaptics, NCLH } from '@src/typings/capacitor'
import { InfoButtonComponent } from '../../cmp/info-button/info-button.component'

type Base64String = string

@Component({
  selector: 'page-lh-scanner-modal',
  templateUrl: './lh-scanner.modal.html',
  styleUrls: ['./lh-scanner.modal.scss'],
})
export class LhScannerModal extends BaseModal {
  private analyticsService = inject(AnalyticsService)
  private cameraPreview = inject(CameraPreview)
  private popupController = inject(PopupController)
  private actionSheetController = inject(ActionSheetController)
  private snackbarService = inject(SnackbarService)
  private lhService = inject(LhService)
  className = 'LhScannerModal'

  @Input()
  public day?: string

  @ViewChild('scannerContainer')
  scannerContainer!: ElementRef

  @ViewChild('infoButton')
  infoButton!: InfoButtonComponent

  public previewImage?: string
  public result?: {
    testResult: TestResult
    mlOutput: number
  }

  private originalImgBase64?: string
  private userImgBase64?: string

  public TestResult = TestResult
  public ICON_BY_LH = ICON_BY_LH
  public ICON = ICON

  private timeout?: NodeJS.Timeout
  private source?: 'camera' | 'gallery'

  private cameraStarted?: DeferredPromise

  public entered = false
  public destroyed = false

  public override ionViewDidEnter(): void {
    super.ionViewDidEnter()

    this.entered = true

    if (!getState().userSettings.lhScannerInfoSeen) {
      void this.infoButton.openInfoModal(undefined, { ctaTitle: 'btn-ok' })

      dispatch('extendUserSettings', { lhScannerInfoSeen: true })

      return // return and dont start the camera, it will be started when infomodal closes
    }

    void this.startCamera()
  }

  public override ionViewWillLeave(): void {
    super.ionViewWillLeave()

    void this.stopCamera()

    this.destroyed = true
  }

  public async openGallery(): Promise<void> {
    void this.stopCamera()

    // Select image from user's gallery
    void this.selectImageFromGallery()
      .then(async image => {
        if (!image) return

        this.source = 'gallery'
        void this.analyticsService.trackEvent(EVENT.LH_SCANNER_IMAGE_PICKED, {
          source: this.source,
        })

        const imageData = image.base64String

        // Run LH test detection
        const okImage = await this.runImageDetection(imageData, 'GALLERY')

        if (!okImage) {
          await this.showBadImageAlert()

          return
        }

        // Run LH test classification
        await this.runImageClassification()
      })
      .catch(() => {
        void this.startCamera()
      })
  }

  private async showBadImageAlert(): Promise<void> {
    const alert = await this.popupController.presentAlert(
      {
        header: tr('lh-scanner-bad-image-alert-title'),
        message: tr('lh-scanner-bad-image-alert-body'),
        buttons: [
          {
            text: tr('lh-scanner-bad-image-btn-camera'),
            role: 'cancel',
            handler: () => void this.startCamera(),
          },
          {
            text: tr('lh-scanner-bad-image-btn-gallery'),
            handler: () => void this.openGallery(),
          },
        ],
      },
      'alert-lhScanner-badImg',
      Priority.IMMEDIATE,
      0,
    )

    await alert.onWillDismiss()
  }

  private async selectImageFromGallery(): Promise<
    { base64String: Base64String; format: string } | undefined
  > {
    await this.checkPermission('photos')

    return (await Camera.getPhoto({
      quality: 95,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      source: CameraSource.Photos,
      width: 672,
      height: 672,
    })) as { base64String: Base64String; format: string }
  }

  private async checkPermission(type: 'camera' | 'photos'): Promise<boolean> {
    const permissions = await Camera.checkPermissions()

    if (permissions[type] === 'granted' || permissions[type] === 'limited') return true

    if (permissions[type] === 'denied') {
      if (permissions['camera'] === 'granted') void this.stopCamera()

      await this.showPermissionsAlert(type)

      if (permissions['camera'] === 'granted') void this.startCamera()

      return false
    }

    await Camera.requestPermissions({ permissions: [type] })

    // check again after requesting
    return await this.checkPermission(type)
  }

  private async showPermissionsAlert(type: string): Promise<void> {
    const alert = await this.popupController.presentAlert(
      {
        header: tr(`lh-scanner-no-permission-title--${type}`),
        message: tr(`lh-scanner-no-permission-body--${type}`),
        buttons: [
          {
            text: tr('btn-cancel'),
            role: 'cancel',
          },
          {
            text: tr('txt-open-settings'),
            handler: () => void NativeSettings.open(),
          },
        ],
      },
      'alert-lhScanner-no-permission',
      Priority.IMMEDIATE,
    )

    await alert.onWillDismiss()
  }

  public cancel(): void {
    void this.dismissModal()
  }

  @loader(LoaderType.BUTTON)
  public async save(): Promise<void> {
    if (!this.originalImgBase64 || !this.userImgBase64 || !this.result) {
      logUtil.warn('Trying to save LH image without base64 data or result')

      return
    }

    const { testResult, mlOutput } = this.result

    void this.analyticsService.trackEvent(EVENT.LH_SCANNER_SAVE_RESULT, {
      testResult: testResult === TestResult.YES,
      source: this.source,
      day: this.day,
    })

    const imageId = await this.lhService.uploadImage(this.originalImgBase64, this.userImgBase64, {
      mlOutput,
      testResult,
    })

    void this.dismissModal({
      result: testResult,
      imageId,
    })
  }

  public retake(): void {
    void this.startCamera()
  }

  public async report(): Promise<void> {
    const actionSheet = await this.actionSheetController.create({
      buttons: [
        {
          icon: 'warning-outline',
          text: tr('lh-scanner-report-cta'),
          handler: () => this.reportImage(),
        },
        {
          icon: 'close',
          text: tr('btn-cancel'),
          role: 'cancel',
        },
      ],
    })

    void actionSheet.present()
  }

  private async reportImage(): Promise<void> {
    void this.analyticsService.trackEvent(EVENT.LH_SCANNER_REPORT_RESULT, {
      testResult: this.result?.testResult === TestResult.YES,
    })

    if (!this.originalImgBase64 || !this.result) return

    const { mlOutput, testResult } = this.result

    await this.lhService.reportImage(this.originalImgBase64, {
      mlOutput,
      testResult,
    })

    this.snackbarService.showSnackbar(SNACKBAR.LH_SCANNER_REPORT)
  }

  public async startCamera(): Promise<void> {
    const permission = await this.checkPermission('camera')

    if (!permission) return

    this.cameraStarted = pDefer<void>()

    this.previewImage = undefined
    this.result = undefined
    this.originalImgBase64 = undefined
    this.userImgBase64 = undefined

    const { x, y, width, height } = this.scannerContainer.nativeElement.getBoundingClientRect()

    await this.cameraPreview.startCamera({
      storeToFile: false,
      x,
      y,
      width,
      height,
      camera: 'rear',
      tapPhoto: false,
    })

    this.cameraStarted.resolve()

    await pDelay(1000) // wait 1s before start taking snapshots

    if (this.timeout) clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      void this.takeSnapshot()
    }, 500)
  }

  public async stopCamera(): Promise<void> {
    if (this.timeout) clearTimeout(this.timeout)

    if (this.cameraStarted) await this.cameraStarted

    await this.cameraPreview
      .stopCamera()
      .catch(err => logUtil.log(`cameraPreview.stopCamera error: ${err}`))
  }

  private async takeSnapshot(_attempt?: number): Promise<void> {
    if (this.destroyed) {
      void this.stopCamera()

      return
    }

    let attempt = _attempt || 1
    const imageData = await this.cameraPreview.takeSnapshot({ quality: 95 }).catch(err => {
      logUtil.log(`cameraPreview.takeSnapshot error: ${err}`)
    })

    if (!imageData) return

    const okImage = await this.runImageDetection(imageData[0], 'CAMERA', attempt)

    if (!okImage) {
      // keep looking
      this.timeout = setTimeout(() => {
        attempt++

        void this.takeSnapshot(attempt)
      }, 300)
      return
    }

    this.source = 'camera'
    void this.analyticsService.trackEvent(EVENT.LH_SCANNER_IMAGE_PICKED, { source: this.source })

    if (this.timeout) clearTimeout(this.timeout)

    void this.stopCamera()

    await this.runImageClassification()
  }

  private async runImageDetection(
    imageData: string,
    imageSource: 'CAMERA' | 'GALLERY',
    attempt?: number,
  ): Promise<boolean> {
    let isLHTest: boolean

    try {
      const startTimestamp = Date.now()
      ;({ isLHTest } = await NCLH.detectLHTest({ imageData, imageSource, attempt }))
      prf('AddData.LH.detectLHTest', startTimestamp)
    } catch (err) {
      logUtil.error(`LH Image detection failed, ${err}`)
      return false
    }

    if (isLHTest) {
      void NCHaptics.impact()

      this.previewImage = 'data:image/jpeg;base64,' + imageData

      void this.analyticsService.trackEvent(EVENT.LH_SCANNER_TEST_DETECTED, {
        attempt,
      })
    }

    return isLHTest
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
  })
  private async runImageClassification(): Promise<void> {
    let isPositive: boolean
    let mlOutput: number
    let img: string
    let userImg: string

    try {
      const startTimestamp = Date.now()
      ;({ isPositive, mlOutput, img, userImg } = await NCLH.classifyLHTest())
      prf('AddData.LH.classifyLHTest', startTimestamp)
    } catch (err) {
      logUtil.error(`LH Image classification failed, ${err}`)
      return
    }

    this.userImgBase64 = userImg
    this.originalImgBase64 = img

    this.result = {
      testResult: isPositive ? TestResult.YES : TestResult.NO,
      mlOutput,
    }

    void this.analyticsService.trackEvent(EVENT.LH_SCANNER_TEST_CLASSIFIED, {
      testResult: isPositive,
    })
  }
}
