import { inject, Pipe, PipeTransform } from '@angular/core'
import { DomSanitizer, SafeHtml, SafeValue } from '@angular/platform-browser'

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  private domSanitizer = inject(DomSanitizer)

  transform(html: string | SafeValue): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(html as string)
  }
}
