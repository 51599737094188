import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { SharedModule } from '@app/shared.module'
import { IonicModule } from '@ionic/angular'
import { NavBannerComponent } from './nav-banner.component'

@NgModule({
  declarations: [NavBannerComponent],
  imports: [IonicModule, SharedModule, CommonModule],
  exports: [NavBannerComponent],
})
export class NavBannerComponentModule {}
