import { AfterViewInit, Directive, ElementRef, inject, Input, NgZone, output } from '@angular/core'
import { GestureController } from '@ionic/angular'
import { isAndroidApp } from '@src/app/cnst/userDevice.cnst'

@Directive({
  selector: '[long-press]',
})
export class LongPressDirective implements AfterViewInit {
  private el = inject(ElementRef)
  private gestureCtrl = inject(GestureController)
  private zone = inject(NgZone)
  tap = output()

  longPressStart = output()

  longPressMove = output<{
    deltaY: number
    deltaX: number
  }>()

  longPressEnd = output()

  shortClick = output()

  @Input()
  delay = 300

  private action?: NodeJS.Timeout

  private positions = {
    start: {
      x: 0,
      y: 0,
    },
    current: {
      x: 0,
      y: 0,
    },
  }

  private isHoldingElement = false
  private isLongPress = false
  private startTime = 0

  ngAfterViewInit(): void {
    this.loadLongPressOnElement()
  }

  private loadLongPressOnElement(): void {
    const gesture = this.gestureCtrl.create({
      el: this.el.nativeElement,
      threshold: 0,
      gestureName: 'long-press',
      disableScroll: true,
      onStart: ev => {
        this.isHoldingElement = true
        this.checkLongPressAction()

        this.positions = {
          start: { x: ev.startX, y: ev.startY },
          current: { x: ev.currentX, y: ev.currentY },
        }
        this.startTime = Date.now()
      },
      onMove: ev => {
        this.positions.current = { x: ev.currentX, y: ev.currentY }

        if (this.isLongPress) {
          this.longPressMove.emit({ deltaX: ev.deltaX, deltaY: ev.deltaY })
        }
      },
      onEnd: _ev => {
        if (this.isLongPress) {
          this.longPressEnd.emit()
        } else if (!this.didPositionChange() && !this.isUnintededClick()) {
          this.shortClick.emit()
        }

        this.isHoldingElement = false
        this.isLongPress = false
      },
    })
    gesture.enable(true)
  }

  private checkLongPressAction(): void {
    if (this.action) {
      clearInterval(this.action)
    }
    this.action = setTimeout(() => {
      this.zone.run(() => {
        // Check distance
        if (this.didPositionChange()) {
          // User dragged finger
          return
        }

        if (this.isHoldingElement) {
          this.longPressStart.emit()

          this.isLongPress = true
        } else {
          this.tap.emit()
        }
      })
    }, this.delay)
  }

  private didPositionChange(): boolean {
    const xDistance = Math.abs(this.positions.start.x - this.positions.current.x)
    const yDistance = Math.abs(this.positions.start.y - this.positions.current.y)

    return xDistance > 15 || !!yDistance
  }

  private isUnintededClick(): boolean | undefined {
    if (!isAndroidApp) return

    return Date.now() - this.startTime < 10
  }
}
