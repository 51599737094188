@if (banner$ | async; as banner) {
  <div
    class="navbanner"
    [ngClass]="{ 'navbanner--clickable': banner.onClick }"
    (click)="banner.onClick && banner.onClick()"
  >
    <ion-icon [src]="banner.icon || ICON.WARNING" color="warning" size="small" />
    <span>{{ banner.title | tr }}</span>
  </div>
}
