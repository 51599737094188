import { AppearanceSettings } from '@app/srv/appearance.service'
import {
  _uniq,
  IsoDateString,
  localTime,
  StringMap,
  UnixTimestampNumber,
} from '@naturalcycles/js-lib'
import { HardwareId } from '@naturalcycles/shared'
import { Action } from 'redux'
import { PendingHardwareDeviceSettings } from './hardwareDevice.reducer'

export interface DataMeterState {
  percentage?: number
  hidden: boolean
  date?: string
}

export interface FirmwareUpdate {
  hwId: HardwareId
  mac: string
  previousVersion: string
  fwVersion: string
  timestamp: number
}

export interface FotaAttempt extends FirmwareUpdate {
  success: boolean
}

export interface UserSettings {
  // Not used since v4.1.7
  showTodayPredictions?: boolean
  showMonthPredictions?: boolean

  /**
   * null means 'unknown'
   * i.e., hasn't received `sessions` from the backend
   */
  multipleSessions: boolean | null
  showPredictions?: boolean
  dismissedMyCycleWelcome?: boolean
  /**
   * @deprecated in favour of dismissedFeedbackAtCycle
   */
  dismissedFeedback?: boolean
  dismissedFeedbackAtCycle?: number[]
  showedOfflinePopup?: boolean
  lastSeenBlogPost?: string
  wakeUpTime?: string
  holisticGraphSeen?: boolean
  declinedCRMAlert?: boolean
  partnerWelcomeMsgShown?: boolean
  lhScannerInfoSeen?: boolean
  appearance?: AppearanceSettings
  yearInReviewOpenDate?: IsoDateString
  clickedMensCardDate?: IsoDateString
  lastActive?: UnixTimestampNumber
  lastPostpartumCheckin?: IsoDateString

  // Snackbars
  deviationSickSnackbarShown?: boolean
  deviationSleepSnackbarShown?: boolean
  deviationAlcoholSnackbarShown?: boolean
  deviationAlgoSnackbarShown?: boolean
  lhTestSnackbarShown?: boolean
  pregTestSnackbarShown?: boolean
  spottingSnackbarShown?: boolean
  withdrawalBleedSnackbarShown?: boolean
  miscarriageBleedSnackbarShown?: boolean
  ouraSyncDelaySnackbarShown?: boolean
  ouraExcludedSleepSnackbarShown?: boolean
  ouraAdjustedTempSnackbarShown?: boolean
  ouraIncompleteDataSnackbarShown?: boolean

  // Feedback
  lhCompareFeedbackShown?: boolean
  deviationSleepFeedbackShown?: boolean
  holisticGraphFeedbackShown?: boolean
  partnerFeedbackDismissed?: boolean
  appleWatchFeedbackShown?: boolean
  showedMensCardFeedback?: boolean

  // partners
  invitedPartnerEmails?: string[]

  // Bluetooth
  toggledBluetoothSettings?: boolean
  pendingBluetoothSettings: PendingHardwareDeviceSettings
  pendingBluetoothSettingsAlertShown?: boolean

  // Badges
  ouraRingPromotionShown?: boolean

  // Tooltips
  missingCoverLineTooltipShown?: boolean

  // DataMeter state
  dataMeterState?: DataMeterState

  // Permissions state
  askedForOuraNotificationPermissions?: boolean

  // Hardware device settings
  testMeasurementDone?: boolean
  latestT3FWVersionDownloaded?: string
  firmwareUpdates?: FirmwareUpdate[]
  failedFotaAttempts?: FotaAttempt[]
  userFertilityFotaReminderShownDate?: IsoDateString

  // Apple Health
  hkExportEnabled?: boolean
  hkImportEnabled?: boolean
  hkSyncConfirmationDisplayed?: boolean

  /**
   * StringMap from HKDataIdentifier to UnixTimestampNumber matching endTimestamp of the last read sample
   */
  hkScienceDataPosted?: StringMap<UnixTimestampNumber>

  // Apple Watch
  /**
   * Date of last imported Apple Watch Wrist temperature
   */
  lastAppleWatchSyncDate?: IsoDateString
  /**
   * true means user has wrist temperatures
   * false means user certainly doesn’t have a supported watch
   * null means we don’t have wrist temperatures nor HR, or we don't have wrist temps and get HR from a supported watch
   */
  hasCompatibleAppleWatch?: boolean | null
  hasRevokedAppleWatchPermissions?: boolean
  revokedPermissionsAlertDisplayed?: boolean
  noAppleWatchTempsModalShownDate?: IsoDateString
  appOpenAfterBgrSyncCount?: number

  // Biometric auth
  biometricAuthEnabled?: boolean
}

type State = UserSettings

const userSettingsInitialData: State = {
  multipleSessions: null,
  pendingBluetoothSettings: {},
}

const actions: any = {
  extendUserSettings(
    state: State,
    action: { type: string; payload: Partial<UserSettings> },
  ): State {
    return {
      ...state,
      ...action.payload,
    }
  },

  setLastActiveNow(state: State): State {
    return {
      ...state,
      lastActive: localTime.nowUnix(),
    }
  },

  extendPendingBluetoothSettings(
    state: State,
    action: { type: string; payload: Partial<PendingHardwareDeviceSettings> },
  ): State {
    return {
      ...state,
      pendingBluetoothSettings: {
        ...state.pendingBluetoothSettings,
        ...action.payload,
      },
    }
  },

  clearPendingBluetoothSettings(state: State): State {
    return {
      ...state,
      pendingBluetoothSettings: {},
    }
  },

  addPartnerEmail(state: State, action: { type: string; payload: string }): State {
    const { payload } = action

    const emails: string[] = [...(state.invitedPartnerEmails || []), payload]

    return {
      ...state,
      invitedPartnerEmails: _uniq(emails.sort()),
    }
  },

  removePartnerEmail(state: State, action: { type: string; payload: string }): State {
    const { payload } = action

    return {
      ...state,
      invitedPartnerEmails: state.invitedPartnerEmails!.filter(email => email !== payload),
    }
  },

  hideDataMeter(state: State): State {
    return {
      ...state,
      dataMeterState: { ...state.dataMeterState, hidden: true },
    }
  },

  dismissFeedback(state: State, action: { type: string; payload: number }): State {
    const { payload: cycleNumber } = action
    return {
      ...state,
      dismissedFeedbackAtCycle: _uniq([...(state.dismissedFeedbackAtCycle || []), cycleNumber]),
    }
  },

  extendFirmwareUpdates(state: State, action: { type: string; payload: FirmwareUpdate }): State {
    return {
      ...state,
      firmwareUpdates: [...(state.firmwareUpdates ?? []), action.payload],
    }
  },

  extendFailedFotaAttempts(state: State, action: { type: string; payload: FotaAttempt }): State {
    return {
      ...state,
      failedFotaAttempts: [...(state.failedFotaAttempts ?? []), action.payload],
    }
  },

  extendHKScienceDataPosted(
    state: State,
    action: { type: string; payload: StringMap<UnixTimestampNumber> },
  ): State {
    return {
      ...state,
      hkScienceDataPosted: {
        ...state.hkScienceDataPosted,
        ...action.payload,
      },
    }
  },

  clearUserSettings(): State {
    return userSettingsInitialData
  },
}

// biome-ignore lint/style/useDefaultParameterLast: ok
export function userSettingsReducer(state = userSettingsInitialData, action: Action): State {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
