import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { IonicModule } from '@ionic/angular'
import { SharedModule } from '../../shared.module'
import { TemperatureNumpadComponent } from './temperature-numpad.component'

@NgModule({
  declarations: [TemperatureNumpadComponent],
  imports: [IonicModule, SharedModule, CommonModule],
  exports: [TemperatureNumpadComponent],
})
export class TemperatureNumpadComponentModule {}
