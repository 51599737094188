import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core'
import { NavigationParams } from '@app/cnst/nav.params.cnst'
import { BaseModal } from '@app/pages/base.modal'
import { SharedModule } from '@app/shared.module'
import { addNavParams, getNavParams } from '@app/srv/nav.service'
import { Keyboard } from '@capacitor/keyboard'
import { DomController, IonicModule, IonTextarea, Platform } from '@ionic/angular'
import { fromEvent } from 'rxjs'

@Component({
  selector: 'app-notes-input-modal',
  templateUrl: './notes-input.modal.html',
  styleUrls: ['./notes-input.modal.scss'],
  standalone: true,
  imports: [IonicModule, SharedModule],
})
export class NotesInputModal extends BaseModal implements AfterViewInit, OnInit {
  className = 'NotesInputModal'

  private platform = inject(Platform)
  private dom = inject(DomController)

  @ViewChild(IonTextarea)
  private textarea!: IonTextarea

  @ViewChild('content')
  private content!: ElementRef

  @Input()
  public readonly = false

  private textareaElement?: HTMLTextAreaElement
  private safeAreaTop = 0

  public value = ''

  public ngOnInit(): void {
    this.value = getNavParams()[NavigationParams.NOTES]
  }

  public ngAfterViewInit(): void {
    this.dom.read(() => {
      this.safeAreaTop = parseInt(
        getComputedStyle(document.documentElement).getPropertyValue('--ion-safe-area-top'),
      )
    })
  }

  public override ionViewWillEnter(): void {
    if (this.platform.is('hybrid')) {
      this.dom.write(() => {
        document.documentElement.classList.add('keyboard-open')
        document.documentElement.style.setProperty(
          '--keyboardHeight',
          '250px', // estimation of keyboard height
        )
      })

      const show = fromEvent(window, 'keyboardWillShow').subscribe((e: any) => {
        this.dom.write(() => {
          document.documentElement.style.setProperty('--keyboardHeight', `${e.keyboardHeight}px`)
        })
        setTimeout(() => this.scrollToBottom(), 400) // update after transition is done
      })
      this.subscriptions.push(show)
    }
  }

  public override async ionViewDidEnter(): Promise<void> {
    this.textareaElement = await this.textarea.getInputElement()
    this.dom.write(() => {
      this.textareaElement!.style.minHeight = '65px'
    })

    if (!this.platform.is('hybrid')) {
      await this.scrollToBottom()
    }

    setTimeout(() => this.textarea.setFocus())
  }

  private async scrollToBottom(): Promise<void> {
    await this.setMaxHeight()

    // move cursor to end
    if (this.textareaElement && this.textarea.value) {
      this.dom.read(() => {
        const length = this.textarea.value!.length

        this.dom.write(() => {
          this.textareaElement!.setSelectionRange(length, length)
          this.textareaElement!.scrollTop = this.textareaElement!.scrollHeight
        })
      })
    }
  }

  public override ionViewWillLeave(): void {
    if (this.platform.is('hybrid')) {
      void Keyboard.hide()

      this.dom.write(() => {
        document.documentElement.classList.remove('keyboard-open')
        document.documentElement.style.setProperty('--keyboardHeight', '0px')
      })
    }
  }

  public onChange(event: CustomEvent): void {
    addNavParams({ [NavigationParams.NOTES]: event.detail.value })

    void this.setMaxHeight()
  }

  private async setMaxHeight(): Promise<void> {
    if (!this.textareaElement) return

    this.dom.read(() => {
      const rect = this.content.nativeElement.getBoundingClientRect() || {}

      rect.height -= 30 // remove the padding for the title

      const currentTextareaHeight = this.textareaElement!.clientHeight

      this.dom.write(() => {
        if (this.textareaElement!.clientHeight > rect.height) {
          this.textareaElement!.style.maxHeight = `${rect.height}px`
          return
        }

        // same calc as in _modal.scss .modal--alert
        const minTopBounds =
          16 + // padding
          44 + // navbar height
          this.safeAreaTop +
          20 // extra buffer just to be sure

        // if top bounds are less than our minTopBounds -> set maxHeight of the textarea
        if (rect.top < minTopBounds) {
          this.textareaElement!.style.maxHeight = `${currentTextareaHeight}px`
        } else {
          this.textareaElement!.style.maxHeight = null as any
        }
      })
    })
  }
}
