import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { SharedModule } from '@app/shared.module'
import { IonicModule } from '@ionic/angular'
import { RadioGroupComponentModule } from '@src/app/cmp/radio-group/radio-group.component.module'
import { MultipleWatchesDetectedModal } from './multiple-watches-detected.modal'

@NgModule({
  declarations: [MultipleWatchesDetectedModal],
  imports: [IonicModule, SharedModule, CommonModule, RadioGroupComponentModule],
  exports: [MultipleWatchesDetectedModal],
})
export class MultipleWatchesDetectedModalModule {}
