<ion-header>
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-button
        mode="md"
        (click)="dismissModal(!!result ? { deviate: false } : undefined)"
        uid="deviationSleep__closeButton"
      >
        <ion-icon slot="icon-only" name="close" />
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [@parent] scrollable>
  <div class="deviationSleep__pagination">
    @for (dot of slides; track trackByUid(i, dot); let i = $index) {
      @if (i < currentIndex) {
        <ion-icon [src]="ICON.CHECK_BOLD" size="small" color="secondary" />
      }
      @if (i >= currentIndex) {
        <span
          class="deviationSleep__paginationCircle"
          [class.deviationSleep__paginationCircle--current]="i === currentIndex"
        ></span>
      }
    }
  </div>

  <section class="deviationSleep__slides">
    <!-- Wake up slide -->
    @if (currentIndex === slideCount - 4) {
      <span [@slideAnimation]>
        <h1>{{ slides[slideCount - 4]!.title | tr }}</h1>
        <p>{{ slides[slideCount - 4]!.body | tr }}</p>
        @if (isAndroidApp) {
          <ion-item
            class="deviationSleep__pickerItem"
            uid="deviationSleep__timePicker"
            (click)="openTimePicker()"
          >
            <ion-input
              [value]="newWakeUpTime"
              [placeholder]="'reminders-set-time' | tr"
              [label]="'txt-morning' | tr"
              labelPlacement="floating"
              readonly="true"
            />
          </ion-item>
        } @else {
          <ion-item
            class="deviationSleep__pickerItem"
            uid="deviationSleep__timePicker"
            [datetimePicker]="datetimePickerConfig"
            (selectedDatetime)="onTimeChange($event)"
          >
            <ion-input
              [label]="'txt-morning' | tr"
              labelPlacement="floating"
              [value]="newWakeUpTime"
              [placeholder]="'reminders-set-time' | tr"
              readonly="true"
            />
          </ion-item>
        }
        <ng-template #time>
          <ion-item
            class="deviationSleep__pickerItem"
            uid="deviationSleep__timePicker"
            [datetimePicker]="datetimePickerConfig"
            (selectedDatetime)="onTimeChange($event)"
          >
            <ion-input
              [label]="'txt-morning' | tr"
              labelPlacement="floating"
              [value]="newWakeUpTime"
              [placeholder]="'reminders-set-time' | tr"
              readonly="true"
            />
          </ion-item>
        </ng-template>
      </span>
    }

    <!-- Question 1 -->
    @if (currentIndex === slideCount - 3) {
      <span [@slideAnimation]>
        <h1>
          {{
            slides[slideCount - 3]!.title
              | tmd: { startTime: startTime, endTime: endTime }
              | stripTags
          }}
        </h1>
        <p>{{ slides[slideCount - 3]!.body | tmd: { time: this.reminderTime } | stripTags }}</p>
        <div class="deviationSleep__wakeUpTimeIllustration">
          <ion-img src="./assets/img/deviation-sleep-wake-up-time.svg" />
          <span class="timeLabel">{{ startTime }}</span>
          <span class="timeLabel timeLabel--endTime">{{ endTime }}</span>
        </div>
      </span>
    }

    <!-- Question 2 -->
    @if (currentIndex === slideCount - 2) {
      <span [@slideAnimation]>
        <h1>{{ slides[slideCount - 2]!.title | tr }}</h1>
        <p>{{ slides[slideCount - 2]!.body | tr }}</p>
      </span>
    }

    <!-- Question 3 -->
    @if (currentIndex === slideCount - 1) {
      <span [@slideAnimation]>
        <h1>{{ slides[slideCount - 1]!.title | tr }}</h1>
        <p>{{ slides[slideCount - 1]!.body | tr }}</p>
      </span>
    }

    <!--  Result  -->
    @if (!!result) {
      <span [@slideAnimation] (@slideAnimation.done)="onSlideAnimationDone()">
        <h1>{{ result.title | tr }}</h1>
        @for (key of result.keys; track key) {
          <p>{{ key | tr }}</p>
        }
      </span>
    }
  </section>
</ion-content>

<ion-footer>
  <!-- Wake up time slide -->
  @if (currentIndex === 0 && !reminderTime && !wakeUpTime) {
    <ion-button
      expand="block"
      shape="round"
      (click)="saveWakeUpTime()"
      uid="deviationSleep__saveWakeUpTime_button"
    >
      <ion-label>{{ 'txt-next' | tr }}</ion-label>
    </ion-button>
  } @else {
    @if (!result) {
      <ion-button expand="block" shape="round" (click)="next(true)">
        <ion-label>{{ 'txt-yes' | tr }}</ion-label>
      </ion-button>
      <ion-button expand="block" shape="round" (click)="next(false)">
        <ion-label>{{ 'txt-no' | tr }}</ion-label>
      </ion-button>
    }
  }

  <!-- Result = Slept differently -->
  @if (result?.positive) {
    <ion-button
      class="deviationSleep__addButton"
      expand="block"
      shape="round"
      (click)="dismissModal({ deviate: true })"
      uid="deviationSleep__result__add__button"
    >
      <ion-icon [src]="ICON.DEVIATION_SLEEP" slot="start" />
      <ion-label>{{ 'add-data-deviation-cause-sleep' | tr }}</ion-label>
      <ion-label slot="end">{{ 'btn-add' | tr }}</ion-label>
    </ion-button>
    <ion-button
      expand="block"
      shape="round"
      fill="outline"
      (click)="dismissModal({ deviate: false })"
      uid="deviationSleep__result__close__button"
    >
      <ion-label>{{ 'txt-skip-close' | tr }}</ion-label>
    </ion-button>
  }

  <!-- Result = Slept normally -->
  @if (result && !result.positive) {
    <ion-button
      expand="block"
      shape="round"
      (click)="dismissModal({ deviate: false })"
      uid="deviationSleep__result__finish__button"
    >
      <ion-label>{{ 'txt-finish' | tr }}</ion-label>
    </ion-button>
  }
</ion-footer>
