import { AfterViewInit, Component, inject, OnDestroy, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { EVENT } from '@app/analytics/analytics.cnst'
import { AnalyticsService } from '@app/analytics/analytics.service'
import { ICON } from '@app/cnst/icons.cnst'
import { IonicModule } from '@ionic/angular'
import { ROUTES } from '@src/app/cnst/nav.cnst'
import { PipesModule } from '@src/app/pipes/pipes.module'
import SwiperCore, { Autoplay, Pagination, SwiperOptions } from 'swiper'
import { SwiperComponent, SwiperModule } from 'swiper/angular'
import { Swiper } from 'swiper/types'
import { ab250AutoplaySlidesOptions, introAb250Slides } from '../ab250'
import { IntroSlidesPaginationAb250Component } from '../intro-slides-pagination-ab250/intro-slides-pagination-ab250.component'

SwiperCore.use([Pagination, Autoplay])

@Component({
  standalone: true,
  selector: 'app-intro-slides-ab250',
  templateUrl: 'intro-slides-ab250.component.html',
  styleUrls: ['intro-slides-ab250.component.scss'],
  imports: [IonicModule, SwiperModule, PipesModule, IntroSlidesPaginationAb250Component],
})
export class IntroSlidesAb250Component implements AfterViewInit, OnDestroy {
  @ViewChild('swiper')
  private swiper?: SwiperComponent

  protected config: SwiperOptions = ab250AutoplaySlidesOptions.config
  protected VIDEO_ID_PREFIX = 'ab250-video-slide'

  private analyticsService = inject(AnalyticsService)
  private router = inject(Router)

  protected SLIDES = introAb250Slides
  protected ICON = ICON

  ngAfterViewInit(): void {
    this.playSlide(0)

    this.swiper?.swiperRef?.on('slideChangeTransitionStart', this.onSlideChangeStart.bind(this))
    this.swiper?.swiperRef?.on('slideChangeTransitionEnd', this.onSlideChangeEnd.bind(this))
  }

  ngOnDestroy(): void {
    this.swiper?.swiperRef?.off('slideChangeTransitionStart')
    this.swiper?.swiperRef?.off('slideChangeTransitionEnd')
  }

  public videoEnd(): void {
    if (this.router.url !== ROUTES.IntroAb250Page) return
    this.swiper?.swiperRef?.slideNext()
  }

  private getVideo = (index: number): HTMLMediaElement | null => {
    return document.querySelector<HTMLMediaElement>(`#${this.VIDEO_ID_PREFIX}${index}`)
  }

  private playSlide = (index: number): void => {
    this.trackSlideStep(index)
    const video = this.getVideo(index)
    if (!video) return
    void video.play()
  }

  private resetSlide = (index: number): void => {
    const video = this.getVideo(index)
    if (!video) return
    video.pause()
    video.currentTime = 0
    video.autoplay = false
  }

  private onSlideChangeStart(e: Swiper): void {
    this.playSlide(e.activeIndex)
  }

  private onSlideChangeEnd(e: Swiper): void {
    this.resetSlide(e.previousIndex)
  }

  private trackSlideStep(index: number): void {
    void this.analyticsService.trackEvent(EVENT.VIEW_AB250_INTRO_SLIDE, {
      slide: index + 1,
    })
  }
}
