// Do not forget to make following updates when adding new value:
// 1. For iOS: update LSApplicationQueriesSchemes array in Info.plist.
//    Details: https://stackoverflow.com/a/42089917/7450866
// 2. For Android: ignore output of canOpenUrl(...). It'll work only for defined packages name (e.g. com.ouraring.oura)
//    Details: https://developer.android.com/training/package-visibility/declaring

import { QuizPage } from './quiz-pages.cnst'

export enum ExternalApp {
  OURA = 'oura://',
  SETTINGS_APPLE_HEALTH = 'App-prefs:HEALTH&path=SOURCES',
}

export enum LinkSource {
  IN_APP = 'IN_APP',
  NOTIFICATION = 'NOTIFICATION',
  DEEPLINK = 'DEEPLINK',
  GLOSSARY = 'GLOSSARY',
}

export enum SUPERTAB {
  HistoryPage = 'HistoryPage',
  MyCyclePage = 'MyCyclePage',
  MyPeriodPage = 'MyPeriodPage',
  MyOvulationPage = 'MyOvulationPage',
  InsightsPage = 'InsightsPage',
}

export const ROUTES = {
  // root pages
  HomePage: '/home',
  IntroPage: '/intro',

  // AB250
  IntroAb250Page: '/intro-ab250',

  // AB251
  VerifyEmailPage: '/verify-email',

  AuthPage: '/auth',
  ConsentPage: '/consent',
  ThermometerPage: '/thermometer',

  // Partner
  PartnerPage: '/partner',
  PartnerLoginPage: '/partner-login',
  PartnerMonthPage: '/partner/month',
  PartnerNotificationSettingsPage: '/partner/notifications',
  PartnerConsentSettingsPage: '/partner/consent',

  // purchase
  AddressPage: '/address',
  PaymentPage: '/payment',
  PaymentAddPage: '/payment/add',
  OrderPage: '/order',

  // signup pages
  SignupMeasuringDevicePage: '/signup/measuring-device',
  PlansPage: '/signup/plans',
  TwoFactorAuthPage: '/two-factor-authentication',

  // Onboarding
  Onboarding: '/flow/onboarding/', // + variable
  RegDonePage: '/flow/onboarding/start',
  OnboardingDemoExitPage: '/flow/onboarding/demo-exit',
  OnboardingOuraAuthPage: '/flow/onboarding/oura-auth',
  OnboardingSummaryPage: '/flow/onboarding/summary',

  // Pregnancy End
  PregnancyEnd: '/flow/pregnancy-end/', // + variable

  // Unplanned Pregnancy
  UnplannedPregnancy: '/flow/unplanned-pregnancy/', // + variable

  // home pages
  TodayPage: '/home/today',
  MonthPage: '/home/cycle-calendar',
  PregnancyPage: '/home/pregnancy',
  MessagesPage: '/home/messages',
  LearnPage: '/home/learn',

  HistoryPage: '/history',
  PregnancyDatesPage: '/pregnancy-dates',
  PregnancyCalendarPage: '/pregnancy-calendar',
  MensCalendarPage: '/mens-calendar',

  GraphPage: '/graph',
  GraphCyclePage: '/graph/cycle',
  GraphComparePage: '/graph/compare',
  GraphHolisticPage: '/graph/holistic',
  AddData: '/add-data', // + variable
  Glossary: '/glossary', // + variable
  Guide: '/guide', // + variable
  Cards: '/cards', // + variable
  CalibratePage: '/calibrate',
  CalibrateReceiptPage: '/calibrate/receipt',
  CalibrateAddressPage: '/calibrate/address',
  ReferPage: '/refer',

  CheckoutPage: '/checkout',
  ShopPage: '/shop', // + variable
  OuraInfoPage: '/oura-info',

  /** @deprecated redirects to InsightsPage */
  StatisticsPage: '/home/statistics',
  /** @deprecated redirects to InsightsPage */
  MyCyclePage: '/home/statistics#mycyclepage',
  /** @deprecated redirects to AddedDataPageFromInsights */
  AddedDataPage: '/home/statistics/added-data',
  /** @deprecated redirects to AchievementsPageFromInsights */
  AchievementsPage: '/home/statistics/added-data/achievements',
  /** @deprecated redirects to InsightsPage */
  DeprecatedMyDataPage: '/home/my-data',
  /** @deprecated redirects to AddedDataPageFromInsights */
  DeprecatedAddedDataPage: '/home/my-data/added-data',
  /** @deprecated redirects to AchievementsPageFromInsights */
  DeprecatedAchievementsPage: '/home/my-data/added-data/achievements',

  InsightsPage: '/insights',
  AddedDataPageFromInsights: '/insights/added-data',
  AchievementsPageFromInsights: '/insights/added-data/achievements',

  ProfilePage: '/profile',
  ProfileNamePage: '/profile/name',
  ProfileDOBPage: '/profile/dob',
  ProfileWeightPage: '/profile/weight',
  ProfileAddressPage: '/profile/address',
  ProfileEmailPage: '/profile/email',
  ProfilePasswordPage: '/profile/password',

  ProfileDuePage: '/profile/profile-due',
  ManageDataPage: '/profile/manage-data',

  SettingsPage: '/settings',
  SettingsAppearancePage: '/settings/appearance',
  SettingsLanguagePage: '/settings/language',
  SettingsUnitsPage: '/settings/units',
  SettingsCalendarPage: '/settings/calendar',
  SettingsGoalPage: '/settings/goal-settings',
  SettingsRemindersPage: '/settings/reminders',
  SettingsNotificationsPage: '/settings/notifications',
  SettingsConsentPage: '/settings/consent',
  SettingsIntegrationsPage: '/settings/integrations',
  SettingsTrackersPage: '/settings/trackers',
  SettingsPartnerPage: '/settings/partner',
  SettingsGoAnonymousPage: '/settings/go-anonymous',
  SettingsCachePage: '/settings/clear-cache',
  SettingsBiometricAuthPage: '/settings/biometric-auth',
  /** @deprecated redirects to MyDevicePage */
  DeprecatedSettingsThermometer: '/settings/thermometer',

  // My device
  MyDevicePage: '/my-device',
  ConnectOtherDevicePage: '/my-device/connect',
  UebePairingPage: '/my-device/uebe-pairing',
  UebeWalkthroughPage: '/my-device/uebe-walkthrough',
  T3PairingPage: '/my-device/t3-pairing',
  T3WalkthroughPage: '/my-device/t3-walkthrough',
  TroubleshootingPage: '/my-device/errors',
  BatteryReplacementGuide: '/guide/battery-replacement',
  DominantHandPage: '/my-device/dominant-hand',
  MyDeviceOuraAuthPage: '/my-device/oura-auth',
  MyDeviceOuraAccountInfoPage: '/my-device/oura-account-info',
  FirmwareUpdatePage: '/my-device/firmware-update',
  WristPositionPage: '/my-device/wrist-position',

  // Apple Watch Setup
  AWSetupPage: '/my-device/aw-setup',
  AWImportPage: '/my-device/aw-setup/import',
  AWExportPage: '/my-device/aw-setup/export',
  AWPermissionPage: '/my-device/aw-setup/permission',
  AWSetupResultPage: '/my-device/aw-setup/result',
  AWSetupStepsPage: '/my-device/aw-setup/steps',
  AWSetupNotificationsPage: '/my-device/aw-setup/notifications',
  AWSetupSuccessPage: '/my-device/aw-setup/success',

  // Switch device from Oura pages
  OuraSwitchPage: '/my-device/switch-oura',
  OuraSwitchFeedbackPage: '/my-device/switch-oura/feedback',
  OuraSwitchSuccessPage: '/my-device/switch-oura/success',
  OuraSwitchBbtThermPage: '/my-device/switch-oura/bbt-therm',
  OuraSwitchOrderedThermPage: '/my-device/switch-oura/ordered-therm',

  // Switch devices flow
  HardwareDeviceInfoPage: '/my-device/switch-device', // + variable
  MultipleDeviceSwitchesPage: '/my-device/switch-device/multiple-switches', // + variable
  BeforeYouSwitchPage: '/my-device/switch-device/before-you-switch', // + variable
  FreeThermometerInfoPage: '/my-device/switch-device/claim-thermometer', // + variable
  ThermometerShipmentConfirmationPage: '/my-device/switch-device/thermometer-shipped', // + variable
  T1MeasurementPage: '/my-device/switch-device/t1-measurement',

  SupportPage: '/support',
  ContactUsPage: '/support/contact-us',

  RegulatoryPage: '/regulatory',
  ManageAccountPage: '/subscriptions-and-payments/manage',

  // Delete account
  DeleteAccountPage: '/delete-account',
  DeletedAccountPage: '/deleted-account',

  // app quiz pages
  QuizPrivacyPage: `/quiz/${QuizPage.privacy}`,
  QuizNamePage: `/quiz/${QuizPage.name}`,
  QuizUspsPage: `/quiz/${QuizPage.usps}`,
  QuizBirthdayPage: `/quiz/${QuizPage.birthday}`,
  QuizFertilityGoal: `/quiz/${QuizPage.fertilityGoal}`,
  QuizFertilityGoalInfo: `/quiz/${QuizPage.fertilityGoalInfo}`,
  QuizBirthControlMethod: `/quiz/${QuizPage.birthControlMethod}`,
  QuizBirthControlMethodInfo: `/quiz/${QuizPage.birthControlMethodInfo}`,
  QuizSideEffects: `/quiz/${QuizPage.sideEffects}`,
  QuizSideEffectsInfo: `/quiz/${QuizPage.sideEffectsInfo}`,
  QuizPlannedPregnancyTiming: `/quiz/${QuizPage.plannedPregnancyTiming}`,
  QuizLearnAboutSexInfo: `/quiz/${QuizPage.learnAboutSexInfo}`,
  QuizBestTimeInCycle: `/quiz/${QuizPage.bestTimeInCycle}`,
  QuizKnowingYourSexDrive: `/quiz/${QuizPage.knowingYourSexDrive}`,
  QuizSexDriveInfo: `/quiz/${QuizPage.sexDriveInfo}`,
  QuizSexFrequency: `/quiz/${QuizPage.sexFrequency}`,
  QuizKnowPregnantDays: `/quiz/${QuizPage.knowPregnantDays}`,
  QuizMenstrualCycleInfo: `/quiz/${QuizPage.menstrualCycleInfo}`,
  QuizTestimonialInfo: `/quiz/${QuizPage.testimonialInfo}`,
  QuizHormonalBirthControlUsage: `/quiz/${QuizPage.hormonalBirthControlUsage}`,
  QuizHormonalBirthControlHowLongPlan: `/quiz/${QuizPage.hormonalBirthControlHowLongPlan}`,
  QuizHormonalBirthControlHowLongPrevent: `/quiz/${QuizPage.hormonalBirthControlHowLongPrevent}`,
  QuizPlannedPregnancyInfo: `/quiz/${QuizPage.plannedPregnancyInfo}`,
  QuizCycleLength: `/quiz/${QuizPage.cycleLength}`,
  QuizPeriodRegularity: `/quiz/${QuizPage.periodRegularity}`,
  QuizPeriodRegularityInfo: `/quiz/${QuizPage.periodRegularityInfo}`,
  QuizCycleStartDate: `/quiz/${QuizPage.cycleStartDate}`,
  QuizMedicalConditionsPlan: `/quiz/${QuizPage.medicalConditionsPlan}`,
  QuizMedicalConditionsPrevent: `/quiz/${QuizPage.medicalConditionsPrevent}`,
  QuizMedicalConditionsInfo: `/quiz/${QuizPage.medicalConditionsInfo}`,
  QuizDoctorInvolvement: `/quiz/${QuizPage.doctorInvolvement}`,
  QuizPartnerInvolvement: `/quiz/${QuizPage.partnerInvolvement}`,
  QuizPartnerInvolvementInfo: `/quiz/${QuizPage.partnerInvolvementInfo}`,
  QuizFollowPregnancyInfo: `/quiz/${QuizPage.followPregnancyInfo}`,
  QuizEmotionalChanges: `/quiz/${QuizPage.emotionalChangesInfo}`,
  QuizCycleChanges: `/quiz/${QuizPage.cycleChanges}`,
  QuizCycleChangesEmotions: `/quiz/${QuizPage.cycleChangesEmotions}`,
  QuizCycleChangesBleeding: `/quiz/${QuizPage.cycleChangesBleeding}`,
  QuizCycleChangesSkin: `/quiz/${QuizPage.cycleChangesSkin}`,
  QuizCycleChangesSexAndLibido: `/quiz/${QuizPage.cycleChangesSexAndLibido}`,
  QuizCycleChangesCervicalMucus: `/quiz/${QuizPage.cycleChangesCervicalMucus}`,
  QuizCycleChangesPain: `/quiz/${QuizPage.cycleChangesPain}`,
  QuizTrackingInfo: `/quiz/${QuizPage.trackingInfo}`,
  QuizNaturalMethod: `/quiz/${QuizPage.naturalMethod}`,
  QuizYourMeasuringDevice: `/quiz/${QuizPage.yourMeasuringDevice}`,
  QuizYourDescription: `/quiz/${QuizPage.yourDescription}`,
  QuizHowDoYouSleep: `/quiz/${QuizPage.howDoYouSleep}`,
  QuizSleepWearable: `/quiz/${QuizPage.sleepWearable}`,
  QuizWhichWearable: `/quiz/${QuizPage.whichWearable}`,
  QuizConsideringPurchase: `/quiz/${QuizPage.consideringPurchase}`,
  QuizEligibleAppleWatch: `/quiz/${QuizPage.eligibleAppleWatch}`,
  QuizPurchaseNewWatch: `/quiz/${QuizPage.purchaseNewWatch}`,
  QuizFitResult: `/quiz/${QuizPage.fitResult}`,
  QuizDeviceResult: `/quiz/${QuizPage.deviceResult}`,
}

// When the key name doesnt match what we want to track
export const analyticsRoutesMap = {
  [ROUTES.SignupMeasuringDevicePage]: 'MeasuringDevicePage',
  [ROUTES.PaymentAddPage]: 'PaymentPage',
  '/(morning:add-data)': 'AddDataPage',
}
