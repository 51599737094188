<ion-header>
  <ion-toolbar mode="ios">
    <ion-title>
      <ion-icon [src]="ICON.NC_LOGO" color="logo" class="verifyEmail__logo" />
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen class="ion-padding-horizontal">
  <div class="ion-text-center">
    <p class="verifyEmail__title">Welcome to Natural Cycles!</p>
    <p class="verifyEmail__desc">
      To finish setting up your account, please verify your email by clicking on the button below
    </p>
  </div>
  <ion-button
    shape="round"
    expand="block"
    color="purple"
    class="verifyEmail__open"
    uid="verifyEmailPage__open"
    (click)="openEmail()"
  >
    <ion-label>Open my inbox</ion-label>
  </ion-button>
  <ion-button
    shape="round"
    expand="block"
    fill="outline"
    color="purple"
    class="verifyEmail__send"
    uid="verifyEmailPage__resend"
    (click)="resendEmail()"
    [disabled]="timeToEnabledResend > 0"
  >
    <ion-label>{{ resendText }}</ion-label>
  </ion-button>
</ion-content>

<ion-footer>
  <ion-button
    shape="round"
    fill="clear"
    color="purple"
    (click)="skip()"
    uid="verifyEmailPage__skip"
  >
    <ion-label>Later</ion-label>
    <ion-icon slot="end" [src]="ICON.ARROW_RIGHT" aria-hidden="true" />
  </ion-button>
</ion-footer>
