import { Component, inject, OnInit } from '@angular/core'
import { ROUTES } from '@app/cnst/nav.cnst'
import { NavigationParams } from '@app/cnst/nav.params.cnst'
import { loader, LoaderType } from '@app/decorators/decorators'
import { IonicModule, NavController } from '@ionic/angular'
import { Experiment } from '@naturalcycles/shared'
import { BasePage } from '@src/app/pages/base.page'
import { PipesModule } from '@src/app/pipes/pipes.module'
import { ExperimentService } from '@src/app/srv/experiment.service'
import { AuthPageMode } from '@src/typings/auth'
import { IntroSlidesAb250Component } from '../intro-slides-ab250/intro-slides-ab250.component'

@Component({
  standalone: true,
  imports: [PipesModule, IonicModule, IntroSlidesAb250Component],
  selector: 'page-intro-ab250',
  styleUrls: ['intro-ab250.page.scss'],
  templateUrl: 'intro-ab250.page.html',
})
export class IntroAb250Page extends BasePage implements OnInit {
  className = 'IntroPageAb250'

  public navController = inject(NavController)
  private experimentService = inject(ExperimentService)

  constructor() {
    super()
    this.shouldDismissOnBack = true
  }

  ngOnInit(): void {
    void this.experimentService.logImpression(Experiment.VIDEO_INTRO_SCREEN)
  }

  @loader(LoaderType.BUTTON)
  public async signup(): Promise<void> {
    await this.navigateForward(ROUTES.QuizPrivacyPage)
  }

  @loader(LoaderType.BUTTON)
  public async login(): Promise<void> {
    await this.navigateForward(
      ROUTES.AuthPage,
      {},
      {
        state: {
          [NavigationParams.AUTH_PAGE_MODE]: AuthPageMode.Login,
        },
      },
    )
  }
}
