import {
  GoalBreakpointOption,
  MedicalConditionsBreakpointOption,
  QuizDataInput,
  QuizFlow,
} from '@naturalcycles/shared'
import { Section, TranslatedInfoPageContent } from '../cnst/quiz.cnst'
import { QuizPage } from '../cnst/quiz-pages.cnst'
import { tr, trIfExists } from '../srv/translation.util'

const trWithFallback = (key: string, fallback: string): string => {
  return trIfExists(key) || tr(fallback)
}

export const getMedicalConditionsInfoContent = (
  quizData: QuizDataInput | null,
): TranslatedInfoPageContent => {
  const medicalConditions = quizData?.medicalConditions || []
  let medicalCondition: keyof typeof MedicalConditionsBreakpointOption | 'multiple' = 'multiple'
  if (medicalConditions.length === 1) {
    medicalCondition = MedicalConditionsBreakpointOption[
      medicalConditions[0]!
    ] as keyof typeof MedicalConditionsBreakpointOption
  }
  const content: TranslatedInfoPageContent = {}
  const isPlan = quizData?.fertilityGoal === GoalBreakpointOption.plan

  const keys = ['title', 'subtitle', 'markdown']
  keys.forEach(key => {
    const baseKey = `txt-quiz-medical-conditions-${key}--${medicalCondition}`
    if (isPlan) content[key] = trWithFallback(`${baseKey}-plan`, baseKey)
    else content[key] = tr(baseKey)
  })

  return content
}

export const addPageToFlowAfterCurrent = (
  flow: QuizFlow<QuizPage>,
  section: Section,
  currentPage: QuizPage,
  pageToAdd: QuizPage,
): QuizFlow<QuizPage> => {
  const updatedSection = [...(flow[section] || [])]
  const currentIndex = updatedSection.indexOf(currentPage)
  if (currentIndex !== -1 && !updatedSection.includes(pageToAdd)) {
    updatedSection.splice(currentIndex + 1, 0, pageToAdd)
  }
  flow[section] = updatedSection
  return flow
}

export const removePagesFromFlow = (
  flow: QuizFlow<QuizPage>,
  section: Section,
  pagesToRemove: QuizPage[],
): QuizFlow<QuizPage> => {
  let updatedFlow = flow
  pagesToRemove.forEach(pageToRemove => {
    updatedFlow = removePageFromFlow(updatedFlow, section, pageToRemove)
  })
  return flow
}

export const removePageFromFlow = (
  flow: QuizFlow<QuizPage>,
  section: Section,
  pageToRemove: QuizPage,
): QuizFlow<QuizPage> => {
  const updatedSection = [...(flow[section] || [])]
  const currentIndex = updatedSection.indexOf(pageToRemove)
  if (currentIndex === -1) return flow

  updatedSection.splice(currentIndex, 1)
  flow[section] = updatedSection
  return flow
}

export const clearFlowAfterCurrentSection = (
  flow: QuizFlow<QuizPage>,
  currentSection: Section,
): QuizFlow<QuizPage> => {
  const updated = [...flow].slice(0, currentSection + 1)
  return updated
}
