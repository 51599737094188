import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { FlowId } from '../pages/flow/flow.cnst'

export const showHeaderShadow$ = new Subject<boolean>()

@Injectable({ providedIn: 'root' })
export class EventService {
  public onResume$ = new Subject<void>()
  public onPause$ = new Subject<void>()
  public leaveDemoMode$ = new Subject<void>()
  public graphClosed$ = new Subject<void>()
  public transitionDone$ = new Subject<void>()
  public modalClosed$ = new Subject<void>()
  public inAppBrowserClosed$ = new Subject<void>()
  public onLogout$ = new Subject<void>()
  public closeDrawer$ = new Subject<void>()
  public openInfoModal$ = new Subject<string>()
  public internalLinkClick$ = new Subject<string>()
  public appearanceUpdated$ = new Subject<string>()
  public onFlowDeactivation$ = new Subject<FlowId>()
}
