import { Experiment } from '@naturalcycles/shared'
import { getState } from '@src/app/srv/store.service'
import { SwiperOptions } from 'swiper'

export const isAb250Test = (): boolean => {
  return getState().experiment.assignments[Experiment.VIDEO_INTRO_SCREEN] === 'test'
}

export interface IntroSlideAb250 {
  mediaSrc: string
  mediaType: 'video' | 'image'
  title: string
  text: string
  gradient?: 'gradient-1' | 'gradient-2'
}

const assetsBase = 'https://assets.naturalcycles.com'

export const introAb250Slides: IntroSlideAb250[] = [
  {
    title: 'Birth control<br/>built around you',
    text: 'Our FDA Cleared app adapts to your unique cycle to detect your fertility',
    mediaSrc: `${assetsBase}/videos/intro-screen-1.mp4`,
    mediaType: 'video',
    gradient: 'gradient-1',
  },
  {
    title: 'No hormones,<br>just science',
    text: 'The algorithm uses your temperature to let you know if you’re fertile or not',
    mediaSrc: `${assetsBase}/videos/intro-screen-2.mp4`,
    mediaType: 'video',
    gradient: 'gradient-1',
  },
  {
    title: 'Measure<br/>seamlessly',
    text: 'Connect to the NC° app with Oura Ring, Apple Watch or Bluetooth thermometer',
    mediaSrc: `${assetsBase}/videos/intro-screen-3.mp4`,
    mediaType: 'video',
  },
  {
    title: 'Trusted by 3+ million users',
    text: 'Non-hormonal, non-invasive birth<br/>control that looks out for your future',
    mediaSrc: `${assetsBase}/videos/intro-screen-4.mp4`,
    mediaType: 'video',
    gradient: 'gradient-2',
  },
  {
    title: 'With you every step of the way',
    text: 'Prevent, plan, follow a pregnancy, and support your recovery after giving birth',
    mediaSrc: `${assetsBase}/images/intro-screen-5.png`,
    mediaType: 'image',
  },
]

export const ab250AutoplaySlidesOptions: {
  config: SwiperOptions
} = {
  config: {
    speed: 700,
  },
}
