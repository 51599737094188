import { DURATION, EASING } from '@app/animations/vertical-slide'
import { di } from '@app/srv/di.service'
import { EventService } from '@app/srv/event.service'
import { AnimationController } from '@ionic/angular'
import { Animation } from '@ionic/core'

export function fadeAnimation(_baseEl: HTMLElement, opts?: any): Animation {
  const animationController = di.get(AnimationController)

  const wrapperAnimation = animationController
    .create()
    .addElement(opts?.enteringEl)
    .beforeStyles({ opacity: 0 })
    .fromTo('opacity', 0, 1)
    .afterStyles({ opacity: 1 })
    .onFinish(() => di.get(EventService).transitionDone$.next())

  const baseAnimation = animationController
    .create()
    .easing(EASING)
    .duration(DURATION)
    .addAnimation(wrapperAnimation)

  return baseAnimation
}
