import {
  animate,
  AnimationEvent,
  query,
  stagger,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations'
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  Input,
  OnChanges,
  OnInit,
  output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { EVENT } from '@app/analytics/analytics.cnst'
import { AnalyticsService } from '@app/analytics/analytics.service'
import { SuperSegmentComponent } from '@app/cmp/super-segment/super-segment.component'
import {
  AddDataConfig,
  ColorBySegment,
  SuperSegmentItem,
  SuperSegmentSpecialButton,
  SuperSegmentValue,
} from '@app/cnst/add-data.cnst'
import { FEEDBACK } from '@app/cnst/feedback.cnst'
import { ICON, ICON_BY_DATAFLAG } from '@app/cnst/icons.cnst'
import { SNACKBAR } from '@app/cnst/snackbars.cnst'
import { AddDataLhPreviewModal } from '@app/modals/add-data-lh-preview/add-data-lh-preview.modal'
import { DeviationSleepModal } from '@app/modals/deviation-sleep/deviation-sleep.modal'
import { FeedbackModal } from '@app/modals/feedback/feedback.modal'
import { LhScannerModal } from '@app/modals/lh-scanner/lh-scanner.modal'
import { RemindersService } from '@app/pages/settings/reminders/reminders.service'
import { PopupController, Priority } from '@app/srv/popup.controller'
import { SnackbarService } from '@app/srv/snackbar.service'
import { dispatch, getState } from '@app/srv/store.service'
import {
  _mapObject,
  _stringMapEntries,
  _Throttle,
  localDate,
  StringMap,
} from '@naturalcycles/js-lib'
import {
  AppId,
  CervicalMucusConsistency,
  DailyEntryBM,
  DataFlag,
  DataIntensity,
  DataQuantity,
  Goal,
  HadSex,
  Libido,
  Mens,
  SexType,
  Sleep,
  TestResult,
} from '@naturalcycles/shared'
import { PregnancyEndTrigger } from '@src/app/pages/flow/pregnancy-end/pregnancy-end.cnst'
import { TourTooltip } from '@src/app/srv/tour.cnst'
import { BehaviorSubject, Subscription } from 'rxjs'
import { DataFlagChangeEvent } from '../data-flag-with-intensity-toggle/data-flag-with-intensity-toggle.component'

enum AdditionalDataControl {
  DATA_FLAGS = 'dataFlags',
  DATA_FLAG_INTENSITY = 'dataFlagIntensity',
  MENS = 'mens',
  MENS_QUANTITY = 'mensQuantity',
  LH_TEST = 'lhTest',
  LH_TEST_IMAGE_IDS = 'lhTestImageIds',
  PREG_TEST = 'pregTest',
  SEX = 'sex',
  LIBIDO = 'libido',
  SEX_TYPE = 'sexType',
  NOTES = 'notes',
  CM_QUANTITY = 'cervicalMucusQuantity',
  CM_CONSISTENCY = 'cervicalMucusConsistency',
  SLEEP = 'sleep',
}

interface AdditionalDataFormModel {
  dataFlags: DataFlag[]
  dataFlagIntensity: StringMap<DataIntensity>
  mens: Mens
  mensQuantity: DataQuantity
  lhTest: TestResult
  lhTestImageIds: string[]
  pregTest: TestResult
  sex: HadSex
  libido: Libido
  sleep: Sleep
  sexType: SexType
  notes: string
  cervicalMucusQuantity: DataQuantity
  cervicalMucusConsistency: CervicalMucusConsistency
}

type ModelFormGroup<T> = FormGroup<{
  [K in keyof T]: T[K] extends readonly (infer A)[]
    ? FormArray<FormControl<A | null>>
    : FormControl<T[K] | null | undefined>
}>

@Component({
  selector: 'app-add-data-additional-info',
  templateUrl: './add-data-additional-info.component.html',
  styleUrls: ['./add-data-additional-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('segmentAnimation', [
      state('show', style({ width: '*' })),
      state('more', style({ width: '0px' })),
      transition('show => hide', animate('0ms')),
      transition('hide => show', animate('0ms')),
    ]),
    trigger('fadeAnimationSequence', [
      transition(':enter', [
        query(
          '.animated',
          [
            style({ opacity: '0' }),
            stagger(100, [animate('150ms ease-in', style({ opacity: '1' }))]),
          ],
          { optional: true },
        ),
      ]),
    ]),
  ],
})
export class AdditionalInfoComponent implements OnInit, OnChanges {
  private cdr = inject(ChangeDetectorRef)
  private fb = inject(FormBuilder)
  private analyticsService = inject(AnalyticsService)
  private popupController = inject(PopupController)
  private remindersService = inject(RemindersService)
  private snackbarService = inject(SnackbarService)
  @ViewChild('scrollButtonContainer')
  scrollButtonContainer!: ElementRef

  public form?: ModelFormGroup<AdditionalDataFormModel>
  public otherSexDisabled = false
  public sexNoneDisabled = false
  private subscriptions: Subscription[] = []

  public DataFlag = DataFlag
  public HadSex = HadSex
  public Goal = Goal
  public ICON = ICON
  public ICON_BY_DATAFLAG = ICON_BY_DATAFLAG
  public TourTooltip = TourTooltip
  public ColorBySegment = ColorBySegment
  public SuperSegmentSpecialButton = SuperSegmentSpecialButton
  public AdditionalDataControl = AdditionalDataControl
  public AppId = AppId

  private _entry!: DailyEntryBM

  @ViewChild('cmQuantity')
  private cmQuantity?: SuperSegmentComponent

  @Input({ required: true })
  private set entry(_entry: DailyEntryBM) {
    this._entry = _entry
    this.setFormValues()
  }

  public get entry(): DailyEntryBM {
    return this._entry
  }

  @Input({ required: true })
  public config: AddDataConfig = {}

  @Input()
  private ouraMode = false

  @Input()
  public downButtonRotated = false

  @Input()
  public ready = false

  @Input()
  public showScrollDownButton!: boolean

  // triggers displaying of trackers below the fold when ghost is removed
  @Input()
  public showAllTrackers = false

  protected changes = output<DailyEntryBM>()

  protected noLongerPregnant = output<PregnancyEndTrigger>()

  protected additionalDataLoaded = output()

  protected scrollDownClick = output()

  public scrollLock = output<boolean>()

  private allTrackersVisible$ = new BehaviorSubject<boolean>(this.showAllTrackers)
  public animationDone?: boolean
  public allTrackersAnimationDone?: boolean

  public ngOnInit(): void {
    setTimeout(() => {
      this.initForm()

      this.cdr.markForCheck()
    })
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['goal']?.currentValue && changes['entry']?.currentValue) {
      this.updateSexSegments(this.entry.sex === HadSex.NO, this.entry.dataFlags)
    }

    if (changes['showAllTrackers']) {
      this.allTrackersVisible$.next(changes['showAllTrackers'].currentValue)
    }
  }

  public async openDeviationSleepModal(): Promise<void> {
    const reminderTime = this.remindersService.getFirstEnabledMorningReminderTime()
    const { wakeUpTime, deviationSleepFeedbackShown } = getState().userSettings

    const modal = await this.popupController.presentModal(
      {
        component: DeviationSleepModal,
        componentProps: {
          reminderTime,
          wakeUpTime,
        },
      },
      'modal-deviationSleep',
      Priority.IMMEDIATE,
    )

    const { data } = await modal.onWillDismiss()

    if (!data) return

    if (data.deviate) {
      this.dataFlags.push(this.fb.control(DataFlag.DEVIATION_REASON_SLEEP))
    }

    if (deviationSleepFeedbackShown) return

    void this.popupController.presentModal(
      {
        component: FeedbackModal,
        cssClass: ['modal--alert'],
        componentProps: FEEDBACK.DEVIATION_SLEEP,
      },
      'modal-feedback',
      Priority.HIGH,
    )

    dispatch('extendUserSettings', { deviationSleepFeedbackShown: true })
  }

  public onDeviationChange(event: CustomEvent, reason: DataFlag): void {
    // this.dom.read(() => {
    const selected = event.detail.checked

    if (selected) {
      // DataFlags should be unique
      if (this.dataFlags.value.includes(reason)) return

      this.dataFlags.push(this.fb.control(reason))
      if (reason !== DataFlag.DEVIATION_REASON_ALGO) {
        const deviationAlgoIndex = this.dataFlags.value.indexOf(DataFlag.DEVIATION_REASON_ALGO)

        if (deviationAlgoIndex !== -1) this.dataFlags.removeAt(deviationAlgoIndex)
      }
      this.showDeviationSnackbar(reason)
    } else {
      const index = this.dataFlags.value.indexOf(reason)
      this.dataFlags.removeAt(index)
    }

    void this.analyticsService.trackEvent(EVENT.DATA_FLAG_TOGGLE, { id: reason, selected })
    // })
  }

  public onMoodChange(event: CustomEvent, mood: DataFlag, _index: number): void {
    const selected = event.detail.checked

    if (selected) {
      this.dataFlags.push(this.fb.control(mood))
    } else {
      const index = this.dataFlags.value.indexOf(mood)
      this.dataFlags.removeAt(index)
    }

    void this.analyticsService.trackEvent(EVENT.DATA_FLAG_TOGGLE, { id: mood, selected })
  }

  private showDeviationSnackbar(reason: DataFlag): void {
    if (this.ouraMode) return

    switch (reason) {
      case DataFlag.DEVIATION_REASON_SICK:
        this.snackbarService.showSnackbar(SNACKBAR.DEVIATION_SICK)
        break
      case DataFlag.DEVIATION_REASON_SLEEP:
        this.snackbarService.showSnackbar(SNACKBAR.DEVIATION_SLEEP)
        break
      case DataFlag.DEVIATION_REASON_ALCOHOL:
        this.snackbarService.showSnackbar(SNACKBAR.DEVIATION_ALCOHOL)
        break
    }
  }

  public onDeviationClick(reason: DataFlag): void {
    if (reason === DataFlag.DEVIATION_REASON_ALGO) {
      void this.snackbarService.showSnackbar(SNACKBAR.DEVIATION_ALGO)
    }
  }

  public onSexChange(event: CustomEvent, reason: SuperSegmentItem): void {
    const { value } = reason

    if ([HadSex.YES, HadSex.NO].includes(value)) {
      this.form?.controls[AdditionalDataControl.SEX]!.setValue(
        event.detail.checked ? value : undefined,
      )
    } else {
      this.onDataFlagChange({ checked: event.detail.checked }, reason.value)
    }
  }

  public onDataFlagChange(event: DataFlagChangeEvent, dataFlag: DataFlag): void {
    if (event.checked) {
      if (!this.isDataFlagChecked(dataFlag)) {
        this.dataFlags.push(this.fb.control(dataFlag))
      }
      this.setDataFlagIntensity(dataFlag, event.intensity)
    } else {
      const index = this.dataFlags.value.indexOf(dataFlag)
      this.dataFlags.removeAt(index)
      this.setDataFlagIntensity(dataFlag, undefined)
    }
    this.cdr.detectChanges()

    void this.analyticsService.trackEvent(EVENT.DATA_FLAG_TOGGLE, {
      id: dataFlag,
      selected: event.checked,
      intensity: event.intensity && DataIntensity[event.intensity],
    })
  }

  private setDataFlagIntensity(dataFlag: DataFlag, intensity: DataIntensity | undefined): void {
    const formControl = this.form?.controls[AdditionalDataControl.DATA_FLAG_INTENSITY]!
    const currentValues = formControl.value || {}
    if (intensity) {
      formControl.setValue({
        ...currentValues,
        [dataFlag]: intensity,
      })
    } else {
      // remove dataFlag from intensities
      formControl.setValue(
        Object.fromEntries(
          _stringMapEntries(currentValues).filter(([flag]) => Number(flag) !== dataFlag),
        ),
      )
    }
  }

  public onMensChange(value: SuperSegmentValue): void {
    this.form?.controls[AdditionalDataControl.MENS]!.setValue(value.value)
    this.form?.controls[AdditionalDataControl.MENS_QUANTITY]!.setValue(value.option)

    switch (value.value) {
      case Mens.SPOTTING: {
        this.snackbarService.showSnackbar(
          this.config.goal === Goal.PREGNANT ? SNACKBAR.SPOTTING_PREGNANT : SNACKBAR.SPOTTING,
        )
        break
      }

      case Mens.WITHDRAWAL:
        this.snackbarService.showSnackbar(SNACKBAR.WITHDRAWAL_BLEED)
        break

      case Mens.MISCARRIAGE_BLEEDING:
        this.snackbarService.showSnackbar(SNACKBAR.MISCARRIAGE_BLEED)
        break
    }
  }

  public onSexSuperSegmentChange(value: SuperSegmentValue): void {
    this.form?.controls[AdditionalDataControl.SEX]!.setValue(value.value)
    this.form?.controls[AdditionalDataControl.SEX_TYPE]!.setValue(value.option)

    if (value.value === HadSex.YES_PROTECTED) {
      this.snackbarService.showSnackbar(SNACKBAR.SEX_PROTECTED)
    }

    if (
      value.value === HadSex.YES &&
      (this.config.goal === Goal.PREVENT || this.config.goal === Goal.POSTPARTUM)
    ) {
      this.snackbarService.showSnackbar(SNACKBAR.SEX_UNPROTECTED)
    }
  }

  public onLibidoChange(value: SuperSegmentValue): void {
    this.form?.controls[AdditionalDataControl.LIBIDO]!.setValue(value.value)
  }

  public onLhTestChange(value: SuperSegmentValue): void {
    if (value.value === SuperSegmentSpecialButton.NO_VALUE) value.value = value.option
    this.form?.controls[AdditionalDataControl.LH_TEST]!.setValue(value.value)

    if (!value.value) return

    this.snackbarService.showSnackbar(SNACKBAR.LH_TEST)
  }

  public async onLhTestWithImageClick(imageIds?: string[], testResult?: TestResult): Promise<void> {
    const modal = await this.popupController.presentModal(
      {
        component: AddDataLhPreviewModal,
        componentProps: { imageIds, testResult },
        cssClass: ['modal--alert'],
      },
      'modal-lhPreview',
      Priority.IMMEDIATE,
    )

    const { data } = await modal.onWillDismiss()
    const { id } = data || {}

    if (id) {
      this.onLhTestChange({})
      const index = (this.lhTestImageIds.value as string[]).indexOf(id)
      this.lhTestImageIds.removeAt(index)
    }
  }

  public onPregTestChange(value: SuperSegmentValue): void {
    if (value.value === SuperSegmentSpecialButton.NO_VALUE) value.value = value.option

    this.form?.controls[AdditionalDataControl.PREG_TEST]!.setValue(value.value)

    if (this.config.goal === Goal.PLAN) {
      this.snackbarService.showSnackbar(SNACKBAR.PREG_TEST)
    }
  }

  public onCervicalMucusQuantityChange(value: SuperSegmentValue): void {
    if (value.value === SuperSegmentSpecialButton.NO_VALUE) value.value = value.option
    this.form?.controls[AdditionalDataControl.CM_QUANTITY]!.setValue(value.value)

    this.snackbarService.showSnackbar(
      this.config.goal === Goal.PREGNANT
        ? SNACKBAR.CERVICAL_MUCUS_PREGNANT
        : SNACKBAR.CERVICAL_MUCUS,
    )
  }

  public onCervicalMucusConsistencyChange(value: SuperSegmentValue): void {
    if (value.value === SuperSegmentSpecialButton.NO_VALUE) value.value = value.option

    this.form?.controls[AdditionalDataControl.CM_CONSISTENCY]!.setValue(value.value)

    this.snackbarService.showSnackbar(
      this.config.goal === Goal.PREGNANT
        ? SNACKBAR.CERVICAL_MUCUS_PREGNANT
        : SNACKBAR.CERVICAL_MUCUS,
    )
  }

  public onNotesChange(notes: string): void {
    this.form?.controls[AdditionalDataControl.NOTES]!.setValue(notes)
  }

  public onSleepChange(value: SuperSegmentValue): void {
    this.form?.controls[AdditionalDataControl.SLEEP]!.setValue(value.value)
  }

  public noLongerPregnantClicked(): void {
    this.noLongerPregnant.emit(PregnancyEndTrigger.NO_LONGER_PREGNANT)
  }

  @_Throttle(2000)
  public async onSpecialButtonClick(button: SuperSegmentSpecialButton): Promise<void> {
    if (button === SuperSegmentSpecialButton.LH_SCANNER_BUTTON) {
      const modal = await this.popupController.presentModal(
        {
          component: LhScannerModal,
          componentProps: {
            day: this.entry.date === localDate.todayString() ? 'today' : 'past',
          },
        },
        'modal-lhCompare',
        Priority.IMMEDIATE,
      )

      const { data } = await modal.onWillDismiss()
      const { result, imageId } = data || {}

      if (result) this.form?.controls[AdditionalDataControl.LH_TEST]!.setValue(result)
      if (imageId) this.lhTestImageIds.push(this.fb.control(imageId))
    }
  }

  public scrollToBottom(): void {
    this.scrollDownClick.emit()
  }

  public isDataFlagChecked(dataFlag: DataFlag): boolean {
    return this.dataFlags.value?.includes(dataFlag)
  }

  public dataFlagIntensityValue(dataFlag: DataFlag): DataIntensity | undefined {
    const formControl = this.form?.controls[AdditionalDataControl.DATA_FLAG_INTENSITY]!

    return formControl.value?.[dataFlag]
  }

  private isLhImageAdded(id: string): boolean {
    return (this.lhTestImageIds?.value as string[])?.includes(id)
  }

  public updateCmQuantity(event: AnimationEvent): void {
    if (event.toState === 'void') return // if triggered on destroy

    // update offset of cm quantity when cm consistency is shown/hidden
    setTimeout(() => {
      void this.cmQuantity?.updateOffsets()
    }, 100)
  }

  public fadeAnimationDone(event: AnimationEvent): void {
    // this means the animation is triggered by the component being destroyed
    if (!event.totalTime) return

    this.allTrackersAnimationDone = true
    this.additionalDataLoaded.emit()
  }

  private get dataFlags(): FormArray<FormControl<DataFlag | null>> {
    return this.form?.get('dataFlags') as FormArray
  }

  private get lhTestImageIds(): FormArray<FormControl<string | null>> {
    return this.form?.get('lhTestImageIds') as FormArray
  }

  private initForm(): void {
    this.form = this.fb.group({
      [AdditionalDataControl.DATA_FLAGS]: this.fb.array(this.entry.dataFlags),
      [AdditionalDataControl.DATA_FLAG_INTENSITY]: this.fb.control(this.entry.dataFlagIntensity),
      [AdditionalDataControl.LH_TEST]: this.fb.control(this.entry.lhTest),
      [AdditionalDataControl.LH_TEST_IMAGE_IDS]: this.fb.array(this.entry.lhTestImageIds || []),
      [AdditionalDataControl.MENS]: this.fb.control(this.entry.mens),
      [AdditionalDataControl.MENS_QUANTITY]: this.fb.control(this.entry.mensQuantity),
      [AdditionalDataControl.NOTES]: this.fb.control(this.entry.notes),
      [AdditionalDataControl.PREG_TEST]: this.fb.control(this.entry.pregTest),
      [AdditionalDataControl.SEX]: this.fb.control(this.entry.sex),
      [AdditionalDataControl.SEX_TYPE]: this.fb.control(this.entry.sexType),
      [AdditionalDataControl.LIBIDO]: this.fb.control(this.entry.libido),
      [AdditionalDataControl.SLEEP]: this.fb.control(this.entry.sleep),
      [AdditionalDataControl.CM_QUANTITY]: this.fb.control(this.entry.cervicalMucusQuantity),
      [AdditionalDataControl.CM_CONSISTENCY]: this.fb.control(this.entry.cervicalMucusConsistency),
    })

    const formSub = this.form.valueChanges.subscribe(val => {
      const val_ = _mapObject(val, k => {
        return val[k] === null ? [k, undefined] : [k, val[k]]
      }) satisfies Partial<DailyEntryBM>

      this.changes.emit({
        ...this.entry,
        ...val_,
      })
    })

    this.subscriptions.push(formSub)
  }

  private setFormValues(): void {
    if (!this.form) return

    const entry = this.entry
    this.form?.controls[AdditionalDataControl.LH_TEST].setValue(entry.lhTest, {
      emitEvent: false,
    })
    this.form?.controls[AdditionalDataControl.MENS].setValue(entry.mens, { emitEvent: false })
    this.form?.controls[AdditionalDataControl.MENS_QUANTITY].setValue(entry.mensQuantity, {
      emitEvent: false,
    })
    this.form?.controls[AdditionalDataControl.NOTES].setValue(entry.notes, { emitEvent: false })
    this.form?.controls[AdditionalDataControl.PREG_TEST].setValue(entry.pregTest, {
      emitEvent: false,
    })
    this.form?.controls[AdditionalDataControl.SEX].setValue(entry.sex, { emitEvent: false })
    this.form?.controls[AdditionalDataControl.SEX_TYPE].setValue(entry.sexType, {
      emitEvent: false,
    })
    this.form?.controls[AdditionalDataControl.LIBIDO].setValue(entry.libido, {
      emitEvent: false,
    })
    this.form?.controls[AdditionalDataControl.SLEEP].setValue(entry.sleep, {
      emitEvent: false,
    })
    this.form?.controls[AdditionalDataControl.CM_QUANTITY].setValue(entry.cervicalMucusQuantity, {
      emitEvent: false,
    })
    this.form?.controls[AdditionalDataControl.CM_CONSISTENCY].setValue(
      entry.cervicalMucusConsistency,
      {
        emitEvent: false,
      },
    )
    this.form?.controls[AdditionalDataControl.DATA_FLAG_INTENSITY].setValue(
      entry.dataFlagIntensity,
      {
        emitEvent: false,
      },
    )

    if (entry.dataFlags.length) {
      this.dataFlags.patchValue(entry.dataFlags, { emitEvent: false })

      for (const flag of entry.dataFlags) {
        if (!this.isDataFlagChecked(flag)) {
          this.dataFlags.push(this.fb.control(flag))
        }
      }
    } else {
      while (this.dataFlags.length !== 0) {
        this.dataFlags.removeAt(0, { emitEvent: false })
      }
    }

    if (entry.lhTestImageIds?.length) {
      this.lhTestImageIds.patchValue(entry.lhTestImageIds, { emitEvent: false })

      for (const id of entry.lhTestImageIds) {
        if (!this.isLhImageAdded(id)) {
          this.lhTestImageIds.push(this.fb.control(id))
        }
      }
    } else {
      while (this.lhTestImageIds.length !== 0) {
        this.lhTestImageIds.removeAt(0)
      }
    }

    this.updateSexSegments(entry.sex === HadSex.NO, entry.dataFlags)
  }

  private updateSexSegments(otherSexDisabled: boolean, dataFlags: DataFlag[]): void {
    // Don't disable any options if sex and other sex are shown separately
    if (
      !this.config.goal ||
      this.config.goal === Goal.PREVENT ||
      this.config.goal === Goal.POSTPARTUM
    ) {
      return
    }

    this.otherSexDisabled = otherSexDisabled

    const sexFlags = dataFlags.filter(flag => DataFlag[flag]?.startsWith('SEX_'))
    this.sexNoneDisabled = !!sexFlags.length
  }
}
