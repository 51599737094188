<div class="fertilityCircle" [attr.uid]="date">
  <!-- day label / pregnancy week label-->
  <div
    class="fertilityCircle__cycleDayLabel"
    [ngClass]="{
      'fertilityCircle__cycleDayLabel--first': dayIndex === 0,
      'fertilityCircle__cycleDayLabel--last': dayIndex === 6,
      'fertilityCircle__cycleDayLabel--today': today,
    }"
  >
    {{ topLabel }}
  </div>

  <!-- status icon -->
  @if (statusIcon) {
    <div class="fertilityCircle__status" slot="icon-only">
      <ion-icon
        class="fertilityCircle__statusIcon"
        [ngClass]="statusIconId"
        [class.fertilityCircle__statusIcon--today]="today"
        [src]="statusIcon"
        color="text"
      />
    </div>
  }

  <!-- circle -->
  @if (today) {
    <div
      class="fertilityCircle__button
        fertilityCircle__button--today
        {{ fertilityColor ? 'fertColor--' + fertilityColor : null }}"
    >
      <svg style="width: 100%; height: 100%">
        <!-- Apply css styles to svg files using <use> tags.
        Put 'id' in the svg file and make the properties you want to change variable.
        Here, We want to change the color of the circle based on the fertilityColor. fill="var(--circle-color, #f1eaea)" -->
        @if (statusIcon && icon) {
          <use
            xlink:href="/assets/img/fertility-circle-today-icon-and-status.svg#fertility-circle-today-icon-and-status"
          />
        } @else if (statusIcon && !icon) {
          <use
            xlink:href="/assets/img/fertility-circle-today-status-only.svg#fertility-circle-today-status-only"
          />
        } @else if (!statusIcon && icon) {
          <use
            xlink:href="/assets/img/fertility-circle-today-icon-only.svg#fertility-circle-today-icon-only"
          />
        } @else {
          <use xlink:href="/assets/img/fertility-circle-today.svg#fertility-circle-today" />
        }
      </svg>
      <div class="fertilityCircle__dateLabel">{{ dateLabel }}</div>
    </div>
  } @else {
    <div
      class="fertilityCircle__button {{ fertilityColor ? 'fertColor--' + fertilityColor : null }}"
      [ngClass]="{
        'fertilityCircle__button--prediction': prediction,
        'fertilityCircle__button--noData': !fertilityColor,
        'fertilityCircle__button--icon': icon,
      }"
      shape="round"
    >
      <div class="fertilityCircle__dateLabel">{{ dateLabel }}</div>
    </div>
  }

  <!-- icon -->
  @if (icon) {
    <ion-icon
      [src]="icon.icon"
      [color]="icon.prediction ? 'prediction' : today ? 'text' : null"
      class="fertilityCircle__icon"
      [class.fertilityCircle__icon--transparent]="today || prediction"
      [ngClass]="iconId"
    />
  }
</div>
