import { Component, Input } from '@angular/core'
import { ICON } from '@app/cnst/icons.cnst'

@Component({
  selector: 'app-nc-secure-logo',
  templateUrl: './nc-secure-logo.component.html',
  styleUrls: ['./nc-secure-logo.component.scss'],
})
export class NCSecureLogoComponent {
  public ICON = ICON

  @Input()
  public color = 'logo'
}
