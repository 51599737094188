import { inject, Injectable } from '@angular/core'
import { NavigationOptions } from '@ionic/angular/common/providers/nav-controller'
import {
  _assert,
  _filterFalsyValues,
  _filterNullishValues,
  _min,
  _objectAssign,
  _objectKeys,
  _omit,
  _stringMapValues,
  IsoDateString,
  localDate,
} from '@naturalcycles/js-lib'
import {
  BirthComplication,
  FeedingMethod,
  Goal,
  MAX_PREGNANCY_DAYS,
  PREGNANCY_STATE_MIN_LENGTH,
  PregnancyEndCareType,
  PregnancyEndDataInput,
  PregnancyEndReason,
  PregnancyEndSymptom,
  PregnancyState,
} from '@naturalcycles/shared'
import { addAnalyticsProps } from '@src/app/analytics/analytics.service'
import { verticalSlideAnimation } from '@src/app/animations/vertical-slide'
import { ROUTES } from '@src/app/cnst/nav.cnst'
import { NavigationParams } from '@src/app/cnst/nav.params.cnst'
import { decorate, ErrorHandlerType, LoaderType } from '@src/app/decorators/decorators'
import { AccountService } from '@src/app/srv/account.service'
import { api } from '@src/app/srv/api.service'
import { EventService } from '@src/app/srv/event.service'
import { addNavParams } from '@src/app/srv/nav.service'
import { dispatch, getState } from '@src/app/srv/store.service'
import { TourId } from '@src/app/srv/tour.cnst'
import { TourService } from '@src/app/srv/tour.service'
import { AddDataService } from '../../add-data/add-data.service'
import { FlowId, FlowInputData, FlowPage } from '../flow.cnst'
import { FlowService } from '../flow.service'
import { PregnancyEndConfirmation, PregnancyEndTrigger } from './pregnancy-end.cnst'
import { PREGNANCY_END_CONFIG, PREGNANCY_END_INFO_SCREEN } from './pregnancy-end.config'

export interface PregnancyEndFlowDataInput extends PregnancyEndDataInput {
  trigger: PregnancyEndTrigger
  confirmation: PregnancyEndConfirmation
  minPossiblePregnancyEndedDate: IsoDateString
  pregnantOnPrevent: boolean
  moreQuestions: boolean
  pregnancyLength: number
  conceptionDate: string

  // keys for special cases
  revisitSettings: boolean
  measureReminders: undefined
  trackers: undefined
  notifications: undefined
  deliveryMethodOther: string
}

interface PregnancyEndStartInput {
  trigger: PregnancyEndTrigger
  goal?: Goal
  pregnancyEndDate?: IsoDateString
  confirmedBirth?: boolean
}

@Injectable({ providedIn: 'root' })
export class PregnancyEndService extends FlowService<PregnancyEndFlowDataInput> {
  CONFIG = PREGNANCY_END_CONFIG
  INFO_SCREENS = PREGNANCY_END_INFO_SCREEN
  FLOW_ID = FlowId.PREGNANCY_END

  private accountService = inject(AccountService)
  private addDataService = inject(AddDataService)
  private tourService = inject(TourService)
  private eventService = inject(EventService)

  public async startPregnancyEndFlow(input: PregnancyEndStartInput): Promise<void> {
    const { trigger, goal, pregnancyEndDate, confirmedBirth } = input
    const {
      account,
      userFertility: { minPossiblePregnancyEndedDate, conceptionDate },
    } = getState()

    const inputData: FlowInputData<PregnancyEndFlowDataInput> = {
      trigger,
      minPossiblePregnancyEndedDate,
      pregnancyEndDate,
      conceptionDate,
      moreQuestions: null,
      pregnancyStartDate: conceptionDate,
      pregnancyLength: conceptionDate ? null : undefined,
      state: this.pregnancyState,
      confirmation: confirmedBirth ? PregnancyEndConfirmation.GAVE_BIRTH : undefined,
    }

    // Pregnant on prevent
    if (account.goal === Goal.PREVENT) {
      _objectAssign(inputData, {
        pregnantOnPrevent: true,
        symptoms: null,
        moreQuestions: undefined,
        goal: Goal.PREVENT,
      })
    }

    // new user signing up on Goal.POSTPARTUM
    if (trigger === PregnancyEndTrigger.ONBOARDING) {
      _objectAssign(inputData, {
        goal,
        confirmation: PregnancyEndConfirmation.GAVE_BIRTH,
        state: PregnancyState.FULL_TERM,
      })
    }

    const config = await this.saveAndGetNextConfig(inputData, undefined, true)

    if (!config?.route) return

    addAnalyticsProps({
      source: trigger,
      pregnancyWeek: conceptionDate
        ? Math.ceil(localDate.today().diff(conceptionDate, 'day') / 7)
        : undefined,
    })

    const navOptions: NavigationOptions = {
      state: {
        ...config,
        config: _omit(config.config, ['sideEffects']), // omit side effects as it seems not possible to pass functions to next page
      },
    }

    if (trigger === PregnancyEndTrigger.ONBOARDING) {
      await this.navController.navigateRoot(config.route, { ...navOptions, animated: true })
    } else {
      await this.navController.navigateForward(config.route, {
        ...navOptions,
        animation: verticalSlideAnimation,
      })
    }
  }

  @decorate({
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  public override async saveAndGetNextConfig(
    data: FlowInputData<PregnancyEndFlowDataInput>,
    key?: keyof PregnancyEndFlowDataInput,
    override = false,
  ): Promise<FlowPage | undefined> {
    let config: FlowPage | undefined

    switch (key) {
      case 'confirmation': {
        if (data.confirmation === PregnancyEndConfirmation.STILL_PREGNANT) {
          await this.navController.pop()
          return
        }

        if (data.confirmation === PregnancyEndConfirmation.CHANGE_TO_FOLLOW) {
          await this.accountService.changeGoal({ goal: Goal.PREGNANT })
          await this.navController.pop()
          return
        }

        config = await super.saveAndGetNextConfig(data, key, override)
        break
      }

      case 'symptoms': {
        const symptoms = _objectKeys(_filterFalsyValues(data)).map(
          k => PregnancyEndSymptom[k as any]!,
        ) as any as PregnancyEndSymptom[]

        config = await super.saveAndGetNextConfig({ symptoms }, key, override)
        break
      }

      case 'receivedCareType': {
        const receivedCareType = _objectKeys(_filterFalsyValues(data)).map(
          k => PregnancyEndCareType[k as any]!,
        ) as any as PregnancyEndCareType[]

        config = await super.saveAndGetNextConfig({ receivedCareType }, key, override)
        break
      }

      case 'birthComplications': {
        const birthComplications = _objectKeys(_filterFalsyValues(data)).map(
          k => BirthComplication[k as any]!,
        ) as any as BirthComplication[]

        config = await super.saveAndGetNextConfig({ birthComplications }, key, override)
        break
      }

      case 'feedingMethods': {
        const feedingMethods = _objectKeys(_filterFalsyValues(data)).map(
          k => FeedingMethod[k as any]!,
        ) as any as FeedingMethod[]

        config = await super.saveAndGetNextConfig({ feedingMethods }, key, override)
        break
      }

      default:
        config = await super.saveAndGetNextConfig(data, key, override)
    }

    if (config) return config

    await this.nextAfterCompletingPregnancyEndData()
  }

  public override _processSideEffects(
    key: keyof PregnancyEndFlowDataInput,
    input: FlowInputData<PregnancyEndFlowDataInput>,
  ): FlowInputData<PregnancyEndFlowDataInput> {
    if (
      key === 'confirmation' &&
      input.confirmation === PregnancyEndConfirmation.WAS_NOT_PREGNANT
    ) {
      return {
        ...this.markAllAsNull(input, []),
        goal: undefined,
      }
    }

    if (key === 'revisitSettings' && input.revisitSettings === false) {
      return this.markAllAsNull(input, [])
    }

    if (key === 'moreQuestions' && input.moreQuestions === false) {
      return this.markAllAsNull(input, [])
    }

    return super._processSideEffects(key, input)
  }

  private markAllAsNull(
    input: FlowInputData<PregnancyEndFlowDataInput>,
    omitKeys: (keyof PregnancyEndFlowDataInput)[],
  ): FlowInputData<PregnancyEndFlowDataInput> {
    const undefinedEntries =
      [...this.CONFIG.entries()].filter(([key]) => {
        if (input[key] === undefined) return true
      }) || []

    const allNull = Object.fromEntries(undefinedEntries.map(([key]) => [key, null]))
    return _omit(allNull, omitKeys)
  }

  public get pregnancyState(): PregnancyState | undefined {
    const { pregnantNow, conceptionDate } = getState().userFertility

    if (!pregnantNow || !conceptionDate) return undefined

    const pregLength = localDate.today().diff(conceptionDate, 'day')

    if (pregLength >= PREGNANCY_STATE_MIN_LENGTH[PregnancyState.FULL_TERM]) {
      return PregnancyState.FULL_TERM
    }
    if (pregLength >= PREGNANCY_STATE_MIN_LENGTH[PregnancyState.MID]) return PregnancyState.MID
    if (pregLength >= PREGNANCY_STATE_MIN_LENGTH[PregnancyState.EARLY]) return PregnancyState.EARLY

    return undefined
  }

  /** Indicates whether user had a early preg loss and ever was on Goal.PREGNANT */
  public get showPregnancyLossCards(): boolean {
    const { accountData, userFertility } = getState()
    return (
      !!accountData.miscarriageBleedDates?.length &&
      _stringMapValues(userFertility.colorMap).some(cc => cc.goal === Goal.PREGNANT)
    )
  }

  private async nextAfterCompletingPregnancyEndData(): Promise<void> {
    const { flowData, userFertility } = getState()

    const data = _filterNullishValues(flowData) as PregnancyEndFlowDataInput

    const { confirmation, goal, trigger } = data

    if (confirmation === PregnancyEndConfirmation.WAS_NOT_PREGNANT) {
      const pregnancyEndedDate =
        userFertility.minPossiblePregnancyEndedDate ?? localDate.todayString()

      await this.accountService.changeGoal({ goal, pregnancyEndedDate })

      const source = trigger === PregnancyEndTrigger.GOAL ? ROUTES.SettingsGoalPage : ROUTES.AddData

      await this.navController.navigateBack(source)

      return
    }

    await this.sendPregnancyEndData()

    this.eventService.closeDrawer$.next()
    await this.navController.navigateRoot(ROUTES.HomePage, {
      animated: true,
      animation: verticalSlideAnimation,
      animationDirection: 'back',
    })

    const { goal: updatedGoal } = getState().account

    if (updatedGoal === Goal.RECOVERY) {
      void this.tourService.startTour(TourId.RECOVERY)
      return
    }

    if (updatedGoal === Goal.POSTPARTUM) {
      // skip popup if trigger is not onboarding
      void this.tourService.startTour(TourId.POSTPARTUM, trigger !== PregnancyEndTrigger.ONBOARDING)
      return
    }
  }

  /**
   * Collects and sends stored PregnancyEndDataInput info
   */
  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  private async sendPregnancyEndData(): Promise<void> {
    const { flowData, userFertility } = getState()

    const data = _filterNullishValues(flowData) as PregnancyEndFlowDataInput

    const { goal, pregnancyStartDate, pregnancyLength } = data

    _assert(goal && pregnancyStartDate, 'Trying to save pregnancyenddata without required data!')

    await this.addDataService.saveModifiedDailyEntries()

    const today = localDate.today().toISODate()

    // if user skipped adding end date, use today or the max possible date
    if (!data.pregnancyEndDate) {
      const conceptionDate = userFertility.conceptionDate

      data.pregnancyEndDate = _min([
        today,
        localDate.orUndefined(conceptionDate)?.plus(MAX_PREGNANCY_DAYS, 'day').toISODate(),
      ])
    }

    let pregnancyWeek: number | undefined

    if (pregnancyLength) {
      pregnancyWeek = Math.floor(pregnancyLength / 7) + 1
    } else {
      const pregnancyEndDate = data.pregnancyEndDate
      const cd = userFertility.colorMap[pregnancyEndDate]?.cd
      pregnancyWeek = cd && Math.floor(cd / 7) + 3
    }

    await api.put('pregnancyenddata', {
      json: {
        ...data,
        pregnancyEndSavedDate: today,
        pregnancyWeek,
      },
    })

    if (data.reason !== PregnancyEndReason.BIRTH && data.goal !== Goal.RECOVERY) {
      addNavParams({ [NavigationParams.SHOW_PREG_END_TOOLTIP]: true })
    }

    // Reset miscarriage bleed snackbar
    dispatch('extendUserSettings', { miscarriageBleedSnackbarShown: false })
    dispatch('clearEntryStash')
  }
}
