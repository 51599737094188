import { Pipe, PipeTransform } from '@angular/core'
import { markdownService } from '@app/srv/markdown.service'

@Pipe({
  name: 'md',
  pure: true,
})
export class MdPipe implements PipeTransform {
  transform(s: string): string {
    return markdownService.parse(s)
  }
}
