import { CommonModule } from '@angular/common'
import { Component, inject } from '@angular/core'
import { ROUTES } from '@app/cnst/nav.cnst'
import { BasePage } from '@app/pages/base.page'
import { InAppBrowserService } from '@app/srv/inappbrowser.service'
import { select } from '@app/srv/store.service'
import { IonicModule } from '@ionic/angular'
import { SubscriptionFM } from '@naturalcycles/shared'
import { SharedModule } from '@src/app/shared.module'
import { OuraService } from '@src/app/srv/oura.service'
import { Observable } from 'rxjs'

@Component({
  standalone: true,
  selector: 'page-oura-info',
  templateUrl: 'oura-info.page.html',
  imports: [IonicModule, SharedModule, CommonModule],
})
export class OuraInfoPage extends BasePage {
  private inAppBrowserService = inject(InAppBrowserService)
  private ouraService = inject(OuraService)
  className = 'OuraInfoPage'

  public ROUTES = ROUTES
  public content!: string

  @select(['subscriptions', 'current'])
  public currentSubscription$!: Observable<SubscriptionFM>

  public claimDiscount(): void {
    void this.navigateBack(ROUTES.ShopPage)
    void this.inAppBrowserService.open(this.ouraService.getDiscountLink(), true)
  }

  public manage(): void {
    void this.navigateForward(ROUTES.ManageAccountPage)
  }
}
