<ion-content slot="fixed" class="intro noScroll">
  <app-intro-slides-ab250 />
</ion-content>

<ion-footer class="introPage__footer">
  <ion-button
    loader
    aria-label="Intro Page Sign Up button"
    uid="introAb250Page__signUp__button"
    shape="round"
    (click)="signup()"
  >
    <ion-label>Get started</ion-label>
  </ion-button>

  <ion-button
    uid="introAb250Page__login__button"
    aria-label="Intro Page Log In button"
    fill="clear"
    shape="round"
    color="purple"
    (click)="login()"
  >
    <ion-label> Log in </ion-label>
  </ion-button>
</ion-footer>
