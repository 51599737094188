import { animate, style, transition, trigger } from '@angular/animations'
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  output,
} from '@angular/core'
import { AnalyticsService } from '@app/analytics/analytics.service'
import { TemperatureDataState } from '@app/cnst/add-data.cnst'
import { HW_CONFIG_BY_HWID, isHardwareSupportedPlatform } from '@app/cnst/hardware.cnst'
import { ICON } from '@app/cnst/icons.cnst'
import { isWebApp } from '@app/cnst/userDevice.cnst'
import { NavService } from '@app/srv/nav.service'
import { getState } from '@app/srv/store.service'
import { UnitsService } from '@app/srv/units.service'
import { NavController } from '@ionic/angular'
import { HardwareId } from '@naturalcycles/shared'
import { BehaviorSubject } from 'rxjs'

export interface TemperatureStateClick {
  state: TemperatureDataState
  hwId: HardwareId
}

@Component({
  selector: 'app-temperature-display',
  templateUrl: './temperature-display.component.html',
  styleUrls: ['./temperature-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('150ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('150ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class TemperatureDisplayComponent implements AfterViewInit {
  @Input()
  public hwId: HardwareId = HardwareId.ORAL_THERMOMETER

  @Input()
  public state?: TemperatureDataState

  @Input()
  public isFocused!: boolean

  @Input()
  public isDeviating!: boolean

  @Input()
  public rowUid?: string

  @Input()
  public timestamp?: string

  @Input()
  public isDisabled = false

  public temperatureStateClick = output<TemperatureStateClick>()

  @Input()
  set digits(digits: number[]) {
    const maxDigits = this.fahrenheit && (!digits?.length || digits[0] === 1) ? 5 : 4

    // eslint-disable-next-line unicorn/no-new-array
    this.placeholderDigits$.next(new Array(maxDigits).fill('-'))
    this.digits$.next(digits || [])
  }

  public ICON = ICON
  public HardwareId = HardwareId
  public TemperatureDataState = TemperatureDataState

  public digits$ = new BehaviorSubject<number[]>([])
  public placeholderDigits$ = new BehaviorSubject<string[]>([])
  public fahrenheit = getState().account.fahrenheit
  public numberSeparator = '.'
  public isHardwareSupportedPlatform = isHardwareSupportedPlatform

  private analyticsService = inject(AnalyticsService)
  private navController = inject(NavController)
  private navService = inject(NavService)
  private unitsService = inject(UnitsService)

  public ngAfterViewInit(): void {
    this.numberSeparator = this.unitsService.getNumberSeparator()
  }

  public onTemperatureStateClick(event: any, state: TemperatureDataState): void {
    event.stopPropagation()
    this.temperatureStateClick.emit({ state, hwId: this.hwId })
  }

  public async startPairingFlow(): Promise<void> {
    if (isWebApp) return
    this.analyticsService.addAnalyticsProps({
      source: 'TemperatureDisplay',
    })

    const route = HW_CONFIG_BY_HWID[this.hwId].pairingRoute!

    await this.navController.navigateForward(route)
  }

  public async openOura(): Promise<void> {
    await this.navService.openOuraApp()
  }
}
