import { ApplicationRef, enableProdMode } from '@angular/core'
import { enableDebugTools } from '@angular/platform-browser'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { AppModule } from '@app/app.module'
import { env } from './environments/environment'

if (!env.dev) {
  enableProdMode()
}

/* eslint-disable unicorn/prefer-top-level-await */
platformBrowserDynamic()
  .bootstrapModule(AppModule, {
    ngZoneEventCoalescing: true,
    ngZoneRunCoalescing: true,
  })
  .then(moduleRef => {
    if (env.prod) return // don't enable in prod

    const applicationRef = moduleRef.injector.get(ApplicationRef)
    const [componentRef] = applicationRef.components
    // allows to run `ng.profiler.timeChangeDetection()` in console
    enableDebugTools(componentRef!)
  })
  .catch(err => console.log(err))
