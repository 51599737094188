import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { BigCircleContainerComponentModule } from '@app/cmp/big-circle-container/big-circle-container.component.module'
import { SharedModule } from '@app/shared.module'
import { CameraPreview } from '@awesome-cordova-plugins/camera-preview/ngx'
import { IonicModule } from '@ionic/angular'
import { LhScannerModal } from './lh-scanner.modal'

@NgModule({
  declarations: [LhScannerModal],
  imports: [IonicModule, CommonModule, SharedModule, BigCircleContainerComponentModule],
  exports: [LhScannerModal],
  providers: [CameraPreview],
})
export class LhScannerModalModule {}
