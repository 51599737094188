import { appVer } from '@app/cnst'
import { buildInfo } from '@app/util/buildInfo.util'
import { _filterFalsyValues, _stringify, StringMap } from '@naturalcycles/js-lib'
import { BrowserOptions, init as sentryBrowserInit } from '@sentry/browser'
import {
  addBreadcrumb,
  Breadcrumb,
  CapacitorOptions,
  captureException,
  captureMessage,
  Event as SentryEvent,
  init as sentryNativeInit,
  setExtras,
  setTags,
  setUser,
  SeverityLevel,
  User,
} from '@sentry/capacitor'
import { CaptureContext } from '@sentry/types'
import { normalize } from '@sentry/utils'
import { env } from '@src/environments/environment'

// Important!
// Please try to avoid importing many services here (e.g StoreService),
// since it becomes vulnerable to circular dependencies

const { sentryDsn: dsn, name: environment, prod } = env

class SentryService {
  constructor() {
    if (env.test) return // no need to init Sentry in test
    ;(globalThis as any).sentryService = this // debug

    const opt: BrowserOptions = {
      dsn,
      environment,
      release: buildInfo.ver,
      maxValueLength: 2000, // default is 250 characters
      maxBreadcrumbs: 50, // to prevent "413 Request Entity Too Large" error
      beforeSend: (_event, _hint) => {
        const event = this.removeTestcafeUrl(_event)

        // todo: use sentryGroupingStrategy from js-lib as soon as it's ready
        // event.fingerprint = ['{{ default }}', _stringify(hint?.originalException)]

        return event
      },
      // todo: figure out how to read RemoteConfig.sentrySampleRate from here
      sampleRate: env.prod ? 0.2 : 1,
    }

    if (window.Capacitor.isNativePlatform()) {
      sentryNativeInit({
        ...opt,
        enableOutOfMemoryTracking: false,
      } satisfies CapacitorOptions)
    } else {
      sentryBrowserInit(opt)
    }

    this.setTags({
      appVer,
      buildInfo_ver: buildInfo.ver, // eslint-disable-line @typescript-eslint/naming-convention
      buildInfo_env: buildInfo.env, // eslint-disable-line @typescript-eslint/naming-convention
    })
  }

  // testcafe rewrites all urls to something like http://192.168.32.3:33153/n2i9LwcKq!s!utf-8/https://master--ncapp3.netlify.app/
  // this removes the extra ip part in front of our normal url, to make sourcemaps work in sentry
  private removeTestcafeUrl(event: SentryEvent): SentryEvent {
    const exception = {
      ...event.exception,
      values: event.exception?.values?.map(exception => {
        return {
          ...exception,
          stacktrace: {
            ...exception.stacktrace,
            frames: exception.stacktrace?.frames?.map(item => {
              const filename = item.filename
              // if more than 1 http in filename -> its probably testcafe who's adding a proxy, only use last part of string
              if (filename && (filename.match(/http/g) || []).length > 1) {
                return {
                  ...item,
                  filename: filename.substring(filename.lastIndexOf('http')),
                }
              }

              return item
            }),
          },
        }
      }),
    }

    return { ...event, exception }
  }

  // Returns lastEventId
  captureException(err: any, captureContext?: CaptureContext): string | undefined {
    if (err?.cause?.data?.userFriendly) return // ignore userFriendly errors

    const errorString = _stringify(err, { includeErrorData: true })

    if (prod) {
      sentryService.addBreadcrumb({
        message: errorString,
      })
    } else {
      console.error(errorString)
    }

    return captureException(err, captureContext)
  }

  // Returns lastEventId
  captureMessage(message: string, severity?: SeverityLevel): string {
    return captureMessage(message, severity)
  }

  addBreadcrumb(crumb: Breadcrumb): void {
    addBreadcrumb({
      ...crumb,
      data: crumb.data ? normalize(crumb.data) : undefined,
    })
  }

  setExtras(ctx: any): void {
    setExtras(ctx)
  }

  setUser(ctx: User | null): void {
    setUser(ctx)
  }

  setTags(tags: StringMap<string | number>): void {
    setTags(_filterFalsyValues(tags))
  }
}

export const sentryService = new SentryService()
