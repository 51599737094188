import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { IonicModule } from '@ionic/angular'
import { SnackbarModal } from './snackbar.modal'

@NgModule({
  declarations: [SnackbarModal],
  imports: [IonicModule, CommonModule],
  exports: [SnackbarModal],
})
export class SnackbarModalModule {}
