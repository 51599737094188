import { Component } from '@angular/core'
import { ScrollFooterComponentModule } from '@app/cmp/scroll-footer/scroll-footer.component.module'
import { BaseModal } from '@app/pages/base.modal'
import { PipesModule } from '@app/pipes/pipes.module'
import { IonicModule } from '@ionic/angular'

@Component({
  selector: 'no-temps-modal',
  standalone: true,
  imports: [IonicModule, PipesModule, ScrollFooterComponentModule],
  templateUrl: './no-temps.modal.html',
  styleUrls: ['./no-temps.modal.scss'],
})
export class NoTempsModal extends BaseModal {
  className = 'NoTempsModal'
}
