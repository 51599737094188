import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { SharedModule } from '@app/shared.module'
import { IonicModule } from '@ionic/angular'
import { ScienceConsentModal } from './science-consent.modal'

@NgModule({
  declarations: [ScienceConsentModal],
  imports: [IonicModule, SharedModule, CommonModule],
  exports: [ScienceConsentModal],
})
export class ScienceConsentModalModule {}
