@if (hwId === HardwareId.OURA && state === TemperatureDataState.NOT_SYNCED) {
  <div class="temperatureDisplay__notSynced">
    <ion-button
      shape="round"
      fill="outline"
      color="pink"
      (click)="openOura()"
      [disabled]="!isHardwareSupportedPlatform"
    >
      <ion-label>{{ 'oura-add-data-sync-ring-txt' | tr }}</ion-label>
    </ion-button>
    <div>
      <ion-button
        class="button__moreInfo"
        shape="round"
        fill="clear"
        color="temperature"
        (click)="onTemperatureStateClick($event, state)"
        [disabled]="!isHardwareSupportedPlatform"
      >
        <ion-label>{{ 'oura-add-data-already-synced-txt' | tr }}</ion-label>
        <ion-icon slot="end" [src]="ICON.ARROW_RIGHT" />
      </ion-button>
    </div>
  </div>
} @else {
  @if (state === TemperatureDataState.WAITING_FOR_DATA) {
    <div class="temperatureDisplay__waiting">
      <ion-button shape="round" fill="outline" color="pink" (click)="openOura()">
        <ion-label>{{ 'oura-add-data-back-to-oura-txt' | tr }}</ion-label>
      </ion-button>
      <div>
        <ion-button
          class="button__moreInfo"
          shape="round"
          fill="clear"
          color="temperature"
          (click)="onTemperatureStateClick($event, state)"
        >
          <ion-label>{{ 'oura-add-data-waiting-txt' | tr }}</ion-label>
          <ion-spinner slot="end" name="lines-small" color="temperature" />
        </ion-button>
      </div>
    </div>
  } @else {
    <div class="temperatureDisplay" [class.temperatureDisplay--deviating]="isDeviating">
      @if (placeholderDigits$ | async; as placeholderDigits) {
        <div
          [attr.uid]="rowUid"
          class="temperatureDisplay__row"
          [class.temperatureDisplay__row--disabled]="isDisabled"
        >
          @for (n of placeholderDigits; track $index) {
            <span>
              @if ($index === placeholderDigits.length - 2) {
                <span class="temperatureDisplay__number">{{ numberSeparator }}</span>
              }
              @if (digits$ | async; as digits) {
                <span
                  [attr.uid]="rowUid ? rowUid! + '--number' : ''"
                  class="temperatureDisplay__number"
                  [class.temperatureDisplay__number--blinking]="
                    (isFocused && $index === digits.length) ||
                    (isFocused &&
                      $index === digits.length - 1 &&
                      placeholderDigits.length === digits.length)
                  "
                  >{{ $index < digits.length ? digits[$index] : '–' }}</span
                >
              }
            </span>
          }
          <span class="temperatureDisplay__unit">°{{ fahrenheit ? 'F' : 'C' }}</span>
        </div>
      }
    </div>
    <div class="temperatureDisplay__statusContainer">
      @if (!isFocused && !!state) {
        <div class="temperatureDisplay__status" [@fadeAnimation]>
          <!--  Added  -->
          @if (state === TemperatureDataState.ADDED) {
            <ion-label> {{ 'txt-added' | tr }} {{ timestamp }} </ion-label>
          }
          <!--  Excluded by algo  -->
          <!--  Excluded because of the critical firmware  -->
          <!-- Excluded by the Oura algo -->
          @if (
            state === TemperatureDataState.EXCLUDED_ALGO ||
            state === TemperatureDataState.EXCLUDED_FIRMWARE ||
            state === TemperatureDataState.EXCLUDED_OURA_ALGO
          ) {
            <ion-button
              [attr.uid]="'temperatureDisplay__status--' + TemperatureDataState[state]"
              class="button__moreInfo"
              shape="round"
              fill="clear"
              color="temperature"
              (click)="onTemperatureStateClick($event, state)"
            >
              @if (
                state === TemperatureDataState.EXCLUDED_ALGO ||
                state === TemperatureDataState.EXCLUDED_OURA_ALGO
              ) {
                <ion-label>{{ 'oura-flag-excluded' | tr }}</ion-label>
              }
              @if (state === TemperatureDataState.EXCLUDED_FIRMWARE) {
                <ion-label>{{ 'txt-firmware-update-deviation' | tr }}</ion-label>
              }
              <div class="temperatureDisplay__statusIcon" slot="end">
                <ion-icon color="purple" [src]="ICON.DEVIATION_ALGO" />
              </div>
            </ion-button>
          }
          @if (
            (state === TemperatureDataState.NOT_SYNCED ||
              state === TemperatureDataState.NOT_SYNCED_TIMEOUT) &&
            !(digits$ | async)?.length
          ) {
            <ion-button
              class="button__moreInfo button__moreInfo--uebe"
              shape="round"
              fill="clear"
              color="temperature"
              (click)="onTemperatureStateClick($event, state)"
            >
              <!-- Looking for thermometer -->
              @if (state === TemperatureDataState.NOT_SYNCED) {
                <ion-label>
                  {{ 'uebe-temp-state-not-synced' | tr }}
                </ion-label>
              }
              <!-- Not synced after timeout -->
              @if (state === TemperatureDataState.NOT_SYNCED_TIMEOUT) {
                <ion-label>
                  {{ 'uebe-temp-state-not-synced-timeout-1' | tr }}&nbsp;
                  <u class="underline">{{ 'uebe-temp-state-not-synced-timeout-2' | tr }}</u>
                </ion-label>
              }
              <ion-spinner slot="end" name="lines-small" color="temperature" />
            </ion-button>
          }
          @if (state === TemperatureDataState.NO_DATA && !(digits$ | async)?.length) {
            @if (hwId === HardwareId.APPLE_WATCH) {
              <ion-button
                class="button__moreInfo"
                shape="round"
                fill="clear"
                color="temperature"
                uid="aw-addData-noData-info"
                (click)="onTemperatureStateClick($event, state)"
              >
                <!-- AW no data -->
                <ion-label>{{ 'txt-no-data' | tr }}</ion-label>
                <ion-icon slot="end" [src]="ICON.INFO" />
              </ion-button>
            } @else {
              <!-- UEBE no data -->
              <ion-label>{{ 'txt-no-data' | tr }}</ion-label>
            }
          }
          <!--  Temp synced  -->
          @if (timestamp && state === TemperatureDataState.SYNCED) {
            <ion-label>
              {{ (hwId === HardwareId.OURA ? 'txt-updated' : 'txt-measured') | tr }}
              {{ timestamp }}
            </ion-label>
          }
          <!--  No paired thermometer (UEBE, T3)  -->
          @if (state === TemperatureDataState.NOT_PAIRED) {
            <ion-button
              [attr.uid]="(rowUid ? rowUid + '--' : '') + 'not__paired__thermometer__button'"
              class="button__moreInfo"
              shape="round"
              fill="clear"
              color="temperature"
              (click)="startPairingFlow()"
            >
              <ion-label>
                {{ 'txt-no-thermometer-paired' | tr }}
              </ion-label>
            </ion-button>
          }
          <!--  Added manually  -->
          @if (timestamp && state === TemperatureDataState.ADDED_MANUALLY) {
            <ion-label> {{ 'uebe-temp-state-added-manual' | tr }} {{ timestamp }} </ion-label>
          }
          <!--  Adjusted by algo  -->
          @if (state === TemperatureDataState.ADJUSTED_TEMPERATURE) {
            <div class="temperatureDisplay__status">
              <ion-button
                class="button__moreInfo"
                shape="round"
                fill="clear"
                color="temperature"
                (click)="onTemperatureStateClick($event, state)"
              >
                <ion-label>{{ 'oura-flag-adjusted' | tr }}</ion-label>
                <div class="temperatureDisplay__statusIcon" slot="end">
                  <ion-icon color="black" [src]="ICON.ADJUSTED_TEMP" />
                </div>
              </ion-button>
            </div>
          }
          <!--  Excluded sleep  -->
          @if (state === TemperatureDataState.EXCLUDED_SLEEP) {
            <div class="temperatureDisplay__status">
              <ion-button
                class="button__moreInfo"
                shape="round"
                fill="clear"
                color="temperature"
                (click)="onTemperatureStateClick($event, state)"
              >
                <ion-label>{{ 'oura-flag-excluded' | tr }}</ion-label>
                <div class="temperatureDisplay__statusIcon" slot="end">
                  <ion-icon color="black" [src]="ICON.DEVIATION_SLEEP" />
                </div>
              </ion-button>
            </div>
          }
          <!--  Not enough data  -->
          @if (
            state === TemperatureDataState.INCOMPLETE_DATA ||
            state === TemperatureDataState.SHORT_SLEEP
          ) {
            <div class="temperatureDisplay__status">
              <ion-button
                class="button__moreInfo"
                shape="round"
                fill="clear"
                color="temperature"
                (click)="onTemperatureStateClick($event, state)"
              >
                @if (state === TemperatureDataState.INCOMPLETE_DATA) {
                  <ion-label>{{ 'oura-flag-no-data' | tr }}</ion-label>
                }
                @if (state === TemperatureDataState.SHORT_SLEEP) {
                  <ion-label>{{ 'oura-flag-short-sleep' | tr }}</ion-label>
                }
                <ion-icon slot="end" [src]="ICON.INFO" />
              </ion-button>
            </div>
          }
          @if (state === TemperatureDataState.BLUETOOTH_OFF) {
            <ion-label>
              {{ 'txt-bluetooth-off' | tr }}
            </ion-label>
          }
        </div>
      }
    </div>
  }
}

<ng-template #temperatureDisplay>
  <div class="temperatureDisplay" [class.temperatureDisplay--deviating]="isDeviating">
    @if (placeholderDigits$ | async; as placeholderDigits) {
      <div
        [attr.uid]="rowUid"
        class="temperatureDisplay__row"
        [class.temperatureDisplay__row--disabled]="isDisabled"
      >
        @for (n of placeholderDigits; track $index) {
          <span>
            @if ($index === placeholderDigits.length - 2) {
              <span class="temperatureDisplay__number">{{ numberSeparator }}</span>
            }
            @if (digits$ | async; as digits) {
              <span
                [attr.uid]="rowUid ? rowUid! + '--number' : ''"
                class="temperatureDisplay__number"
                [class.temperatureDisplay__number--blinking]="
                  (isFocused && $index === digits.length) ||
                  (isFocused &&
                    $index === digits.length - 1 &&
                    placeholderDigits.length === digits.length)
                "
                >{{ $index < digits.length ? digits[$index] : '–' }}</span
              >
            }
          </span>
        }
        <span class="temperatureDisplay__unit">°{{ fahrenheit ? 'F' : 'C' }}</span>
      </div>
    }
  </div>

  <div class="temperatureDisplay__statusContainer">
    @if (!isFocused && !!state) {
      <div class="temperatureDisplay__status" [@fadeAnimation]>
        <!--  Added  -->
        @if (state === TemperatureDataState.ADDED) {
          <ion-label> {{ 'txt-added' | tr }} {{ timestamp }} </ion-label>
        }
        <!--  Excluded by algo  -->
        <!--  Excluded because of the critical firmware  -->
        <!-- Excluded by the Oura algo -->
        @if (
          state === TemperatureDataState.EXCLUDED_ALGO ||
          state === TemperatureDataState.EXCLUDED_FIRMWARE ||
          state === TemperatureDataState.EXCLUDED_OURA_ALGO
        ) {
          <ion-button
            [attr.uid]="'temperatureDisplay__status--' + TemperatureDataState[state]"
            class="button__moreInfo"
            shape="round"
            fill="clear"
            color="temperature"
            (click)="onTemperatureStateClick($event, state)"
          >
            @if (
              state === TemperatureDataState.EXCLUDED_ALGO ||
              state === TemperatureDataState.EXCLUDED_OURA_ALGO
            ) {
              <ion-label>{{ 'oura-flag-excluded' | tr }}</ion-label>
            }
            @if (state === TemperatureDataState.EXCLUDED_FIRMWARE) {
              <ion-label>{{ 'txt-firmware-update-deviation' | tr }}</ion-label>
            }
            <div class="temperatureDisplay__statusIcon" slot="end">
              <ion-icon color="purple" [src]="ICON.DEVIATION_ALGO" />
            </div>
          </ion-button>
        }
        @if (
          (state === TemperatureDataState.NOT_SYNCED ||
            state === TemperatureDataState.NOT_SYNCED_TIMEOUT) &&
          !(digits$ | async)?.length
        ) {
          <ion-button
            class="button__moreInfo button__moreInfo--uebe"
            shape="round"
            fill="clear"
            color="temperature"
            (click)="onTemperatureStateClick($event, state)"
          >
            <!-- Looking for thermometer -->
            @if (state === TemperatureDataState.NOT_SYNCED) {
              <ion-label>
                {{ 'uebe-temp-state-not-synced' | tr }}
              </ion-label>
            }
            <!-- Not synced after timeout -->
            @if (state === TemperatureDataState.NOT_SYNCED_TIMEOUT) {
              <ion-label>
                {{ 'uebe-temp-state-not-synced-timeout-1' | tr }}&nbsp;
                <u class="underline">{{ 'uebe-temp-state-not-synced-timeout-2' | tr }}</u>
              </ion-label>
            }
            <ion-spinner slot="end" name="lines-small" color="temperature" />
          </ion-button>
        }
        @if (state === TemperatureDataState.NO_DATA && !(digits$ | async)?.length) {
          @if (hwId === HardwareId.APPLE_WATCH) {
            <ion-button
              class="button__moreInfo"
              shape="round"
              fill="clear"
              color="temperature"
              uid="aw-addData-noData-info"
              (click)="onTemperatureStateClick($event, state)"
            >
              <!-- AW no data -->
              <ion-label>{{ 'txt-no-data' | tr }}</ion-label>
              <ion-icon slot="end" [src]="ICON.INFO" />
            </ion-button>
          } @else {
            <!-- UEBE no data -->
            <ion-label>{{ 'txt-no-data' | tr }}</ion-label>
          }
        }
        <!--  Temp synced  -->
        @if (timestamp && state === TemperatureDataState.SYNCED) {
          <ion-label>
            {{ (hwId === HardwareId.OURA ? 'txt-updated' : 'txt-measured') | tr }}
            {{ timestamp }}
          </ion-label>
        }
        <!--  No paired thermometer (UEBE, T3)  -->
        @if (state === TemperatureDataState.NOT_PAIRED) {
          <ion-button
            [attr.uid]="(rowUid ? rowUid + '--' : '') + 'not__paired__thermometer__button'"
            class="button__moreInfo"
            shape="round"
            fill="clear"
            color="temperature"
            (click)="startPairingFlow()"
          >
            <ion-label>
              {{ 'txt-no-thermometer-paired' | tr }}
            </ion-label>
          </ion-button>
        }
        <!--  Added manually  -->
        @if (timestamp && state === TemperatureDataState.ADDED_MANUALLY) {
          <ion-label> {{ 'uebe-temp-state-added-manual' | tr }} {{ timestamp }} </ion-label>
        }
        <!--  Adjusted by algo  -->
        @if (state === TemperatureDataState.ADJUSTED_TEMPERATURE) {
          <div class="temperatureDisplay__status">
            <ion-button
              class="button__moreInfo"
              shape="round"
              fill="clear"
              color="temperature"
              (click)="onTemperatureStateClick($event, state)"
            >
              <ion-label>{{ 'oura-flag-adjusted' | tr }}</ion-label>
              <div class="temperatureDisplay__statusIcon" slot="end">
                <ion-icon color="black" [src]="ICON.ADJUSTED_TEMP" />
              </div>
            </ion-button>
          </div>
        }
        <!--  Excluded sleep  -->
        @if (state === TemperatureDataState.EXCLUDED_SLEEP) {
          <div class="temperatureDisplay__status">
            <ion-button
              class="button__moreInfo"
              shape="round"
              fill="clear"
              color="temperature"
              (click)="onTemperatureStateClick($event, state)"
            >
              <ion-label>{{ 'oura-flag-excluded' | tr }}</ion-label>
              <div class="temperatureDisplay__statusIcon" slot="end">
                <ion-icon color="black" [src]="ICON.DEVIATION_SLEEP" />
              </div>
            </ion-button>
          </div>
        }
        <!--  Not enough data  -->
        @if (
          state === TemperatureDataState.INCOMPLETE_DATA ||
          state === TemperatureDataState.SHORT_SLEEP
        ) {
          <div class="temperatureDisplay__status">
            <ion-button
              class="button__moreInfo"
              shape="round"
              fill="clear"
              color="temperature"
              (click)="onTemperatureStateClick($event, state)"
            >
              @if (state === TemperatureDataState.INCOMPLETE_DATA) {
                <ion-label>{{ 'oura-flag-no-data' | tr }}</ion-label>
              }
              @if (state === TemperatureDataState.SHORT_SLEEP) {
                <ion-label>{{ 'oura-flag-short-sleep' | tr }}</ion-label>
              }
              <ion-icon slot="end" [src]="ICON.INFO" />
            </ion-button>
          </div>
        }
        @if (state === TemperatureDataState.BLUETOOTH_OFF) {
          <ion-label>
            {{ 'txt-bluetooth-off' | tr }}
          </ion-label>
        }
      </div>
    }
  </div>
</ng-template>
