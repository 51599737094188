import { Injectable } from '@angular/core'
import { NavigationParams } from '@app/cnst/nav.params.cnst'
import { PageGuard } from '@app/guards/page.guard'
import { getState } from '@app/srv/store.service'

@Injectable({
  providedIn: 'root',
})
export class ShopItemPageGuard extends PageGuard {
  className = 'ShopItemPageGuard'

  get requirements(): boolean[] {
    const id = this.route.params[NavigationParams.ID]

    const requirements = [getState().product.items.some(p => p.key === id)]

    return requirements
  }
}
