import { Component, Input } from '@angular/core'
import { BaseModal } from '@app/pages/base.modal'
import { TestResult } from '@naturalcycles/shared'

@Component({
  selector: 'app-add-data-lh-preview-modal',
  templateUrl: './add-data-lh-preview.modal.html',
  styleUrls: ['./add-data-lh-preview.modal.scss'],
})
export class AddDataLhPreviewModal extends BaseModal {
  className = 'AddDataLhPreviewModal'

  @Input()
  public imageIds?: string[]

  @Input()
  public testResult?: TestResult

  public TestResult = TestResult
}
