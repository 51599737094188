import { UnixTimestampNumber } from '@naturalcycles/js-lib'
import { BatteryStatus, LANG } from '@naturalcycles/shared'
import { GATTCharacteristicUuid, GATTServiceUuid } from '../cnst/hardware.cnst'

export const FIXED_TEMPERATURE_T3_FW_VERSION = '4.11.1'

export const T3_SERVICE_UUID = {
  DEVICE_INFORMATION: GATTServiceUuid.DEVICE_INFORMATION,
  DEVICE: '5D160100-C6A0-4B91-BE6E-33893B251C59',
  USER_CONFIG: '5D170100-C6A0-4B91-BE6E-33893B251C59',
}
// FIXME to be removed after FW 4.12.x is out
export const T3_SCREEN_BRIGHTNESS_MODIFIER = 0.5

export const T3_CHARACTERISTIC_UUID = {
  FIRMWARE_REVISION: GATTCharacteristicUuid.FIRMWARE_REVISION,
  SERIAL_NUMBER: '5D160101-C6A0-4B91-BE6E-33893B251C59',
  DEVICE_STATUS: '5D160102-C6A0-4B91-BE6E-33893B251C59',
  COMMANDS: '5D160104-C6A0-4B91-BE6E-33893B251C59',
  MEASUREMENT_STATUS: '5D160105-C6A0-4B91-BE6E-33893B251C59',
  LOGS: '5D160106-C6A0-4B91-BE6E-33893B251C59',
  DIAGNOSTIC_DATA: '5D160109-C6A0-4B91-BE6E-33893B251C59',
  UI_CONFIG: '5D170101-C6A0-4B91-BE6E-33893B251C59',
  USER_NAME: '5D170102-C6A0-4B91-BE6E-33893B251C59',
  BRIGHTNESS: '5D170103-C6A0-4B91-BE6E-33893B251C59',
}

export enum T3Screen {
  HOME = 0x00,
  FACTORY_RESET = 0x01,
  SETTINGS = 0x02,
}

export enum T3Command {
  NOTHING = 0x00,
  START_MEASUREMENT = 0x01,
  STOP_MEASUREMENT = 0x02,
  SET_ALGO = 0x03,
  SET_TIME = 0x04,
  FACTORY_RESET = 0x05,
  FOTA = 0x06,
  SET_SCREEN = 0x07,
  SET_MODE = 0x08,
  SET_NFC_AVAILABILITY = 0x09,
}

export enum T3DeviceFlags {
  UNSYNCED_LOGS = 0x01,
  PSU_ENABLE = 0x02,
  LOW_BATTERY = 0x04,
  INITIATING_FOTA = 0x08,
}

export enum T3BatteryLevel {
  GOOD = 0x00,
  LOW = 0x01,
  CRITICAL = 0x02,
}

export interface T3BrightnessConfig {
  screen: number
  onOffButton: number
  settingsButton: number
  logsButton: number
}

export interface T3UIConfig {
  backlight?: boolean
  sound?: boolean
  ddMM?: boolean
  fahrenheit?: boolean
  leftHandMode?: boolean
  language?: T3Lang
}

export interface T3DeviceStatus {
  time: UnixTimestampNumber
  state: T3DeviceState
  batteryVoltage: number
  batteryLevel: T3BatteryLevel
  deviceFlags: number // bitfield of 'type' T3DeviceFlags
}

export interface T3MeasurementStatus {
  state: T3MeasurementState
  temperature: number
}

export enum T3MeasurementState {
  IDLE = 0x00,
  WAITING_FOR_RISE = 0x01,
  IN_PROGRESS = 0x02,
  SUCCESS = 0x03,
  NO_MEAS_DETECT = 0x04,
  TOO_LOW = 0x05,
  TOO_HIGH = 0x06,
  MAX_HOLD = 0x07,
  ALGO_FAIL = 0x08, // todo: if algofail we get bytes 0x08 -> 0xFE
  UNKNOWN = 0xff,
}

export enum T3DeviceState {
  UNKNOWN = 0x00,
  INIT = 0x01,
  STANDBY = 0x02,
  HOME = 0x03,
  MEASURING = 0x04,
  DONE = 0x05,
  BLUETOOTH = 0x06,
  LOGS = 0x07,
  FOTA = 0x08,
  FOTA_SUCCEED = 0x09,
  ERROR = 0x0a,
  FACTORY_RESET = 0x0b,
  SETTINGS = 0x0c,
}

export const T3_SUCCESS_SCREEN_TO_BATTERY_STATUS = {
  1: BatteryStatus.HIGH,
  2: BatteryStatus.HIGH,
  3: BatteryStatus.HIGH,
  4: BatteryStatus.HIGH,
  5: BatteryStatus.LOW,
  6: BatteryStatus.CRITICALLY_LOW,
}

export const USERNAME_MAX_LENGTH = 12

export enum T3Lang {
  EN = 0x00,
  SE = 0x01,
  ES = 0x02,
  PT = 0x03,
}

export const ACCOUNT_LANG_TO_T3_LANG: Record<LANG, T3Lang> = {
  [LANG.en_US]: T3Lang.EN,
  [LANG.en_GB]: T3Lang.EN,
  [LANG.en_CA]: T3Lang.EN,
  [LANG.sv_SE]: T3Lang.SE,
  [LANG.es_US]: T3Lang.ES,
  [LANG.pt_BR]: T3Lang.PT,
}

// todo: check languages date format
export const ACCOUNT_LANG_TO_T3_DDMM: Record<LANG, boolean> = {
  [LANG.en_US]: false,
  [LANG.en_CA]: true,
  [LANG.en_GB]: true,
  [LANG.sv_SE]: true,
  [LANG.es_US]: true,
  [LANG.pt_BR]: true,
}

export enum T3Algo {
  ONE_SHOT = 0,
  CONTINUOUS = 1,
  QUICK_TEST = 2,
  MAX_HOLD = 3,
  PREDICTIVE = 4,
  QUICK_TEST_RANDOM = 5,
  TEST_NO_RISE = 6,
}

export enum T3FWUpdateStatus {
  DISCONNECTED = 1,
  STARTING = 2,
  IN_PROGRESS = 3,
  FAILED = 4,
  SUCCEEDED = 5,
  WRONG_CONNECTION = 6,
  /**
   * Firmware update is done, ready to sync settings
   */
  WAITING_TO_SYNC = 7,
  SYNC_FAILED = 8,
  COMPLETED = 9,
}

export interface T3DiagnosticData {
  powerOn: number
  rtcSync: number
  userConfig: number
  predMeasurement: number
  maxHoldMeasurement: number
  tooLowMeasurement: number
  tooHighMeasurement: number
  fwError: number
  hwError: number
  calibrationOffset: number
  calibrationGain: number
  ntcGroup: number
  algorithmId: number
  latestWakeUpVoltage?: number
  averageBatteryVoltage?: number
}

export const T3_BATTERY_LEVEL_TO_STATUS: Record<T3BatteryLevel, BatteryStatus> = {
  [T3BatteryLevel.CRITICAL]: BatteryStatus.CRITICALLY_LOW,
  [T3BatteryLevel.LOW]: BatteryStatus.LOW,
  [T3BatteryLevel.GOOD]: BatteryStatus.HIGH,
}
