import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { ICON, ICON_PATH } from '@app/cnst/icons.cnst'
import { FertilityCircleEntry, FertilityIcon } from '@app/model/uf.model'
import { MonthViewEmptyDay } from '@app/pages/home/month/month.page.service'
import { SharedModule } from '@app/shared.module'
import { IonicModule } from '@ionic/angular'
import { IsoDateString } from '@naturalcycles/js-lib'

@Component({
  selector: 'app-fertility-circle',
  templateUrl: 'fertility-circle.component.html',
  styleUrls: ['fertility-circle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonicModule, CommonModule, SharedModule],
})
export class FertilityCircleComponent {
  @Input()
  public set entry(entry: FertilityCircleEntry | MonthViewEmptyDay) {
    this.date = entry.date
    this.dateLabel = String(Number(entry.date.slice(8, 10)))

    if (!('cd' in entry)) {
      this.icon = undefined
      this.statusIcon = undefined
      this.topLabel = undefined
      this.fertilityColor = undefined
      return
    }
    // If cd is present then it's a FertilityCircleEntry

    this.today = !!entry.today
    this.prediction = !!entry.prediction
    this.icon = entry.icon
    this.iconId = this.getIconId(entry.icon, entry.prediction)
    this.statusIcon = entry.statusIcon
    this.statusIconId = entry.statusIcon?.replace(ICON_PATH, '').replace('.svg', '')
    this.fertilityColor = entry.colorClass
    this.cycleDay = entry.cd
    this.topLabel = entry.topLabel
  }

  @Input()
  public dayIndex?: number

  public date!: IsoDateString
  public dateLabel?: string
  public today = false
  public prediction = false
  public fertilityColor?: string
  public icon?: FertilityIcon
  public statusIcon?: ICON
  public cycleDay?: number
  public topLabel?: string

  public iconId?: string
  public statusIconId?: string

  private getIconId(icon?: FertilityIcon, prediction?: boolean): string | undefined {
    if (!icon) return

    const iconId = icon.icon.replace(ICON_PATH, '').replace('.svg', '')

    return `${iconId}${prediction ? '--prediction' : ''}`
  }
}
