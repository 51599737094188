import { animate, state, style, transition, trigger } from '@angular/animations'
import { Component, Input, OnChanges, OnDestroy, output, SimpleChanges } from '@angular/core'
import { ICON } from '@app/cnst/icons.cnst'
import { IonContent } from '@ionic/angular'
import { Subscription } from 'rxjs'

export type ScrollFooterState = 'minimized' | 'open'

@Component({
  selector: 'app-scroll-footer',
  templateUrl: 'scroll-footer.component.html',
  styleUrls: ['scroll-footer.component.scss'],
  animations: [
    trigger('footerState', [
      state(
        'minimized',
        style({
          transform: 'translateY(calc(100% - 46px))',
        }),
      ),
      state(
        'open',
        style({
          transform: 'translateY(0)',
        }),
      ),
      transition('open => minimized', animate('150ms linear')),
      transition('minimized => open', animate('150ms linear')),
    ]),
  ],
})
export class ScrollFooterComponent implements OnChanges, OnDestroy {
  public showScrollButton = true
  public footerState: ScrollFooterState = 'minimized'
  private scrollElement?: HTMLElement
  private scrollSubscription?: Subscription
  public ICON = ICON

  @Input()
  public content!: IonContent

  @Input()
  public color!: string

  public stateChanged = output<ScrollFooterState>()

  public ngOnChanges(_changes: SimpleChanges): void {
    if (this.scrollSubscription || !this.content) return

    this.content.scrollEvents = true
    this.scrollSubscription = this.content.ionScroll.subscribe(() => void this.onScroll())
  }

  public ngOnDestroy(): void {
    this.scrollSubscription?.unsubscribe()
  }

  public async onScroll(): Promise<void> {
    const { scrollHeight, scrollTop, clientHeight } = (this.scrollElement ||=
      await this.content.getScrollElement())
    const offset = scrollHeight - clientHeight - scrollTop

    if (offset < 80) {
      this.openFooter()
    } else {
      if (this.footerState !== 'minimized') this.stateChanged.emit('minimized')
      this.showScrollButton = true
      this.footerState = 'minimized'
    }
  }

  public scrollToBottom(): void {
    this.content.scrollEvents = false
    void this.content.scrollToBottom(300).then(() => (this.content.scrollEvents = true))
    this.openFooter()
  }

  private openFooter(): void {
    if (this.footerState !== 'open') this.stateChanged.emit('open')
    this.footerState = 'open'
    this.showScrollButton = false
  }
}
