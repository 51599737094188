@if (isVisible) {
  <div
    class="overlay"
    [@overlay]
    (@overlay.start)="animationStarted($event)"
    (@overlay.done)="animationDone($event)"
  >
    <span class="overlay__dot"></span>
    <span class="overlay__dot overlay__dot___bottom"></span>
    <ng-content />
  </div>
}
