import { Injectable } from '@angular/core'
import { api } from '@app/srv/api.service'
import { storageService } from '@app/srv/storage.service'
import { select2 } from '@app/srv/store.service'
import { logUtil } from '@app/util/log.util'
import { Assignments, Experiment, ExperimentState, getBucket, NO_AB } from '@naturalcycles/shared'
import { Observable } from 'rxjs'
import { distinctUntilChanged, map } from 'rxjs/operators'

// For setting localStorage value to force an assignment
export const forceExperimentPrefix = 'EXPERIMENT_'

@Injectable({ providedIn: 'root' })
export class ExperimentService {
  private assignments$ = select2(s => s.experiment.assignments)

  public lastRequest?: Promise<ExperimentState>

  private filterAssignment(experiment: Experiment, bucket: string): string {
    const bucketFromStorage = this.getAssignmentFromStorage(experiment)
    const forceAbKeys = storageService.get('forceAbKeys')

    if (bucketFromStorage !== undefined && forceAbKeys === 'true') {
      return bucketFromStorage
    }

    // Force an ABTest from the localstorage but after the conditions
    if (bucketFromStorage !== undefined) {
      return bucketFromStorage
    }

    return bucket
  }

  public getAssignment(experiment: Experiment): Observable<string> {
    return this.assignments$.pipe(
      map<Assignments, string>(assignments => {
        const assignment = assignments[experiment]
        return assignment ? getBucket(assignment) : ''
      }),
      distinctUntilChanged(),
      map(bucket => {
        if (bucket) {
          logUtil.log(`ExperimentService: Assignment to ${experiment} is ${bucket}`)
        }
        // else -> The experiment is not live on wasabi
        return bucket
      }),
      map(bucket => {
        return this.filterAssignment(experiment, bucket)
      }),
    )
  }

  public async logImpression(experiment: Experiment): Promise<void> {
    return await this.logEvent(experiment)
  }

  public async logEvent(experiment: Experiment, event?: string, payload?: string): Promise<void> {
    return await new Promise<void>((resolve, reject) => {
      this.getAssignment(experiment).subscribe((bucket: string) => {
        let url = `experiments/${experiment}`

        if (event) {
          url = `${url}/${event}`
        }

        if (bucket) {
          api
            .post<void>(url, {
              json: {
                payload,
              },
            })
            .then(resolve)
            .catch(reject)
        } else {
          resolve()
        }
      })
    })
  }

  public getAssignmentFromStorage(experiment: Experiment): string | undefined {
    // QA local storage values
    // removeAllAbtest = will return '' for all the ab test that are currently running
    const removeAllAbTest = storageService.get(NO_AB)
    if (removeAllAbTest === 'true') {
      return ''
    }

    // Check if the assignment is set inside localStorage for testing reasons
    // If the assignment exist, we return the bucket from there
    const bucketForTesting = storageService.get(experiment)
    if (bucketForTesting !== undefined) {
      return bucketForTesting
    }
  }
}
