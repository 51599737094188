import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { IonicModule } from '@ionic/angular'
import { ScrollFooterComponent } from './scroll-footer.component'

@NgModule({
  declarations: [ScrollFooterComponent],
  imports: [IonicModule, CommonModule],
  exports: [ScrollFooterComponent],
})
export class ScrollFooterComponentModule {}
