import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { SharedModule } from '@app/shared.module'
import { IonicModule } from '@ionic/angular'
import { RadioGroupComponent } from './radio-group.component'

@NgModule({
  declarations: [RadioGroupComponent],
  imports: [IonicModule, SharedModule, CommonModule],
  exports: [RadioGroupComponent],
})
export class RadioGroupComponentModule {}
