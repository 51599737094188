<ion-header>
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-button mode="md" (click)="dismissModal()" uid="ctaModal__closeButton" color="text">
        <ion-icon slot="icon-only" name="close" />
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-text-center ion-padding-horizontal">
  @if (title) {
    <h2>{{ title | tr }}</h2>
  }

  @if (body) {
    <div [innerHtml]="body | tmd"></div>
  }
</ion-content>

<ion-footer>
  @for (button of ctas; track button) {
    <ion-button
      shape="round"
      size="block"
      [fill]="button.outline ? 'outline' : 'solid'"
      (click)="dismissModal(button.id)"
      class="ion-margin-top"
    >
      <ion-label>{{ button.title | tr }}</ion-label>
    </ion-button>
  }
</ion-footer>
