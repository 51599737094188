<div>
  @if (currentStep < totalSteps) {
    <ion-button
      uid="tourNavbar__nextStepButton"
      class="tourNavbar__navigateButton tourNavbar__navigateButton--{{
        ctaCenter ? 'center' : 'right'
      }}"
      shape="round"
      (click)="onNextClick()"
      [@navigateButtonAnimation]="{
        value: shouldAnimate ? 'in' : 'out',
        params: { translateX: ctaCenter ? '-50%' : 0 },
      }"
    >
      <ion-label>{{ 'txt-next' | tr }}</ion-label>
      <ion-icon class="tourNavbar__navigationButton__icon" [src]="ICON.ARROW_RIGHT" />
    </ion-button>
  } @else {
    <ion-button
      uid="tourNavbar__doneButton"
      class="tourNavbar__navigateButton tourNavbar__navigateButton--{{
        ctaCenter ? 'center' : 'right'
      }}"
      shape="round"
      (click)="onEndTourClick()"
      [@navigateButtonExchange]="{
        value: shouldAnimate ? 'in' : 'out',
        params: { translateX: ctaCenter ? '-50%' : 0 },
      }"
      [@navigateButtonAnimation]="{
        value: shouldAnimate ? 'in' : 'out',
        params: { translateX: ctaCenter ? '-50%' : 0 },
      }"
    >
      <ion-label>{{ 'txt-done-only' | tr }}</ion-label>
      <ion-icon class="tourNavbar__navigationButton__icon" [src]="ICON.CHECK_OUTLINE" />
    </ion-button>
  }

  <ion-toolbar
    class="tourNavbar__bar"
    color="lightGray"
    [@navigateButtonExchange]="{
      value: shouldAnimate ? 'in' : 'out',
      params: { opacityValue: 1, translateX: 0 },
    }"
  >
    <ion-button slot="start" mode="md" color="black" fill="clear" (click)="onEndTourClick(true)">
      <ion-icon slot="icon-only" name="close" />
    </ion-button>

    <div class="tourNavbar__pagination">{{ currentStep }}/{{ totalSteps }}</div>
  </ion-toolbar>
</div>
