import { CommonModule } from '@angular/common'
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouteReuseStrategy } from '@angular/router'
import { horizontalSlideAnimation } from '@app/animations/horizontal-slide'
import { modalEnterAnimation, modalLeaveAnimation } from '@app/animations/vertical-slide'
import { AppComponent } from '@app/app.component'
import { AppErrorHandler } from '@app/app.error.handler'
import { AppRoutingModule } from '@app/app-routing.module'
import { HistoryRowComponentModule } from '@app/cmp/history-row/history-row.component.module'
import { AddDataPreviewModalModule } from '@app/modals/add-data-preview/add-data-preview.modal.module'
import { AnnouncementModalModule } from '@app/modals/announcement/announcement.modal.module'
import { ConfettiModalModule } from '@app/modals/confetti/confetti.modal.module'
import { DatetimePickerModalModule } from '@app/modals/datetime-picker/datetime-picker.modal.module'
import { FeedbackModalModule } from '@app/modals/feedback/feedback.modal.module'
import { MessageModalModule } from '@app/modals/message/message.modal.module'
import { ScienceConsentModalModule } from '@app/modals/science-consent/science-consent.modal.module'
import { SnackbarModalModule } from '@app/modals/snackbar/snackbar.modal.module'
import { LangService } from '@app/srv/lang.service'
import { Adjust } from '@awesome-cordova-plugins/adjust/ngx'
import { IonicModule, IonicRouteStrategy } from '@ionic/angular'
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { env } from '@src/environments/environment'
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, ReCaptchaV3Service } from 'ng-recaptcha'
import { NgxMaskModule } from 'ngx-mask'
import { BlueDotComponent } from './cmp/blue-dot/blue-dot.component'
import { RedDotComponent } from './cmp/red-dot/red-dot.component'
import { MultipleWatchesDetectedModalModule } from './modals/multiple-watches-detected/multiple-watches-detected.modal.module'
import { AppMissingTranslationHandler } from './translate/missingTranslationHandler'
import { AppTranslateLoader } from './translate/translateLoader'

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule.withConfig({
      disableAnimations: !('animate' in document.documentElement),
    }),
    CommonModule,
    IonicModule.forRoot({
      // backButtonText: '',
      // backButtonIcon: 'n/a',
      mode: 'md',
      navAnimation: horizontalSlideAnimation,
      modalEnter: modalEnterAnimation,
      modalLeave: modalLeaveAnimation,
      swipeBackEnabled: true,
      innerHTMLTemplatesEnabled: true,
      // rippleEffect: false,
      // animated: false,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: AppTranslateLoader,
        // deps: [Platform, File],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: AppMissingTranslationHandler,
      },
      defaultLanguage: 'en-US',
    }),
    AppRoutingModule,
    RedDotComponent,
    BlueDotComponent,
    ScienceConsentModalModule,
    // TODO: try to remove from here
    AddDataPreviewModalModule,
    MessageModalModule,
    MultipleWatchesDetectedModalModule,
    FeedbackModalModule,
    AnnouncementModalModule,
    ConfettiModalModule,
    HistoryRowComponentModule,
    SnackbarModalModule,
    DatetimePickerModalModule,
    NgxMaskModule.forRoot(),
    RecaptchaV3Module,
  ],
  bootstrap: [AppComponent],
  providers: [
    ReCaptchaV3Service,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: env.recaptchaSiteKey },
    Adjust,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useClass: AppErrorHandler },
    {
      provide: LOCALE_ID,
      useFactory: (langService: LangService) => {
        return langService.locale
      },
      deps: [LangService],
    },
  ],
})
export class AppModule {}
