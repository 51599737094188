import { Component } from '@angular/core'
import { baseImgLink } from '@src/app/cnst/quiz.cnst'
import { SharedModule } from '@src/app/shared.module'

@Component({
  standalone: true,
  imports: [SharedModule],
  selector: 'app-quiz-testimonal-box',
  templateUrl: 'testimonial-box.component.html',
  styleUrls: ['testimonial-box.component.scss'],
})
export class QuizTestimonalBoxComponent {
  public baseImgLink = baseImgLink
}
