import { Injectable } from '@angular/core'
import { ICON_PATH } from '@app/cnst/icons.cnst'
import { decorate, ErrorHandlerType } from '@app/decorators/decorators'
import { api } from '@app/srv/api.service'
import { getState } from '@app/srv/store.service'
import { tr } from '@app/srv/translation.util'
import {
  AccountTM,
  Achievement,
  AppId,
  Goal,
  HardwareId,
  UserAchievementsProgress,
} from '@naturalcycles/shared'

export interface AchievementModel {
  title: string
  text: string
  icon: string
  state: string
  disabled: string
  key: string
}

const ACHIEVEMENTS_PREVENT: Achievement[] = [
  Achievement.signupAndInstallTheApp,
  Achievement.completedAppTour,
  Achievement.verifiedEmail,
  Achievement.calibratedThermometer,
  Achievement.firstTemperatureMeasurement,
  Achievement.firstDisabledMeasurement,
  Achievement.upgradedAccount,
  Achievement.fiveInAWeek,
  Achievement.twentyInAMonth,
  Achievement.lhRecommendation,
  Achievement.lhResult,
  Achievement.ovulation,
  Achievement.referredFriend,
  Achievement.sexPrevent,
  Achievement.greenDays40,
  Achievement.greenDays50,
  Achievement.greenDays60,
  Achievement.greenDays70,
  Achievement.cycles10,
  Achievement.cycles50,
  Achievement.cycles100,
  Achievement.year1,
  Achievement.year10,
  Achievement.helpedNCResearch,
]

const ACHIEVEMENTS_PLAN: Achievement[] = [
  Achievement.signupAndInstallTheApp,
  Achievement.completedAppTour,
  Achievement.verifiedEmail,
  Achievement.calibratedThermometer,
  Achievement.firstTemperatureMeasurement,
  Achievement.firstDisabledMeasurement,
  Achievement.upgradedAccount,
  Achievement.fiveInAWeek,
  Achievement.twentyInAMonth,
  Achievement.lhRecommendation,
  Achievement.lhResult,
  Achievement.ovulation,
  Achievement.referredFriend,
  Achievement.sexPlan,
  Achievement.pregRecommendation,
  Achievement.cycles10,
  Achievement.cycles50,
  Achievement.cycles100,
  Achievement.year1,
  Achievement.year10,
  Achievement.helpedNCResearch,
]

const ACHIEVEMENTS_PREGNANT: Achievement[] = [
  Achievement.signupAndInstallTheApp,
  Achievement.verifiedEmail,
  Achievement.calibratedThermometer,
  Achievement.firstTemperatureMeasurement,
  Achievement.firstDisabledMeasurement,
  Achievement.upgradedAccount,
  Achievement.fiveInAWeek,
  Achievement.twentyInAMonth,
  Achievement.referredFriend,
  Achievement.year1,
  Achievement.year10,
  Achievement.helpedNCResearch,
]

const ACHIEVEMENTS_EXCLUDED_FOR_OURA: Achievement[] = [
  Achievement.calibratedThermometer,
  Achievement.readGuideMeasure,
]

const ACHIEVEMENTS_BY_GOAL: Record<Goal, Achievement[]> = {
  [Goal.PREVENT]: ACHIEVEMENTS_PREVENT,
  [Goal.PLAN]: ACHIEVEMENTS_PLAN,
  [Goal.PREGNANT]: ACHIEVEMENTS_PREGNANT,
  [Goal.RECOVERY]: ACHIEVEMENTS_PLAN,
  // DEV-17462 TODO: Update with correct achievements
  [Goal.POSTPARTUM]: ACHIEVEMENTS_PLAN,
}

@Injectable({ providedIn: 'root' })
export class AchievementsService {
  public getAchievementsList(account: AccountTM): AchievementModel[] {
    const { goal, hwId, appId, achievements: userAchievements } = account
    const progress = account.achievementsProgress || {}
    const achievements: AchievementModel[] = []

    // changing order here will change order they are displayed
    // needs to have different list for plan / prevent (type)
    let allAchievements = this.getAllAchievements()

    // remove calibration for clearblue T1 users and other HwId's than T1/T2
    if (
      (appId === AppId.CLEARBLUE && hwId === HardwareId.ORAL_THERMOMETER) ||
      !(hwId === HardwareId.ORAL_THERMOMETER || hwId === HardwareId.UEBE_THERMOMETER)
    ) {
      allAchievements = allAchievements.filter(ach => ach !== Achievement.calibratedThermometer)
    }

    if (hwId === HardwareId.OURA) {
      allAchievements = allAchievements.filter(ach => !ACHIEVEMENTS_EXCLUDED_FOR_OURA.includes(ach))
    }

    const goalAchievements = ACHIEVEMENTS_BY_GOAL[goal!]

    for (const ach of allAchievements) {
      const achievement: AchievementModel = {} as AchievementModel
      achievement.key = ach
      achievement.text = this.getAchievementText(ach)
      achievement.icon = ICON_PATH + 'achievement-' + ach + '.svg'
      achievement.disabled = this.getAchievementDisabled(ach)
      achievement.state = this.getAchievementState(ach, userAchievements)
      achievement.title =
        this.getAchievementTitle(ach) +
        this.getAchievementProgress(ach, achievement.state, progress)

      if (goalAchievements.includes(ach) || achievement.state === 'achieved') {
        achievements.push(achievement)
      }
    }
    return achievements
  }

  getAchievementProgress(
    achievementKey: string,
    achieved: string,
    progress: UserAchievementsProgress,
  ): string {
    let title = ''

    if (achievementKey === 'fiveInAWeek' && achieved === 'unachieved' && progress?.fiveInAWeek) {
      title += ' (' + progress.fiveInAWeek + '/5)'
    }

    if (
      achievementKey === 'twentyInAMonth' &&
      achieved === 'unachieved' &&
      progress?.twentyInAMonth
    ) {
      title += ' (' + progress.twentyInAMonth + '/20)'
    }

    return title
  }

  private getAchievementTitle(achievementKey: string): string {
    return tr(`achievement--${achievementKey}--title`)
  }

  private getAchievementText(achievementKey: string): string {
    return tr(`achievement--${achievementKey}`)
  }

  private getAchievementDisabled(achievementKey: Achievement): string {
    const enabled = [
      Achievement.verifiedEmail,
      Achievement.completedAppTour,
      Achievement.referredFriend,
      Achievement.signupAndInstallTheApp,
      Achievement.firstTemperatureMeasurement,
      Achievement.firstDisabledMeasurement,
      Achievement.upgradedAccount,
      Achievement.calibratedThermometer,
    ]

    return enabled.includes(achievementKey) ? '' : 'disabled'
  }

  getAchievementState(achievement: Achievement, userAchievements: Achievement[]): string {
    return userAchievements.includes(achievement) ? 'achieved' : 'unachieved'
  }

  private getAllAchievements(): Achievement[] {
    return [
      Achievement.signupAndInstallTheApp,
      Achievement.completedAppTour,
      Achievement.verifiedEmail,
      Achievement.calibratedThermometer,
      Achievement.firstTemperatureMeasurement,
      Achievement.firstDisabledMeasurement,
      Achievement.upgradedAccount,
      Achievement.fiveInAWeek,
      Achievement.twentyInAMonth,
      Achievement.lhRecommendation,
      Achievement.lhResult,
      Achievement.ovulation,
      Achievement.referredFriend,
      Achievement.sexPrevent,
      Achievement.sexPlan,
      Achievement.pregRecommendation,
      Achievement.greenDays40,
      Achievement.greenDays50,
      Achievement.greenDays60,
      Achievement.greenDays70,
      Achievement.cycles10,
      Achievement.cycles50,
      Achievement.cycles100,
      Achievement.year1,
      Achievement.year10,
      Achievement.helpedNCResearch,
    ]
  }

  @decorate({
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  public async completeAchievement(achievement: Achievement): Promise<void> {
    const { account } = getState()
    if (account.achievements.includes(achievement)) return

    await api.post(`achievements/complete/${achievement}`)
  }
}
