import { ICON, ICON_BY_DATAFLAG } from '@app/cnst/icons.cnst'
import { SNACKBAR } from '@app/cnst/snackbars.cnst'
import { SnackbarOptions } from '@app/srv/snackbar.service'
import { _by, _numberEnumKeys, StringMap } from '@naturalcycles/js-lib'
import {
  AppId,
  CervicalMucusConsistency,
  DataFlag,
  DataQuantity,
  Goal,
  HadSex,
  Libido,
  Mens,
  SexType,
  Sleep,
  TestResult,
} from '@naturalcycles/shared'

export interface AddDataConfig {
  mensSegments?: SuperSegmentItem[]
  cmSegments?: SuperSegmentItem[]
  sexSegments?: SuperSegmentItem[]
  otherSexItems?: SuperSegmentItem[]
  lhSegments?: SuperSegmentItem[]
  pregTestSegments?: SuperSegmentItem[]
  cmQuantitySegments?: SuperSegmentItem[]
  cmConsistencySegments?: SuperSegmentItem[]
  libidoSegments?: SuperSegmentItem[]
  sleepSegments?: SuperSegmentItem[]
  painFlags?: DataFlag[]
  skinFlags?: DataFlag[]
  moodFlags?: DataFlag[]
  deviationReasonFlags?: DataFlag[]
  covidTestSegments?: SuperSegmentItem[]
  covidSymptomSegments?: DataFlag[]
  goal?: Goal
  showNoLongerPregnant?: boolean
  showSensitiveTrackers?: boolean
  pregnantDay?: boolean
  appId?: AppId
}

export enum TemperatureDataState {
  NOT_SYNCED = 1, // T2, T3, Oura
  NOT_SYNCED_TIMEOUT = 2, // T2, T3, Oura
  WAITING_FOR_DATA = 3, // Oura
  ADDED = 4, // Oral
  ADDED_MANUALLY = 5, // T2, T3
  SYNCED = 6, // T2, T3, Oura
  INCOMPLETE_DATA = 7, // Oura
  NO_DATA = 8, // Oral, T2, T3, AW
  EXCLUDED_ALGO = 9, // All
  EXCLUDED_SLEEP = 10, // Oura
  ADJUSTED_TEMPERATURE = 11, // Oura
  NOT_PAIRED = 12, // T2, T3
  EXCLUDED_FIRMWARE = 13, // T3
  EXCLUDED_OURA_ALGO = 14, // Oura
  BLUETOOTH_OFF = 15, // T2, T3
  SHORT_SLEEP = 16, // Oura
}

export interface TemperatureState {
  formattedTimestamp?: string
  state?: TemperatureDataState
}

interface SuperSegmentOptionItem {
  title: string
  value: number
  uid: string
  icon?: string
}

export interface SuperSegmentItem extends SuperSegmentOptionItem {
  options?: { type: any; items: SuperSegmentOptionItem[] }
  styles?: string[]
}

export interface SuperSegmentValue {
  value?: number
  option?: number
}

export interface EmergencyItem extends SuperSegmentItem {
  label: string
}

// these values should be something that a normal SuperSegmentValue will never be :)
// I guess it is safe to assume that DailyEntry enums will always be positive numbers
export enum SuperSegmentSpecialButton {
  NO_VALUE = -11111,
  LH_SCANNER_BUTTON = -99999,
}

const mens: Readonly<SuperSegmentItem> = {
  title: 'txt-menstruation',
  icon: ICON.MENSTRUATION,
  value: Mens.MENSTRUATION,
  uid: 'addData__mens__period__button',
}

const mensOptions: Readonly<SuperSegmentOptionItem>[] = [
  {
    title: 'txt-quantity-light',
    icon: ICON.MENSTRUATION_LIGHT,
    value: DataQuantity.LIGHT,
    uid: 'addData__mens__period__light',
  },
  {
    title: 'txt-quantity-medium',
    icon: ICON.MENSTRUATION_MEDIUM,
    value: DataQuantity.MEDIUM,
    uid: 'addData__mens__period__medium',
  },
  {
    title: 'txt-quantity-heavy',
    icon: ICON.MENSTRUATION_HEAVY,
    value: DataQuantity.HEAVY,
    uid: 'addData__mens__period__heavy',
  },
]

const withdrawal: Readonly<SuperSegmentItem> = {
  title: 'txt-menstruation-withdrawal',
  icon: ICON.WITHDRAWAL_MEDIUM,
  value: Mens.WITHDRAWAL,
  uid: 'addData__mens__withdrawal__button',
}

const withdrawalOptions: Readonly<SuperSegmentOptionItem>[] = [
  {
    title: 'txt-quantity-light',
    icon: ICON.WITHDRAWAL_LIGHT,
    value: DataQuantity.LIGHT,
    uid: 'addData__mens__withdrawal__light',
  },
  {
    title: 'txt-quantity-medium',
    icon: ICON.WITHDRAWAL_MEDIUM,
    value: DataQuantity.MEDIUM,
    uid: 'addData__mens__withdrawal__medium',
  },
  {
    title: 'txt-quantity-heavy',
    icon: ICON.WITHDRAWAL_HEAVY,
    value: DataQuantity.HEAVY,
    uid: 'addData__mens__withdrawal__heavy',
  },
]

const miscarriage: Readonly<SuperSegmentItem> = {
  title: 'txt-menstruation-miscarriage',
  icon: ICON.MISCARRIAGE_MEDIUM,
  value: Mens.MISCARRIAGE_BLEEDING,
  uid: 'addData__mens_miscarriage__button',
}

const miscarriageOptions: Readonly<SuperSegmentOptionItem>[] = [
  {
    title: 'txt-quantity-light',
    icon: ICON.MISCARRIAGE_LIGHT,
    value: DataQuantity.LIGHT,
    uid: 'addData__mens__miscarriage__light',
  },
  {
    title: 'txt-quantity-medium',
    icon: ICON.MISCARRIAGE_MEDIUM,
    value: DataQuantity.MEDIUM,
    uid: 'addData__mens__miscarriage__medium',
  },
  {
    title: 'txt-quantity-heavy',
    icon: ICON.MISCARRIAGE_HEAVY,
    value: DataQuantity.HEAVY,
    uid: 'addData__mens__miscarriage__heavy',
  },
]

const postpartum: Readonly<SuperSegmentItem> = {
  title: 'txt-menstruation-postpartum',
  icon: ICON.POSTPARTUM_MEDIUM,
  value: Mens.POSTPARTUM_BLEEDING,
  uid: 'addData__mens__postpartum__button',
}

const postpartumOptions: Readonly<SuperSegmentOptionItem>[] = [
  {
    title: 'txt-quantity-light',
    icon: ICON.POSTPARTUM_LIGHT,
    value: DataQuantity.LIGHT,
    uid: 'addData__mens__postpartum__light',
  },
  {
    title: 'txt-quantity-medium',
    icon: ICON.POSTPARTUM_MEDIUM,
    value: DataQuantity.MEDIUM,
    uid: 'addData__mens__postpartum__medium',
  },
  {
    title: 'txt-quantity-heavy',
    icon: ICON.POSTPARTUM_HEAVY,
    value: DataQuantity.HEAVY,
    uid: 'addData__mens__postpartum__heavy',
  },
]

const spotting: Readonly<SuperSegmentItem> = {
  title: 'txt-spotting',
  icon: ICON.SPOTTING,
  value: Mens.SPOTTING,
  uid: 'addData__mens__spotting__button',
}

export const bleedingItems = {
  Mens: mens,
  MensWithOptions: {
    ...mens,
    options: {
      type: DataQuantity,
      items: mensOptions,
    },
  },
  Spotting: spotting,
  Withdrawal: withdrawal,
  WithdrawalWithOptions: {
    ...withdrawal,
    options: {
      type: DataQuantity,
      items: withdrawalOptions,
    },
  },
  Miscarriage: miscarriage,
  MiscarriageWithOptions: {
    ...miscarriage,
    options: {
      type: DataQuantity,
      items: miscarriageOptions,
    },
  },
  Postpartum: postpartum,
  PostpartumWithOptions: {
    ...postpartum,
    options: {
      type: DataQuantity,
      items: postpartumOptions,
    },
  },
}

const sexProtected: Readonly<SuperSegmentItem> = {
  title: 'txt-protected',
  icon: ICON.SEX_SAFE,
  value: HadSex.YES_PROTECTED,
  uid: 'addData__sex__yesProtected__button',
}

const sexProtectedOptions: Readonly<SuperSegmentOptionItem>[] = [
  {
    title: 'txt-condom',
    value: SexType.CONDOM,
    uid: 'addData__sex__yesProtected__condom',
  },
  {
    title: 'txt-other',
    value: SexType.OTHER,
    uid: 'addData__sex__yesProtected__other',
  },
]

const sexUnprotected: Readonly<SuperSegmentItem> = {
  title: 'txt-unprotected',
  icon: ICON.SEX,
  value: HadSex.YES,
  uid: 'addData__sex__yes__button',
}

const sexUnprotectedOptions: Readonly<SuperSegmentOptionItem>[] = [
  {
    title: 'txt-withdrawal',
    value: SexType.WITHDRAWAL,
    uid: 'addData__sex__yes__withdrawal',
  },
  {
    title: 'txt-no-withdrawal',
    value: SexType.NO_WITHDRAWAL,
    uid: 'addData__sex__yes__noWithdrawal',
  },
]

const sexNone: Readonly<SuperSegmentItem> = {
  title: 'txt-none',
  value: HadSex.NO,
  uid: 'addData__sex__no__button',
}

const sexYes: Readonly<SuperSegmentItem> = {
  title: 'txt-yes',
  icon: ICON.SEX,
  value: HadSex.YES,
  uid: 'addData__sex__yes__button',
}

const sexVaginal: Readonly<SuperSegmentItem> = {
  ...sexYes,
  title: 'txt-sex-vaginal',
}

const sexNo: Readonly<SuperSegmentItem> = {
  title: 'txt-no',
  value: HadSex.NO,
  uid: 'addData__sex__no__button',
}

export const sexItems = {
  Protected: sexProtected,
  ProtectedWithOptions: {
    ...sexProtected,
    options: { type: SexType, items: sexProtectedOptions },
  },
  Unprotected: sexUnprotected,
  UnprotectedWithOptions: {
    ...sexUnprotected,
    options: { type: SexType, items: sexUnprotectedOptions },
  },
  None: sexNone,
  Yes: sexYes,
  Vaginal: sexVaginal,
  No: sexNo,
}

const sexDataFlags = _numberEnumKeys(DataFlag).filter(flag => flag.startsWith('SEX_'))
export const otherSexItems = _by(
  sexDataFlags.map(
    flag =>
      ({
        title: `txt-dataflag--${flag}`,
        icon: ICON_BY_DATAFLAG[DataFlag[flag]],
        value: DataFlag[flag],
        uid: `addData__sex__${flag}`,
      }) as SuperSegmentItem,
  ),
  item => DataFlag[item.value],
)

const lhPos: Readonly<SuperSegmentItem> = {
  title: 'txt-positive',
  icon: ICON.LH_POS,
  value: TestResult.YES,
  uid: 'addData__LHtest__positive__button',
}

const lhNeg: Readonly<SuperSegmentItem> = {
  title: 'txt-negative',
  icon: ICON.LH_NEG,
  value: TestResult.NO,
  uid: 'addData__LHtest__negative__button',
}

const lhScanner: Readonly<SuperSegmentItem> = {
  title: '',
  icon: ICON.CAMERA,
  value: SuperSegmentSpecialButton.LH_SCANNER_BUTTON,
  uid: 'addData__LHtest__scanner__button',
  styles: ['outline', 'lhscanner'],
}

export const lhItems = {
  Positive: lhPos,
  Negative: lhNeg,
  Scanner: lhScanner,
}

const pregPos: Readonly<SuperSegmentItem> = {
  title: 'txt-positive',
  icon: ICON.PREG_TEST_POS,
  value: TestResult.YES,
  uid: 'addData__pregTest__positive__button',
}

const pregNeg: Readonly<SuperSegmentItem> = {
  title: 'txt-negative',
  icon: ICON.PREG_TEST_NEG,
  value: TestResult.NO,
  uid: 'addData__pregTest__negative__button',
}

export const pregItems = {
  Positive: pregPos,
  Negative: pregNeg,
}

const cervicalMucusQuantityNone: Readonly<SuperSegmentItem> = {
  title: 'txt-cervical-mucus-quantity--NONE',
  icon: ICON.CM_QUANTITY_NONE,
  value: DataQuantity.NONE,
  uid: 'addData__cervicalMucusQuantity__none__button',
}

const cervicalMucusQuantityLight: Readonly<SuperSegmentItem> = {
  title: 'txt-cervical-mucus-quantity--LIGHT',
  icon: ICON.CM_QUANTITY_LIGHT,
  value: DataQuantity.LIGHT,
  uid: 'addData__cervicalMucusQuantity__light__button',
}

const cervicalMucusQuantityMedium: Readonly<SuperSegmentItem> = {
  title: 'txt-cervical-mucus-quantity--MEDIUM',
  icon: ICON.CM_QUANTITY_MEDIUM,
  value: DataQuantity.MEDIUM,
  uid: 'addData__cervicalMucusQuantity__medium__button',
}

const cervicalMucusQuantityHeavy: Readonly<SuperSegmentItem> = {
  title: 'txt-cervical-mucus-quantity--HEAVY',
  icon: ICON.CM_QUANTITY_HEAVY,
  value: DataQuantity.HEAVY,
  uid: 'addData__cervicalMucusQuantity__heavy__button',
}

const cervicalMucusQuantity: Readonly<SuperSegmentItem> = {
  title: 'txt-cervical-mucus-quantity',
  value: SuperSegmentSpecialButton.NO_VALUE,
  uid: 'addData__cervicalMucusQuantity__button',
  options: {
    type: DataQuantity,
    items: [cervicalMucusQuantityLight, cervicalMucusQuantityMedium, cervicalMucusQuantityHeavy],
  },
}

const cervicalMucusConsistency: Readonly<SuperSegmentItem> = {
  title: 'txt-cervical-mucus-consistency',
  value: SuperSegmentSpecialButton.NO_VALUE,
  uid: 'addData__cervicalMucusConsistency__button',
  options: {
    type: CervicalMucusConsistency,
    items: [
      {
        title: 'txt-cervical-mucus-consistency--STICKY',
        icon: ICON.CM_CONSISTENCY_STICKY,
        value: CervicalMucusConsistency.STICKY,
        uid: 'addData__cervicalMucusConsistency__sticky__button',
      },
      {
        title: 'txt-cervical-mucus-consistency--CREAMY',
        icon: ICON.CM_CONSISTENCY_CREAMY,
        value: CervicalMucusConsistency.CREAMY,
        uid: 'addData__cervicalMucusConsistency__creamy__button',
      },
      {
        title: 'txt-cervical-mucus-consistency--EGGWHITE',
        icon: ICON.CM_CONSISTENCY_EGGWHITE,
        value: CervicalMucusConsistency.EGGWHITE,
        uid: 'addData__cervicalMucusConsistency__eggwhite__button',
      },
      {
        title: 'txt-cervical-mucus-consistency--WATERY',
        icon: ICON.CM_CONSISTENCY_WATERY,
        value: CervicalMucusConsistency.WATERY,
        uid: 'addData__cervicalMucusConsistency__watery__button',
      },
    ],
  },
}

export const cervicalMucusItems = {
  Quantity: cervicalMucusQuantity,
  QuantityWithNone: {
    ...cervicalMucusQuantity,
    options: {
      type: DataQuantity,
      items: [
        cervicalMucusQuantityNone,
        cervicalMucusQuantityLight,
        cervicalMucusQuantityMedium,
        cervicalMucusQuantityHeavy,
      ],
    },
  },
  QuantityNone: cervicalMucusQuantityNone,
  QuantityLight: cervicalMucusQuantityLight,
  QuantityMedium: cervicalMucusQuantityMedium,
  QuantityHeavy: cervicalMucusQuantityHeavy,
  Consistency: cervicalMucusConsistency,
}

const libidoLow: Readonly<SuperSegmentItem> = {
  title: 'txt-sex-drive-low',
  icon: ICON.LIBIDO_LOW,
  value: Libido.LOW,
  uid: 'addData__libido__low__button',
}

const libidoMedium: Readonly<SuperSegmentItem> = {
  title: 'txt-sex-drive-medium',
  icon: ICON.LIBIDO_MEDIUM,
  value: Libido.MEDIUM,
  uid: 'addData__libido__medium__button',
}

const libidoHigh: Readonly<SuperSegmentItem> = {
  title: 'txt-sex-drive-high',
  icon: ICON.LIBIDO_HIGH,
  value: Libido.HIGH,
  uid: 'addData__libido__high__button',
}

export const libidoItems = {
  Low: libidoLow,
  Medium: libidoMedium,
  High: libidoHigh,
}

const sleepPoor: Readonly<SuperSegmentItem> = {
  title: `txt-sleep--${Sleep[Sleep.POOR]}`,
  icon: ICON.SLEEP_POOR,
  value: Sleep.POOR,
  uid: 'addData__sleep__poor__button',
}

const sleepOkay: Readonly<SuperSegmentItem> = {
  title: `txt-sleep--${Sleep[Sleep.OKAY]}`,
  icon: ICON.SLEEP_OKAY,
  value: Sleep.OKAY,
  uid: 'addData__sleep__okay__button',
}

const sleepRestful: Readonly<SuperSegmentItem> = {
  title: `txt-sleep--${Sleep[Sleep.RESTFUL]}`,
  icon: ICON.SLEEP_RESTFUL,
  value: Sleep.RESTFUL,
  uid: 'addData__sleep__restful__button',
}

export const sleepItems = {
  Poor: sleepPoor,
  Okay: sleepOkay,
  Restful: sleepRestful,
}

const pill: Readonly<EmergencyItem> = {
  title: 'txt-pill',
  icon: ICON.MORE_EMERGENCY_PILL,
  label: 'txt-emergency-contraceptive-pill',
  value: DataFlag.MORE_EMERGENCY_PILL,
  uid: 'addData__emergency__pill__button',
}

const IUD: Readonly<EmergencyItem> = {
  title: 'txt-iud',
  icon: ICON.MORE_EMERGENCY_IUD,
  label: 'txt-emergency-contraceptive-iud',
  value: DataFlag.MORE_EMERGENCY_IUD,
  uid: 'addData__emergency__iud__button',
}

export const emergencyItems = {
  Pill: pill,
  IUD,
}

export enum ColorBySegment {
  DEVIATION = 'temperature',
  BLEEDING = 'bleeding',
  LH_TEST = 'lh',
  PREG_TEST = 'lh', // eslint-disable-line @typescript-eslint/no-duplicate-enum-values
  SEX = 'sex',
  CERVICAL_MUCUS = 'cervicalMucus',
  SENSITIVE = 'purple',
  SENSITIVE_LIGHT = 'medium',
  PAIN = 'pain',
  SKIN = 'skin',
  MOOD = 'mood',
  PREGNANT = 'pregnant',
  SLEEP = 'sleep',
}

export const COLOR_BY_DATA_FLAG = {}
Object.keys(DataFlag).forEach(k => {
  if (Number(k)) return

  let color: ColorBySegment
  if (k.startsWith('MOOD_')) color = ColorBySegment.MOOD
  else if (k.startsWith('DEVIATION_')) color = ColorBySegment.DEVIATION
  else if (k.startsWith('MORE_')) color = ColorBySegment.SENSITIVE_LIGHT
  else color = ColorBySegment.PAIN

  Object.assign(COLOR_BY_DATA_FLAG, { [k]: color })
})

export enum AddDataSource {
  ACHIEVEMENT_DEVIATED = 'AchievementFirstDisabledMeasurement',
  ACHIEVEMENT_TEMP = 'AchievementFirstTemperatureMeasurement',
  ADDED_TEMPERATURE_DISPLAY = 'AddedTemperatureDisplay',
  AUTOMATIC = 'AutomaticOpen',
  GRAPH = 'Graph',
  HISTORY = 'History',
  HOME_HEADER = 'HomeHeader',
  HOME_TABS_FAB = 'HomeTabsFab',
  GRAPH_COMPARE = 'GraphCompare',
  GRAPH_HOLISTIC = 'GraphHolistic',
  MESSAGE = 'Message',
  MY_CYCLE = 'MyCycle',
  NEW_APPLE_WATCH_DATA = 'NewAppleWatchData',
  NOTIFICATION = 'Notification',
  PREGNANCY_REASON = 'PregnancyReason',
  SMART_CIRCLE = 'SmartCircle',
  SWIPE = 'Swipe',
  SWIPE_BUTTON = 'SwipeButton',
  TODAY = 'TodayPage',
  TOUR = 'Tour',
  TOUR_SKIPPED = 'TourSkipped',
  TODAY_FEELINGS = 'TodayFeelings',
  BLUETOOTH = 'Bluetooth',
}

export const OURA_SNACKBAR: Readonly<StringMap<SnackbarOptions>> = {
  [TemperatureDataState.WAITING_FOR_DATA]: SNACKBAR.OURA_SYNC_DELAY,
  [TemperatureDataState.EXCLUDED_ALGO]: SNACKBAR.OURA_EXCLUDED_ALGO,
  [TemperatureDataState.EXCLUDED_SLEEP]: SNACKBAR.OURA_EXCLUDED_SLEEP,
  [TemperatureDataState.ADJUSTED_TEMPERATURE]: SNACKBAR.OURA_ADJUSTED_TEMP,
  [TemperatureDataState.INCOMPLETE_DATA]: SNACKBAR.OURA_INCOMPLETE_DATA,
  [TemperatureDataState.SHORT_SLEEP]: SNACKBAR.OURA_SHORT_SLEEP,
}
