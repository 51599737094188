import { Injectable } from '@angular/core'
import { getState, select2 } from '@app/srv/store.service'
import { distinctUntilDeeplyChanged } from '@app/util/distinctUntilDeeplyChanged'
import { _isEmptyObject, _Memo, _stringMapValues, localDate } from '@naturalcycles/js-lib'
import {
  DataQuantity,
  Goal,
  HadSex,
  MedicalStats,
  Mens,
  TestResult,
  UFColor,
  UFColorCode,
  UserFertility,
} from '@naturalcycles/shared'
import { BehaviorSubject } from 'rxjs'
import { combineLatestWith, skipWhile } from 'rxjs/operators'

export interface StatisticsAverages {
  cycleLength: number
  lutealLength: number
  follicularLength: number
  periodLength: number
  ovulationDay: number
  ovulationVariation: number
  averageDaysPerCycle?: AverageDaysPerCycle
}

export interface TotalTestsAdded {
  lhTestPositive: number
  lhTestNegative: number
  pregTestPositive: number
  pregTestNegative: number
}

export interface TotalSexAdded {
  sex?: number
  sexProtected?: number
  sexUnprotected?: number
}

export enum TimePeriod {
  SIX_CYCLES = 'SIX_CYCLES',
  ALL = 'ALL',
}

export interface MinMaxCycleLength {
  min: number
  max: number
}

interface EarliestLatestOvulation {
  earliest: number
  latest: number
}

interface AverageDaysPerCycle {
  value?: number
  percentageValue?: number
  daysType: UFColor
}

interface CycleMeasurements {
  percentage: number
  days: number
  level: string
}

export interface StatisticsData {
  shouldShowData: boolean
  ovulated: boolean
  measurementThisCycle?: CycleMeasurements
  totalTestsAdded?: TotalTestsAdded
  totalSexAdded?: TotalSexAdded
  averageValues: StatisticsAverages
  numberOfCycles?: number
  minMaxCycleLength?: MinMaxCycleLength
  minMaxCycleLengthLast6Cycles?: MinMaxCycleLength
  follicularLengthVariation?: number
  lutealLengthVariation?: number
  earliestLatestOvulation6Months?: EarliestLatestOvulation
  earliestLatestOvulationDay?: EarliestLatestOvulation
  cycleLengthVariation?: number
  cycleLengthVariationRating?: string
  follicularAverageTemperature?: number
  follicularTemperatureVariation?: number
  follicularVariationRating?: string
  lutealAverageTemperature?: number
  lutealTemperatureVariation?: number
  lutealVariationRating?: string
  pregnancyAverageTemperature?: number
  ovulationVariation?: number
  numberOfAnovulatoryCycles?: number
  numberOfMens?: number
  numberOfSpottings?: number
  numberOfOvulations?: number
  numberOfUnconfirmedOvulations?: number
  predictedPeriodFlows?: DataQuantity[]
}

@Injectable({ providedIn: 'root' })
export class StatisticsService {
  private medicalStats$ = select2(s => s.account.medicalStats)
  private _medicalStats!: MedicalStats

  private userFertility$ = select2(s => s.userFertility)
  private _uf!: UserFertility

  public statisticsDataDefault: StatisticsData = {
    shouldShowData: false,
    ovulated: false,
    averageValues: {
      cycleLength: 0,
      lutealLength: 0,
      follicularLength: 0,
      periodLength: 0,
      ovulationDay: 0,
      ovulationVariation: 0,
    },
  }

  public statisticsData$ = new BehaviorSubject<StatisticsData>(this.statisticsDataDefault)

  @_Memo()
  init(): void {
    this.medicalStats$
      .pipe(
        combineLatestWith(
          this.userFertility$.pipe(
            skipWhile(uf => _isEmptyObject(uf)),
            distinctUntilDeeplyChanged(),
          ),
        ),
      )
      .subscribe(([medicalStats, uf]) => {
        this._medicalStats = medicalStats
        this._uf = uf

        this._generateData(uf)
      })
  }

  private _generateData(uf: UserFertility): void {
    if (!this._uf) return

    const data: StatisticsData = {
      shouldShowData: this._shouldShowData(),
      ovulated: this._hasOvulated(this._medicalStats),
      measurementThisCycle: this._getMeasurementsThisCycle(),
      totalTestsAdded: this._getTotalDataAdded(),
      totalSexAdded: this._getTotalSexAdded(),
      averageValues: this._getAverageValues(),
      numberOfCycles: this._getNumberOfCycles(),
      minMaxCycleLength: this._getMinMaxCycleLength(),
      minMaxCycleLengthLast6Cycles: this._getMinMaxCycleLength(TimePeriod.SIX_CYCLES),
      follicularLengthVariation: this._getFollicularLengthVariation(),
      lutealLengthVariation: this._getLutealLengthVariation(),
      earliestLatestOvulation6Months: this._getEarliestLatestOvulationDay(TimePeriod.SIX_CYCLES),
      earliestLatestOvulationDay: this._getEarliestLatestOvulationDay(),
      cycleLengthVariation: this._getCycleLengthVariation(),
      cycleLengthVariationRating: this._getCycleRegularity(),
      pregnancyAverageTemperature: this._getPregnancyAverageTemperature(),
      lutealAverageTemperature: this._getLutealAverageTemperature(),
      lutealTemperatureVariation: this._getLutealTemperatureVariation(),
      lutealVariationRating: this._getLutealVariationRating(),
      ovulationVariation: this._getOvulationVariation(),
      numberOfAnovulatoryCycles: this._getNumberOfAnovulatoryCycles(),
      numberOfMens: this._getNumberOfMens(),
      numberOfSpottings: this._getNumberOfSpottings(),
      numberOfOvulations: this._getNumberOfOvulations(),
      numberOfUnconfirmedOvulations: this._getNumberOfUnconfirmedOvulations(),
      predictedPeriodFlows: this._getPredictedPeriodFlows(uf),
    }

    this.statisticsData$.next(data)
  }

  _getNumberOfCycles(): number {
    return this._uf.ncycles
  }

  _getAverageValues(): StatisticsAverages {
    return {
      cycleLength: this._getAverageCycleLength(),
      lutealLength: Math.round(this._uf.lpave),
      follicularLength: Math.round(this._uf.oave - 1),
      periodLength: Math.round(this._uf.mdays),
      ovulationDay: Math.round(this._uf.oave),
      ovulationVariation: this._getOvulationVariation(),
      averageDaysPerCycle: this._getAverageDaysPerCycle(),
    }
  }

  _getAverageCycleLength(): number {
    return Math.round(this._uf.clave)
  }

  _getCycleLengthVariation(): number | undefined {
    return this._uf.claveRMS ? Math.round(this._uf.claveRMS) : undefined
  }

  _getCycleRegularity(): string | undefined {
    const variation = this._getCycleLengthVariation()
    if (this._uf.ncycles > 0 && variation) {
      if (variation > 7) {
        return 'my-data-cycle-highly-irregular'
      }

      if (variation > 4) return 'my-data-cycle-irregular'

      return 'my-data-cycle-regular'
    }
    return undefined
  }

  _getOvulationVariation(): number {
    // Max variation should not be more than 10 days
    const maxVariation = 10

    // We use standard deviation * 2 as average variation
    let averageVariation = Math.round(this._uf.oaveRMS * 2)

    // // Ovulation never happens before CD 8.
    // // The variation can never be larger than ovulationDay - 8.
    const limitationFirstDayOfWindow = Math.round(this._uf.oave) - 8
    if (averageVariation > limitationFirstDayOfWindow) {
      averageVariation = limitationFirstDayOfWindow
    }
    //
    // // The variation is never larger than lutealLength - 3
    const lutealLengthLimit = Math.round(this._uf.lpave) - 3
    if (averageVariation > lutealLengthLimit) {
      averageVariation = lutealLengthLimit
    }

    if (averageVariation > maxVariation) {
      averageVariation = maxVariation
    }

    return averageVariation
  }

  private getOvulations(): (number | null)[] {
    return this._uf.ovulationDays.slice(0, this._uf.ncycles)
  }

  _getEarliestLatestOvulationDay(
    period: string = TimePeriod.ALL,
  ): EarliestLatestOvulation | undefined {
    if (!this._uf.colorMap) return undefined

    let ovulations = this.getOvulations()

    if (period === TimePeriod.SIX_CYCLES && this._uf.ncycles > 6) {
      ovulations = ovulations.slice(ovulations.length - 6)
    }

    const ovulationDays = ovulations.filter(v => !!v)

    if (ovulationDays.length === 0) return undefined

    return {
      earliest: Math.min(...(ovulationDays as number[])),
      latest: Math.max(...(ovulationDays as number[])),
    }
  }

  _getAverageDaysPerCycle(): AverageDaysPerCycle | undefined {
    if (!this._shouldShowData()) {
      if (getState().account.goal === Goal.PLAN) {
        return { daysType: UFColor.RED }
      }
      return { daysType: UFColor.GREEN }
    }

    const goal = this._uf.colorMap[this._uf.todayDate]!.goal
    let daysColor: UFColor
    let averageValue: number | undefined
    switch (goal) {
      case Goal.PREGNANT: {
        const dayBeforePregnancy = this._lastEntryBeforePregnancy()
        if (!dayBeforePregnancy) {
          daysColor = UFColor.RED
        } else {
          daysColor = dayBeforePregnancy.goal === Goal.PREVENT ? UFColor.GREEN : UFColor.RED
          averageValue = this._calculateAverageNumberOfDays(
            dayBeforePregnancy.goal === Goal.PREVENT ? UFColor.GREEN : UFColor.RED,
          )
        }
        break
      }
      case Goal.PREVENT:
        daysColor = UFColor.GREEN
        averageValue = this._calculateAverageNumberOfDays(UFColor.GREEN)
        break
      case Goal.PLAN:
        daysColor = UFColor.RED
        averageValue = this._calculateAverageNumberOfDays(UFColor.RED)
        break
      default:
        daysColor = UFColor.RED
    }
    return {
      daysType: daysColor,
      value: averageValue,
      percentageValue: this._getAverageDaysPercentage(averageValue),
    }
  }

  _getAverageDaysPercentage(averageDays: number | undefined): number | undefined {
    return averageDays
      ? Math.round((averageDays * 100) / this._getAverageCycleLength()) / 100
      : undefined
  }

  _lastEntryBeforePregnancy(): UFColorCode | undefined {
    const pregnantDay = Object.keys(this._uf.colorMap).find(
      date => this._uf.colorMap[date]!.goal === Goal.PREGNANT,
    )
    const dayBeforePregnancyDate = localDate(pregnantDay!).minus(1, 'day')
    return this._uf.colorMap[dayBeforePregnancyDate.toISODate()]
  }

  /*
   * We do not count `Pregnant` days because they are marked as `GREEN` days
   * and they would affect overall stats.
   */
  _calculateAverageNumberOfDays(color: UFColor): number | undefined {
    if (!this._shouldShowData()) return undefined
    const { account } = getState()

    const datesInCompleteCycles = Object.keys(this._uf.colorMap).filter(
      date =>
        !(this._uf.colorMap[date]!.cycleStartDate < account.completeDate!) &&
        this._uf.colorMap[date]!.nCycle !== this._uf.ncycles,
    )

    const totalRedGreenDays = datesInCompleteCycles.filter(
      date =>
        (this._uf.colorMap[date]!.color === UFColor.RED ||
          this._uf.colorMap[date]!.color === UFColor.GREEN) &&
        !this._uf.colorMap[date]!.code.defPreg,
    )

    const daysOfColor = totalRedGreenDays.filter(date => this._uf.colorMap[date]!.color === color)

    if (!daysOfColor || !totalRedGreenDays) return undefined

    return Math.round(
      (daysOfColor.length / totalRedGreenDays.length) * this._getAverageCycleLength(),
    )
  }

  private getCycleLengths(): (number | null)[] {
    return this._uf.cycleLengths.slice(0, this._uf.ncycles)
  }

  _getMinMaxCycleLength(period: string = TimePeriod.ALL): MinMaxCycleLength | undefined {
    if (!this._uf.colorMap) return undefined
    if (!this._shouldShowData()) return undefined

    let cycleLengths = this.getCycleLengths()

    if (period === TimePeriod.SIX_CYCLES && this._uf.ncycles > 6) {
      cycleLengths = cycleLengths.slice(cycleLengths.length - 6)
    }

    cycleLengths = cycleLengths.filter(v => !!v)
    if (cycleLengths.length === 0) return undefined

    return {
      min: Math.min(...(cycleLengths as number[])),
      max: Math.max(...(cycleLengths as number[])),
    }
  }
  _getFollicularLengthVariation(): number | undefined {
    return Math.round(this._uf.oaveRMS)
  }

  _getLutealLengthVariation(): number | undefined {
    return this._uf.lpaveRMS ? Math.round(this._uf.lpaveRMS) : undefined
  }

  _getLutealAverageTemperature(): number | undefined {
    if (!this._uf.highTempMean) return undefined
    if (!this._hasOvulated(this._medicalStats)) return undefined
    return Math.round(this._uf.highTempMean * 100) / 100
  }

  _getLutealTemperatureVariation(): number | undefined {
    if (!this._uf.highTempRMS) return undefined
    if (!this._hasOvulated(this._medicalStats)) return undefined
    return Math.round(this._uf.highTempRMS * 100) / 100
  }

  _getLutealVariationRating(): string | undefined {
    if (!this._uf.highTempRMS) return undefined
    return this._temperatureVariationRating(this._uf.highTempRMS, getState().account.fahrenheit)
  }

  _getNumberOfAnovulatoryCycles(): number | undefined {
    return this._uf.noOCycles ? this._uf.noOCycles.filter(Boolean).length : undefined
  }

  _getNumberOfOvulations(): number {
    if (!this._uf.colorMap) return 0
    return Object.keys(this._uf.colorMap).filter(k => this._uf.colorMap[k]!.code.ovulation).length
  }

  _getNumberOfUnconfirmedOvulations(): number {
    if (!this._uf.colorMap) return 0

    return Object.keys(this._uf.predOvulationDays).filter(key => {
      // Dont compare current or future cycles
      if (parseInt(key) >= this._uf.ncycles) return false

      // If prediction = null & ovuDays = undefined
      if (!this._uf.predOvulationDays[key] && !this._uf.ovulationDays[key]) {
        return false
      }

      // Check if prediction === ovuDays and it is not a confirmed anovulatory cycle
      return (
        this._uf.predOvulationDays[key] !== this._uf.ovulationDays[key] && !this._uf.noOCycles[key]
      )
    }).length
  }

  _getMeasurementsThisCycle(): CycleMeasurements | undefined {
    if (!this._uf?.colorMap || !Object.keys(this._uf.entryMap || {}).length) {
      return undefined
    }

    const daysThisCycle = Object.keys(this._uf.colorMap).filter(key => {
      return this._uf.colorMap[key]!.nCycle === this._uf.ncycles
    })

    const entriesWithTemperature = Object.values(daysThisCycle).filter(key => {
      return this._uf.entryMap[key]?.temperature
    })

    const percentage = entriesWithTemperature.length / daysThisCycle.length
    let level = 'my-data-measure-level-1'

    if (percentage > 0.8) level = 'my-data-measure-level-5'
    else if (percentage > 0.7) level = 'my-data-measure-level-4'
    else if (percentage > 0.6) level = 'my-data-measure-level-3'
    else if (percentage >= 0.4) level = 'my-data-measure-level-2'

    return {
      percentage: Math.round(percentage * 100) / 100, // rounded to two decimals
      days: entriesWithTemperature.length,
      level,
    }
  }

  _getTotalDataAdded(): TotalTestsAdded | undefined {
    if (!this._uf.entryMap) return undefined

    const data: TotalTestsAdded = {
      lhTestNegative: Object.keys(this._uf.entryMap).filter(
        k => this._uf.entryMap[k]!.lhTest === TestResult.NO,
      ).length,
      lhTestPositive: Object.keys(this._uf.entryMap).filter(
        k => this._uf.entryMap[k]!.lhTest === TestResult.YES,
      ).length,
      pregTestNegative: Object.keys(this._uf.entryMap).filter(
        k => this._uf.entryMap[k]!.pregTest === TestResult.NO,
      ).length,
      pregTestPositive: Object.keys(this._uf.entryMap).filter(
        k => this._uf.entryMap[k]!.pregTest === TestResult.YES,
      ).length,
    }

    const goal = this._uf.colorMap[this._uf.todayDate]!.goal

    // Don't show pregTests for Prevent users
    if (goal === Goal.PREVENT) {
      data.pregTestNegative = 0
      data.pregTestPositive = 0
    }

    return data
  }

  _getTotalSexAdded(): TotalSexAdded | undefined {
    if (!this._uf.entryMap) return undefined

    const { goal } = getState().account
    const entries = _stringMapValues(this._uf.entryMap)

    if (goal === Goal.PREVENT) {
      return {
        sexProtected: entries.filter(({ sex }) => sex === HadSex.YES_PROTECTED).length,
        sexUnprotected: entries.filter(({ sex }) => sex === HadSex.YES).length,
      }
    }

    return {
      sex: entries.filter(({ sex }) => sex === HadSex.YES || sex === HadSex.YES_PROTECTED).length,
    }
  }

  _getNumberOfMens(): number | undefined {
    if (!this._uf.entryMap) return undefined

    const mensDays = _stringMapValues(this._uf.entryMap).filter(entry => {
      return entry.mens === Mens.MENSTRUATION
    })

    return mensDays.length
  }

  _getNumberOfSpottings(): number | undefined {
    if (!this._uf.entryMap) return undefined

    let spottings = 0
    const ovulationDates = Object.keys(this._uf.entryMap).filter(k => {
      if (this._uf.colorMap[k]?.code.ovulation) return k
    })

    for (const ovulationDate of ovulationDates) {
      const dayBefore = this._uf.entryMap[localDate(ovulationDate).minus(1, 'day').toISODate()]
      const dayAfter = this._uf.entryMap[localDate(ovulationDate).plus(1, 'day').toISODate()]

      if (this._uf.entryMap[ovulationDate]?.mens === Mens.SPOTTING) spottings++
      if (dayBefore && dayBefore.mens === Mens.SPOTTING) spottings++
      if (dayAfter && dayAfter.mens === Mens.SPOTTING) spottings++
    }

    return spottings
  }

  _getPregnancyAverageTemperature(): number | undefined {
    const { goal } = getState().account
    if (goal === Goal.PREVENT) return undefined

    return this._uf.pregTempMean || undefined
  }

  private _shouldShowData(): boolean {
    return this._uf.ncycles > 2 || this._hasOvulated(this._medicalStats)
  }

  _temperatureVariationRating(temp: number, isFahrenheit = false): string {
    if (isFahrenheit) temp /= 1.8

    if (temp < 0.14) return 'excellent'
    if (temp < 0.19) return 'very-good'
    if (temp < 0.23) return 'stable'
    if (temp < 0.27) return 'unstable'
    return 'very-unstable'
  }

  _getPredictedPeriodFlows(uf: UserFertility): DataQuantity[] | undefined {
    const { predictionMap, ncycles, mdays } = uf
    if (!predictionMap) return

    const nextCycleMensDates = Object.keys(predictionMap).filter(date => {
      const prediction = predictionMap[date]!
      return prediction.nCycle === ncycles + 1 && (prediction.mensQuantity || prediction.code.mens)
    })

    if (nextCycleMensDates.length > 0) {
      return nextCycleMensDates.map(
        date => predictionMap[date]!.mensQuantity || DataQuantity.MEDIUM,
      )
    }
    // eslint-disable-next-line unicorn/no-new-array
    return new Array(Math.round(mdays)).fill(DataQuantity.MEDIUM)
  }

  _hasOvulated(medicalStats: MedicalStats): boolean {
    return !!medicalStats?.foundOvulation
  }
}
