import { Component, inject, Input, OnInit, QueryList, ViewChildren } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { EVENT } from '@app/analytics/analytics.cnst'
import { addAnalyticsProps, AnalyticsService } from '@app/analytics/analytics.service'
import { ICON } from '@app/cnst/icons.cnst'
import { BaseModal } from '@app/pages/base.modal'
import { SharedModule } from '@app/shared.module'
import { getState } from '@app/srv/store.service'
import { IonicModule, IonRadioGroup } from '@ionic/angular'
import { HardwareId } from '@naturalcycles/shared'
import { dayjs } from '@naturalcycles/time-lib'
import { MultipleEntryItems } from './multiple-entries.service'

@Component({
  selector: 'app-multiple-entries-modal',
  templateUrl: './multiple-entries.modal.html',
  styleUrls: ['./multiple-entries.modal.scss'],
  standalone: true,
  imports: [IonicModule, SharedModule, ReactiveFormsModule],
})
export class MultipleEntriesModal extends BaseModal implements OnInit {
  className = 'MultipleEntriesModal'

  private analyticsService = inject(AnalyticsService)

  @Input()
  public title?: string

  @Input()
  public body?: string

  @Input()
  public dismissTitle = 'txt-close'

  @Input()
  public editable?: boolean

  @Input()
  public entryItems: MultipleEntryItems[] = []

  @ViewChildren(IonRadioGroup)
  private radios?: QueryList<IonRadioGroup>

  public isOura = getState().account.hwId === HardwareId.OURA

  public ICON = ICON

  public ngOnInit(): void {
    const entries = this.entryItems.flatMap(item => item.entries)

    addAnalyticsProps({
      temperatures: entries.length,
      days: this.entryItems.length,
      entries: entries.map(e => {
        const { temperature, date, temperatureUpdatedTimestamp, temperatureMeasuredTimestamp } = e

        return {
          date,
          temperature,
          measured: temperatureMeasuredTimestamp
            ? dayjs.unix(temperatureMeasuredTimestamp).toPretty()
            : undefined,
          updated: temperatureUpdatedTimestamp
            ? dayjs.unix(temperatureUpdatedTimestamp).toPretty()
            : undefined,
        }
      }),
    })
  }

  public dismiss(): void {
    const changedTemp = this.radios?.some(r => r.value > 0)
    const clearedTemp = this.radios?.some(r => r.value === undefined)

    const result =
      this.radios?.map((radio, index) => {
        const entry = this.entryItems[index]?.entries[radio.value]
        return entry
      }) || []

    const entriesToSave = result.filter(Boolean)

    this.analyticsService.trackEvent(EVENT.MULTI_ENTRIES_DISMISS, {
      changedTemp: this.editable ? changedTemp : undefined,
      clearedTemp: this.editable ? clearedTemp : undefined,
    })

    void this.dismissModal(entriesToSave)
  }
}
