import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { IonicModule } from '@ionic/angular'
import { BestTimeInCycleOption } from '@naturalcycles/shared'
import { QuizProgressIndicatorComponent } from '@src/app/cmp/quiz/progress-indicator/progress-indicator.component'
import { QuizHeaderComponent } from '@src/app/cmp/quiz/quiz-header/quiz-header.component'
import { QuizSelectionComponent } from '@src/app/cmp/quiz/selection/selection.component'
import { quizAnimation, QuizSelectOption } from '@src/app/cnst/quiz.cnst'
import { QuizPage } from '@src/app/cnst/quiz-pages.cnst'
import { SharedModule } from '@src/app/shared.module'
import { dispatch, select } from '@src/app/srv/store.service'
import { Observable } from 'rxjs'
import { QuizBasePage } from '../quiz.base.page'

@Component({
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    IonicModule,
    QuizHeaderComponent,
    QuizProgressIndicatorComponent,
    QuizSelectionComponent,
  ],
  selector: `page-quiz-${QuizPage.bestTimeInCycle}`,
  templateUrl: 'best-time-in-cycle.page.component.html',
  styleUrls: ['best-time-in-cycle.page.component.scss', '../quiz.base.scss'],
  animations: [quizAnimation],
})
export class BestTimeInCyclePage extends QuizBasePage {
  @select(['quiz', 'data', 'bestTimeInCycle'])
  selectedKey$!: Observable<BestTimeInCycleOption>

  // prettier-ignore
  public selections: QuizSelectOption[] = [
    { key: BestTimeInCycleOption.beforePeriod, textKey: 'txt-quiz-BestTimeInCycleOption--beforePeriod' },
    { key: BestTimeInCycleOption.duringPeriod, textKey: 'txt-quiz-BestTimeInCycleOption--duringPeriod' },
    { key: BestTimeInCycleOption.afterPeriod, textKey: 'txt-quiz-BestTimeInCycleOption--afterPeriod' },
    { key: BestTimeInCycleOption.dontKnow, textKey: 'txt-quiz-BestTimeInCycleOption--dontKnow' },
    { key: BestTimeInCycleOption.ratherNotSay, textKey: 'txt-quiz-BestTimeInCycleOption--ratherNotSay' },
  ]

  public saveSelectedAnswer(key: BestTimeInCycleOption): void {
    dispatch('updateQuizData', { bestTimeInCycle: key })

    this.analyticsService.trackEvent(this.analyticsEventName, {
      question: 'bestTimeInCycle',
      answer: BestTimeInCycleOption[key],
    })

    this.nextPage()
  }

  public nextPage(): void {
    void super.navigateForwardInQuiz()
  }
}
