import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { TourNavbarComponent } from '@app/cmp/tour-navbar/tour-navbar.component'
import { SharedModule } from '@app/shared.module'
import { IonicModule } from '@ionic/angular'

@NgModule({
  declarations: [TourNavbarComponent],
  imports: [IonicModule, SharedModule, CommonModule],
  exports: [TourNavbarComponent],
})
export class TourNavbarComponentModule {}
