import { animate, style, transition, trigger } from '@angular/animations'
import { Component, inject, Input, OnInit, ViewChild } from '@angular/core'
import { EVENT } from '@app/analytics/analytics.cnst'
import { AnalyticsService } from '@app/analytics/analytics.service'
import { ICON } from '@app/cnst/icons.cnst'
import { isAndroidApp } from '@app/cnst/userDevice.cnst'
import { DatetimePickerDirective } from '@app/dir/datetime-picker/datetime-picker.directive'
import { ScrollableDirective } from '@app/dir/scrollable/scrollable.directive'
import {
  DatetimePickerValue,
  IonDatetimeConfig,
} from '@app/modals/datetime-picker/datetime-picker.model'
import { BaseModal } from '@app/pages/base.modal'
import { DateService } from '@app/srv/date.service'
import { dispatch } from '@app/srv/store.service'

interface DeviationSleepResult {
  title: string
  keys: string[]
  positive: boolean
}

interface Slide {
  uid: string
  title: string
  body: string
}

const slides: readonly Slide[] = [
  {
    uid: 'question2',
    title: 'txt-zzz-question2-title',
    body: 'txt-zzz-question2-body',
  },
  {
    uid: 'question3',
    title: 'txt-zzz-question3-title',
    body: 'txt-zzz-question3-body',
  },
]

const question1ReminderSlide: Readonly<Slide> = {
  uid: 'question1',
  title: 'txt-zzz-question1-title',
  body: 'txt-zzz-question1-body',
}

const question1NoReminderSlide: Readonly<Slide> = {
  uid: 'question1NoReminder',
  title: 'txt-zzz-question1-title',
  body: 'txt-zzz-question1-body-noreminder',
}

const wakeUpSlide: Readonly<Slide> = {
  uid: 'wakeUpTime',
  title: 'txt-zzz-wakeup-time-title',
  body: 'txt-zzz-wakeup-time-body',
}

@Component({
  selector: 'page-deviation-sleep-modal',
  templateUrl: './deviation-sleep.modal.html',
  styleUrls: ['./deviation-sleep.modal.scss'],
  animations: [
    trigger('parent', [transition(':enter', [])]),
    trigger('slideAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('250ms 100ms ease-out', style({ transform: 'translateX(0)' })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate('200ms ease-in', style({ transform: 'translateX(-100%)' })),
      ]),
    ]),
  ],
})
export class DeviationSleepModal extends BaseModal implements OnInit {
  private dateService = inject(DateService)
  private analyticsService = inject(AnalyticsService)
  className = 'DeviationSleepModal'

  public ICON = ICON
  public isAndroidApp = isAndroidApp

  public slideCount = 3
  public currentIndex = 0
  public startTime?: string
  public endTime?: string
  public slides!: Slide[]
  public result?: DeviationSleepResult
  private answers: boolean[] = []

  @Input()
  public reminderTime?: string

  @Input()
  public wakeUpTime?: string

  @ViewChild(ScrollableDirective)
  public content!: ScrollableDirective

  @ViewChild(DatetimePickerDirective)
  public datetime?: DatetimePickerDirective

  public datetimePickerConfig: IonDatetimeConfig = {
    presentation: 'time',
    value: '07:00',
  }

  public get newWakeUpTime(): DatetimePickerValue {
    return this.datetimePickerConfig.value
  }

  public ngOnInit(): void {
    if (this.reminderTime) {
      // Has an enabled morning reminder
      this.slides = [question1ReminderSlide, ...slides]
      this.formatTimeStrings(this.reminderTime)
    } else if (this.wakeUpTime) {
      // No enabled morning reminders but has saved a wakeUpTime
      this.slides = [question1NoReminderSlide, ...slides]
      this.formatTimeStrings(this.wakeUpTime)
    } else {
      // No enabled morning reminders and no saved wakeUpTime
      this.slides = [wakeUpSlide, question1NoReminderSlide, ...slides]
      this.slideCount = 4
    }
  }

  public async openTimePicker(): Promise<void> {
    if (!this.isAndroidApp) {
      void this.datetime?.showDatetimePicker()
      return
    }
    if (!this.newWakeUpTime) return

    this.datetimePickerConfig.value = await this.dateService.getTimeOnAndroid(this.newWakeUpTime)
  }

  public onTimeChange(time: DatetimePickerValue): void {
    if (!time) return

    this.datetimePickerConfig.value = time
  }

  public next(answer: boolean): void {
    this.answers.push(answer)

    const uid = this.slides[this.currentIndex]!.uid
    const element = `deviationSleep__${uid}__${answer ? 'yes' : 'no'}__button`
    void this.analyticsService.trackEvent(EVENT.CLICK, { element })

    if (++this.currentIndex === this.slideCount) {
      this.result = this.getResult()

      const props = { answers: this.answers, result: this.result.positive }
      void this.analyticsService.trackEvent(EVENT.DEVIATION_SLEEP_RESULT, props)
    }
  }

  public saveWakeUpTime(): void {
    if (!this.newWakeUpTime) return

    dispatch('extendUserSettings', { wakeUpTime: this.newWakeUpTime })
    this.formatTimeStrings(this.newWakeUpTime)
    this.currentIndex++
  }

  public onSlideAnimationDone(): void {
    this.content.updateScrollable()
  }

  private getResult(): DeviationSleepResult {
    let title = 'txt-zzz-good-answer-title'
    let keys = ['txt-zzz-good-answer-body']
    let positive = false

    if (this.answers.some(answer => !answer)) {
      title = 'txt-zzz-bad-answer-title'
      keys = []
      positive = true

      for (const [index, answer] of this.answers.entries()) {
        if (answer) continue

        keys.push(`txt-zzz-bad-answer--${index + 1}--body`)
      }
    }

    return { title, keys, positive }
  }

  private formatTimeStrings(time: string): void {
    const [hour, minute] = time.split(':')

    this.startTime = `${Number(hour) - 2}:${minute}`
    this.endTime = `${Number(hour) + 2}:${minute}`

    if (this.reminderTime?.startsWith('0')) {
      this.reminderTime = this.reminderTime.substr(1)
    }
  }

  trackByUid(_i: number, item: any): any {
    return item?.uid
  }
}
