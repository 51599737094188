import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { AdditionalInfoComponentModule } from '@app/cmp/add-data-additional-info/add-data-additional-info.component.module'
import { NavBannerComponentModule } from '@app/cmp/nav-banner/nav-banner.component.module'
import { TemperatureDisplayComponentModule } from '@app/cmp/temperature-display/temperature-display.component.module'
import { TemperatureNumpadComponentModule } from '@app/cmp/temperature-numpad/temperature-numpad.component.module'
import { TourNavbarComponentModule } from '@app/cmp/tour-navbar/tour-navbar.component.module'
import { DeviationSleepModalModule } from '@app/modals/deviation-sleep/deviation-sleep.modal.module'
import { LhScannerModalModule } from '@app/modals/lh-scanner/lh-scanner.modal.module'
import { MultipleEntriesModal } from '@app/modals/multiple-entries/multiple-entries.modal'
import { SensitiveTrackersModalModule } from '@app/modals/sensitive-trackers/sensitive-trackers.modal.module'
import { AddDataPage } from '@app/pages/add-data/add-data.page'
import { SharedModule } from '@app/shared.module'
import { IonicModule } from '@ionic/angular'

@NgModule({
  declarations: [AddDataPage],
  imports: [
    IonicModule,
    SharedModule,
    CommonModule,
    TemperatureDisplayComponentModule,
    AdditionalInfoComponentModule,
    TemperatureNumpadComponentModule,
    TourNavbarComponentModule,
    MultipleEntriesModal,
    LhScannerModalModule,
    DeviationSleepModalModule,
    SensitiveTrackersModalModule,
    NavBannerComponentModule,
  ],
})
export class AddDataPageModule {}
