import { Component, ElementRef, HostBinding, inject, Input } from '@angular/core'
import { ICON } from '@app/cnst/icons.cnst'
import { BaseModal } from '@app/pages/base.modal'
import { confettiService } from '@app/srv/confetti.service'
import { runOutsideAngular } from '@app/srv/di.service'

export interface ConfettiModalProps {
  title?: string
  body?: string
  image?: string
  roundImage?: boolean
  ctas?: ConfettiModalCta[]
  uid?: string
}

interface ConfettiModalCta {
  title: string
  uid: string
  outline?: boolean
}

@Component({
  selector: 'page-confetti-modal',
  templateUrl: './confetti.modal.html',
  styleUrls: ['./confetti.modal.scss'],
})
export class ConfettiModal extends BaseModal {
  className = 'ConfettiModal'
  public ICON = ICON

  constructor() {
    const elementRef = inject(ElementRef)

    super()
    this.elementRef = elementRef
  }

  /**
   * Inputs should match interface ConfettiModalProps
   */
  @Input()
  public title?: string

  @Input()
  public body?: string

  @Input()
  public image?: string

  @Input()
  public roundImage?: boolean

  @Input()
  public ctas?: ConfettiModalCta[]

  @HostBinding('attr.uid')
  public uid?: string

  protected override elementRef: ElementRef

  public override ionViewDidEnter(): void {
    void import('@app/srv/confetti.service').then(({ confettiService }) =>
      runOutsideAngular(
        () => void confettiService.addConfetti(this.elementRef.nativeElement, { x: 0.5, y: 0.5 }),
      ),
    )
  }

  public override ionViewWillLeave(): void {
    confettiService.reset()
  }
}
