import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'stripTags',
  pure: true,
})
export class StripTagsPipe implements PipeTransform {
  transform(text: string | null): string {
    if (text === null) return '' // safely pipe after `| async` which is typed as ` | null` in Angular
    return String(text).replace(/<[^>]+>/gm, '')
  }
}
