import { inject, Injectable } from '@angular/core'
import { EVENT } from '@app/analytics/analytics.cnst'
import { AnalyticsService } from '@app/analytics/analytics.service'
import { ConnectionStatus, Network } from '@capacitor/network'
import { Platform } from '@ionic/angular'
import { _Debounce, getFetcher } from '@naturalcycles/js-lib'
import { env } from '@src/environments/environment'
import { EventService } from './event.service'
import { dispatch, getState } from './store.service'

const RETRY_TIME = 10000 // interval of retrying connection, in milliseconds

const pingFetcher = getFetcher()

/**
 * Returns true if Backend API is accessible (online).
 */
async function ping(): Promise<boolean> {
  const { ok } = await pingFetcher.doFetch({
    url: env.apiHost,
    method: 'HEAD',
    responseType: 'void',
  })
  return ok
}

@Injectable({ providedIn: 'root' })
export class NetworkService {
  private platform = inject(Platform)
  private analyticsService = inject(AnalyticsService)
  private eventService = inject(EventService)
  private online = true
  private timeout?: NodeJS.Timer

  init(): void {
    if (!this.platform.is('hybrid')) return

    void this.goOnline()

    this.eventService.onResume$.subscribe(() => {
      if (this.online) return

      // try to go back online if offline on resume
      void this.goOnline()
    })

    void Network.addListener('networkStatusChange', status => this.onNetworkStatusChange(status))
  }

  @_Debounce(2000)
  private onNetworkStatusChange(status: ConnectionStatus): void {
    if (!status.connected) {
      this.goOffline()
      return
    }

    void this.goOnline()
  }

  public goOffline(): void {
    // clear timer
    if (this.timeout) clearTimeout(this.timeout as any)

    dispatch('setOnline', false)
    this.online = false

    this.timeout = setTimeout(() => this.goOnline(), RETRY_TIME)
  }

  private async goOnline(): Promise<void> {
    const success = await ping()

    if (!success) {
      this.goOffline()
      return // stay offline
    }

    // clear timer
    if (this.timeout) clearTimeout(this.timeout as any)

    if (this.online) return // already online

    dispatch('setOnline', true)

    this.trackOffline()

    this.eventService.onResume$.next()

    this.online = true
  }

  private trackOffline(): void {
    const { addData } = getState()
    const sinceLastSync = addData.sinceLastSync
    const minutes = sinceLastSync.inMinutes
    const measurementsOnHold = Object.keys(addData.modifiedDailyEntries || {}).length

    void this.analyticsService.trackEvent(EVENT.OFFLINE, {
      measurementsOnHold,
      minutes,
    })
  }

  public async isOnline(): Promise<boolean> {
    if (!this.platform.is('hybrid')) return true // For WebApp - always return "online"

    const networkStatus = await Network.getStatus()
    return networkStatus.connected
  }
}
