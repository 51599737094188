import { animate, AnimationEvent, style, transition, trigger } from '@angular/animations'
import { Component, HostBinding, Input, output } from '@angular/core'
import { IonicModule } from '@ionic/angular'
import { SharedModule } from '@src/app/shared.module'

@Component({
  standalone: true,
  imports: [SharedModule, IonicModule],
  selector: 'app-quiz-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['../../../pages/quiz/quiz.base.scss', './overlay.component.scss'],
  animations: [
    trigger('overlay', [
      transition('void => *', [style({ opacity: 0 }), animate(300, style({ opacity: 1 }))]),
    ]),
  ],
})
export class QuizOverlayComponent {
  @Input() isVisible: boolean | undefined

  @Input() hasBackgroundAnimation = true

  animationStartedEmitter = output<AnimationEvent>()

  animationDoneEmitter = output<AnimationEvent>()

  animationStarted($event: AnimationEvent): void {
    if ($event.fromState === 'void') {
      this.animationStartedEmitter.emit($event)
    }
  }

  animationDone($event: AnimationEvent): void {
    if ($event.fromState === 'void') {
      this.animationDoneEmitter.emit($event)
    }
  }

  @HostBinding('style.--quiz-result-dots-display')
  get getBackgroundDisplay(): string {
    return this.hasBackgroundAnimation ? 'block' : 'none'
  }
}
