import { ChangeDetectionStrategy, Component, Input, output } from '@angular/core'
import { DirectivesModule } from '@app/dir/directives.module'
import { SharedModule } from '@app/shared.module'
import { IonicModule } from '@ionic/angular'

@Component({
  selector: 'app-added-temperature-display',
  templateUrl: './added-temperature-display.component.html',
  styleUrls: ['./added-temperature-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonicModule, DirectivesModule, SharedModule],
})
export class AddedTemperatureDisplayComponent {
  @Input()
  public temperature?: number

  @Input()
  public deviated?: boolean

  @Input()
  public date?: string

  public clickDate = output<string | undefined>()

  public onClick(): void {
    this.clickDate.emit(this.date)
  }
}
