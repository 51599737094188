// https://github.com/danielsogl/awesome-cordova-plugins/blob/v5.36.0/src/%40ionic-native/plugins/in-app-purchase/index.ts

interface IAPTransactionDetails {
  transactionId: string
  receipt: string
  signature: string
  productType: string
}

interface IAPProduct {
  currency: string
  description: string
  price: string
  priceAsDecimal: number
  productId: string
  title: string
}

// https://github.com/AlexDisler/cordova-plugin-inapppurchase#inapppurchaserestorepurchases
export interface IAPRestorePurchase {
  productId: string
  // https://github.com/AlexDisler/cordova-plugin-inapppurchase/blob/master/src/ios/PaymentsPlugin.m#L103
  // date format : yyyy-MM-dd'T'HH:mm:ss'Z'
  date: string
  state: number // https://developer.apple.com/documentation/storekit/skpaymenttransactionstate
  transactionId: string
  productType: string
  receipt: string
  signature: string
}

export class InAppPurchase {
  async getProducts(productIds: string[]): Promise<IAPProduct[]> {
    return await window.inAppPurchase.getProducts(productIds)
  }
  async buy(productId: string): Promise<IAPTransactionDetails> {
    return await window.inAppPurchase.buy(productId)
  }
  async subscribe(productId: string): Promise<IAPTransactionDetails> {
    return await window.inAppPurchase.subscribe(productId)
  }
  async consume(productType: string, receipt: string, signature: string): Promise<any> {
    return await window.inAppPurchase.consume(productType, receipt, signature)
  }
  async restorePurchases(): Promise<IAPRestorePurchase[]> {
    return await window.inAppPurchase.restorePurchases()
  }
  async getReceipt(): Promise<string> {
    return await window.inAppPurchase.getReceipt()
  }
}
