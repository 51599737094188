import { inject, Injectable } from '@angular/core'
import { usesBluetoothDevice } from '@app/cnst/hardware.cnst'
import { ROUTES } from '@app/cnst/nav.cnst'
import { isAndroidApp, isIOSApp, isWebApp } from '@app/cnst/userDevice.cnst'
import { PendingHardwareDeviceSettings } from '@app/reducers/hardwareDevice.reducer'
import { NotificationService } from '@app/srv/notification.service'
import { getState, select, select2 } from '@app/srv/store.service'
import { tr } from '@app/srv/translation.util'
import { _findKeyByValue, StringMap } from '@naturalcycles/js-lib'
import { BatteryStatus, Blog, FWVersion, HardwareId, Quiz } from '@naturalcycles/shared'
import { dayjs } from '@naturalcycles/time-lib'
import { Badge } from '@src/typings/capacitor'
import { Observable } from 'rxjs'
import { combineLatestWith, distinctUntilChanged, map } from 'rxjs/operators'
import { AnnouncementService } from './announcement.service'
import { EventService } from './event.service'
import { GuideService } from './guide.service'
import { HardwareDeviceService } from './hardwareDevice.service'
import { ChannelId, LocalNotificationService } from './notification.local.service'

@Injectable({ providedIn: 'root' })
export class BadgeService {
  private localNotificationService = inject(LocalNotificationService)
  private eventService = inject(EventService)
  private notificationService = inject(NotificationService)
  private guideService = inject(GuideService)
  private announcementService = inject(AnnouncementService)
  private hardwareDeviceService = inject(HardwareDeviceService)
  @select(['messages', 'unreadMessages'])
  private unreadMessages$!: Observable<number>

  @select(['quizzes', 'items'])
  private quizzes$!: Observable<Quiz[]>

  @select(['blog'])
  private blog$!: Observable<Blog[]>

  @select(['userSettings', 'lastSeenBlogPost'])
  private lastSeenBlogPost$!: Observable<string | undefined>

  @select(['userSettings', 'pendingBluetoothSettings'])
  private pendingBluetoothSettings$!: Observable<PendingHardwareDeviceSettings>

  @select(['notifications', 'settings', 'educationalContent'])
  public educationalContent$!: Observable<boolean | undefined>

  @select(['account', 'demoMode'])
  public demoMode$!: Observable<boolean | undefined>

  @select(['account', 'hwId'])
  private hwId$!: Observable<HardwareId>

  private t2SentDate$ = select2(s => s.account.t2SentDate)

  private t3SentDate$ = select2(s => s.account.t3SentDate)

  @select(['hwDevice', 'mac'])
  private hardwareDeviceId$!: Observable<string | undefined>

  @select(['hwDevice', 'testMeasurementDone'])
  private testMeasurementDone$!: Observable<boolean | undefined>

  @select(['hwChanges'])
  private hwChanges$!: Observable<StringMap<HardwareId>>

  @select(['latestHWDeviceFWVersion'])
  private latestFWVersion$!: Observable<FWVersion | null>

  private hardwareDevice$ = this.hardwareDeviceService.hardwareDevice$
  public fwVersion$ = this.hardwareDevice$.pipe(map(({ fwVersion }) => fwVersion))

  public badgeMessages$ = this.unreadMessages$.pipe(
    combineLatestWith(this.announcementService.announcement$),
    map(([unreadMessages, announcement]) => {
      return announcement ? 1 : 0 + unreadMessages
    }),
  )

  public badgeLearn$ = this.guideService.guideThumbnails$.pipe(
    combineLatestWith(
      this.quizzes$,
      this.blog$,
      this.lastSeenBlogPost$,
      this.educationalContent$,
      this.demoMode$,
    ),
    map(([guides, quizzes, blog, lastSeen, educationalContent, demoMode]) => {
      if (!educationalContent || demoMode) return 0

      const newGuides = guides.filter(a => a.badge)
      const newQuizzes = quizzes.filter(a => a.badge)

      const blogItem = blog[0]
      const unseenBlog = blogItem && (!lastSeen || dayjs(lastSeen).isBefore(blogItem.date))

      return newGuides.length + newQuizzes.length + (unseenBlog ? 1 : 0)
    }),
  )

  public hardwareDeviceBadge$ = this.hwId$.pipe(
    combineLatestWith(
      this.hardwareDeviceId$,
      this.testMeasurementDone$,
      this.pendingBluetoothSettings$,
      this.hardwareDeviceService.hardwareDevice$.pipe(map(hwDevice => hwDevice.batteryStatus)),
      this.latestFWVersion$,
      this.fwVersion$,
      this.t2SentDate$,
      this.t3SentDate$,
      this.hwChanges$,
    ),
    map(
      ([
        hwId,
        deviceId,
        isTestMeasurementDone,
        pendingBluetoothSettings,
        batteryStatus,
        latestFWVersion,
        currentFWVersion,
        t2SentDate,
        t3SentDate,
        hwChanges,
      ]) => {
        if (isWebApp) return
        // We check if NC has shipped the thermometer to the user but they haven't paired it yet

        const t2SentLately = t2SentDate && dayjs().diff(dayjs(t2SentDate), 'day') < 15
        const t3SentLately = t3SentDate && dayjs().diff(dayjs(t3SentDate), 'day') < 15

        const t2ChangeDate = _findKeyByValue(hwChanges, HardwareId.UEBE_THERMOMETER)
        const t3ChangeDate = _findKeyByValue(hwChanges, HardwareId.T3_THERMOMETER)

        const t2NoChangeLately =
          !t2ChangeDate || (t2ChangeDate && dayjs(t2ChangeDate) < dayjs(t2SentDate))
        const t3NoChangeLately =
          !t3ChangeDate || (t3ChangeDate && dayjs(t3ChangeDate) < dayjs(t3SentDate))

        if (
          (t2SentLately && hwId !== HardwareId.UEBE_THERMOMETER && t2NoChangeLately) ||
          (t3SentLately && hwId !== HardwareId.T3_THERMOMETER && t3NoChangeLately)
        ) {
          return true
        }

        if (!usesBluetoothDevice(hwId)) return

        if (
          batteryStatus &&
          [BatteryStatus.LOW, BatteryStatus.CRITICALLY_LOW].includes(batteryStatus)
        ) {
          return true
        }
        if (pendingBluetoothSettings && Object.keys(pendingBluetoothSettings).length) return true
        if (
          (hwId === HardwareId.T3_THERMOMETER || hwId === HardwareId.B1) &&
          !!deviceId &&
          !!currentFWVersion &&
          !!latestFWVersion &&
          latestFWVersion.version !== currentFWVersion
        ) {
          return true
        }

        return !deviceId || !isTestMeasurementDone
      },
    ),
  )

  public init(): void {
    if (isWebApp) return

    this.badgeMessages$
      .pipe(combineLatestWith(this.badgeLearn$), distinctUntilChanged())
      .subscribe(([messages, learn]) => {
        let title = ''
        let text = ''

        if (messages) {
          const messages = getState().messages
          if (!messages.unreadMessages) return

          title = tr('txt-new-message')
          text = messages.messages[0]!.msg.replace('# ', '').split(/\r?\n/g)[0]!
        } else if (learn) {
          title = tr('txt-android-notification-title')
          text = tr('txt-android-notification-body')
        }

        const value = messages + (learn ? 1 : 0) // group all badges in learn to one

        if (value) {
          void this.updateBadge(value, title, text, !!messages)
        } else {
          this.clearBadge()
        }
      })

    this.eventService.onLogout$.subscribe(() => this.clearBadge())
  }

  /**
   * 0 = denied
   * 1 = granted
   * -1 = not available
   */
  public async hasPermission(): Promise<boolean> {
    if (isAndroidApp) {
      const channels = await this.localNotificationService.listAllChannels()

      const enabled = channels.some(({ badgeEnabled }) => badgeEnabled)
      const hasNotificationsPermission = await this.notificationService.hasPermission()

      return enabled && hasNotificationsPermission
    }
    if (isIOSApp) {
      const { state } = await Badge.checkPermission()

      return state === 1
    }

    return false
  }

  public clearBadge(): void {
    if (isAndroidApp) {
      this.localNotificationService.androidBadge$.next(undefined)
    }

    if (isIOSApp) {
      Badge.clear()
    }
  }

  private async updateBadge(
    value: number,
    title: string,
    body: string,
    unreadMessages = false,
  ): Promise<void> {
    if (isAndroidApp) {
      this.localNotificationService.androidBadge$.next({
        title,
        body,
        channelId: unreadMessages ? ChannelId.NCMessages : ChannelId.NCEducational,
        extra: {
          link: unreadMessages ? ROUTES.MessagesPage : ROUTES.LearnPage,
        },
      })
    }

    if (isIOSApp) {
      Badge.set({ value })
    }
  }
}
