import { inject, Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { select } from '@app/srv/store.service'
import { NavController } from '@ionic/angular'
import { pDelay, StringMap } from '@naturalcycles/js-lib'
import {
  Achievement,
  AppTracker,
  Goal,
  HardwareId,
  RecentlyUsedHormones,
} from '@naturalcycles/shared'
import {
  BehaviorSubject,
  combineLatestWith,
  delay,
  from,
  Observable,
  Subject,
  takeUntil,
  takeWhile,
} from 'rxjs'
import { EVENT } from '../analytics/analytics.cnst'
import { AnalyticsService } from '../analytics/analytics.service'
import { contentShowTimeout } from '../cnst'
import { AddDataSource } from '../cnst/add-data.cnst'
import { ROUTES } from '../cnst/nav.cnst'
import { NavigationParams } from '../cnst/nav.params.cnst'
import { ConfettiModal, ConfettiModalProps } from '../modals/confetti/confetti.modal'
import { AddDataPageService } from '../pages/add-data/add-data.page.service'
import { AchievementsService } from './achievements.service'
import { di } from './di.service'
import { MarkdownService } from './markdown.service'
import { addNavParams } from './nav.service'
import { PartnerService } from './partner.service'
import { PopupController, Priority } from './popup.controller'
import { dispatch, getState } from './store.service'
import {
  ANALYTICS_BY_TOUR,
  ANALYTICS_BY_TOUR_AND_GOAL,
  TOUR_ROUTE_BY_STEP,
  TOUR_TOOLTIP_BY_GOAL,
  TOUR_TOOLTIP_BY_HWID,
  TourId,
  TourTooltip,
} from './tour.cnst'
import { tr } from './translation.util'

export interface TourProgress {
  id: TourId
  steps: number
  currentStep: number
  route: string
  ctaCenter?: boolean
}

export interface TourTooltipKey {
  id: TourTooltip
  key: string
}

@Injectable({ providedIn: 'root' })
export class TourService {
  private analyticsService = inject(AnalyticsService)
  private popupController = inject(PopupController)
  private markdownService = inject(MarkdownService)
  private navController = inject(NavController)
  private router = inject(Router)
  @select(['account', 'goal'])
  private goal$!: Observable<Goal | undefined>

  @select(['account', 'hwId'])
  private hwId$!: Observable<HardwareId>

  public activeTour$ = new BehaviorSubject<TourProgress | undefined>(undefined)
  private activeTour: TourProgress | undefined

  /** Triggered when a tour starts */
  public tourStarted$ = new Subject<TourId>()

  /** Triggered when a tour ends, regardless of reason */
  public tourEnded$ = new Subject<TourId>()

  /** Triggered when a tour is complete */
  public tourCompleted$ = new Subject<TourId>()

  /** Triggered when a tour is cancelled */
  public tourCancelled$ = new Subject<TourId>()

  /** Triggered when a tour is skipped */
  public tourSkipped$ = new Subject<TourId>()

  public popupTooltip$ = new Subject<TourTooltipKey>()

  private timeouts: NodeJS.Timeout[] = []

  public async startTour(id: TourId, skipPopup = false): Promise<void> {
    if (!skipPopup) {
      const startTour = await this.popupTourAlertAndStartTour(id)

      if (!startTour) {
        this.trackEvent(EVENT.TOUR_SKIP, id)

        this.onTourSkip(id)
        return
      }
    }

    this.popupController.blockPopups()

    const steps = Object.keys(TOUR_ROUTE_BY_STEP[id]).length

    this.activeTour = {
      id,
      steps,
      currentStep: 1,
      route: TOUR_ROUTE_BY_STEP[id][1],
      ctaCenter: id === TourId.DEMO,
    }

    // if moods not enabled
    if (id === TourId.RECOVERY && !getState().appSettings?.trackers[AppTracker.MOOD]) {
      this.activeTour.steps = steps - 1
    }

    // if added today box is not available
    if (id === TourId.PARTNER && !di.get(PartnerService).showAddedToday) {
      this.activeTour.steps = steps - 1
    }

    this.activeTour$.next(this.activeTour)
    this.tourStarted$.next(this.activeTour.id)

    this.trackEvent(EVENT.TOUR_START, id)

    this.activeTour$
      .pipe(
        combineLatestWith(this.goal$, this.hwId$),
        takeWhile(([tour, goal]) => !!tour && !!goal),
        takeUntil(from(this.tourEnded$)),
        delay(600), // let navigation happen before handling tour step
      )
      .subscribe(([tour, goal, hwId]) => this.handleTourStep(tour!, goal!, hwId))
  }

  public nextStep(): void {
    this.clearTimeouts()

    const tour = { ...this.activeTour! }
    const currentStep = tour.currentStep + 1

    this.activeTour = {
      ...tour,
      currentStep,
      route: TOUR_ROUTE_BY_STEP[tour.id][currentStep],
    }

    this.trackEvent(EVENT.TOUR_STEP, tour.id, { step: currentStep })

    this.activeTour$.next(this.activeTour)
  }

  public endTour(cancelled = false): void {
    this.clearTimeouts()

    const tourId = this.activeTour!.id

    if (cancelled) {
      this.tourCancelled$.next(tourId)
      this.trackEvent(EVENT.TOUR_CANCELLED, tourId)
    } else {
      this.onTourFinish(tourId)
    }

    this.popupController.unblockPopups()

    this.activeTour = undefined
    this.activeTour$.next(this.activeTour)
    this.tourEnded$.next(tourId)
  }

  public isTourActive(): boolean {
    return !!this.activeTour
  }

  private async handleTourStep(tour: TourProgress, goal: Goal, hwId: HardwareId): Promise<void> {
    const tooltips = Object.keys(TourTooltip).filter(t => t.split('_')[0] === tour.id)
    const currentSteps = tooltips.filter(t => Number(t.split('_')[1]) === tour.currentStep)

    if (tour.route) {
      await this.navigateToRoute(tour.route)

      await pDelay(contentShowTimeout * 2)
    }

    for (const [index, currentStep] of currentSteps.entries()) {
      const id = TourTooltip[currentStep as TourTooltip] as TourTooltip

      const timeout = setTimeout(() => {
        // remove first index in timeouts array when timer is triggered
        this.timeouts.splice(0, 1)

        this.popupTooltip$.next({
          id,
          key: TOUR_TOOLTIP_BY_HWID[hwId][id] || TOUR_TOOLTIP_BY_GOAL[goal][id] || id,
        })
      }, 1500 * index)

      this.timeouts.push(timeout)
    }
  }

  private onTourFinish(tour: TourId): void {
    this.tourCompleted$.next(tour)

    switch (tour) {
      case TourId.APP:
        void di.get(AchievementsService).completeAchievement(Achievement.completedAppTour)
        break
    }
  }

  private onTourSkip(tour: TourId): void {
    this.tourSkipped$.next(tour)

    switch (tour) {
      case TourId.APP:
        addNavParams({ [NavigationParams.BLOCK_NUMPAD]: true })

        void di.get(AddDataPageService).openAddData({
          source: AddDataSource.TOUR_SKIPPED,
          skipPreview: true,
          skipAnimation: true,
        })
        break
    }
  }

  private async navigateToRoute(route: string): Promise<void> {
    if (route === ROUTES.AddData) {
      await di.get(AddDataPageService).openAddData({
        source: AddDataSource.TOUR,
        skipPreview: true,
      })

      return
    }

    if (this.router.url === ROUTES.InsightsPage) {
      await this.navController.pop()
    }

    await this.navController.navigateForward(route)
  }

  private async popupTourAlertAndStartTour(tour: TourId): Promise<boolean> {
    if (
      tour === TourId.DEMO &&
      getState().account.onboardingData!.recentlyUsedHormones !== RecentlyUsedHormones.YES_STILL
    ) {
      return await this.showDemoTourAlert()
    }

    if (tour === TourId.APP) {
      return await this.showAppTourAlert()
    }

    if (tour === TourId.PARTNER) {
      return await this.showPartnerTourModal()
    }

    // tour alert will only be shown for users entering PP mode after signing up, and not when ending a pregnancy
    // that's why we show the app tour alert here
    if (tour === TourId.POSTPARTUM) {
      return await this.showAppTourAlert()
    }

    return true
  }

  private async showDemoTourAlert(): Promise<boolean> {
    this.trackEvent(EVENT.TOUR_POPUP, TourId.DEMO)

    return await new Promise(resolve => {
      void this.popupController.presentAlert(
        {
          header: tr('demo-tour-alert-title'),
          message: this.markdownService.parse(tr('demo-tour-alert-message')),
          cssClass: 'alert--attentionButtons',
          buttons: [
            {
              text: tr('tour-alert-skip-tour'),
              cssClass: 'button--outline',
              id: 'tour-alert-skip-tour',
              role: 'cancel',
              handler: () => resolve(false),
            },
            {
              text: tr('tour-alert-start-tour'),
              handler: () => resolve(true),
            },
          ],
        },
        `alert-tour-${TourId.DEMO}`,
        Priority.MEDIUM,
        3000,
      )
    })
  }

  private async showAppTourAlert(): Promise<boolean> {
    this.trackEvent(EVENT.TOUR_POPUP, TourId.APP)

    const confettiProps: ConfettiModalProps = {
      uid: 'setupComplete__page',
      title: 'app-tour-alert-title',
      body: 'app-tour-alert-message',
      image: '../assets/img/onboarding/setup-complete.svg',
      ctas: [
        {
          title: 'tour-alert-start-tour',
          uid: 'setupComplete__tour__btn',
        },
        {
          title: 'tour-alert-skip-tour',
          uid: 'setupComplete__skip__btn',
          outline: true,
        },
      ],
    }

    const modal = await this.popupController.presentModal(
      {
        component: ConfettiModal,
        componentProps: {
          ...confettiProps,
          analyticsProps: { id: 'SetupComplete' },
        },
      },
      'modal-setupComplete',
      Priority.IMMEDIATE,
    )

    return await new Promise(resolve => {
      void modal.onWillDismiss().then(({ data }) => {
        resolve(data === 'setupComplete__tour__btn')
      })
    })
  }

  private async showPartnerTourModal(): Promise<boolean> {
    const { name1 } = getState().account
    this.trackEvent(EVENT.TOUR_POPUP, TourId.PARTNER)

    const alert = await this.popupController.presentAlert(
      {
        header: tr('partner-msg-welcome-title'),
        message: tr('partner-msg-welcome-body', { partnerName: name1 }),
        buttons: [
          {
            text: tr('txt-ok'),
          },
        ],
      },
      'alert-partner-msg-welcome',
      Priority.VERY_HIGH,
      500,
    )

    dispatch('extendUserSettings', { partnerWelcomeMsgShown: true })

    await alert.onWillDismiss()

    return true
  }

  private trackEvent(event: EVENT, tour: TourId, params?: StringMap<any>): void {
    const goal = getState().account.goal!

    const analyticsId = ANALYTICS_BY_TOUR_AND_GOAL[goal]?.[tour] || ANALYTICS_BY_TOUR[tour]

    void this.analyticsService.trackEvent(event, { tour: analyticsId, ...params })
  }

  private clearTimeouts(): void {
    this.timeouts.forEach(timeout => {
      clearTimeout(timeout)
    })

    this.timeouts = []
  }
}
