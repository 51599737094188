import { Directive, HostListener, inject } from '@angular/core'
import { Platform } from '@ionic/angular'
import { NCHaptics } from '@src/typings/capacitor'

@Directive({
  selector: '[haptic]',
})
export class HapticFeedbackDirective {
  private platform = inject(Platform)

  @HostListener('click')
  onClick(): void {
    if (!this.platform.is('hybrid')) return

    void NCHaptics.impact()
  }
}
