import { Injectable } from '@angular/core'
import { ICON } from '@app/cnst/icons.cnst'
import { getState } from '@app/srv/store.service'
import { _Memo, StringMap } from '@naturalcycles/js-lib'
import { marked } from 'marked'

const CUSTOM_EMOJIS: string[] = [
  'ovulation',
  'lhtest',
  'pregtest',
  'clock',
  'ovulationGrape',
  'warning-custom',
  'warning-circle',
  'settings',
  'history',
  'aw-sleep-app',
  'aw-full-schedule',
]

const ICON_BY_EMOJI = {
  ovulation: ICON.OVULATION,
  ovulationGrape: ICON.OVULATION,
  lhtest: ICON.LH_TEST,
  pregtest: ICON.PREG_TEST,
  clock: ICON.CLOCK,
  'warning-custom': ICON.WARNING,
  'warning-circle': ICON.WARNING_CIRCLE,
  settings: ICON.SETTINGS,
  history: ICON.HISTORY,
  'aw-sleep-app': ICON.AW_SLEEP,
  'aw-full-schedule': ICON.AW_FULL_SCHEDULE,
}

// 'ovulation' => RegExp()
// eslint-disable-next-line unicorn/no-array-reduce
const EMOJI_MAP = CUSTOM_EMOJIS.reduce((r, s) => {
  r[s] = new RegExp(`:${s}:`, 'g')
  return r
}, {} as StringMap<RegExp>)

@Injectable({ providedIn: 'root' })
export class MarkdownService {
  @_Memo()
  parse(_txt: string, stripSingleP = true): string {
    const txt = this.handleCustomEmojis(_txt)

    let htmlValue = marked.parse(txt) as string

    // how many paragraphs?
    const count = (htmlValue.match(/<p>/g) || []).length

    // if there is only one paragraph, strip it - unless we dont want to
    if (count === 1 && htmlValue.startsWith('<p>') && stripSingleP) {
      htmlValue = htmlValue.replace(/^<p>(.+)<\/p>\s*$/g, '$1')
    }

    return '<md>' + htmlValue + '</md>'
  }

  public getGlossaryItem(id: string): string | undefined {
    const item = getState().glossary.items.find(item => item.id === id)
    if (!item) return undefined

    return this.parse(`# ${item.title}\n\n ${item.body}`)
  }

  private handleCustomEmojis(txt: string): string {
    let r = txt

    Object.keys(EMOJI_MAP).forEach(k => {
      const s = `:${k}:`
      const iconSrc = ICON_BY_EMOJI[k]
      if (iconSrc && r.match(EMOJI_MAP[k]!)) {
        const a = r.substr(0, r.indexOf(s))
        const b = r.substr(r.indexOf(s) + s.length, r.length - r.indexOf(s))
        const icon = `<ion-icon class="ncEmoji ${k}Icon" src="${iconSrc}"></ion-icon>`
        r = a + icon + b
      }
    })

    return r
  }
}

export const markdownService = new MarkdownService()
