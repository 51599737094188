import { NgModule } from '@angular/core'
import { AppleAuthDirective } from '@app/dir/apple-auth/apple-auth.directive'
import { DatetimePickerDirective } from '@app/dir/datetime-picker/datetime-picker.directive'
import { GhostDirective } from '@app/dir/ghost/ghost.directive'
import { GoogleAuthDirective } from '@app/dir/google-auth/google-auth.directive'
import { HapticFeedbackDirective } from '@app/dir/haptic-directive/haptic-feedback.directive'
import { KeyboardButtonDirective } from '@app/dir/keyboard-button/keyboard-button.directive'
import { KeyboardOpenDirective } from '@app/dir/keyboard-open/keyboard-open.directive'
import { LoaderDirective } from '@app/dir/loader/loader.directive'
import { MarkdownDirective } from '@app/dir/markdown/markdown.directive'
import { ScrollableDirective } from '@app/dir/scrollable/scrollable.directive'
import { SwipeDirective } from '@app/dir/swipe/swipe.directive'
import { TextFitDirective } from '@app/dir/text-fit/text-fit.directive'
import { TooltipDirective } from '@app/dir/tooltip/tooltip.directive'
import { FormFocusDirective } from './form-focus/form-focus.directive'
import { LongPressDirective } from './long-press/long-press.directive'
import { ScrollOnExpandDirective } from './scroll-on-expand/scroll-on-expand.directive'
import { TourDirective } from './tour/tour.directive'

@NgModule({
  declarations: [
    TextFitDirective,
    MarkdownDirective,
    GhostDirective,
    KeyboardButtonDirective,
    LoaderDirective,
    TooltipDirective,
    SwipeDirective,
    HapticFeedbackDirective,
    ScrollableDirective,
    GoogleAuthDirective,
    AppleAuthDirective,
    KeyboardOpenDirective,
    DatetimePickerDirective,
    ScrollOnExpandDirective,
    FormFocusDirective,
    LongPressDirective,
    TourDirective,
  ],
  imports: [],
  exports: [
    TextFitDirective,
    MarkdownDirective,
    GhostDirective,
    KeyboardButtonDirective,
    LoaderDirective,
    TooltipDirective,
    SwipeDirective,
    HapticFeedbackDirective,
    ScrollableDirective,
    GoogleAuthDirective,
    AppleAuthDirective,
    KeyboardOpenDirective,
    DatetimePickerDirective,
    ScrollOnExpandDirective,
    FormFocusDirective,
    LongPressDirective,
    TourDirective,
  ],
})
export class DirectivesModule {}
