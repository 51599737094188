import { NgModule } from '@angular/core'
import { InfoButtonComponent } from '@app/cmp/info-button/info-button.component'
import { ScrollFooterComponentModule } from '@app/cmp/scroll-footer/scroll-footer.component.module'
import { DirectivesModule } from './dir/directives.module'
import { PipesModule } from './pipes/pipes.module'

@NgModule({
  imports: [DirectivesModule, PipesModule, InfoButtonComponent, ScrollFooterComponentModule],
  exports: [DirectivesModule, PipesModule, InfoButtonComponent, ScrollFooterComponentModule],
})
export class SharedModule {}
