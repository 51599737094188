import { inject, Injectable } from '@angular/core'
import { AnalyticsService } from '@app/analytics/analytics.service'
import { isHardwareSupportedPlatform } from '@app/cnst/hardware.cnst'
import { decorate, ErrorHandlerType, LoaderType } from '@app/decorators/decorators'
import { api } from '@app/srv/api.service'
import { getState, select } from '@app/srv/store.service'
import { logUtil } from '@app/util/log.util'
import { BackendResponseFMResp, HardwareId, OuraState, Product } from '@naturalcycles/shared'
import { env } from '@src/environments/environment'
import { firstValueFrom, Observable } from 'rxjs'
import { combineLatestWith, map } from 'rxjs/operators'
import { LINK } from '../cnst/links.cnst'
import { isNativeApp } from '../cnst/userDevice.cnst'
import { CtaModal, CtaModalInput } from '../modals/cta-modal/cta.modal'
import { OuraAuthSuccessModal } from '../pages/oura/oura-auth-success/oura-auth-success.modal'
import { EventService } from './event.service'
import { InAppBrowserService } from './inappbrowser.service'
import { PopupController, Priority } from './popup.controller'

const TRY_AGAIN = 'try-again'

@Injectable({ providedIn: 'root' })
export class OuraService {
  private analyticsService = inject(AnalyticsService)
  private popupController = inject(PopupController)
  private eventService = inject(EventService)
  private inAppBrowserService = inject(InAppBrowserService)
  @select(['product', 'items'])
  private items$!: Observable<Product[]>

  @select(['account', 'hwId'])
  public hwId$!: Observable<HardwareId>

  public hasOura$ = this.hwId$.pipe(map(hwId => hwId === HardwareId.OURA))

  public isOuraRingAvailable$ = this.items$.pipe(
    map(
      products =>
        products.some(product => product.hwId === HardwareId.OURA) && isHardwareSupportedPlatform,
    ),
  )

  public isOuraRingPromoAvailable$ = this.hasOura$.pipe(
    combineLatestWith(this.isOuraRingAvailable$),
    map(([hasOura, isOuraRingAvailable]) => !hasOura && isOuraRingAvailable),
  )

  public async auth(): Promise<OuraState | undefined> {
    const token = await this.getOuraAuthToken()

    const url = `${env.prod ? LINK.OURA_AUTH : LINK.OURA_AUTH_INTERNAL}&state=${token}`

    void this.inAppBrowserService.open(url)

    await this.authCallback()

    return await this.getOuraState()
  }

  private async authCallback(): Promise<void> {
    if (isNativeApp) {
      await firstValueFrom(this.eventService.inAppBrowserClosed$)
    } else {
      await firstValueFrom(this.eventService.onResume$)
    }
  }

  private async getOuraAuthToken(): Promise<string> {
    const { token } = await api.put<{ token: string }>(`oura/createAuthToken`)

    return token
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
    messageKey: 'blocking-loader-oura-sync',
  })
  private async getOuraState(): Promise<OuraState | undefined> {
    return await api
      .get<BackendResponseFMResp>(`oura/state`, { timeoutSeconds: 60 }) // request can take long time for user with lots of data
      .then(r => r.backendResponse.oura)
      .catch(err => {
        logUtil.error(`Error getting Oura status: ${err}`)
        return { authorized: false } as OuraState
      })
  }

  public getDiscountLink(): string {
    const { account } = getState()
    const viewType = this.analyticsService.getViewType()
    return `${env.apiUrl}/oura/discountLink/${account.personalId}?source=${viewType}`
  }

  public async openAuthFailedModal(): Promise<{ tryAgain: boolean }> {
    const componentProps: CtaModalInput = {
      title: 'oura-onboarding-auth-fail-title',
      body: 'oura-onboarding-auth-fail-body',
      ctas: [
        {
          title: 'txt-try-again',
          id: TRY_AGAIN,
        },
        {
          title: 'txt-skip',
          id: 'skip',
          outline: true,
        },
      ],
    }

    const modal = await this.popupController.presentModal(
      {
        component: CtaModal,
        componentProps,
      },
      'modal-oura-auth-fail',
      Priority.IMMEDIATE,
    )

    const { data } = await modal.onDidDismiss()

    return {
      tryAgain: data === TRY_AGAIN,
    }
  }

  public async openAuthSuccessModal(confetti?: boolean): Promise<void> {
    const modal = await this.popupController.presentModal(
      {
        component: OuraAuthSuccessModal,
        componentProps: {
          confetti,
        },
      },
      'modal-oura-auth-success',
      Priority.IMMEDIATE,
    )

    await modal.onDidDismiss()
  }
}
