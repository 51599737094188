import { inject, Injectable } from '@angular/core'
import { EVENT } from '@app/analytics/analytics.cnst'
import { AnalyticsService } from '@app/analytics/analytics.service'
import { snackbarEnterAnimation, snackbarLeaveAnimation } from '@app/animations/snackbar-animation'
import { AppAction } from '@app/cnst/action.cnst'
import { LINK } from '@app/cnst/links.cnst'
import { runOutsideAngular } from '@app/srv/di.service'
import { InAppBrowserService } from '@app/srv/inappbrowser.service'
import { PopupController, Priority } from '@app/srv/popup.controller'
import { dispatch, getState } from '@app/srv/store.service'
import { tr } from '@app/srv/translation.util'
import { StringMap } from '@naturalcycles/js-lib'
import { EventService } from './event.service'

export interface SnackbarOptions {
  id: string
  message: string
  messageParams?: () => StringMap
  color?: string
  textColor?: 'dark' | 'light'
  buttonColor?: string
  button?: SnackbarButton
  duration?: number
  stateProperty?: string
  confetti?: boolean
  priority?: Priority
  delay?: number
  cssClass?: string[]
}

export interface SnackbarButton {
  label: string
  action?: SnackbarAction
  role?: 'cancel' | string
}

interface SnackbarAction {
  type: AppAction
  path: string | LINK
}

@Injectable({ providedIn: 'root' })
export class SnackbarService {
  private popupController = inject(PopupController)
  private inAppBrowserService = inject(InAppBrowserService)
  private analyticsService = inject(AnalyticsService)
  private eventService = inject(EventService)

  public showSnackbar(options: SnackbarOptions): void {
    const { userSettings } = getState()
    if (options.stateProperty && userSettings[options.stateProperty]) return

    void this.openSnackbar(options)

    if (!options.stateProperty) return

    dispatch('extendUserSettings', {
      [options.stateProperty]: true,
    })
  }

  private async openSnackbar(options: SnackbarOptions): Promise<void> {
    void this.popupController.presentSnackbar(
      {
        message: tr(options.message, options.messageParams?.apply(this)),
        color: options.color || 'lightGray',
        duration: options.duration || 5000,
        cssClass: [
          'snackbar',
          `snackbar--${options.textColor}`,
          `snackbar__button--${options.buttonColor}`,
          ...(options.cssClass || []),
        ],
        mode: 'md',
        enterAnimation: snackbarEnterAnimation,
        leaveAnimation: snackbarLeaveAnimation,
        buttons: options.button
          ? [
              {
                text: tr(options.button.label),
                side: 'end',
                handler: () => this.handleButtonClick(options.button!.action),
              },
            ]
          : [],
      },
      options.id,
      options.priority,
      options.delay,
    )

    if (options.confetti) {
      const { confettiService } = await import('@app/srv/confetti.service')
      runOutsideAngular(() => void confettiService.addConfetti())
    }

    void this.analyticsService.trackEvent(EVENT.SNACKBAR, { id: options.id })
  }

  private handleButtonClick(action: SnackbarAction | undefined): void {
    if (!action) return

    switch (action.type) {
      case AppAction.INTERNAL_LINK:
        this.eventService.internalLinkClick$.next(action.path)
        break
      case AppAction.EXTERNAL_LINK:
        void this.inAppBrowserService.open(action.path)
        break
      case AppAction.INFO_MODAL:
        this.eventService.openInfoModal$.next(action.path)
        break
    }
  }
}
