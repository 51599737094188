import { Directive, inject, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { accountSharedUtil } from '@naturalcycles/shared'
import { isAb251Test } from '@src/ab251/ab251'
import { EVENT } from '@src/app/analytics/analytics.cnst'
import { AnalyticsService } from '@src/app/analytics/analytics.service'
import { ROUTES } from '@src/app/cnst/nav.cnst'
import { NavigationParams } from '@src/app/cnst/nav.params.cnst'
import { baseImgLink, quizParentSlug } from '@src/app/cnst/quiz.cnst'
import { QuizPage } from '@src/app/cnst/quiz-pages.cnst'
import { BasePage } from '@src/app/pages/base.page'
import { QuizService } from '@src/app/srv/quiz/quiz.service'
import { getState } from '@src/app/srv/store.service'
import { isE2e } from '@src/environments/env.util'
import { AuthPageMode } from '@src/typings/auth'

@Directive()
export abstract class QuizBasePage extends BasePage implements OnInit {
  public className = 'QuizBasePage'
  public baseImgLink = baseImgLink
  public page: QuizPage | null = null
  public selfHidingModalTimeout = isE2e ? 1000 : 2000
  public analyticsEventName = EVENT.QUIZ_ANSWER
  public quizService = inject(QuizService)
  public analyticsService = inject(AnalyticsService)
  public router = inject(Router)
  public route = inject(ActivatedRoute)

  public ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.page = data['page'] as QuizPage
    })
  }

  async navigateForwardInQuiz(): Promise<void> {
    const { quiz, sessionId, account } = getState()

    if (!this.page) {
      console.warn('[QUIZ dev] Route data missing')
      return
    }

    const navigateTo = this.quizService.getEngine().getNextPageInFlow(this.page, quiz.flow)

    // If the only remaining page is this one, we consider the quiz completed
    if (navigateTo === this.page) {
      // AB251
      const isEmailHidden = account.email && accountSharedUtil.isEmailHidden(account.email)
      if (sessionId && !isEmailHidden && isAb251Test()) {
        void this.navigateForward(ROUTES.VerifyEmailPage)
        return
      }

      void this.navigateForward(
        ROUTES.AuthPage,
        {},
        {
          state: {
            [NavigationParams.AUTH_PAGE_MODE]: AuthPageMode.Signup,
          },
        },
      )
      return
    }

    await super.navigateForward(`${quizParentSlug}/${navigateTo}`)
  }
}
