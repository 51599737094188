import { NgModule } from '@angular/core'
import { DatePipe } from '@app/pipes/date.pipe'
import { DiscountPipe } from '@app/pipes/discount.pipe'
import { MdPipe } from '@app/pipes/md.pipe'
import { PricePipe } from '@app/pipes/price.pipe'
import { SafePipe } from '@app/pipes/safe.pipe'
import { TemperaturePipe } from '@app/pipes/temperature.pipe'
import { TrPipe } from '@app/pipes/tr.pipe'
import { CyclerStatusPipe } from '@src/app/pipes/cyclerStatus.pipe'
import { EnumeratePipe } from './enumerate.pipe'
import { KeysPipe } from './keys.pipe'
import { MarkdownPipe } from './markdown.pipe'
import { StripTagsPipe } from './strip-tags.pipe'

@NgModule({
  declarations: [
    MarkdownPipe,
    StripTagsPipe,
    KeysPipe,
    PricePipe,
    DatePipe,
    SafePipe,
    DiscountPipe,
    TrPipe,
    MdPipe,
    TemperaturePipe,
    CyclerStatusPipe,
    EnumeratePipe,
  ],
  imports: [],
  exports: [
    MarkdownPipe,
    StripTagsPipe,
    KeysPipe,
    PricePipe,
    DatePipe,
    SafePipe,
    DiscountPipe,
    TrPipe,
    MdPipe,
    TemperaturePipe,
    CyclerStatusPipe,
    EnumeratePipe,
  ],
})
export class PipesModule {}
