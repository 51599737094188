import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { CyclerStatusComponent } from '@app/cmp/cycler-status/cycler-status.component'
import { SharedModule } from '@app/shared.module'
import { IonicModule } from '@ionic/angular'
import { MessageModal } from './message.modal'

@NgModule({
  declarations: [MessageModal],
  imports: [IonicModule, SharedModule, CyclerStatusComponent, CommonModule],
  exports: [MessageModal],
})
export class MessageModalModule {}
