import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core'
import { BaseModal } from '@app/pages/base.modal'
import { GestureController } from '@ionic/angular'

@Component({
  selector: 'app-snackbar-modal',
  templateUrl: './snackbar.modal.html',
  styleUrls: ['./snackbar.modal.scss'],
})
export class SnackbarModal extends BaseModal implements AfterViewInit, OnDestroy {
  private gestureCtrl = inject(GestureController)
  className = 'SnackbarModal'

  @ViewChild('snackbar', { read: ElementRef })
  element!: ElementRef<HTMLElement>

  @Input()
  public duration = 5000

  @Input()
  public text!: string

  @Input()
  public buttonLabel?: string

  @Input()
  public imgSrc?: string

  @Input()
  public uid?: string

  @Input()
  public swipeToDismiss?: boolean

  private dismissTimeout?: NodeJS.Timeout

  ngAfterViewInit(): void {
    this.dismissTimeout = setTimeout(() => this.dismissModal(), this.duration)

    if (this.swipeToDismiss && this.element.nativeElement) {
      const swipeGesture = this.gestureCtrl.create({
        el: this.element.nativeElement,
        threshold: 1,
        maxAngle: 95,
        gestureName: 'swipeGesture',
        onEnd: e => {
          if (e.velocityY > 0.075 && e.deltaY > 80) {
            void this.dismissModal()
          }
        },
      })
      swipeGesture.enable(true)
    }
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy()

    if (!this.dismissTimeout) return
    clearTimeout(this.dismissTimeout)
  }

  public onButtonClick(): void {
    void this.dismissModal(true)
  }
}
