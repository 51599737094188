import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { SharedModule } from '@app/shared.module'
import { IonicModule } from '@ionic/angular'
import { DeviationSleepModal } from './deviation-sleep.modal'

@NgModule({
  declarations: [DeviationSleepModal],
  imports: [IonicModule, CommonModule, SharedModule],
  exports: [DeviationSleepModal],
})
export class DeviationSleepModalModule {}
