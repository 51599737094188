import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  inject,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core'
import { TooltipService } from '@app/srv/tooltip.service'
import { DomController } from '@ionic/angular'
import { StringMap } from '@naturalcycles/js-lib'

@Directive({
  selector: '[tooltip]',
})
export class TooltipDirective implements AfterViewInit, OnChanges {
  private renderer = inject(Renderer2)
  private dom = inject(DomController)
  protected elementRef = inject(ElementRef)
  private tooltipService = inject(TooltipService)
  @Input()
  tooltip?: string

  @Input()
  tooltipParams?: StringMap

  @Input()
  tooltipOpen?: boolean

  @Input()
  tooltipPosition?: 'top' | 'bottom'

  @Input()
  tooltipSize?: 'small' | 'large'

  @Input()
  tooltipColor?: string

  ngAfterViewInit(): void {
    if (!this.tooltip || !this.tooltipOpen) return

    void this.createTooltip(this.tooltip)
  }

  @HostListener('change')
  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (!changes['tooltip']) return

    if (!changes['tooltipOpen']) return

    if (
      changes['tooltipOpen'].previousValue === false &&
      changes['tooltipOpen'].currentValue === true &&
      changes['tooltip'].currentValue
    ) {
      void this.createTooltip(changes['tooltip'].currentValue)
    }
  }

  @HostListener('click', ['$event'])
  async click(event: MouseEvent): Promise<void> {
    if (!this.tooltip || !event.pageX || this.tooltipOpen) return

    void this.createTooltip(this.tooltip, event.pageX, false)
  }

  private createTooltip(key: string, positionX?: number, autoOpen = true): void {
    this.dom.read(async () => {
      const rect = this.elementRef.nativeElement.getBoundingClientRect()

      const offsetX = rect.width / 2 + rect.x

      const container = this.tooltipService.getContainer(this.elementRef.nativeElement)

      const appOffset = document.getElementById('root_nav')!.getBoundingClientRect().left

      const tooltip = await this.tooltipService.createTooltip(
        container,
        this.elementRef.nativeElement,
        (positionX || offsetX) - appOffset,
        key,
        this.tooltipParams,
        this.tooltipPosition,
        this.tooltipSize,
        this.tooltipColor,
      )

      this.dom.write(() => {
        void this.tooltipService
          .renderTooltip(tooltip, this.renderer, autoOpen ? 3000 : 0)
          .then(() => (this.tooltipOpen = false))
        this.tooltipOpen = true
      })
    })
  }
}
