import { inject, Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { EVENT } from '@app/analytics/analytics.cnst'
import { AnalyticsService } from '@app/analytics/analytics.service'
import { SNACKBAR } from '@app/cnst/snackbars.cnst'
import { isAndroidApp, isIOSApp, isWebApp } from '@app/cnst/userDevice.cnst'
import { tryCatch } from '@app/decorators/tryCatch.decorator'
import { select2 } from '@app/srv/store.service'
import { logUtil } from '@app/util/log.util'
import {
  dataViewToNumbers,
  numbersToDataView,
  numberToUUID,
  ScanMode,
  ScanResult,
} from '@capacitor-community/bluetooth-le'
import { localTime, StringMap, UnixTimestampNumber } from '@naturalcycles/js-lib'
import { HardwareId } from '@naturalcycles/shared'
import { dayjs } from '@naturalcycles/time-lib'
import { env } from '@src/environments/environment'
import { BluetoothClient, NativeSettings } from '@src/typings/capacitor'
import { BehaviorSubject, Subject, Subscription } from 'rxjs'
import { combineLatestWith, distinctUntilChanged, first, map, takeWhile } from 'rxjs/operators'
import { usesBluetoothDevice } from '../cnst/hardware.cnst'
import { distinctUntilDeeplyChanged } from '../util/distinctUntilDeeplyChanged'
import { NavService } from './nav.service'
import { SnackbarService } from './snackbar.service'
import { getState } from './store.service'
import { T3_SERVICE_UUID } from './t3.cnst'

export enum BluetoothStatus {
  UNPAIRED = 'unpaired',
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected',
  CONNECTING = 'connecting',
  BLUETOOTH_OFF = 'bluetoothOff',
  SCANNING = 'scanning',
  FIRMWARE_UPDATE = 'firmware',
}

export interface SyncedTemperature {
  temperature: number
  timestamp: UnixTimestampNumber
  successMsgId?: number
  temperatureRawCelsius?: number
}

interface BluetoothConfig {
  hwId: HardwareId
  serviceUuid: string
  supportedDeviceNamePrefixes?: string[]
}

export const CONFIG_BY_HWID: StringMap<BluetoothConfig> = {
  [HardwareId.UEBE_THERMOMETER]: {
    hwId: HardwareId.UEBE_THERMOMETER,
    serviceUuid: '0000fff0-0000-1000-8000-00805f9b34fb',
    supportedDeviceNamePrefixes: [
      'mySense',
      'NC Thermometer1',
      'NC Thermometer2',
      'NC Thermometer (Gen2)',
    ],
  },
  [HardwareId.T3_THERMOMETER]: {
    hwId: HardwareId.T3_THERMOMETER,
    serviceUuid: T3_SERVICE_UUID.DEVICE,
    supportedDeviceNamePrefixes: ['TC'],
  },
}

const BT_CONFIG: () => BluetoothConfig = () => {
  const hwId = getState().account.hwId

  if (CONFIG_BY_HWID[hwId]) return CONFIG_BY_HWID[hwId]!

  logUtil.error(`Trying to get BluetoothConfig for hwId ${hwId}`)

  return {
    hwId,
    serviceUuid: numberToUUID(0),
  }
}

@Injectable({ providedIn: 'root' })
export class BluetoothService {
  private analyticsService = inject(AnalyticsService)
  private snackbarService = inject(SnackbarService)
  private navService = inject(NavService)
  private router = inject(Router)

  public logs$ = new BehaviorSubject<string[]>([])
  public temperatures$ = new Subject<SyncedTemperature[]>()
  public bluetoothEnabled$ = new BehaviorSubject<boolean | undefined>(undefined)
  public connected$ = new BehaviorSubject<string | false>(false)
  public bluetoothStatus$ = new BehaviorSubject<BluetoothStatus | undefined>(undefined)
  public firmwareUpdateOngoing$ = new BehaviorSubject<boolean>(false)
  public firmwareUpdateFailed$ = new Subject<boolean>()
  public inPairingFlow$ = new BehaviorSubject<boolean>(false)

  private scanning$ = new BehaviorSubject<boolean>(false)
  private connecting$ = new BehaviorSubject<boolean>(false)
  private initializedFrom$ = new Subject<string>()

  private hwId$ = select2(s => s.account.hwId)
  private hardwareDeviceId$ = select2(s => s.hwDevice?.mac)

  private subscriptions: Subscription[] = []

  public init(): void {
    this.hwId$.pipe(combineLatestWith(this.inPairingFlow$)).subscribe(([hwId, inPairingFlow]) => {
      this.resetSubscriptions()

      if (!inPairingFlow && !usesBluetoothDevice(hwId)) return

      this.setupSubscriptions()
    })
  }

  private setupSubscriptions(): void {
    this.subscriptions.push(
      // bluetooth enabled notifications
      this.initializedFrom$.subscribe(async source => {
        void BluetoothClient.startEnabledNotifications(enabled => {
          this.log(`Enabled changed: ${enabled}`)
          this.bluetoothEnabled$.next(enabled)
          this.analyticsService.trackEvent(EVENT.BLUETOOTH_ENABLED, { enabled })
        })

        const enabled = await this.isBluetoothEnabled()
        this.bluetoothEnabled$.next(enabled)
        this.analyticsService.trackEvent(EVENT.BLUETOOTH_ENABLED, { enabled, source })
      }),

      // temperatures
      this.temperatures$.subscribe(temperatures => {
        temperatures.forEach(temperature => {
          this.analyticsService.trackEvent(EVENT.BLUETOOTH_TEMPERATURE_SYNCED, {
            ...temperature,
            temperatureMeasuredTimestamp: dayjs(temperature.timestamp * 1000)
              .utc()
              .toPretty(),
            temperatureSyncedTimestamp: dayjs().utc().toPretty(),
          })
        })
      }),

      // bluetooth status
      this.hardwareDeviceId$
        .pipe(
          combineLatestWith(
            this.bluetoothEnabled$,
            this.scanning$,
            this.connecting$,
            this.connected$,
            this.firmwareUpdateOngoing$,
          ),
          distinctUntilDeeplyChanged(),
          map(([id, bluetoothEnabled, scanning, connecting, connected, firmwareUpdateOngoing]) => {
            if (bluetoothEnabled === false) return BluetoothStatus.BLUETOOTH_OFF // check explicitly for false - undefined means we dont know yet
            if (firmwareUpdateOngoing) return BluetoothStatus.FIRMWARE_UPDATE
            if (connected) return BluetoothStatus.CONNECTED
            if (connecting) return BluetoothStatus.CONNECTING
            if (scanning) return BluetoothStatus.SCANNING
            if (!id) return BluetoothStatus.UNPAIRED

            return BluetoothStatus.DISCONNECTED
          }),
          distinctUntilChanged(),
        )
        .subscribe(status => this.bluetoothStatus$.next(status)),
    )
  }

  private resetSubscriptions(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe())
    this.subscriptions.length = 0 // this clears the array
    this.bluetoothStatus$.next(undefined)
  }

  @tryCatch({
    alert: false,
    onError: error => {
      logUtil.error(`[ble][error] initBluetooth ${error.message}`)
    },
  })
  public async initBluetooth(source: string): Promise<boolean> {
    if (isWebApp && !env.mockBluetooth) return false
    /*
     * Triggers enable bluetooth dialog and permission dialogs automatically (iOS only)
     * awaits permissions but not bluetooth settings
     */
    return await new Promise(resolve => {
      BluetoothClient.initialize({ androidNeverForLocation: true })
        .then(async () => {
          this.log(`Bluetooth initialized`)
          logUtil.log('[BLE] init; should be all good')

          this.initializedFrom$.next(source)

          resolve(true)
        })
        .catch(err => {
          logUtil.log('[BLE] ERROR init', err)

          this.bluetoothEnabled$.next(false)

          resolve(false)
        })
    })
  }

  private async isBluetoothEnabled(): Promise<boolean> {
    if (isWebApp && !env.mockBluetooth) return false
    return await BluetoothClient.isEnabled()
  }

  public async enableBluetooth(showSnackbar = true): Promise<boolean> {
    if (isWebApp) {
      if (env.mockBluetooth) return true

      this.snackbarService.showSnackbar(SNACKBAR.UEBE_BLUETOOTH_OFF)

      return false
    }
    if (isIOSApp) {
      if (showSnackbar) this.snackbarService.showSnackbar(SNACKBAR.UEBE_BLUETOOTH_OFF)

      return false
    }

    await NativeSettings.enableBluetooth()

    return await new Promise(resolve => {
      this.bluetoothEnabled$.pipe(first(Boolean)).subscribe(enabled => resolve(enabled))
    })
  }

  public async ensureBluetoothEnabled(showSnackbar = false): Promise<boolean> {
    let enabled = await this.isBluetoothEnabled()
    if (enabled) return true

    enabled = await this.enableBluetooth(showSnackbar)
    if (enabled) return true

    // Listen to bluetooth enabled changes if dialog dismissed
    return await new Promise(resolve => {
      this.bluetoothEnabled$.pipe(first(Boolean)).subscribe(enabled => resolve(enabled))
    })
  }

  public async ensureBluetoothScanning(): Promise<boolean> {
    const status = this.bluetoothStatus$.value

    if (status === BluetoothStatus.SCANNING) return true

    if (status === BluetoothStatus.BLUETOOTH_OFF) {
      await this.ensureBluetoothEnabled(true)
    }

    // Listen to bluetooth status changes until it starts searching
    return await new Promise(resolve => {
      this.bluetoothStatus$
        .pipe(takeWhile(status => status !== BluetoothStatus.SCANNING, true))
        .subscribe(status => resolve(status === BluetoothStatus.SCANNING))
    })
  }

  public async scanAndPair(hwId: HardwareId): Promise<string | false> {
    const config = CONFIG_BY_HWID[hwId]
    if (!config) return false

    // biome-ignore lint/suspicious/noAsyncPromiseExecutor: ok
    return await new Promise(async resolve => {
      this.analyticsService.trackEvent(EVENT.BLUETOOTH_SCANNING, { source: 'Pairing' })

      await this.startScan(config, async ({ device }) => {
        const { deviceId } = device

        this.analyticsService.trackEvent(EVENT.BLUETOOTH_CONNECTION_ATTEMPT, { ...device })
        const connected = await this.connectDevice(deviceId, config)
        if (connected) {
          return resolve(connected && deviceId)
        }

        await this.scanAndPair(hwId)
      })
    })
  }

  /**
   * @param pairedDeviceName is meant to be passed only for recurring calls. Avoid passing it for the first time.
   *
   * More detailed explanation: there is difference between iOS and Android deviceId value.
   * On Android it is actual MAC address, on iOS it is some UUID value which might change so we expect to have mismatches sometimes.
   * While T2 name is generic, every T3 has unique name so we might use it to check whether we found the correct device.
   */
  public async scanAndConnect(pairedDeviceId: string): Promise<void> {
    if (!pairedDeviceId) return this.log('Trying to scan with missing deviceId')

    const initialized = await this.initBluetooth('scanAndConnect')

    if (!initialized) return

    await this.ensureBluetoothEnabled()

    const config = BT_CONFIG()

    this.analyticsService.trackEvent(EVENT.BLUETOOTH_SCANNING, {
      source: 'Connecting',
      deviceId: pairedDeviceId,
    })

    void this.startScan(config, async ({ device }) => {
      const { deviceId } = device

      // only connect to the current paired device
      if (pairedDeviceId && deviceId !== pairedDeviceId) return

      this.analyticsService.trackEvent(EVENT.BLUETOOTH_CONNECTION_ATTEMPT, { ...device })

      void this.connectDevice(deviceId, config)
    })
  }

  @tryCatch({
    alert: false,
    onError: error => {
      logUtil.error(`[ble][error] disconnect ${error.message}`)
    },
  })
  public async disconnect(deviceId: string): Promise<void> {
    if (this.connected$.value) await BluetoothClient.disconnect(deviceId)
  }

  private onDisconnect(): void {
    this.connected$.next(false)

    this.analyticsService.trackEvent(EVENT.BLUETOOTH_DISCONNECT)

    this.log('Disconnected')
  }

  @tryCatch({
    alert: false,
    onError: error => {
      logUtil.error(`[ble][error] stopScan ${error.message}`)
    },
  })
  public async stopScan(reason: string): Promise<void> {
    // not currently scanning
    if (!this.scanning$.value) return

    this.log(`Stop scanning because: ${reason}`)

    this.analyticsService.trackEvent(EVENT.BLUETOOTH_SCANNING_STOPPED, { reason })

    await BluetoothClient.stopLEScan()

    this.scanning$.next(false)
  }

  /*
   * iOS will throw if called without startScan
   */
  @tryCatch({
    alert: false,
    onError: error => {
      logUtil.error(`[ble][error] connectDevice ${error.message}`)
    },
  })
  private async connectDevice(deviceId: string, config: BluetoothConfig): Promise<boolean> {
    this.connecting$.next(true)

    await this.stopScan('Thermometer was found')

    const connected = await BluetoothClient.connect(deviceId, () => this.onDisconnect(), {
      timeout: 15_000,
    }).catch(err => {
      this.analyticsService.trackEvent(EVENT.BLUETOOTH_CONNECTION_ERROR, { error: err?.message })
      logUtil.error(`[BLE] connect ${err?.message}`)
      return false
    })

    // if we are sure we are not connected due to timeout
    if (connected === false) {
      this.connected$.next(false)
      this.connecting$.next(false)
      return false
    }

    const connectedDevices = await BluetoothClient.getConnectedDevices([config.serviceUuid])
    const isConnected = connectedDevices.some(device => device.deviceId === deviceId)

    this.connected$.next(isConnected ? deviceId : false)
    this.connecting$.next(false)

    if (isConnected) {
      this.log(`Connected to: ${deviceId}`)
      const page = this.navService.getCurrentComponent()

      this.analyticsService.trackEvent(EVENT.BLUETOOTH_CONNECT, { page, route: this.router.url })
    }

    return isConnected
  }

  @tryCatch({
    alert: false,
    onError: error => {
      logUtil.error(`[ble][startScan] read ${error.message}`)
    },
  })
  private async startScan(
    config: BluetoothConfig,
    callback: (result: ScanResult) => void,
  ): Promise<void> {
    this.scanning$.next(true)

    this.log(`Start scanning`)

    let isDeviceFound = false

    await BluetoothClient.requestLEScan(
      {
        scanMode: ScanMode.SCAN_MODE_LOW_LATENCY,
        services: [config.serviceUuid],
        allowDuplicates: true,
      },
      async result => {
        this.analyticsService.trackEvent(EVENT.BLUETOOTH_SCANNING_RESULT, { ...result.device })

        // Skip callback for duplicates
        if (isDeviceFound) return

        this.log(JSON.stringify(result.device))

        if (config.supportedDeviceNamePrefixes) {
          const { name } = result.device

          if (
            !name ||
            !config.supportedDeviceNamePrefixes.some(prefix => name?.startsWith(prefix))
          ) {
            return
          }
        }

        isDeviceFound = true

        callback(result)

        this.analyticsService.trackEvent(EVENT.BLUETOOTH_DEVICE_FOUND, { ...result.device })
      },
    ).catch(err => {
      logUtil.log('[BLE] ERROR startScan', err.message)

      if (err.message.startsWith('Already scanning')) {
        void this.startScan(config, callback)
      }
    })
  }

  /**
   * Only to be called from Bluetooth Device Services
   */
  public async write(
    deviceId: string,
    service: string,
    characteristic: string,
    value: number[],
    description?: string,
  ): Promise<void> {
    this.log(
      `Writing to: ${deviceId}; Service: ${service}; Characteristic: ${characteristic}; Data: ${value}`,
    )
    const dataView = numbersToDataView(value)

    const eventBody = { deviceId, service, characteristic, value, description }

    if (isAndroidApp) {
      return await BluetoothClient.writeWithoutResponse(deviceId, service, characteristic, dataView)
        .then(() => {
          this.analyticsService.trackEvent(EVENT.BLUETOOTH_WRITE, { ...eventBody, failed: false })
        })
        .catch(err => {
          logUtil.error(`[ble][error] write ${err.message}`)

          this.analyticsService.trackEvent(EVENT.BLUETOOTH_WRITE, {
            ...eventBody,
            failed: true,
            failReason: err.message,
          })
        })
    }

    return await BluetoothClient.write(deviceId, service, characteristic, dataView)
      .then(() => {
        this.analyticsService.trackEvent(EVENT.BLUETOOTH_WRITE, { ...eventBody, failed: false })
      })
      .catch(err => {
        if (err.message.startsWith('Write timeout')) {
          logUtil.log('[ble][error] write', err.message)
        } else {
          logUtil.error(`[ble][error] write ${err.message}`)
        }

        this.analyticsService.trackEvent(EVENT.BLUETOOTH_WRITE, {
          ...eventBody,
          failed: true,
          failReason: err.message,
        })
      })
  }

  /**
   * Only to be called from Bluetooth Device Services
   */
  @tryCatch({
    alert: false,
    onError: error => {
      logUtil.error(`[ble][error] read ${error.message}`)
    },
  })
  public async read(
    deviceId: string,
    service: string,
    characteristic: string,
    description?: string,
  ): Promise<DataView> {
    const data = await BluetoothClient.read(deviceId, service, characteristic)
    this.log(
      `Reading from: ${deviceId}; Service: ${service}, Characteristic: ${characteristic}, Description: ${description}, Data: ${dataViewToNumbers(
        data,
      )}`,
    )

    const eventBody = {
      deviceId,
      service,
      characteristic,
      description,
      dataNumbers: dataViewToNumbers(data),
    }
    this.analyticsService.trackEvent(EVENT.BLUETOOTH_READ, { ...eventBody })

    return data
  }

  /**
   * Only to be called from Bluetooth Device Services
   */
  public log(message: string): void {
    const logs = this.logs$.value
    logs.push(`${localTime.now().toPretty()} ${message}`)
    this.logs$.next(logs)
    console.log(`[ble] ${message}`)
  }
}
