import { inject, Injectable } from '@angular/core'
import { AlertOptions, OverlayEventDetail } from '@ionic/core'
import { DailyEntryBM, HardwareId } from '@naturalcycles/shared'
import { SNACKBAR } from '@src/app/cnst/snackbars.cnst'
import { InfoModal } from '@src/app/modals/info/info.modal'
import { NoTempsModal } from '@src/app/modals/no-temps/no-temps.modal'
import { SensitiveTrackersModal } from '@src/app/modals/sensitive-trackers/sensitive-trackers.modal'
import { MarkdownService } from '@src/app/srv/markdown.service'
import { Popup, PopupController, Priority } from '@src/app/srv/popup.controller'
import { SnackbarService } from '@src/app/srv/snackbar.service'
import { dispatch, getState } from '@src/app/srv/store.service'
import { tr } from '@src/app/srv/translation.util'
import { AddDataDay } from './add-data.service'

@Injectable({ providedIn: 'root' })
export class AddDataPopupService {
  private popupController = inject(PopupController)
  private markdownService = inject(MarkdownService)
  private snackbarService = inject(SnackbarService)

  public async showExitAlert(): Promise<{ shouldExit?: boolean }> {
    const alertId = 'alert-addData-exit'
    const activePopup = this.popupController.getActivePopupById(alertId)

    if (activePopup) return {}

    const alertOptions = {
      header: tr('add-data-exit-alert-title'),
      message: tr('add-data-exit-alert-body'),
      buttons: [
        {
          text: tr('add-data-exit-alert-btn-yes'),
          role: 'confirm',
          handler: () => {
            return { shouldExit: true }
          },
        },
        {
          text: tr('btn-cancel'),
          role: 'cancel',
        },
      ],
      backdropDismiss: false,
    } satisfies AlertOptions

    const alert = await this.popupController.presentAlert(
      alertOptions,
      alertId,
      Priority.IMMEDIATE,
      0,
    )

    return (await alert.onDidDismiss()).data
  }

  public async presentAddDataClearPopup(): Promise<{ hasDataToClear: boolean }> {
    const alert = await this.popupController.presentAlert(
      {
        header: tr('add-data-clear-title'),
        message: tr('add-data-clear-body'),
        cssClass: 'addData__clear__alert',
        buttons: [
          {
            text: tr('btn-cancel'),
            cssClass: 'addData__clear__cancel',
            handler: () => {
              return { hasDataToClear: true }
            },
          },
          {
            text: tr('add-data-clear-cta'),
            cssClass: 'addData__clear__ok',
            handler: async () => {
              return { hasDataToClear: false }
            },
          },
        ],
        backdropDismiss: false,
      },
      'alert-addData-clear',
      Priority.IMMEDIATE,
      0,
    )

    return (await alert.onDidDismiss()).data
  }

  public async presentSensitiveTrackersModal(
    entry: DailyEntryBM,
    dayParams: AddDataDay | undefined,
  ): Promise<OverlayEventDetail> {
    const modal = await this.popupController.presentModal(
      {
        component: SensitiveTrackersModal,
        cssClass: ['modal--transparent', 'modal--alert'],
        backdropDismiss: true,
        componentProps: {
          entry,
          dayParams,
        },
      },
      'modal-sensitiveTrackers',
      Priority.IMMEDIATE,
    )

    return await modal.onDidDismiss()
  }

  public async showBuySubscriptionAlert(): Promise<{ renewAccountPressed: boolean } | undefined> {
    const alert = await this.popupController.presentAlert(
      {
        header: tr('add-data-expired-popup-title'),
        message: this.markdownService.parse(tr('add-data-expired-popup-test-body')),
        cssClass: 'alert--attentionButtons addData__expiredSub__alert',
        buttons: [
          {
            text: tr('btn-renew-account'),
            cssClass: 'button--warning addData__expiredSub__btn',
            handler: () => {
              return { renewAccountPressed: true }
            },
          },
        ],
      },
      'alert-addData-expiredSubscription',
      Priority.VERY_HIGH,
      undefined,
    )

    return (await alert.onDidDismiss()).data
  }

  public async showOfflineAlert(): Promise<void> {
    if (getState().userSettings.showedOfflinePopup) return

    await this.popupController.presentAlert(
      {
        header: tr('add-data-offline-popup-title'),
        message: tr('add-data-offline-popup-body'),
        buttons: [
          {
            text: tr('txt-understand'),
            role: 'cancel',
          },
        ],
      },
      'alert-addData-offline',
      Priority.HIGH,
    )

    dispatch('extendUserSettings', {
      showedOfflinePopup: true,
    })
  }

  public async showNoNewDataAlert(): Promise<void> {
    await this.popupController.presentAlert(
      {
        header: tr('add-data-no-new-data-title'),
        message: tr('add-data-no-new-data-body'),
        buttons: [
          {
            text: tr('txt-understand'),
          },
        ],
      },
      'alert-addData-no-new-data',
      Priority.HIGH,
      0,
    )
  }

  public async showTempConflictAlert(): Promise<void> {
    await this.popupController.presentAlert(
      {
        header: tr('uebe-manual-temps-alert-title'),
        message: tr('uebe-manual-temps-alert-body'),
        buttons: [
          {
            text: tr('txt-understand'),
            role: 'cancel',
          },
        ],
      },
      'alert-addData-temp-conflict',
      Priority.VERY_HIGH,
      0,
    )
  }

  public async openInfoModal(title: string, body: string, id: string): Promise<void> {
    await this.popupController.presentModal(
      {
        component: InfoModal,
        componentProps: {
          title,
          body,
        },
      },
      `modal-info-${id}`,
      Priority.VERY_HIGH,
    )
  }

  public showIncompleteTempSnackbar(digits: number[]): boolean {
    const expectedDigits = digits[0] === 1 ? 5 : 4

    if (!digits.length || digits.length === expectedDigits) {
      return false
    }

    this.snackbarService.showSnackbar(SNACKBAR.INCOMPLETE_TEMP)

    return true
  }

  public async showAWNoTempsModal(): Promise<void> {
    await this.popupController.presentModal(
      {
        component: NoTempsModal,
        cssClass: ['modal--alert'],
      },
      'apple-watch-no-temps-modal',
      Priority.IMMEDIATE,
      1000,
    )
  }

  public async presentMultipleTempsAlert(): Promise<void> {
    await this.popupController.presentAlert(
      {
        header: tr('uebe-multi-temps-alert-title'),
        message: tr('uebe-multi-temps-alert-body'),
        buttons: [tr('txt-understand')],
      },
      'uebe-multi-temps-alert',
      Priority.VERY_HIGH,
      0,
    )
  }

  public async presentUnableToConnectAlert(
    onTemperatureDisplayClick: () => void,
    startConnectionTimeout: () => void,
  ): Promise<Popup> {
    const hwId = getState().account.hwId
    return await this.popupController.presentAlert(
      {
        header: tr('add-data-bluetooth-connection-title'),
        message: this.markdownService.parse(
          tr(`alert-add-data-connection-body--${HardwareId[hwId]}`),
        ),
        buttons: [
          {
            text: tr('txt-add-manually'),
            handler: () => onTemperatureDisplayClick(),
          },
          {
            text: tr('txt-try-again'),
            handler: () => startConnectionTimeout(),
          },
        ],
      },
      `alert-addData-BLE-connection`,
      Priority.HIGH,
      0,
    )
  }
}
