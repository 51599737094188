import { BackendResponseFM, OuraState } from '@naturalcycles/shared'
import { Action, Reducer } from 'redux'

export interface OuraStateFM extends OuraState {
  lastSyncClick?: string
}

type State = OuraStateFM

const initialState: State = {
  lastSyncClick: '',
}

const actions: any = {
  onBackendResponse(state: State, action: { type: string; payload: BackendResponseFM }): State {
    if (action.payload.oura) {
      return {
        ...state,
        ...action.payload.oura,
      }
    }

    return state
  },

  extendOura(state: State, action: { type: string; payload: OuraStateFM }): State {
    return {
      ...state,
      ...action.payload,
    }
  },
}

// biome-ignore lint/style/useDefaultParameterLast: ok
export const ouraReducer: Reducer<State> = (state = initialState, action: Action) => {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
