import { Pipe, PipeTransform } from '@angular/core'
import { DateFormat, DateService } from '@app/srv/date.service'
import { di } from '@app/srv/di.service'
import { dayjs } from '@naturalcycles/time-lib'

@Pipe({
  name: 'nc_date',
})
export class DatePipe implements PipeTransform {
  /**
   * Input date as string or numbers (timestamp in seconds)
   */
  public transform(
    date: string | number | null | undefined,
    format: string = DateFormat.MEDIUM,
  ): string {
    if (date === null || date === undefined) return '' // safely pipe after `| async` which is typed as ` | null` in Angular

    if (typeof date === 'number') {
      return di.get(DateService).localizeDateTime(dayjs.unix(date), format as DateFormat)
    }

    return di.get(DateService).localizeDate(dayjs(date), format as DateFormat)
  }
}
