import { Component, inject, OnDestroy, OnInit } from '@angular/core'
import { IonicModule, NavController } from '@ionic/angular'
import { ICON } from '@src/app/cnst/icons.cnst'
import { ROUTES } from '@src/app/cnst/nav.cnst'
import { decorate, LoaderType } from '@src/app/decorators/decorators'
import { AccountService } from '@src/app/srv/account.service'
import { select } from '@src/app/srv/store.service'
import { Observable, Subscription } from 'rxjs'
import { msToTimeString, RESEND_TIMEOUT } from '../ab251'

@Component({
  standalone: true,
  selector: 'ab251-verify-email-page',
  templateUrl: 'verify-email-page.component.html',
  styleUrls: ['verify-email-page.component.scss'],
  imports: [IonicModule],
})
export class VerifyEmailPage implements OnDestroy, OnInit {
  className = 'VerifyEmailPage'

  public ICON = ICON
  public timeToEnabledResend = RESEND_TIMEOUT
  private countdownStartTimestamp = Date.now()

  public get resendText(): string {
    if (this.timeToEnabledResend <= 0) return 'Resend email'
    return `Re-send email in ${msToTimeString(this.timeToEnabledResend)}`
  }

  @select(['account', 'emailVerified'])
  private emailVerified$!: Observable<boolean>

  private navController = inject(NavController)
  private accountService = inject(AccountService)
  private subscription?: Subscription
  private interval?: NodeJS.Timeout

  ngOnDestroy(): void {
    clearInterval(this.interval)
    this.subscription?.unsubscribe()
  }

  ngOnInit(): void {
    this.subscription = this.emailVerified$.subscribe(emailVerified => {
      if (emailVerified) {
        void this.skip()
      }
    })

    this.countdownStartTimestamp = Date.now()
    this.interval = setInterval(() => {
      const now = Date.now()
      this.timeToEnabledResend = Math.max(0, RESEND_TIMEOUT - (now - this.countdownStartTimestamp))
    }, 1000)
  }

  public async skip(): Promise<void> {
    await this.navController.navigateRoot(ROUTES.HomePage)
  }

  public openEmail(): void {
    window.open('message://')
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
  })
  public async resendEmail(): Promise<void> {
    await this.accountService.sendVerificationEmail()
    this.timeToEnabledResend = RESEND_TIMEOUT
    this.countdownStartTimestamp = Date.now()
  }
}
