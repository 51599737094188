import { Component, ElementRef, inject, Input, OnChanges } from '@angular/core'
import { ICON } from '@app/cnst/icons.cnst'
import { IonicModule } from '@ionic/angular'
import { CyclerStatus } from '@naturalcycles/shared'

@Component({
  selector: 'app-cycler-status',
  templateUrl: 'cycler-status.component.html',
  styleUrls: ['cycler-status.component.scss'],
  standalone: true,
  imports: [IonicModule],
})
export class CyclerStatusComponent implements OnChanges {
  public el = inject(ElementRef)
  @Input()
  public compact = false

  @Input()
  public cyclerStatus!: CyclerStatus

  @Input()
  public size?: 'small' | 'large'

  public stars!: string[]
  private achieved = 'pink'
  private unachieved = 'medium'
  public ICON = ICON

  public ngOnChanges(): void {
    this.stars = []
    for (let i = 0; i < 5; i++) {
      if (i < this.cyclerStatus) {
        this.stars.push(this.achieved)
      } else {
        this.stars.push(this.unachieved)
      }
    }
  }
}
