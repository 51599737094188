import { BackendResponseFM, HardwareId, Reminders } from '@naturalcycles/shared'
import { Action } from 'redux'
import { ReminderType } from '../pages/settings/reminders/reminders.service'

interface ReminderDay {
  enabled: boolean
  title?: string
  text?: string
}

export interface MeasureReminder {
  id: number
  enabled: boolean

  /**
   * can be empty object or an object with 7 ReminderDays
   */
  days: Partial<Record<number, ReminderDay>>
  time?: string
  type: ReminderType
  lastUpdate?: string
  lang?: string
  utcOffset?: number
  hwId?: HardwareId
}

interface NotificationSettings {
  notificationPermission?: boolean
  badgePermission?: boolean
  educationalContent?: boolean
}

export interface Notifications {
  local: {
    items: MeasureReminder[]
  }
  push: Reminders
  settings?: NotificationSettings
}

type State = Notifications

const initialState: State = {
  local: {
    items: [],
  },
  push: {},
  settings: {
    educationalContent: true,
  },
}

const actions: any = {
  addReminder(state: State, action: { type: string; payload: MeasureReminder }): State {
    return {
      ...state,
      local: {
        items: [...state.local.items, action.payload],
      },
    }
  },

  removeReminder(
    state: State,
    action: { type: string; payload: { notificationId: number } },
  ): State {
    return {
      ...state,
      local: {
        ...state.local,
        items: state.local.items.filter(item => item.id !== action.payload.notificationId),
      },
    }
  },

  setTypeOfLocalNotification(
    state: State,
    action: {
      type: string
      payload: { notificationId: number; type: ReminderType }
    },
  ): State {
    const updated = state.local.items.map(item => {
      if (item.id === action.payload.notificationId) {
        return {
          ...item,
          type: action.payload.type,
        }
      }
      return item
    })

    return {
      ...state,
      local: {
        ...state.local,
        items: updated,
      },
    }
  },

  enableLocalNotification(state: State, action: { type: string; payload: any }): State {
    const updated = state.local.items.map(item => {
      if (item.id === action.payload.notificationId) {
        return {
          ...item,
          enabled: action.payload.isEnabled,
        }
      }
      return item
    })
    return {
      ...state,
      local: {
        ...state.local,
        items: updated,
      },
    }
  },

  toggleLocalNotificationDay(
    state: State,
    action: {
      type: string
      payload: { notificationId: number; weekday: number; enabled: boolean }
    },
  ): State {
    const updated = state.local.items.map(item => {
      if (item.id === action.payload.notificationId) {
        return {
          ...item,
          days: {
            ...item.days,
            [action.payload.weekday]: {
              ...(item.days as any)[action.payload.weekday],
              enabled: action.payload.enabled,
            },
          },
        }
      }
      return item
    })

    return {
      ...state,
      local: {
        ...state.local,
        items: updated,
      },
    }
  },

  updateNotification(state: State, action: { type: string; payload: MeasureReminder }): State {
    const updated = state.local.items.map(item => {
      if (item.id === action.payload.id) {
        return action.payload
      }
      return item
    })
    return {
      ...state,
      local: {
        ...state.local,
        items: updated,
      },
    }
  },

  updateLocalNotificationTime(state: State, action: { type: string; payload: any }): State {
    const updated = state.local.items.map(item => {
      if (item.id === action.payload.notificationId) {
        return {
          ...item,
          time: action.payload.time,
        }
      }
      return item
    })
    return {
      ...state,
      local: {
        ...state.local,
        items: updated,
      },
    }
  },

  updateLocalNotificationCopy(
    state: State,
    action: {
      type: string
      payload: {
        notificationId: number
        weekday: number
        title: string
        text: string
      }
    },
  ): State {
    const updated = state.local.items.map(item => {
      if (item.id === action.payload.notificationId) {
        return {
          ...item,
          days: {
            ...item.days,
            [action.payload.weekday]: {
              ...(item.days as any)[action.payload.weekday],
              title: action.payload.title,
              text: action.payload.text,
            },
          },
        }
      }
      return item
    })

    return {
      ...state,
      local: {
        ...state.local,
        items: updated,
      },
    }
  },

  extendPushNotificationSettings(
    state: State,
    action: { type: string; payload: Reminders },
  ): State {
    return {
      ...state,
      push: {
        ...state.push,
        ...action.payload,
      },
    }
  },

  extendNotifications(state: State, action: { type: string; payload: Notifications }): State {
    return {
      ...state,
      ...action.payload,
    }
  },

  extendNotificationSettings(
    state: State,
    action: { type: string; payload: NotificationSettings },
  ): State {
    return {
      ...state,
      settings: {
        ...state.settings,
        ...action.payload,
      },
    }
  },

  onBackendResponse(state: State, action: { type: string; payload: BackendResponseFM }): State {
    const reminders = action.payload.account?.reminders

    if (reminders) {
      return {
        ...state,
        push: {
          ...state.push,
          ...reminders,
        },
      }
    }

    return state
  },
}

// biome-ignore lint/style/useDefaultParameterLast: ok
export function notificationReducer(state = initialState, action: Action): State {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
