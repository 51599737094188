import { inject, Injectable } from '@angular/core'
import { EVENT } from '@app/analytics/analytics.cnst'
import { AnalyticsService } from '@app/analytics/analytics.service'
import { decorate, ErrorHandlerType } from '@app/decorators/decorators'
import { tryCatch } from '@app/decorators/tryCatch.decorator'
import { di } from '@app/srv/di.service'
import { ErrorService } from '@app/srv/error.service'
import { getState } from '@app/srv/store.service'
import {
  _anyToError,
  _findLast,
  _invert,
  _pick,
  _Retry,
  _sortBy,
  _stringify,
  _stringMapValues,
} from '@naturalcycles/js-lib'
import {
  PaymentMethodInput,
  PaymentMethodType,
  ProductKey,
  ProductType,
} from '@naturalcycles/shared'
import { dayjs } from '@naturalcycles/time-lib'
import { type IAPRestorePurchase, InAppPurchase } from '@src/app/srv/inapppurchase.service'
import { env } from '@src/environments/environment'
import { sentryService } from './sentry.service'

const IAP_PRODUCT_KEY = {
  [ProductKey.YEAR]: `${env.appleBundleIdentifier}.yearly3`,
  [ProductKey.MONTH]: `${env.appleBundleIdentifier}.monthly3`,
  [ProductKey.ROLLING_TRIAL_7DAYS]: `${env.appleBundleIdentifier}.trial2`,
}
const IAP_PRODUCT_BY_NAME = _invert(IAP_PRODUCT_KEY)

type SafeRestorePurchase = Pick<IAPRestorePurchase, 'productId' | 'date'>

@Injectable({ providedIn: 'root' })
export class IAPService {
  private analyticsService = inject(AnalyticsService)
  private inAppPurchase = new InAppPurchase()

  @tryCatch({
    alert: false,
    onError: error => {
      if (error?.errorCode === 0 && error?.errorMessage?.includes('An unknown error occurred')) {
        return false
      }
    },
  })
  public async init(): Promise<void> {
    await this.inAppPurchase.getProducts(_stringMapValues(IAP_PRODUCT_KEY))
  }

  @tryCatch({
    alert: false,
    onError: error => {
      // https://developer.apple.com/documentation/storekit/skerror/code/paymentcancelled
      const paymentCancelledByUser =
        error?.errorCode === 2 && _stringify(error).includes('SKErrorDomain')
      if (paymentCancelledByUser) {
        return false
      }

      void di.get(ErrorService).showErrorDialog(error)
    },
  })
  async purchase(): Promise<string> {
    const subscription = getState().cart.items.find(item => item.type === ProductType.SUBSCRIPTION)
    const productId = IAP_PRODUCT_KEY[subscription!.key]
    try {
      const { receipt } = await this.inAppPurchase.subscribe(productId)
      return receipt
    } catch (err) {
      console.log('IAP Purchase Error', _stringify(err))
      console.log({
        subscription,
        productId,
        IAP_PRODUCT_KEY,
      })
      sentryService.captureException(_anyToError(err), { fingerprint: ['IAPService.purchase'] })
      throw err
    }
  }

  @_Retry({
    maxAttempts: 2,
    delay: 500,
  })
  @decorate({
    errorHandlerType: ErrorHandlerType.LOG,
  })
  async getExistingIAPSubscription(): Promise<ProductKey | undefined> {
    const products: SafeRestorePurchase[] = (await this.inAppPurchase.restorePurchases()).map(
      (p: IAPRestorePurchase) => {
        p.date = dayjs(p.date).toISODate()
        return _pick(p, ['date', 'productId'])
      },
    )

    void this.analyticsService.trackEvent(EVENT.RESTORE_IAP, {
      products,
    })

    const sortedPurchases = _sortBy(products, p => p.date) // ascending order
    const latestPurchase = _findLast(sortedPurchases, p => !!IAP_PRODUCT_BY_NAME[p.productId])
    return latestPurchase && IAP_PRODUCT_BY_NAME[latestPurchase.productId]
  }

  async getIAPReceipt(): Promise<PaymentMethodInput> {
    return {
      type: PaymentMethodType.IAPAPPLE,
      nonce: await this.inAppPurchase.getReceipt(),
      verified: true,
    }
  }
}
