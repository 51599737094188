import { inject, Injectable } from '@angular/core'
import { _filterNullishValues, _omit, IsoDateString } from '@naturalcycles/js-lib'
import { Goal, PregnancyReason, UnplannedPregnancyDataInput } from '@naturalcycles/shared'
import { verticalSlideAnimation } from '@src/app/animations/vertical-slide'
import { ROUTES } from '@src/app/cnst/nav.cnst'
import { decorate, ErrorHandlerType, LoaderType } from '@src/app/decorators/decorators'
import { api } from '@src/app/srv/api.service'
import { EventService } from '@src/app/srv/event.service'
import { dispatch, getState } from '@src/app/srv/store.service'
import { filter, take } from 'rxjs'
import { AddDataService } from '../../add-data/add-data.service'
import { FlowId, FlowInputData, FlowPage } from '../flow.cnst'
import { FlowService } from '../flow.service'
import { UnplannedPregnancyTrigger } from './unplanned-pregnancy.cnst'
import {
  UNPLANNED_PREGNANCY_CONFIG,
  UNPLANNED_PREGNANCY_INFO_SCREEN,
} from './unplanned-pregnancy.config'

export interface UnplannedPregnancyFlowDataInput extends UnplannedPregnancyDataInput {
  currentGoal: Goal
  trigger: UnplannedPregnancyTrigger
}

interface UnplannedPregnancyStartInput {
  trigger: UnplannedPregnancyTrigger
  currentGoal: Goal
  pregnancyStartDate: IsoDateString
}

@Injectable({ providedIn: 'root' })
export class UnplannedPregnancyService extends FlowService<UnplannedPregnancyFlowDataInput> {
  CONFIG = UNPLANNED_PREGNANCY_CONFIG
  INFO_SCREENS = UNPLANNED_PREGNANCY_INFO_SCREEN
  FLOW_ID = FlowId.UNPLANNED_PREGNANCY

  private addDataService = inject(AddDataService)
  private eventService = inject(EventService)

  public async startUnplannedPregnancyFlow(input: UnplannedPregnancyStartInput): Promise<void> {
    const config = await this.saveAndGetNextConfig(input, undefined, true)

    this.eventService.onFlowDeactivation$
      .pipe(
        filter(id => id === this.FLOW_ID),
        take(1),
      )
      .subscribe(this.onFlowDeactivation)

    if (!config?.route) return

    await this.navController.navigateForward(config.route, {
      state: {
        ...config,
        config: _omit(config.config, ['sideEffects']), // omit side effects as it seems not possible to pass functions to next page
      },
      animation: verticalSlideAnimation,
    })
  }

  private onFlowDeactivation(): void {
    const { flowData } = getState()

    const data = _filterNullishValues(flowData) as UnplannedPregnancyFlowDataInput

    // user cancelled the flow
    if (!data['pregnancyReason']) {
      // We don't want to restore pregnancy test in case user realized that they ended up in the flow by mistake
      // If user had logged only pregnancy test in Add data then we want to omit it together with 'updated' prop
      // which would cause Add data show "Clear" button for empty data otherwise
      dispatch('omitEntryStashPregnancyTest')
    }
  }

  /**
   *  Indicates whether user is currently pregnant on prevent
   */
  public get showUnplannedPregnancyCards(): boolean {
    const { colorMap, todayDate } = getState().userFertility
    const lastColorDay = colorMap?.[todayDate]

    return !!lastColorDay?.code.defPreg && lastColorDay.goal === Goal.PREVENT
  }

  public override async saveAndGetNextConfig(
    data: FlowInputData<UnplannedPregnancyFlowDataInput>,
    key?: keyof UnplannedPregnancyFlowDataInput,
    override = false,
  ): Promise<FlowPage | undefined> {
    let config: FlowPage | undefined

    switch (key) {
      case 'pregnancyReason': {
        if (data.pregnancyReason === (-1 as PregnancyReason)) {
          await this.navController.pop()
          return
        }

        config = await super.saveAndGetNextConfig(data, key, override)
        break
      }

      default:
        config = await super.saveAndGetNextConfig(data, key, override)
    }

    if (config) return config

    await this.nextAfterCompletingUnplannedPregnancyData()
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  private async nextAfterCompletingUnplannedPregnancyData(): Promise<void> {
    const { flowData } = getState()

    const data = _filterNullishValues(flowData) as UnplannedPregnancyFlowDataInput

    await this.addDataService.saveModifiedDailyEntries()

    await api.put('unplannedpregnancydata', {
      json: {
        ...data,
        previousGoal: data.currentGoal,
      },
    })

    this.eventService.closeDrawer$.next()
    await this.navController.navigateRoot(ROUTES.HomePage, {
      animated: true,
      animation: verticalSlideAnimation,
      animationDirection: 'back',
    })
  }
}
