import { ChangeDetectionStrategy, Component, inject, Input, output } from '@angular/core'
import { ICON } from '@app/cnst/icons.cnst'
import { InfoModal } from '@app/modals/info/info.modal'
import { InfoModalModule } from '@app/modals/info/info.modal.module'
import { PipesModule } from '@app/pipes/pipes.module'
import { PopupController, Priority } from '@app/srv/popup.controller'
import { IonicModule } from '@ionic/angular'
import { StringMap } from '@naturalcycles/js-lib'

@Component({
  selector: 'app-info-button',
  templateUrl: './info-button.component.html',
  styleUrls: ['./info-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonicModule, PipesModule, InfoModalModule],
})
export class InfoButtonComponent {
  private popupController = inject(PopupController)

  @Input()
  public buttonTitle?: string

  @Input()
  public title?: string

  @Input()
  public body?: string

  @Input()
  public html?: string

  @Input()
  public ctaTitle?: string

  @Input()
  public isLogoDisplayed?: boolean

  @Input()
  public infoBoxBody?: string

  @Input()
  public iconColor = 'text'

  @Input()
  public ariaLabel?: string

  @Input()
  public small?: boolean

  public infoModalOpened = output<void>()

  public infoModalDismissed = output<void>()

  public ICON = ICON

  public async openInfoModal(event?: Event, overrideProps?: StringMap): Promise<void> {
    if (!this.title && !this.body && !this.html) return

    this.infoModalOpened.emit()

    event?.stopPropagation()

    const modal = await this.popupController.presentModal(
      {
        component: InfoModal,
        componentProps: {
          title: this.title,
          body: this.body,
          html: this.html,
          ctaTitle: this.ctaTitle,
          isLogoDisplayed: this.isLogoDisplayed,
          infoBoxBody: this.infoBoxBody,
          ...overrideProps,
        },
      },
      `modal-info-${this.title}`,
      Priority.IMMEDIATE,
    )

    await modal.onDidDismiss()

    this.infoModalDismissed.emit()
  }
}
