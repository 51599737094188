<!-- Not the best to start the component with an ion-toolbar, but I had no other choice in order to style ONLY THIS ion-toolbar to fit the use case -->
<ion-toolbar>
  <div class="progressIndicator">
    <div class="progressIndicator__inner">
      @for (section of sections; track section) {
        <div class="progressIndicator__section" [class.active]="false">
          <div class="progressIndicator__fill" [style.width]="section + '%'"></div>
        </div>
      }
    </div>
  </div>
</ion-toolbar>
