import { Injectable } from '@angular/core'
import { PageGuard } from '@app/guards/page.guard'
import { getState } from '@src/app/srv/store.service'
import { isAb251Test } from '../ab251'

@Injectable({
  providedIn: 'root',
})
export class VerifyEmailPageGuard extends PageGuard {
  className = 'VerifyEmailPageGuard'

  private hasLastPaymentGate(): boolean {
    return !!getState().account.lastPaymentGate
  }

  private hasVerifiedEmail(): boolean {
    return !!getState().account.emailVerified
  }

  get requirements(): boolean[] {
    const requirements = [
      this.hasSession(),
      isAb251Test(),
      !this.hasLastPaymentGate(),
      !this.hasVerifiedEmail(),
    ]
    return requirements
  }
}
