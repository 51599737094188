@if (showDismisser) {
  <div
    #dismisser
    uid="numpad__dismisser_area"
    class="numpad__dismisser"
    (click)="dismissClicked()"
  ></div>
}

<div class="numpad__keyboard">
  @for (digit of numpadDigits$ | async; track trackByDigitValue($index, digit)) {
    <ion-button
      class="numpad__keyboard__button"
      expand="block"
      shape="round"
      fill="clear"
      color="white"
      [attr.uid]="'numpad__' + digit.value"
      [disabled]="!!digit.disabled"
      (click)="pressBtn(digit.value)"
      haptic
    >
      <ion-label>{{ digit.value }}</ion-label>
    </ion-button>
  }

  <ion-button
    uid="addData__numpadKeyboard__backspace__button"
    class="numpad__keyboard__button"
    expand="block"
    shape="round"
    fill="clear"
    color="pink"
    [disabled]="(selectedDigits$ | async)?.length === 0"
    (click)="pressBtn(-1)"
    haptic
  >
    <ion-icon [src]="ICON.BACKSPACE" slot="icon-only" />
  </ion-button>

  <ion-button
    uid="addData__numpadKeyboard__ok__button"
    class="numpad__keyboard__button numpad__keyboard__button--dismiss"
    expand="block"
    shape="round"
    fill="clear"
    color="pink"
    [disabled]="dismissDisabled"
    (click)="dismissClicked()"
  >
    <ion-label [tmd]="dismissTitle" />
  </ion-button>
</div>
