import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { DataFlagWithIntensityToggleComponent } from '@app/cmp/data-flag-with-intensity-toggle/data-flag-with-intensity-toggle.component'
import { PaginationComponent } from '@app/cmp/pagination/pagination.component'
import { PipesModule } from '@app/pipes/pipes.module'
import { SharedModule } from '@app/shared.module'
import { IonicModule } from '@ionic/angular'
import { SwiperModule } from 'swiper/angular'
import { AnnouncementModal } from './announcement.modal'

@NgModule({
  declarations: [AnnouncementModal],
  imports: [
    IonicModule,
    CommonModule,
    PipesModule,
    SharedModule,
    PaginationComponent,
    SwiperModule,
    DataFlagWithIntensityToggleComponent,
  ],
  exports: [AnnouncementModal],
})
export class AnnouncementModalModule {}
