import { inject, Injectable } from '@angular/core'
import { isNativeApp } from '@app/cnst/userDevice.cnst'
import { Platform } from '@ionic/angular'
import { OS } from '@naturalcycles/shared'

export interface WH {
  w: number
  h: number
}

@Injectable({ providedIn: 'root' })
export class DeviceService {
  private platform = inject(Platform)

  public isIOSDevice = this.platform.is('ios')
  public isAndroidDevice = this.platform.is('android')

  public inFrame(): boolean {
    return !isNativeApp && this.getWindowSize().w >= 768
  }

  public getWindowSize(): WH {
    return {
      // w: window.innerWidth,
      // h: window.innerHeight
      // this addresses the iOS9 bug when width/height is reported totally wrong (980x1461 on ip6 in safari browser)
      w: document.documentElement.clientWidth,
      h: document.documentElement.clientHeight,
    }
  }

  public isTouch(): boolean {
    return 'ontouchstart' in document.documentElement
  }

  /**
   * Returns IOS, ANDROID or undefined otherwise.
   */
  public getOS(): OS | undefined {
    if (this.isIOSDevice) return OS.IOS
    if (this.isAndroidDevice) return OS.ANDROID
  }
}
