import { AccountDataFM, BackendResponseFM } from '@naturalcycles/shared'
import { Action, Reducer } from 'redux'

type State = Partial<AccountDataFM>

const initialState: State = {}

const actions: any = {
  onBackendResponse(state: State, action: { type: string; payload: BackendResponseFM }): State {
    // replace!
    if (action.payload.accountData) {
      return {
        ...action.payload.accountData,
      }
    }

    return state
  },
}

// biome-ignore lint/style/useDefaultParameterLast: ok
export const accountDataReducer: Reducer<State> = (state = initialState, action: Action) => {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
