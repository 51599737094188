import {
  ChangeDetectorRef,
  Directive,
  HostBinding,
  HostListener,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core'
import { StoreService } from '@app/srv/store.service'
import { distinctUntilChanged } from 'rxjs/operators'

@Directive({
  selector: '[loader]',
})
export class LoaderDirective implements OnInit, OnChanges {
  private storeService = inject(StoreService)
  private cdr = inject(ChangeDetectorRef)
  @HostBinding('class.button--spinner')
  public spinner = false

  @HostBinding('class.button--loading')
  public loading = false

  @Input()
  public showSpinner?: boolean

  public ngOnInit(): void {
    this.storeService
      .select(s => !!s.ui.buttonLoader)
      .pipe(distinctUntilChanged())
      .subscribe(loading => this.handleLoader(loading))
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['showSpinner']) this.spinner = changes['showSpinner'].currentValue
  }

  @HostListener('click')
  public onClick(): void {
    this.spinner = true
  }

  private handleLoader(loading: boolean): void {
    if (loading) {
      this.loading = true
    } else {
      this.spinner = false
      this.loading = false
    }
    this.cdr.markForCheck()
  }
}
