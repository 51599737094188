<div uid="sensitiveTrackers__modal" class="sensitiveTrackers__container">
  <app-nc-secure-logo color="purple" />

  <form [formGroup]="form" class="addData__form">
    <!-- No longer pregnant-button (show if pregnant && prevent or pregnant more than 8 months)  -->
    @if (noLongerPregnantButton$ | async) {
      <ion-row align-items-end class="noLongerPregnant__container">
        <ion-col class="ion-text-center">
          <ion-button
            uid="sensitiveTrackers__noLongerPregnant__button"
            class="sensitiveTrackers__button"
            color="medium"
            (click)="noLongerPregnantClicked()"
            haptic
          >
            <ion-label tmd="add-data-no-longer-pregnant" />
          </ion-button>
        </ion-col>
        <app-info-button
          class="sensitiveTrackers__infoButton"
          uid="sensitiveTrackers__infoButton__noLongerPregnant"
          title="info-noLongerPregnant-title"
          body="info-noLongerPregnant-body"
          iconColor="black"
        />
      </ion-row>
    }

    @if (emergencyContraceptives$ | async; as emergencyContraceptives) {
      <ion-row class="ion-align-items-end">
        <ion-col>
          <h3 tmd="txt-emergency-contraceptive"></h3>
          <app-super-segment
            class="sensitiveTrackers__superSegment"
            [ready]="ready"
            [items]="emergencyContraceptives"
            [value]="{ value: form.controls['emergency']!.value }"
            [color]="ColorBySegment.SENSITIVE"
            (valueChange)="onControlChange(SensitiveTrackerControls.EMERGENCY, $event)"
            haptic
          />
        </ion-col>
        @if (emergencyContraceptivesGlossary$ | async; as emergencyContraceptivesGlossary) {
          <app-info-button
            class="sensitiveTrackers__infoButton"
            uid="sensitiveTrackers__infoButton__emergencyContraceptive"
            [html]="emergencyContraceptivesGlossary"
            iconColor="black"
          />
        }
      </ion-row>
    }

    @if (pregTestSegments$ | async; as pregTestSegments) {
      <ion-row class="ion-align-items-end">
        <ion-col>
          <h3 tmd="txt-pregnancy-test"></h3>
          <app-super-segment
            class="sensitiveTrackers__superSegment"
            [ready]="ready"
            [items]="pregTestSegments"
            [value]="{ value: form.controls['pregTest']!.value }"
            [color]="ColorBySegment.SENSITIVE"
            (valueChange)="onControlChange(SensitiveTrackerControls.PREG_TEST, $event)"
            haptic
          />
        </ion-col>
        @if (pregTestGlossary$ | async; as pregTestGlossary) {
          <app-info-button
            class="sensitiveTrackers__infoButton"
            uid="sensitiveTrackers__infoButton__pregTest"
            [html]="pregTestGlossary"
            iconColor="black"
          />
        }
        @if ((goal$ | async) === Goal.PREGNANT) {
          <app-info-button
            class="sensitiveTrackers__infoButton"
            uid="sensitiveTrackers__infoButton__pregTest"
            title="info-pregTestFollow-title"
            body="info-pregTestFollow-body"
            iconColor="black"
          />
        }
      </ion-row>
    }
  </form>
</div>

<ion-footer class="sensitiveTrackers__footer">
  <ion-toolbar color="transparent">
    <ion-buttons slot="end">
      <ion-button uid="sensitiveTrackers__done" color="primary" (click)="dismissModal()">
        <ion-label tmd="txt-done-only" />
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
