<div
  class="ion-margin-horizontal ion-padding-bottom"
  uid="feedBackModal"
  class="feedbackModal feedbackModal--{{ type }}"
>
  <h2 class="ion-padding-horizontal">{{ title | tr }}</h2>

  @if (type === 'questions') {
    @for (item of feedbackItems; track trackByUid($index, item)) {
      <ion-item class="feedbackModal__item">
        <ion-label class="ion-text-wrap">{{ item.label | tr }}</ion-label>
        <ion-radio-group
          class="feedbackModal__buttonsContainer"
          slot="end"
          (ionChange)="itemChanged($any($event))"
        >
          <ion-item
            [attr.itemvalue]="item.uid + '--1'"
            [attr.uid]="'feedbackModal__item--' + item.uid"
            color="layer"
          >
            <ion-radio class="feedbackModal__radio" slot="start" [value]="item.uid + '--1'" />
            <ion-icon slot="start" [src]="ICON.THUMBS_UP" />
          </ion-item>
          <ion-item
            [attr.itemvalue]="item.uid + '--0'"
            [attr.uid]="'feedbackModal__item--' + item.uid"
            color="layer"
          >
            <ion-radio class="feedbackModal__radio" slot="start" [value]="item.uid + '--0'" />
            <ion-icon slot="start" [src]="ICON.THUMBS_DOWN" />
          </ion-item>
        </ion-radio-group>
      </ion-item>
    }
  }

  @if (type === 'input') {
    <div class="feedbackModal__input">
      <ion-textarea
        mode="ios"
        [rows]="4"
        autocapitalize="sentences"
        [maxlength]="maxLength"
        [placeholder]="'txt-type-answer' | tr"
      />
      <p class="feedbackModal__inputCount">{{ textarea?.value?.length || 0 }} / {{ maxLength }}</p>
    </div>
  }
</div>

<ion-footer>
  <ion-toolbar color="transparent">
    <ion-buttons slot="end">
      <ion-button uid="feedbackModal__skip" color="primary" (click)="skip()">
        <ion-label>{{ 'txt-skip' | tr }}</ion-label>
      </ion-button>

      <ion-button uid="feedbackModal__submit" color="primary" (click)="submit()">
        <ion-label>{{ 'btn-submit' | tr }}</ion-label>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
