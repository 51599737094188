<ion-header>
  <app-quiz-header />
  <app-quiz-progress-indicator [page]="page" />
</ion-header>

<ion-content>
  <h1 class="quizTitle">{{ 'txt-quiz-know-pregnant-days' | tr }}</h1>

  <app-quiz-selection
    (selectedKeyEmitter)="saveSelectedAnswer($event)"
    [selectedKey]="selectedKey$ | async"
    [selections]="selections"
  />
</ion-content>
