<app-tree-layer [data]="data" />

<ion-footer>
  <ion-button fill="clear" (click)="copyToClipboard()" loader>
    <ion-label>{{ 'txt-copy-to-clipboard' | tr }}</ion-label>
  </ion-button>

  <ion-button fill="clear" (click)="dismissModal()" loader>
    <ion-label>{{ 'txt-ok' | tr }}</ion-label>
  </ion-button>
</ion-footer>
