import { Component, Input } from '@angular/core'
import { ICON } from '@app/cnst/icons.cnst'
import { BaseModal } from '@app/pages/base.modal'

@Component({
  selector: 'page-notifications-perm-modal',
  templateUrl: 'notifications-permission.modal.html',
  styleUrls: ['notifications-permission.modal.scss'],
})
export class NotificationsPermissionModal extends BaseModal {
  className = 'NotificationPermissionModal'

  @Input()
  title = 'notifications-popup-title'

  @Input()
  body = 'notifications-popup-text'

  @Input()
  ctaYes = 'btn-allow'

  @Input()
  ctaNo = 'txt-decline'

  public ICON = ICON
}
