import { animate, state, style, transition, trigger } from '@angular/animations'
import { AfterViewInit, ChangeDetectorRef, Component, inject, Input, output } from '@angular/core'
import { ICON } from '@app/cnst/icons.cnst'

@Component({
  selector: 'app-tour-navbar',
  templateUrl: 'tour-navbar.component.html',
  styleUrls: ['tour-navbar.component.scss'],
  animations: [
    trigger('navigateButtonAnimation', [
      state(
        'out',
        style({
          transform: 'translate({{translateX}}, 24px)',
          opacity: 0,
        }),
        { params: { translateX: '-50%' } },
      ),
      state(
        'in',
        style({
          transform: 'translate({{translateX}}, 0)',
          opacity: 1,
        }),
        { params: { translateX: '-50%' } },
      ),
      transition('out=>in', animate('200ms 100ms')),
      transition('in=>out', animate('150ms')),
      transition(
        ':leave',
        [
          style({
            transform: 'translate({{translateX}}, 0)',
            opacity: 1,
          }),
          animate(
            '150ms',
            style({
              transform: 'translate({{translateX}}, 6px)',
              opacity: 0,
            }),
          ),
        ],
        { params: { translateX: '-50%' } },
      ),
    ]),
    trigger('navigateButtonExchange', [
      state(
        'out',
        style({
          transform: 'translateY(6px)',
          opacity: 0,
        }),
      ),
      transition(
        ':enter, out=>in',
        [
          style({
            transform: 'translate({{translateX}}, 6px)',
            opacity: 0,
          }),
          animate(
            '150ms 150ms',
            style({
              transform: 'translate({{translateX}}, 0)',
              opacity: '{{opacityValue}}',
            }),
          ),
        ],
        { params: { opacityValue: '1', translateX: '-50%' } },
      ),
      transition(
        ':leave, in=>out',
        [
          style({
            transform: 'translate({{translateX}}, 0)',
            opacity: 0,
          }),
          animate(
            '150ms',
            style({
              transform: 'translate({{translateX}}, 6px)',
              opacity: 0,
            }),
          ),
        ],
        { params: { opacityValue: '1', translateX: '-50%' } },
      ),
    ]),
  ],
})
export class TourNavbarComponent implements AfterViewInit {
  private cdr = inject(ChangeDetectorRef)
  @Input()
  currentStep = 0

  @Input()
  totalSteps = 0

  @Input()
  ctaCenter?: boolean

  nextClick = output()

  endTour = output<boolean>()

  ctaClick = output()

  public shouldAnimate = false
  public ICON = ICON

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.shouldAnimate = true
      this.cdr.markForCheck()
    }, 0)
  }

  public onNextClick(): void {
    this.nextClick.emit()
  }

  public onEndTourClick(cancelled = false): void {
    this.endTour.emit(cancelled)
  }

  public onCtaClick(): void {
    this.ctaClick.emit()
  }
}
