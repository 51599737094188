import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  output,
  ViewChild,
} from '@angular/core'
import { IonRadioGroup } from '@ionic/angular'
import { ICON } from '@src/app/cnst/icons.cnst'

export interface RadioGroupValue<T> {
  title: string
  subtitle?: string
  /**
   * Adding an icon will replace the radio icon
   */
  icon?: ICON
  infoButton?: string
  value: T
  disabled?: boolean
  uid: string
}

type RadioGroupType = string | boolean | number

@Component({
  selector: 'app-radio-group',
  templateUrl: 'radio-group.component.html',
  styleUrls: ['radio-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioGroupComponent {
  @ViewChild(IonRadioGroup)
  public radioGroup!: IonRadioGroup

  @Input()
  public title?: string

  @Input()
  public key?: string

  @Input()
  public items?: RadioGroupValue<RadioGroupType>[]

  @Input()
  public value?: RadioGroupType

  @HostBinding('class.compact')
  @Input()
  public compact?: boolean

  public valueChange = output<any>()

  public ionChange(event: CustomEvent): void {
    this.valueChange.emit(event.detail.value)
  }

  public setValue(value: RadioGroupType): void {
    this.radioGroup.value = value
  }

  public trackByValue(_i: number, item: RadioGroupValue<RadioGroupType>): RadioGroupType {
    return item.value
  }
}
