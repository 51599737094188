import { inject, Injectable } from '@angular/core'
import { LoadingController } from '@ionic/angular'
import { LoadingOptions } from '@ionic/core'
import { pDelay } from '@naturalcycles/js-lib'
import { StoreService } from './store.service'

export interface BlockingLoader {
  show: boolean
  message?: string
}

@Injectable({ providedIn: 'root' })
export class BlockingLoaderService {
  private loadingController = inject(LoadingController)
  private storeService = inject(StoreService)
  // loader instance can be used only once, and recreated every next time
  private loaderPromise?: Promise<HTMLIonLoadingElement>

  private async createLoader(message?: string): Promise<HTMLIonLoadingElement> {
    const opt: LoadingOptions = {
      spinner: message ? 'lines' : null,
      cssClass: message ? undefined : 'nc-logo-loader',
      message,
    }
    return await this.loadingController.create(opt)
  }

  /**
   * Subscribe to State.ui.blockingLoader and show/hide the loader accordingly.
   */
  init(): void {
    this.storeService
      .select(s => s.ui.blockingLoader)
      .subscribe(blockingLoader => {
        if (blockingLoader?.show) {
          void this.show(blockingLoader?.message)
        } else {
          void this.hide()
        }
      })
  }

  async show(message?: string): Promise<void> {
    // alert('show')
    if (this.loaderPromise) {
      // blockingLoader.show called when loader was already present, doing nothing
      return
    }

    this.loaderPromise = this.createLoader(message)
    await this.loaderPromise

    await pDelay(150)

    if (this.loaderPromise) {
      const loader = await this.loaderPromise
      void loader.present()
    }
  }

  async hide(): Promise<void> {
    // alert('hide')
    if (!this.loaderPromise) {
      // blockingLoader.hide called when loader was NOT present, doing nothing
      return
    }
    const loader = await this.loaderPromise
    this.loaderPromise = undefined
    if (loader) {
      void loader.dismiss()
    }
  }
}
