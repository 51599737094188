import { Directive, ElementRef, inject, Input, OnChanges, Renderer2 } from '@angular/core'
import { markdownService } from '@app/srv/markdown.service'
import { DomController } from '@ionic/angular'
import { StringMap } from '@naturalcycles/js-lib'
import { TranslateService } from '@ngx-translate/core'

@Directive({
  selector: '[tmd]',
})
export class MarkdownDirective implements OnChanges {
  private translateService = inject(TranslateService)
  private el = inject(ElementRef)
  private renderer = inject(Renderer2)
  private dom = inject(DomController)
  // en.json key to translate
  @Input()
  tmd?: string

  @Input()
  tmdParams?: StringMap

  ngOnChanges(): void {
    // some components(long-text) use directive with empty tmd key.
    if (!this.tmd) return

    let target = this.el.nativeElement

    if (this.el.nativeElement.hasAttribute('ion-button')) {
      this.el.nativeElement.childNodes.forEach((element: HTMLElement) => {
        if (element.classList.contains('button-inner')) {
          target = element
        }
      })
    }

    this.translateService.get(this.tmd, this.tmdParams).subscribe((value: string) => {
      this.dom.write(() => {
        this.renderer.setProperty(target, 'innerHTML', markdownService.parse(value))
      })
    })
  }
}
