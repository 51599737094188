import { StringMap } from '@naturalcycles/js-lib'

export const DONT_REPORT_PAGEVIEW = [
  'LoadingCmp', // not really a page view
  'AlertCmp', // not really a page view
  'PickerCmp', // not really a page view
  'ActionSheetCmp', // not really a page view
  'ToastCmp', // not really a page view
  'CalibrateModal', // was reporting twice
  'ThermometerPage', // was reporting twice
]

export enum EVENT {
  // Safe (Adjust + Mixpanel)
  REGISTRATION_START = 'RegistrationStart',
  REGISTRATION_COMPLETE = 'RegistrationComplete',
  DEMO_ACCESS = 'AccessDemo',

  // Personal (Adjust)
  /** @see {ADJUST_ONLY_EVENT} */
  INITIAL_PURCHASE_MONTH = 'InitialPurchaseMonth',
  INITIAL_PURCHASE_FOURMONTH = 'InitialPurchaseFourMonth',
  INITIAL_PURCHASE_YEAR = 'InitialPurchaseYear',
  INITIAL_PURCHASE_ROLLING_TRIAL = 'InitialPurchaseRollingTrial',
  PAYMENT_START = 'PaymentStart',
  PAYMENT_START_MONTH = 'PaymentStartMonth',
  PAYMENT_START_FOURMONTH = 'PaymentStartFourMonth',
  PAYMENT_START_YEAR = 'PaymentStartYear',
  PAYMENT_START_ROLLING_TRIAL = 'PaymentStartRollingTrial',
  CHOOSE_SUB_PAID = 'ChooseSubscriptionPaid',
  CHOOSE_SUB_MONTH = 'ChooseSubscriptionMonth',
  CHOOSE_SUB_YEAR = 'ChooseSubscriptionYear',
  CHOOSE_SUB_ROLLING_TRIAL = 'ChooseSubscriptionRollingTrial',
  ADDRESS_START = 'AddressInputStart',

  // Sensitive (Mixpanel)
  GOAL_SELECTED = 'GoalSelected',
  CALIBRATION_RESULT = 'Calibration result',
  NO_LONGER_PREGNANT = 'NoLongerPregnant',
  FERTILITY_STATUS_RECEIVED = 'Fertility status received',
  VIEW_PREG_WEEK = 'ViewPregWeek',
  FERTILITY_STATUS_CLICK = 'FertilityStatusClick',
  TEMPERATURE_DISPLAY_CLICK = 'TemperatureDisplayClick',
  TEMPERATURE_ENTERED = 'TemperatureEntered',
  LH_SCANNER_IMAGE_PICKED = 'LHScannerImagePicked',
  LH_SCANNER_TEST_DETECTED = 'LHScannerTestDetected',
  LH_SCANNER_TEST_CLASSIFIED = 'LHScannerTestClassified',
  LH_SCANNER_SAVE_RESULT = 'LHScannerSaveResult',
  LH_SCANNER_REPORT_RESULT = 'LHScannerReportResult',
  DEVIATION_SLEEP_RESULT = 'DeviationSleepResult',
  ERROR = 'Error', // Uses SentryID
  SUPER_SEGMENT_TOGGLE = 'SuperSegmentToggle',
  DATA_FLAG_TOGGLE = 'DataFlagToggle',
  ADD_DATA_SETTINGS_CHANGE = 'AddDataSettingsChange',
  ADD_DATA_SETTINGS_SAVE = 'AddDataSettingsSave',
  TRACKER_TOGGLE = 'TrackerToggle',

  // Safe (only Mixpanel)
  CLICK = 'Click',
  FOCUS = 'Focus',
  OFFLINE = 'Offline',
  SNACKBAR = 'Snackbar',
  SCREENSHOT = 'Screenshot',
  APP_COLD_START = 'AppColdStart',
  APP_RESUME = 'AppResume',
  APP_PAUSE = 'AppPause',
  APP_UPDATE = 'AppUpdate',
  DRAWER_OPENED = 'DrawerOpened',
  DRAWER_CLOSED = 'DrawerClosed',
  DEMO_EXIT = 'ExitDemo',
  IAB_EXIT = 'IABExit',
  OUTBOUND_LINK = 'OutboundLink',
  WIDGET_CLICK = 'WidgetClick',
  MENS_BUTTON_WIDGET_CLICK = 'MensButtonWidgetClick',
  TOUR_POPUP = 'TourPopup',
  TOUR_SKIP = 'TourSkip',
  TOUR_START = 'TourStart',
  TOUR_STEP = 'ViewTourStep',
  TOUR_CANCELLED = 'CancelTour',
  GUIDE_START = 'StartGuide',
  GUIDE_STEP = 'GuideStep',
  GUIDE_EXIT = 'ExitGuide',
  CARDS_STEP = 'CardsStep',
  INFOMODAL = 'InfoModal',
  PAGE_GUARD_FAILED = 'PageGuardFailed',
  INPUT_VALIDATION = 'InputValidation',
  ADD_DATA_SAVE_TO_LOCAL = 'AddDataSaveToLocal',
  FEEDBACK_ANSWER = 'FeedbackAnswer',
  FEEDBACK_SKIPPED = 'FeedbackSkipped',
  INTRO_SLIDE_START = 'IntroSlideStart',
  INTRO_SLIDE_STEP = 'IntroSlideStep',
  INTRO_SLIDE_END = 'IntroSlideEnd',
  INVALID_DISCOUNT = 'InvalidDiscount',
  MESSAGE_FEEDBACK = 'MessageFeedback',
  MESSAGE_DISLIKED = 'MessageDisliked',
  DECLINED_REGISTRATION_CONSENT = 'DeclinedRegistrationConsent',
  REMINDERS_NOTIFICATION_QUESTION = 'ReminderNotificationQuestion',
  UPDATE_MEASURING_REMINDERS = 'UpdateMeasuringReminders',
  UPDATE_CYCLE_REMINDERS = 'UpdateCycleReminders',
  READ_TOOLTIP = 'ReadTooltip',
  READ_TUTORIAL = 'ReadTutorial',
  DISMISS_TUTORIAL = 'DismissTutorial',
  SHARE_BUTTON_CLICK = 'ShareButtonClick',
  NOTIFICATIONS_ALLOWED = 'NotificationsAllowed',
  NOTIFICATIONS_DECLINED = 'NotificationsDeclined',
  NOTIFICATION_PERMISSION = 'NotificationPermission',
  NOTIFICATION_TRIGGERED = 'TriggeredNotification',
  NOTIFICATION_OPENED = 'OpenedNotification',
  NOTIFICATION_QUESTION = 'PushNotificationQuestion',
  NOTIFICATION_CALLBACK_APP_INIT = 'NotificationCallbackAppInit',
  ANNOUNCEMENT_START = 'StartAnnouncement',
  ANNOUNCEMENT_STEP = 'AnnouncementStep',
  ANNOUNCEMENT_EXIT = 'ExitAnnouncement',
  INVALID_EMAIL = 'InvalidEmail',
  EMAIL_VALIDITY_UNKNOWN = 'EmailValidityUnknown',
  EXCLUDED_PASSWORD = 'ExcludedPassword',
  LIKE_NC = 'LikeNC',
  RATE_NC = 'RateNC',
  EMAIL_NC = 'EmailNC',
  TODAY_SCROLLED_TO_BOTTOM = 'TodayScrolledToBottom',
  TODAY_OURA_SYNC_STATE_CHANGED = 'TodayOuraSyncStateChanged',
  ADD_DATA_PREVIEW_SWIPE = 'AddDataPreviewSwipe',
  BUY_THERMOMETER_IN_APP = 'BuyThermometerInApp',
  CLICK_TO_PAY = 'ClickToPay',
  APPLY_DISCOUNT = 'ApplyDiscount',
  APPLIED_PREDEFINED_CART_DISCOUNT = 'AppliedPredefinedCartDiscount',
  REMOVE_DISCOUNT = 'RemoveDiscount',
  PURCHASE = 'Purchase',
  RESTORE_IAP = 'RestoreIAP',
  RENEW_SUBSCRIPTION_CLICK = 'RenewSubscriptionClick',
  PLANS_PAGE_YEAR = 'PlansPageYear',
  PLANS_PAGE_MONTH = 'PlansPageMonth',
  PLANS_PAGE_ROLLING_TRIAL = 'PlansPageRollingTrial',
  GRAPH_LEGEND_CLICK = 'GraphLegendClick',
  MULTI_ENTRIES_DISMISS = 'Dismiss MultipleEntriesModal',
  UNSYNCED_TEMPERATURES = 'UnsyncedTemperatures',
  // Thermometer log with timestamp < year 2000
  NO_TIMESTAMP_TEMPERATURE = 'NoTimestampTemperature',
  // Thermometer log with !temperature
  NO_TEMPERATURE_LOG = 'NoTemperatureLog',
  SAVE_APPEARANCE_SETTINGS = 'SaveAppearanceSettings',
  PARTNER_SETTINGS_CHANGE = 'PartnerSettingsChange',
  PARTNER_SETTINGS_CHANGE_START = 'PartnerSettingsChangeStartDate',
  PARTNER_SETTINGS_SAVE = 'PartnerSettingsSave',
  THERMOMETER_SETTINGS_CHANGE = 'ThermometerSettingsChange',
  THERMOMETER_WALKTHROUGH_STEP = 'ThermometerWalkthroughStep',
  THERMOMETER_PAIRING_PERMISSION = 'ThermometerPairingStep_Permission',
  THERMOMETER_PAIRING_ENABLED = 'ThermometerPairingStep_Enabled',
  THERMOMETER_PAIRING_START_THERMOMETER = 'ThermometerPairingStep_StartThermometer',
  THERMOMETER_PAIRING_PAIR = 'ThermometerPairingStep_Pair',
  BLUETOOTH_ENABLED = 'BluetoothEnabled',
  /** Event to see that we always start scanning when expected */
  BLUETOOTH_SCANNING = 'BluetoothScanning',
  /** Event to see all scan results so we could prove that we don't miss wanted devices */
  BLUETOOTH_SCANNING_RESULT = 'BluetoothScanningResult',
  /** Event to see when we stop scanning and for what reason */
  BLUETOOTH_SCANNING_STOPPED = 'BluetoothScanningStopped',
  BLUETOOTH_CONNECT = 'BluetoothConnect',
  BLUETOOTH_CONNECTION_ATTEMPT = 'BluetoothConnectionAttempt',
  BLUETOOTH_CONNECTION_ERROR = 'BluetoothConnectionError',
  BLUETOOTH_DISCONNECT = 'BluetoothDisconnect',
  BLUETOOTH_TEMPERATURE_SYNCED = 'BluetoothTemperatureSynced',
  BLUETOOTH_SYNCED = 'BluetoothSynced',
  BLUETOOTH_DEVICE_FOUND = 'BluetoothDeviceFound',
  BLUETOOTH_BATTERY_STATUS = 'BluetoothBatteryStatus',
  /** Event to see what we actually write the thermometer */
  BLUETOOTH_WRITE = 'BluetoothWrite',
  /** Event to see what we actually read from the thermometer */
  BLUETOOTH_READ = 'BluetoothRead',
  BLUETOOTH_NOTIFICATION = 'BluetoothNotification',
  BLUETOOTH_DEVICE_STATE = 'BluetoothDeviceState',
  FIRMWARE_UPDATE_FOTA = 'FirmwareUpdateFota',
  FIRMWARE_UPDATE_FOTA_INIT = 'FirmwareUpdateFotaInit',
  FIRMWARE_UPDATE_FOTA_PROGRESS = 'FirmwareUpdateFotaProgress',
  FIRMWARE_UPDATE_FOTA_PLUGIN_ERROR = 'FirmwareUpdateFotaPluginError',
  FIRMWARE_UPDATE_THERM_STATUS = 'FirmwareUpdateThermStatus',
  FW_DOWNLOADED = 'FWDownloaded',
  FOTA_SUCCEEDED = 'FotaSucceeded',
  FW_UPDATE_DONE = 'FwUpdateDone',
  FW_UPDATE_STATUS_CHANGED = 'FwUpdateStatusChanged',
  FW_BINARY_UNAVAILABLE = 'FwBinaryUnavailable',
  FW_FILE_SIZE = 'FWFileSize',
  THERMOMETER_FACTORY_RESET = 'ThermometerFactoryReset',
  OURA_SWITCH_REASON = 'OuraSwitchReason',
  HK_IMPORT_TOGGLED = 'HKImportToggled',
  HK_EXPORT_TOGGLED = 'HKExportToggled',
  HK_DATA_EXPORT = 'HKDataExport',
  HK_CAN_REQUEST_PERMISSION = 'HKCanRequestPermission',
  AW_SETUP_RESULT = 'AWSetupResult',
  MISSING_EXTERNAL_APP = 'MissingExternalApp',
  EXTERNAL_APP_OPENED = 'ExternalAppOpened',
  MULTIPLE_TEMPS_FOR_ONE_NIGHT = 'MultipleTempsForOneNight',
  CACHE_CLEARED = 'CacheCleared',
  ENTERED_SHIPPING_ADDRESS = 'EnteredShippingAddress',
  ENTERED_BILLING_ADDRESS = 'EnteredBillingAddress',
  TOGGLED_BILLING_ADDRESS = 'ToggledBillingAddress',
  AUTO_FILL_ADDRESS = 'AutofillAddress',
  REQUEST_ATT = 'RequestATT',
  BIO_AUTH_FAILED = 'BioAuthFailed',
  BIO_AUTH_SETTINGS_CHANGED = 'BioAuthSettingsChanged',
  BIO_AUTH_CHECK_AVAILABILITY = 'BioAuthCheckAvailability',
  RECENT_HORMONES_DEBUG = 'RecentHormonesDebug',
  MONTH_PAGE_SCROLL = 'MonthPageScroll',
  DEVICE_SELECTED = 'DeviceSelected',

  // Quiz
  QUIZ_ANSWER = 'QuizAnswer',
  QUIZ_AUTO_MODAL = 'QuizAutoModal',
  QUIZ_INFO_PAGE = 'QuizInfoPage',
  QUIZ_DEBUG = 'QuizDebug',

  // AB250
  VIEW_AB250_INTRO_SLIDE = 'ViewAB250IntroSlide',
}

export const ADJUST_ONLY_EVENT = [
  EVENT.INITIAL_PURCHASE_MONTH,
  EVENT.INITIAL_PURCHASE_FOURMONTH,
  EVENT.INITIAL_PURCHASE_YEAR,
  EVENT.INITIAL_PURCHASE_ROLLING_TRIAL,
  EVENT.PAYMENT_START,
  EVENT.PAYMENT_START_MONTH,
  EVENT.PAYMENT_START_FOURMONTH,
  EVENT.PAYMENT_START_YEAR,
  EVENT.PAYMENT_START_ROLLING_TRIAL,
  EVENT.CHOOSE_SUB_PAID,
  EVENT.CHOOSE_SUB_MONTH,
  EVENT.CHOOSE_SUB_YEAR,
  EVENT.CHOOSE_SUB_ROLLING_TRIAL,
  EVENT.ADDRESS_START,
]

export const ADJUST_TOKENS: Readonly<StringMap<string>> = {
  [EVENT.REGISTRATION_START]: '4m3frj',
  [EVENT.REGISTRATION_COMPLETE]: '3hwx0u',
  [EVENT.DEMO_ACCESS]: 'nd6q7e',
  [EVENT.INITIAL_PURCHASE_MONTH]: 'tpiplr',
  [EVENT.INITIAL_PURCHASE_FOURMONTH]: 'ophbam',
  [EVENT.INITIAL_PURCHASE_YEAR]: 'rrp9cz',
  [EVENT.INITIAL_PURCHASE_ROLLING_TRIAL]: 'hcd3c4',
  [EVENT.PAYMENT_START]: 'akwr5z',
  [EVENT.PAYMENT_START_MONTH]: 't1ubxn',
  [EVENT.PAYMENT_START_FOURMONTH]: 'm1fcpn',
  [EVENT.PAYMENT_START_YEAR]: 'pfgczw',
  [EVENT.PAYMENT_START_ROLLING_TRIAL]: '85g1sc',
  [EVENT.CHOOSE_SUB_PAID]: 'hxllwr',
  [EVENT.CHOOSE_SUB_MONTH]: '3hyhsl',
  [EVENT.CHOOSE_SUB_YEAR]: 'n7l2rm',
  [EVENT.CHOOSE_SUB_ROLLING_TRIAL]: 'o0181p',
  [EVENT.ADDRESS_START]: '14f2kx',
}

export enum AnalyticsComponentNameMap {
  DeviatingModal = 'DeviatingReasonPage',
  AddData = 'AddDataPage',
  CalibrateModal = 'CalibrateIntroPage',
  EndPregnancyModal = 'EndPregnancyPage',
  InfoModal = 'InfoPage',
  ThermometerPage = 'BuyThermometerPage',
}

export enum OptimoveEvent {
  pageViewApp = 'pageViewApp',
  registrationStart = 'registrationStart',
  registrationData = 'registrationData',
}

/**
 * To ensure that name of the Class is (manually) preserved after
 * js minification.
 */
export interface ClassName {
  className: string
}
