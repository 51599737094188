import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { IonicModule } from '@ionic/angular'
import { KnowingYourSexDriveOption } from '@naturalcycles/shared'
import { QuizProgressIndicatorComponent } from '@src/app/cmp/quiz/progress-indicator/progress-indicator.component'
import { QuizHeaderComponent } from '@src/app/cmp/quiz/quiz-header/quiz-header.component'
import { QuizSelectionComponent } from '@src/app/cmp/quiz/selection/selection.component'
import { quizAnimation, QuizSelectOption } from '@src/app/cnst/quiz.cnst'
import { QuizPage } from '@src/app/cnst/quiz-pages.cnst'
import { SharedModule } from '@src/app/shared.module'
import { dispatch, select } from '@src/app/srv/store.service'
import { Observable } from 'rxjs'
import { QuizBasePage } from '../quiz.base.page'

@Component({
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    QuizSelectionComponent,
    QuizHeaderComponent,
    IonicModule,
    QuizProgressIndicatorComponent,
  ],
  selector: `page-quiz-${QuizPage.knowingYourSexDrive}`,
  templateUrl: 'knowing-your-sex-drive.page.component.html',
  styleUrls: ['knowing-your-sex-drive.page.component.scss', '../quiz.base.scss'],
  animations: [quizAnimation],
})
export class KnowingYourSexDrivePage extends QuizBasePage {
  @select(['quiz', 'data', 'knowingYourSexDrive'])
  selectedKey$!: Observable<KnowingYourSexDriveOption>

  // prettier-ignore
  public selections: QuizSelectOption[] = [
    { key: KnowingYourSexDriveOption.veryWell, textKey: 'txt-quiz-KnowingYourSexDriveOption--veryWell' },
    { key: KnowingYourSexDriveOption.notVeryWell, textKey: 'txt-quiz-KnowingYourSexDriveOption--notVeryWell' },
    { key: KnowingYourSexDriveOption.notSure, textKey: 'txt-quiz-KnowingYourSexDriveOption--notSure' },
  ]

  public saveSelectedAnswer(key: KnowingYourSexDriveOption): void {
    dispatch('updateQuizData', { knowingYourSexDrive: key })
    this.analyticsService.trackEvent(this.analyticsEventName, {
      question: 'KnowingYourSexDrive',
      answer: KnowingYourSexDriveOption[key],
    })

    this.nextPage()
  }

  public nextPage(): void {
    void super.navigateForwardInQuiz()
  }
}
