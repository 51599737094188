import { Directive, ElementRef, inject, OnInit, output } from '@angular/core'
import { DeviceService } from '@app/srv/device.service'
import { di, runOutsideAngular } from '@app/srv/di.service'

export enum SwipeDirection {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export enum SwipeSource {
  CLICK = 'click',
  SWIPE = 'swipe',
}

export const swipeThreshold = 50

@Directive({
  selector: '[swipe]',
})
export class SwipeDirective implements OnInit {
  private elementRef = inject(ElementRef)
  swipe = output<string>()

  private touchStart!: number

  ngOnInit(): void {
    runOutsideAngular(() => {
      if (di.get(DeviceService).isTouch()) {
        this.elementRef.nativeElement.addEventListener('touchstart', this.onTouchStart.bind(this), {
          passive: true,
        })
        this.elementRef.nativeElement.addEventListener('touchend', this.onTouchEnd.bind(this), {
          passive: true,
        })
      } else {
        this.elementRef.nativeElement.addEventListener('mousedown', this.onMouseDown.bind(this), {
          passive: true,
        })
        this.elementRef.nativeElement.addEventListener('mouseup', this.onMouseUp.bind(this), {
          passive: true,
        })
      }
    })
  }

  onMouseDown(e: MouseEvent): void {
    this.touchStart = e.clientX
  }

  onMouseUp(e: MouseEvent): void {
    this.handleSwipe(e.clientX)
  }

  onTouchStart(e: TouchEvent): void {
    this.touchStart = e.changedTouches[0]!.clientX
  }

  onTouchEnd(e: TouchEvent): void {
    this.handleSwipe(e.changedTouches[0]!.clientX)
  }

  private handleSwipe(endClientX: number): void {
    let swipeDirection: SwipeDirection | undefined
    const touchDelta = endClientX - this.touchStart
    if (touchDelta > 0 && touchDelta > swipeThreshold) {
      swipeDirection = SwipeDirection.RIGHT
    } else if (touchDelta < 0 && Math.abs(touchDelta) > swipeThreshold) {
      swipeDirection = SwipeDirection.LEFT
    }

    if (swipeDirection) this.swipe.emit(swipeDirection)
  }
}
