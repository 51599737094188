import { inject, Injectable } from '@angular/core'
import { EVENT } from '@app/analytics/analytics.cnst'
import { AnalyticsService } from '@app/analytics/analytics.service'
import { AddDataSource } from '@app/cnst/add-data.cnst'
import { COLOR } from '@app/cnst/color.cnst'
import { LinkSource, ROUTES } from '@app/cnst/nav.cnst'
import { isAndroidApp } from '@app/cnst/userDevice.cnst'
import { AddDataPageService } from '@app/pages/add-data/add-data.page.service'
import { ReminderType } from '@app/pages/settings/reminders/reminders.service'
import { MeasureReminder } from '@app/reducers/notification.reducer'
import { DeviceService } from '@app/srv/device.service'
import { getState, select, select2 } from '@app/srv/store.service'
import { distinctUntilDeeplyChanged } from '@app/util/distinctUntilDeeplyChanged'
import { logUtil } from '@app/util/log.util'
import {
  Channel,
  ListChannelsResult,
  LocalNotificationDescriptor,
  LocalNotifications,
  LocalNotificationSchema,
  PendingLocalNotificationSchema,
  PendingResult,
} from '@capacitor/local-notifications'
import { _Memo, _stringify } from '@naturalcycles/js-lib'
import { HardwareId } from '@naturalcycles/shared'
import { dayjs } from '@naturalcycles/time-lib'
import { BehaviorSubject, Observable } from 'rxjs'
import { combineLatestWith, debounceTime, map, startWith } from 'rxjs/operators'
import { BadgeService } from './badge.service'
import { di } from './di.service'
import { EventService } from './event.service'
import { firstPageOpened } from './milestones'
import { NavService } from './nav.service'
import { dispatch } from './store.service'
import { tr } from './translation.util'
import { UFService } from './uf.service'

enum NotificationId {
  Badge = 0,
  HormonesQuitDate = 1,
}

export enum ChannelId {
  NCReminders = 'NCReminders',
  NCCycleReminders = 'NCCycleReminders',
  NCMessages = 'NCMessages',
  NCEducational = 'NCEducational',
  NCTipsAndOffers = 'NCTipsAndOffers',
}

export enum NotificationActionTypeId {
  MEASURING_REMINDER = 'MEASURING_REMINDER',
  PREPARATION_REMINDER = 'PREPARATION_REMINDER',
}

interface AndroidBadge extends Omit<LocalNotificationSchema, 'id'> {}

enum ActionId {
  OPEN_ADD_DATA = 'OPEN_ADD_DATA',
}

@Injectable({ providedIn: 'root' })
export class LocalNotificationService {
  private analyticsService = inject(AnalyticsService)
  private eventService = inject(EventService)
  private ufService = inject(UFService)
  private deviceService = inject(DeviceService)
  @select(['notifications', 'local', 'items'])
  private measureReminders$!: Observable<MeasureReminder[]>

  private expectedHormonesQuitDate$ = select2(
    s => s.account.onboardingData?.expectedHormonesQuitDate,
  )

  @select(['account', 'hwId'])
  public hwId$!: Observable<HardwareId>

  public androidBadge$ = new BehaviorSubject<AndroidBadge | undefined>(undefined)

  /**
   * Called either after app init or after giving notification permission
   * Should only be called once
   */
  @_Memo()
  public init(): void {
    this.measureReminders$
      .pipe(
        distinctUntilDeeplyChanged(),
        combineLatestWith(
          this.ufService.todayEntry$.pipe(
            map(entry => !!entry?.updated),
            distinctUntilDeeplyChanged(),
          ),
          this.androidBadge$,
          this.expectedHormonesQuitDate$,
          this.eventService.onResume$.pipe(startWith(1)),
          this.hwId$,
        ),
        debounceTime(500),
      )
      .subscribe(
        ([measureReminders, todayEntry, androidBadge, expectedHormonesQuitDate, _onResume, hwId]) =>
          void this.validate(
            measureReminders,
            todayEntry,
            hwId,
            androidBadge,
            expectedHormonesQuitDate,
          ),
      )

    if (isAndroidApp) {
      this.createChannels()

      this.registerActionTypes()
    }

    void LocalNotifications.removeAllListeners()

    void LocalNotifications.addListener(
      'localNotificationReceived',
      ({ id, actionTypeId, channelId, title, body }) => {
        void this.analyticsService.trackEvent(EVENT.NOTIFICATION_TRIGGERED, {
          type: NotificationId[id],
          actionTypeId,
          channelId,
          title,
          body,
          // Mixpanel doesn't treat objects nicely so we stringify it
          accountReminders: _stringify(getState().account.reminders),
        })
      },
    )

    void LocalNotifications.addListener(
      'localNotificationActionPerformed',
      async notificationAction => {
        const {
          actionId,
          notification: { id, actionTypeId, channelId, extra },
        } = notificationAction

        void this.analyticsService.trackEvent(EVENT.NOTIFICATION_OPENED, {
          type: NotificationId[id],
          actionTypeId,
          channelId,
          ...extra,
        })

        const { hwId } = getState().account

        if (
          actionId === ActionId.OPEN_ADD_DATA &&
          ((hwId !== HardwareId.APPLE_WATCH && hwId !== HardwareId.OURA) ||
            (hwId === HardwareId.OURA &&
              actionTypeId === NotificationActionTypeId.MEASURING_REMINDER))
        ) {
          dispatch('setBlockAutoOpen', true)

          await firstPageOpened
          void di
            .get(AddDataPageService)
            .openAddData({ source: AddDataSource.NOTIFICATION, skipPreview: true })
          return
        }

        const { link } = extra
        if (link) {
          // Don't open AddData if already open
          if (link === ROUTES.AddData && location.pathname === '/(morning:add-data)') return
          dispatch('setBlockAutoOpen', true)

          await firstPageOpened
          void di.get(NavService).processInternalLink(link, LinkSource.NOTIFICATION)
        }
      },
    )

    this.eventService.onLogout$.subscribe(() => {
      void this.cancelAll()
    })
  }

  public async registerPermission(): Promise<boolean> {
    const { display } = await LocalNotifications.requestPermissions().catch(() => ({
      display: 'denied',
    }))

    return display === 'granted'
  }

  public async listAllChannels(): Promise<Channel[]> {
    const channelList = await this.listChannels()

    return channelList?.channels || []
  }

  public async listAllNotifications(): Promise<PendingResult> {
    const pending = await LocalNotifications.getPending()

    return pending
  }

  private async cancelAll(): Promise<void> {
    const { notifications } = await this.listAllNotifications()

    if (!notifications.length) return

    await this.cancel(notifications)
  }

  private async cancel(
    request: LocalNotificationDescriptor[],
    cancelDuplicate = true,
  ): Promise<void> {
    const notifications: LocalNotificationDescriptor[] = request

    if (cancelDuplicate) {
      // If it is a reminder to measure, also cancel the duplicate one week later
      request.forEach(n => {
        const id = n.id
        if (id > 100 && id < 1000) {
          notifications.push({
            id: id * 10,
          })
        }
      })
    }

    await LocalNotifications.cancel({ notifications })

    void this.listAllNotifications()
  }

  private async schedule(_settings: LocalNotificationSchema): Promise<void> {
    const settings: LocalNotificationSchema = {
      iconColor: COLOR.DARK_PURPLE,
      smallIcon: 'logo_negative',
      channelId: ChannelId.NCReminders,
      sound: 'default',
      ..._settings,
    }

    const notifications = [settings]

    // schedule a duplicate reminder one week later for reminders to measure, reminder id's start at 101
    if (_settings.id > 100) {
      const nextWeek = dayjs(settings.schedule?.at).add(1, 'week').toDate()

      notifications.push({
        ...settings,
        id: settings.id * 10,
        schedule: {
          at: nextWeek,
          allowWhileIdle: true,
        },
      })
    }

    await LocalNotifications.schedule({
      notifications,
    }).catch(err => {
      logUtil.log('LocalNotifications.schedule error', { notifications })
      logUtil.error(err)
    })

    void this.listAllNotifications()
  }

  private createChannels(): void {
    // Delete default channels
    void this.deleteChannel('default')

    // Will be recreated automatically if needed
    void this.deleteChannel('fcm_fallback_notification_channel')

    void this.createChannel({
      id: ChannelId.NCReminders,
      name: 'Daily data reminders',
      description: 'Notifications for daily reminders to measure or sync',
      importance: 5,
      vibration: true,
    })

    void this.createChannel({
      id: ChannelId.NCCycleReminders,
      name: 'Cycle reminders',
      description: 'Notifications for cycle reminders',
      importance: 3,
      vibration: true,
    })

    void this.createChannel({
      id: ChannelId.NCMessages,
      name: 'Messages',
      description: 'Notifications for unread messages',
      importance: 1,
    })

    void this.createChannel({
      id: ChannelId.NCEducational,
      name: 'Educational content',
      description: 'Notifications related to our latest guides, quizzes, blog posts & more',
      importance: 1,
    })

    void this.createChannel({
      id: ChannelId.NCTipsAndOffers,
      name: 'Offers & Extras',
      description:
        'Get notifications on our latest promotions and how to make the most of our app & product offers',
      importance: 4,
      vibration: true,
    })
  }

  private registerActionTypes(): void {
    void LocalNotifications.registerActionTypes({
      types: [
        {
          id: NotificationActionTypeId.MEASURING_REMINDER,
          actions: [
            {
              id: ActionId.OPEN_ADD_DATA,
              title: tr('reminder-action-measure-title'),
              foreground: true,
            },
          ],
        },
        {
          id: NotificationActionTypeId.PREPARATION_REMINDER,
          actions: [
            {
              id: ActionId.OPEN_ADD_DATA,
              title: tr('reminder-action-preparation-title'),
              foreground: true,
            },
          ],
        },
      ],
    })
  }

  /*
   * Notification channels don't exist on Android <8. Catching all 'not available' errors here to avoid spamming Sentry
   */
  private async deleteChannel(id: string): Promise<void> {
    try {
      await LocalNotifications.deleteChannel({ id })
    } catch (err) {
      logUtil.log('[notifications] [error] deleteChannel', JSON.stringify(err))
    }
  }

  private async createChannel(channel: Channel): Promise<void> {
    try {
      await LocalNotifications.createChannel(channel)
    } catch (err) {
      logUtil.log('[notifications] [error] createChannel', JSON.stringify(err))
    }
  }

  private async listChannels(): Promise<ListChannelsResult> {
    try {
      return await LocalNotifications.listChannels()
    } catch (err) {
      logUtil.log('[notifications] [error] listChannels', JSON.stringify(err))

      return {
        channels: [],
      }
    }
  }

  private async validate(
    measureReminders: MeasureReminder[],
    todayEntry: boolean,
    hwId: HardwareId,
    androidBadge?: AndroidBadge,
    expectedHormonesQuitDate?: string,
  ): Promise<void> {
    const pending = await this.listAllNotifications()
    const notifications = [...pending.notifications]
    const okScheduled: number[] = []

    if (measureReminders.length) {
      const handled = this._handleMeasureReminders(notifications, measureReminders, hwId)

      okScheduled.push(...handled)
    }

    if (todayEntry) {
      void this.rescheduleTodayMeasuringReminders()
    }

    if (androidBadge) {
      this.scheduleAndroidBadge(androidBadge)
    }

    if (expectedHormonesQuitDate) {
      const handled = this.handleExpectedHormonesQuitDate(notifications, expectedHormonesQuitDate)

      okScheduled.push(...handled)
    }

    if (measureReminders.some(reminder => reminder.hwId !== hwId)) {
      await this.cancelAll()
      this._handleMeasureReminders(notifications, measureReminders, hwId)
    }

    // If any of the scheduled notifications are not handled above by measure reminders or hormone quit reminder, cancel them!
    const notHandledNotifications = notifications.filter(
      n => !okScheduled.includes(n.id) && !this.isAppleWatchNotification(n),
    )

    notHandledNotifications.forEach(({ id }) => this.cancel([{ id }]))
  }

  private isAppleWatchNotification(notification: PendingLocalNotificationSchema): boolean {
    return notification.extra['id'] === 'apple-watch-temperature'
  }

  public _handleMeasureReminders(
    notifications: PendingLocalNotificationSchema[],
    measureReminders: MeasureReminder[],
    hwId: HardwareId,
  ): number[] {
    const enabledReminders = measureReminders
      .filter(r => r.enabled)
      .flatMap(reminder => {
        const { id, days, time } = reminder

        const enabledDays = Object.keys(days)
          .filter(k => days[k].enabled)
          .map(val => parseInt(val))

        return enabledDays.map(dayId => ({ id: id * 100 + dayId, time }))
      })

    const toBeScheduled: number[] = []
    const alreadyScheduled: number[] = []

    enabledReminders.forEach(reminder => {
      const matchingNotification = notifications.find(n => n.id === reminder.id)

      // day is not scheduled
      if (!matchingNotification) {
        // cancel eventual duplicate reminder
        void this.cancel([{ id: reminder.id * 10 }])

        return toBeScheduled.push(reminder.id)
      }

      const [hour, minute] = reminder.time?.split(':') || []
      const at = matchingNotification.schedule?.at
      const atHour = dayjs(at).hour()
      const atMinute = dayjs(at).minute()

      // scheduled with correct time
      if (Number(hour) === atHour && Number(minute) === atMinute) {
        return alreadyScheduled.push(reminder.id)
      }

      // scheduled with incorrect time
      void this.cancel([{ id: reminder.id }], true)

      toBeScheduled.push(reminder.id)
    })

    toBeScheduled.forEach(id => this.scheduleMeasureReminder(id, measureReminders, hwId))

    return [
      ...alreadyScheduled,
      ...alreadyScheduled.map(id => id * 10),
      ...toBeScheduled,
      ...toBeScheduled.map(id => id * 10),
    ]
  }

  private async rescheduleTodayMeasuringReminders(): Promise<void> {
    const { notifications } = await this.listAllNotifications()

    const todayMeasuringNotifications = notifications.filter(({ extra, schedule }) => {
      if (extra?.type !== ReminderType.MEASURING) return false

      return dayjs(schedule?.at).isSame(dayjs(), 'day')
    })

    todayMeasuringNotifications.forEach(n => {
      void this.cancel([n])

      void this.schedule({
        ...n,
        schedule: {
          ...n.schedule,
          at: dayjs(n.schedule?.at).add(1, 'week').toDate(),
        },
      })
    })
  }

  private scheduleAndroidBadge(badge: AndroidBadge): void {
    const { title, body, channelId, extra } = badge

    void this.schedule({
      id: NotificationId.Badge,
      title,
      body,
      channelId,
      extra,
    })

    di.get(BadgeService).clearBadge()
  }

  private handleExpectedHormonesQuitDate(
    notifications: PendingLocalNotificationSchema[],
    date?: string,
  ): number[] {
    const scheduleTime = dayjs(date).hour(19)

    if (scheduleTime.isBefore(dayjs())) return []

    const alreadyScheduled = notifications.some(
      n =>
        n.id === NotificationId.HormonesQuitDate &&
        dayjs(n.schedule?.at).isSame(dayjs(date), 'date'),
    )

    if (!alreadyScheduled) {
      void this.schedule({
        id: NotificationId.HormonesQuitDate,
        title: tr('onboarding-exit-demo-push-title'),
        body: tr('onboarding-exit-demo-push-txt'),
        largeBody: tr('onboarding-exit-demo-push-txt'), // include largeBody to make notifications expandable on Android
        schedule: {
          at: scheduleTime.toDate(),
        },
        extra: {
          link: ROUTES.OnboardingDemoExitPage,
        },
        attachments: [
          {
            id: 'start_measuring',
            url: this.deviceService.isIOSDevice
              ? 'res:///assets/img/onboarding/start_measuring_sq.png'
              : 'public/assets/img/onboarding/start_measuring_ls.png',
          },
        ],
      })
    }

    return [NotificationId.HormonesQuitDate]
  }

  private scheduleMeasureReminder(
    id: number,
    measureReminders: MeasureReminder[],
    hwId: HardwareId,
  ): void {
    const nId = Math.floor(id / 100)

    const { days, time, type } = measureReminders.find(r => r.id === nId) || {}

    if (!time || !days || !type) return

    const weekday = Object.keys(days).find(key => parseInt(key) === id - nId * 100)

    if (!weekday) return

    const { title, text } = days[weekday] || {}

    const date = this.getDateFromTimeAndDay(time, parseInt(weekday))

    const settings: LocalNotificationSchema = {
      id,
      title,
      body: text,
      largeBody: text, // include largeBody to make notifications expandable on Android
      schedule: {
        at: date,
        allowWhileIdle: true,
      },
      extra: {
        type,
        link: ROUTES.AddData,
        hwId,
      },
      actionTypeId:
        type === ReminderType.MEASURING
          ? NotificationActionTypeId.MEASURING_REMINDER
          : NotificationActionTypeId.PREPARATION_REMINDER,
    }

    void this.schedule(settings)
  }

  private getDateFromTimeAndDay(time: string, weekday: number): Date {
    const [hour, minute] = time.split(':')
    let date = dayjs().isoWeekday(weekday).hour(parseInt(hour!)).minute(parseInt(minute!)).second(0)

    if (dayjs().diff(date) > 0) {
      date = date.add(1, 'week')
    }

    return date.toDate()
  }
}
