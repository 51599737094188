import { Component, Input, output } from '@angular/core'
import { IonicModule } from '@ionic/angular'
import { ICON } from '@src/app/cnst/icons.cnst'
import { QuizSelectOption } from '@src/app/cnst/quiz.cnst'
import { SharedModule } from '@src/app/shared.module'

@Component({
  standalone: true,
  imports: [SharedModule, IonicModule],
  selector: 'app-quiz-selection',
  templateUrl: './selection.component.html',
  styleUrls: ['./selection.component.scss'],
})
export class QuizSelectionComponent {
  @Input()
  public selections!: QuizSelectOption[]

  @Input()
  public selectedKey?: number

  @Input()
  public selectedKeys?: number[]

  @Input()
  public twoColumns = false

  @Input()
  public isMultiSelect = false

  @Input()
  public continue: () => void | (() => Promise<void>) = () => {}

  public selectedKeyEmitter = output<number>()

  public selectedKeysEmitter = output<number[]>()

  public ICON = ICON

  public select(key: number): void {
    if (!this.isMultiSelect) {
      this.selectedKeyEmitter.emit(key)
      this.selectedKey = key
    } else {
      this.selectedKeys ??= []
      const index = this.selectedKeys.indexOf(key)
      if (index === -1) {
        this.selectedKeys.push(key)
      } else {
        this.selectedKeys.splice(index, 1)
      }
      this.selectedKeysEmitter.emit(this.selectedKeys)
    }
  }
}
