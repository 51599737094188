/**
 * Checks localStorage.apiHost variable.
 * Validates it.
 * Overrides apiHost to this value if it's allowed to.
 * Used to run e2e tests for "branch deployments" for NCBackend3/NCBackend.
 * Mutates the environment object passed.
 */
export function checkApiHost(env: any): void {
  try {
    if (env.prod) return // disabled in prod

    const apiHost = localStorage.getItem('apiHost')
    if (!apiHost) return

    if (validateApiHost(apiHost)) {
      // Override apiHost
      Object.assign(env, {
        apiHost,
        apiUrl: `${apiHost}/api/v3`,
      })

      // console.log({apiHost})
    }
  } catch {}
}

function validateApiHost(apiHost: string): boolean {
  const url = new URL(apiHost)
  const host = url.host

  // Used for local backend in perf testing
  if (url.hostname === '127.0.0.1') return true

  if (
    url.protocol === 'https:' &&
    (host === 'naturalcycles.com' ||
      host.endsWith('.naturalcycles.com') ||
      host === 'ncbackend-test.appspot.com' ||
      host.endsWith('-dot-ncbackend-test.appspot.com') ||
      host === 'ncbackend.appspot.com' ||
      host.endsWith('-dot-ncbackend.appspot.com'))
  ) {
    return true
  }

  console.warn(`apiHost is not allowed: ${host}`)
  return false
}

export const isE2e = !!localStorage.getItem('e2e')
