import { inject, Injectable } from '@angular/core'
import { TemperatureDataState } from '@app/cnst/add-data.cnst'
import { DateFormat, DateService } from '@app/srv/date.service'
import { di } from '@app/srv/di.service'
import { getState } from '@app/srv/store.service'
import { DailyEntryBM, DataFlag, FWVersionSeverity, HardwareId } from '@naturalcycles/shared'
import { dayjs } from '@naturalcycles/time-lib'
import { BluetoothService } from '@src/app/srv/bluetooth.service'
import { ExperimentService } from '@src/app/srv/experiment.service'

@Injectable({ providedIn: 'root' })
export class AddDataHWDeviceService {
  private bluetoothService = inject(BluetoothService)
  private experimentService = inject(ExperimentService)

  public getDataState(entry: DailyEntryBM): TemperatureDataState | undefined {
    switch (this.hwId) {
      case HardwareId.UEBE_THERMOMETER:
      case HardwareId.T3_THERMOMETER:
        return this.getConnectedThermDataState(entry)
      case HardwareId.OURA:
        return this.getOuraDataState(entry)
      case HardwareId.APPLE_WATCH:
        return this.getAppleWatchDataState(entry)
      default:
        return this.getOralThermometerDataState(entry)
    }
  }

  private getConnectedThermDataState(entry: DailyEntryBM): TemperatureDataState {
    const { latestHWDeviceFWVersion, account } = getState()

    if (account.demoMode) return TemperatureDataState.SYNCED

    if (!this.hwDeviceId) return TemperatureDataState.NOT_PAIRED

    if (
      latestHWDeviceFWVersion?.severity === FWVersionSeverity.CRITICAL &&
      latestHWDeviceFWVersion.releaseDate <= entry.date
    ) {
      return TemperatureDataState.EXCLUDED_FIRMWARE
    }

    if (entry.dataFlags.includes(DataFlag.DEVIATION_REASON_ALGO)) {
      return TemperatureDataState.EXCLUDED_ALGO
    }

    if (entry.temperatureMeasuredTimestamp) return TemperatureDataState.SYNCED

    if (entry.temperatureUpdatedTimestamp) return TemperatureDataState.ADDED_MANUALLY

    if (dayjs().isAfter(entry.date, 'date')) return TemperatureDataState.NO_DATA

    if (this.bluetoothService.bluetoothEnabled$.value === false) {
      return TemperatureDataState.BLUETOOTH_OFF
    }

    return TemperatureDataState.NOT_SYNCED
  }

  private getOuraDataState(entry: DailyEntryBM): TemperatureDataState {
    const dataFlags = entry.dataFlags || []

    if (dataFlags.includes(DataFlag.DEVIATION_REASON_ALGO)) {
      return TemperatureDataState.EXCLUDED_ALGO
    }

    if (dataFlags.includes(DataFlag.OURA_ADJUSTED_TEMPERATURE)) {
      return TemperatureDataState.ADJUSTED_TEMPERATURE
    }

    if (dataFlags.includes(DataFlag.OURA_INCOMPLETE_DATA)) {
      return TemperatureDataState.INCOMPLETE_DATA
    }

    if (dataFlags.includes(DataFlag.OURA_IRREGULAR_SLEEP)) {
      return TemperatureDataState.EXCLUDED_SLEEP
    }

    if (dataFlags.includes(DataFlag.OURA_DEVIATION_ALGO)) {
      return TemperatureDataState.EXCLUDED_OURA_ALGO
    }

    if (dataFlags.includes(DataFlag.OURA_SHORT_SLEEP)) {
      return TemperatureDataState.SHORT_SLEEP
    }

    // There's data for today
    if (entry.temperature) {
      return TemperatureDataState.SYNCED
    }

    // Entry is >7 days old or there's a more recent temperature entry
    const sevenDaysBeforeToday = dayjs().subtract(7, 'day')
    const { entryMap } = getState().userFertility
    const latestDayWithTemp = Object.keys(entryMap)
      .sort()
      .reverse()
      .find(date => !!entryMap[date]!.temperature)
    const latestIsAfterEntryDate =
      latestDayWithTemp && dayjs(latestDayWithTemp).isAfter(entry.date, 'day')

    if (sevenDaysBeforeToday.isAfter(entry.date, 'day') || latestIsAfterEntryDate) {
      return TemperatureDataState.INCOMPLETE_DATA
    }

    // 'Sync Oura Ring' clicked after latest data received
    const { lastSyncClick } = getState().oura
    if (lastSyncClick && dayjs(lastSyncClick).isSameOrAfter(dayjs(entry.date), 'day')) {
      return TemperatureDataState.WAITING_FOR_DATA
    }

    return TemperatureDataState.NOT_SYNCED
  }

  private getAppleWatchDataState(entry: DailyEntryBM): TemperatureDataState | undefined {
    if (entry.dataFlags.includes(DataFlag.DEVIATION_REASON_ALGO)) {
      return TemperatureDataState.EXCLUDED_ALGO
    }

    if (entry.temperature) return TemperatureDataState.ADDED

    if (dayjs().isAfter(entry.date, 'date')) return TemperatureDataState.NO_DATA
  }

  private getOralThermometerDataState(entry: DailyEntryBM): TemperatureDataState | undefined {
    if (entry.dataFlags.includes(DataFlag.DEVIATION_REASON_ALGO)) {
      return TemperatureDataState.EXCLUDED_ALGO
    }

    if (entry.temperature) return TemperatureDataState.ADDED

    if (dayjs().isAfter(entry.date, 'date')) return TemperatureDataState.NO_DATA
  }

  public getFormattedTimestamp(entry: DailyEntryBM): string | undefined {
    const timestamp =
      entry.temperatureMeasuredTimestamp || entry.temperatureUpdatedTimestamp || entry.updated

    if (!timestamp) return

    const updatedDate = dayjs.unix(timestamp).toISODate()
    const format = entry.date === updatedDate ? DateFormat.TIME : DateFormat.DAY_MONTH_TIME

    return di.get(DateService).localizeDateTime(dayjs.unix(timestamp), format)
  }

  public inOuraMode(): boolean {
    return this.hwId === HardwareId.OURA
  }

  public inAppleWatchMode(): boolean {
    return this.hwId === HardwareId.APPLE_WATCH
  }

  public inWearableMode(): boolean {
    return this.inOuraMode() || this.inAppleWatchMode()
  }

  public inUebeMode(): boolean {
    return this.hwId === HardwareId.UEBE_THERMOMETER
  }

  private get hwId(): HardwareId | undefined {
    return getState().account.hwId
  }

  private get hwDeviceId(): string | undefined {
    return getState().hwDevice?.mac
  }
}
