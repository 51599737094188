import { Component } from '@angular/core'
import { ICON } from '@app/cnst/icons.cnst'
import { IonicModule } from '@ionic/angular'
import { QuizProgressIndicatorComponent } from '@src/app/cmp/quiz/progress-indicator/progress-indicator.component'
import { SharedModule } from '@src/app/shared.module'

@Component({
  selector: 'app-quiz-header',
  templateUrl: './quiz-header.component.html',
  styleUrls: ['../../../pages/quiz/quiz.base.scss', './quiz-header.component.scss'],
  imports: [IonicModule, SharedModule, QuizProgressIndicatorComponent],
  standalone: true,
})
export class QuizHeaderComponent {
  public ICON = ICON
}
