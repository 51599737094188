import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, HostBinding, inject } from '@angular/core'
import { DebugT3Page } from '@app/pages/debug-t3/debug-t3.page'
import { DebugUebePage } from '@app/pages/debug-uebe/debug-uebe.page'
import { SharedModule } from '@app/shared.module'
import { PopupController, Priority } from '@app/srv/popup.controller'
import { select2 } from '@app/srv/store.service'
import { IonicModule } from '@ionic/angular'
import { HardwareId } from '@naturalcycles/shared'
import { Observable } from 'rxjs'
import { combineLatestWith, map } from 'rxjs/operators'

const pageByHardwareId = {
  [HardwareId.UEBE_THERMOMETER]: DebugUebePage,
  [HardwareId.T3_THERMOMETER]: DebugT3Page,
}

const colorByHardwareId = {
  [HardwareId.UEBE_THERMOMETER]: 'blue',
  [HardwareId.T3_THERMOMETER]: 'blue',
}

@Component({
  selector: 'app-blue-dot',
  standalone: true,
  imports: [IonicModule, SharedModule, CommonModule],
  templateUrl: './blue-dot.component.html',
  styleUrls: ['./blue-dot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlueDotComponent {
  private popupController = inject(PopupController)

  @HostBinding('style.--background')
  private backgroundColor = 'blue'

  private admin$ = select2(s => s?.ui.admin)
  private hwId$ = select2(s => s?.account.hwId)

  public blueDot$: Observable<false | HardwareId> = this.admin$.pipe(
    combineLatestWith(this.hwId$),
    map(([admin, hwId]) => {
      if (!admin) return false
      if (!pageByHardwareId[hwId]) return false

      this.backgroundColor = colorByHardwareId[hwId]

      return hwId
    }),
  )

  public onClick(hwId: HardwareId): void {
    void this.popupController.presentModal(
      { component: pageByHardwareId[hwId] },
      'modal-bluetoothDebugPage',
      Priority.IMMEDIATE,
    )
  }
}
