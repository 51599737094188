import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-illustration',
  templateUrl: './illustration.component.html',
  styleUrls: ['./illustration.component.scss'],
  standalone: true,
})
export class IllustrationComponent {
  @Input()
  src?: string
}
