import { Directive, ElementRef, inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core'
import { DomController } from '@ionic/angular'
import { TooltipService } from '@src/app/srv/tooltip.service'
import { TourTooltip } from '@src/app/srv/tour.cnst'
import { TourService, TourTooltipKey } from '@src/app/srv/tour.service'
import { Subscription } from 'rxjs'

@Directive({
  selector: '[tour]',
  // for debugging
  // host: {
  //   '[style.background-color]': '"red"',
  //   '[style.height]': '"10px"',
  //   '[style.width]': '"10px"',
  // }
})
export class TourDirective implements OnInit, OnDestroy {
  private tourService = inject(TourService)
  private renderer = inject(Renderer2)
  private dom = inject(DomController)
  protected elementRef = inject(ElementRef)
  private tooltipService = inject(TooltipService)
  @Input()
  public tour?: TourTooltip

  @Input()
  public tooltipPosition?: 'top' | 'bottom'

  private subscription?: Subscription

  public ngOnInit(): void {
    this.subscription = this.tourService.popupTooltip$.subscribe(tooltip =>
      this.handleTourStep(tooltip),
    )
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe()
  }

  private handleTourStep(tooltip: TourTooltipKey): void {
    if (tooltip.id !== this.tour) return

    this.createTooltip(tooltip.key)
  }

  private createTooltip(key: string): void {
    this.dom.read(async () => {
      const rect = this.elementRef.nativeElement.getBoundingClientRect()

      const offsetX = rect.width / 2 + rect.x

      const container = this.tooltipService.getContainer(this.elementRef.nativeElement)

      const appRect = document.getElementById('root_nav')!.getBoundingClientRect()
      const appOffset = appRect.left

      const tooltip = await this.tooltipService.createTooltip(
        container,
        this.elementRef.nativeElement,
        offsetX - appOffset,
        key,
        undefined,
        this.tooltipPosition,
      )

      this.dom.write(() => {
        void this.tooltipService.renderTooltip(tooltip, this.renderer, 3000)
      })

      const element = await container.getScrollElement()
      const { clientHeight, scrollTop } = element

      const visible =
        tooltip.posY > scrollTop + 100 && tooltip.posY < clientHeight + scrollTop - 100

      if (!visible) {
        void container.scrollToPoint(0, tooltip.posY - appRect.height * 0.25, 400)
      }
    })
  }
}
