import { UnixTimestampNumber } from '@naturalcycles/js-lib'
import { LoginOrRegisterInput } from '@naturalcycles/shared'
import { Action } from 'redux'

export interface UserDeviceAuth extends Pick<LoginOrRegisterInput, 'email' | 'pw'> {
  lastLoginTime: UnixTimestampNumber
}

type State = UserDeviceAuth | null

const initialState: State = null

const actions: any = {
  replaceUserDeviceAuth(state: State, action: { type: string; payload: State }): State {
    if (action.payload) {
      return {
        email: action.payload.email,
        pw: action.payload.pw,
        lastLoginTime: action.payload.lastLoginTime,
      }
    }

    return state
  },

  resetUserDeviceAuth(): State {
    return initialState
  },
}

// biome-ignore lint/style/useDefaultParameterLast: ok
export function userDeviceAuthReducer(state = initialState, action: Action): State {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
