import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router'
import { ROUTES } from '@app/cnst/nav.cnst'
import { quizParentSlug } from '@app/cnst/quiz.cnst'
import { QuizPage } from '@app/cnst/quiz-pages.cnst'
import { bootstrapDone } from '@app/srv/milestones'
import { getState } from '@app/srv/store.service'
import { QuizService } from '@src/app/srv/quiz/quiz.service'

export const canActivateQuiz: CanActivateFn = async (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
) => {
  const router = inject(Router)
  const quizService = inject(QuizService)

  await bootstrapDone

  const { quiz, account } = getState()

  const page: QuizPage = route.data['page']

  const redirectPage = quizService.getEngine().canActivateQuizPage(page, quiz.flow, quiz.data)

  // If the user has a goal already set, it means they have gone through the onboarding
  // and signed up already, as the goal is set after the auth step
  // therefore we should not show them the quiz again
  const hasGoal = !!account.goal

  if (hasGoal) {
    return router.createUrlTree([ROUTES.SignupMeasuringDevicePage])
  }

  if (!redirectPage) {
    // All conditions are met for the user to view the current page
    return true
  }

  return router.createUrlTree([`${quizParentSlug}/${redirectPage}`])
}
