import { Directive } from '@angular/core'
import { AnalyticsComponentNameMap, EVENT } from '@app/analytics/analytics.cnst'
import { AnalyticsService } from '@app/analytics/analytics.service'
import { di } from '@app/srv/di.service'
import { EventService } from '@app/srv/event.service'
import { PopupController } from '@app/srv/popup.controller'
import { logUtil } from '@app/util/log.util'
import { StringMap } from '@naturalcycles/js-lib'
import { BasePage } from './base.page'

@Directive()
export abstract class BaseModal extends BasePage {
  protected analyticsProps?: StringMap<any>
  protected override blockPopups = true

  public constructor() {
    super()

    const originalionViewWillEnter = this.ionViewWillEnter

    this.ionViewWillEnter = () => {
      void this._viewWillEnter()
      originalionViewWillEnter.apply(this)
    }

    const originalIonViewDidEnter = this.ionViewDidEnter
    this.ionViewDidEnter = () => {
      this._viewDidEnter()
      originalIonViewDidEnter.apply(this)
    }

    const originalIonViewWillLeave = this.ionViewWillLeave
    this.ionViewWillLeave = () => {
      this._viewWillLeave()
      originalIonViewWillLeave.apply(this)
    }

    const originalIonViewDidLeave = this.ionViewDidLeave
    this.ionViewDidLeave = () => {
      this._viewDidLeave()
      originalIonViewDidLeave.apply(this)
    }
  }

  private _viewWillEnter(): void {
    logUtil.log(`${this.className}.BaseModal.ionViewWillEnter`)
  }

  private _viewDidEnter(): void {
    const { className, analyticsProps } = this
    logUtil.log(`${className}.BaseModal.ionViewDidEnter`)

    if (className === 'ModalNavPage') {
      // no analytics
    } else if (className === 'InfoModal' && (this as any).body) {
      void di.get(AnalyticsService).trackView(EVENT.INFOMODAL, {
        text: (this as any).body,
      })
    } else {
      // Default analytics
      const PAGE_NAME =
        analyticsProps?.['PAGE_NAME'] || AnalyticsComponentNameMap[className] || className

      void di.get(AnalyticsService).trackView(PAGE_NAME, this.analyticsProps)
    }

    this.updateNavigationBarColor()
  }

  private _viewWillLeave(): void {}

  private _viewDidLeave(): void {
    di.get(EventService).modalClosed$.next()

    this.updateNavigationBarColor()
  }

  async dismissModal(options?: any): Promise<void> {
    await di.get(PopupController).dismissActive(options)
  }
}
