import { inject, Injectable } from '@angular/core'
import { T3_TEMPERATURE_MAP, T3_TEMPERATURE_MAP_FAHRENHEIT } from '@app/srv/t3-temperature.cnst'
import { UnitsService } from '@app/srv/units.service'
import { _round } from '@naturalcycles/js-lib'

@Injectable({ providedIn: 'root' })
export class TemperatureService {
  private unitsService = inject(UnitsService)

  public parseSyncedTemperature(temperature: number, fahrenheit: boolean): number {
    if (fahrenheit) {
      return _round(this.unitsService.convertTempToFahrenheit(temperature / 100), 0.01)
    }

    return _round(temperature / 100, 0.01)
  }

  public parseSyncedT3Temperature(temperature: number, fahrenheit: boolean): number {
    if (fahrenheit) {
      return Number(T3_TEMPERATURE_MAP_FAHRENHEIT[temperature])
    }

    return Number(T3_TEMPERATURE_MAP[temperature])
  }
}
