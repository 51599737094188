import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  inject,
  Input,
  OnChanges,
  output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core'
import { EVENT } from '@app/analytics/analytics.cnst'
import { AnalyticsService } from '@app/analytics/analytics.service'
import {
  SuperSegmentItem,
  SuperSegmentSpecialButton,
  SuperSegmentValue,
} from '@app/cnst/add-data.cnst'
import { ICON } from '@app/cnst/icons.cnst'
import { DomController, IonRadioGroup } from '@ionic/angular'
import { _deepEquals, pDefer, StringMap } from '@naturalcycles/js-lib'
import { BehaviorSubject } from 'rxjs'

interface SuperSegmentItemWithSelected extends SuperSegmentItem {
  selected?: boolean
  selectedOption?: string
}

// enums needs to be exported if they are used in animations, https://github.com/angular/angular/issues/24047
export enum SuperSegmentState {
  MIDDLE = 'MIDDLE',
  NEUTRAL = 'NEUTRAL',
  OPTIONS = 'OPTIONS',
}

interface SuperSegmentItemSize {
  rect: { left: number; width: number }
  offsets: {
    [SuperSegmentState.NEUTRAL]: number
    [SuperSegmentState.MIDDLE]: number
    [SuperSegmentState.OPTIONS]?: number
    optionsItems?: number
  }
  optionHeight?: number
  optionPosition?: 'bottom' | 'bottomRows'
}

const viewInit = pDefer<void>()

@Component({
  selector: 'app-super-segment',
  templateUrl: 'super-segment.component.html',
  styleUrls: ['super-segment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuperSegmentComponent implements AfterViewInit, OnChanges {
  private elementRef = inject<ElementRef<HTMLElement>>(ElementRef<HTMLElement>)
  private analyticsService = inject(AnalyticsService)
  private dom = inject(DomController)
  private cdr = inject(ChangeDetectorRef)
  @Input()
  public items!: SuperSegmentItemWithSelected[]

  @Input()
  public value?: SuperSegmentValue

  @Input()
  public color?: string

  @Input()
  public ready?: boolean

  @Input()
  public prefixIcon?: ICON

  @Input()
  public readonly = false

  public valueChange = output<SuperSegmentValue>()

  public specialButtonClick = output<SuperSegmentSpecialButton>()

  @ViewChild(IonRadioGroup, { static: true })
  private radio!: IonRadioGroup

  @ViewChildren('superSegmentItem')
  private superSegmentItems!: QueryList<ElementRef<HTMLElement>>

  @ViewChildren('options')
  private optionGroups?: QueryList<ElementRef<HTMLElement>>

  public currentOffsets$ = new BehaviorSubject<StringMap<number>>({})
  public itemSizes$ = new BehaviorSubject<StringMap<SuperSegmentItemSize>>({})

  public SuperSegmentState = SuperSegmentState
  public ICON = ICON
  public SuperSegmentSpecialButton = SuperSegmentSpecialButton
  public segmentState: StringMap<SuperSegmentState> = {}
  public selectedIcon?: string
  public initDone = false
  public maxWidth = 0

  private documentWidth = window.innerWidth

  public ngAfterViewInit(): void {
    viewInit.resolve()
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // if (Object.values(changes).find(i => !i.firstChange)) {
    //   console.warn('[ss] re-render super-segment', changes)
    // }

    if (changes['value']) {
      this.setValue(changes['value'].currentValue, changes['value'].firstChange)
    }

    if (changes['items']) {
      this.setItems(changes['items'].currentValue || [], !!changes['value']?.firstChange)
    }

    if (changes['ready']?.currentValue) {
      void this.onReady()
    }
  }

  private async onReady(): Promise<void> {
    await this.updateOffsets()

    this.updateState(this.value || {}, false)

    setTimeout(() => {
      this.initDone = true
    })
  }

  private setItems(items: SuperSegmentItemWithSelected[], firstChange: boolean): void {
    this.items = items.map(i => {
      const { value, option } = this.value || {}

      return {
        ...i,
        selected: i.value === value,
        selectedOption: option ? `${option}` : undefined,
      }
    })

    this.updateState(this.value || {}, !firstChange) // dont show options if we are settings the initial value
  }

  private setValue(superSegmentValue: SuperSegmentValue = {}, firstChange?: boolean): void {
    this.updateState(superSegmentValue, !firstChange) // dont show options if we are settings the initial value

    this.setItems(this.items, false) // reset items to set selected & selectedOption

    let { value, option } = superSegmentValue

    // update selected icon
    if (option) {
      this.selectedIcon = this.getIconForOption(option) || this.selectedItem?.icon
    } else {
      this.selectedIcon = this.selectedItem?.icon
    }

    // remove option if its not available
    if (!this.isOptionAvailable(this.items, option)) {
      option = undefined
    }

    this.radio.value = option ? `${value}--${option}` : value

    // if there is no selected value -> update widths after animation is complete
    if (!value && this.initDone) {
      setTimeout(() => {
        void this.updateOffsets()
      }, 300)
    }
  }

  public async updateOffsets(): Promise<void> {
    await viewInit

    this.dom.read(() => {
      const parentWidth = this.elementRef.nativeElement.parentElement?.getBoundingClientRect().width

      const elementRect = this.elementRef.nativeElement.getBoundingClientRect()

      this.maxWidth = parentWidth || elementRect.width

      const _itemSizes: StringMap<SuperSegmentItemSize> = {}

      // set size of items
      this.superSegmentItems.forEach((element: ElementRef<HTMLElement>) => {
        const item = element.nativeElement.attributes['itemvalue'].value as string

        const iconSize = this.selectedIcon ? 24 : 0

        const _rect = element.nativeElement.getBoundingClientRect()
        const rect = {
          left: _rect.left + iconSize / 2,
          width: _rect.width - iconSize,
        }

        const offset = elementRect.width / 2 - (rect.left - elementRect.left) - rect.width / 2

        _itemSizes[item] = {
          rect,
          offsets: {
            [SuperSegmentState.NEUTRAL]: 0,
            [SuperSegmentState.MIDDLE]: offset,
          },
        }
      })

      // set size of options
      this.optionGroups?.forEach((option: ElementRef<HTMLElement>) => {
        const item = option.nativeElement.attributes['itemvalue'].value as string

        const itemSize = _itemSizes[item]
        if (!itemSize) return

        const itemOffset = itemSize.offsets
        const optionWidth = option.nativeElement.getBoundingClientRect().width

        itemOffset[SuperSegmentState.OPTIONS] = itemOffset[SuperSegmentState.MIDDLE]

        // adjust the offset of option items to center the screen
        if (itemOffset[SuperSegmentState.OPTIONS] === 0) {
          itemOffset.optionsItems =
            this.documentWidth / 2 - elementRect.left - elementRect.width / 2
        }

        itemSize.optionPosition = 'bottom'
        itemSize.optionHeight = option.nativeElement.getBoundingClientRect().height

        // if options doesn't fit in one row
        if (optionWidth > this.maxWidth) {
          itemSize.optionPosition = 'bottomRows'
        }
      })

      this.itemSizes$.next(_itemSizes)
    })
  }

  private isOptionAvailable(items: SuperSegmentItem[], option?: number): boolean {
    return items.some(item => item.options?.items?.some(opt => opt.value === option))
  }

  public onItemClick(item: SuperSegmentItemWithSelected): void {
    const { uid: id, selected } = item
    void this.analyticsService.trackEvent(EVENT.SUPER_SEGMENT_TOGGLE, {
      id,
      selected,
    })
  }

  public async segmentChanged(event: CustomEvent): Promise<void> {
    const _value = event.detail.value as number | string | undefined

    const [value, option] = _value
      ? _value
          .toString()
          .split('--')
          .map(i => parseInt(i))
      : [undefined, undefined]

    const newValue: SuperSegmentValue = { value, option }

    if (value === SuperSegmentSpecialButton.LH_SCANNER_BUTTON) {
      this.specialButtonClick.emit(SuperSegmentSpecialButton.LH_SCANNER_BUTTON)

      this.setValue({})
      return
    }

    if (
      _deepEquals(newValue, this.value) ||
      (value === SuperSegmentSpecialButton.NO_VALUE && !option)
    ) {
      this.setValue(newValue)
      this.updateState(newValue)
      return
    }

    this.setValue(newValue)
    this.valueChange.emit(newValue)
  }

  public trackByItem(_n: number, i: SuperSegmentItem): number {
    return i.value
  }

  private getIconForOption(option?: number): string | undefined {
    if (!option) return

    const selectedOption = this.items.map(item => {
      return item.options?.items?.find(opt => opt.value === option)
    })[0]

    return selectedOption?.icon
  }

  private updateState(_superSegmentValue: SuperSegmentValue, showOptions = true): void {
    this.dom.write(() => {
      const { value, option } = _superSegmentValue

      // update state
      this.items.map(item => {
        // neutral if no selected value
        this.segmentState[item.value] = !value
          ? SuperSegmentState.NEUTRAL
          : SuperSegmentState.MIDDLE

        // options state if item is selected but without selected option
        if (showOptions && item.value === value && item.options && !option) {
          this.segmentState[item.value] = SuperSegmentState.OPTIONS
        }
      })

      this.updateCurrentOffsets()
    })
  }

  private updateCurrentOffsets(): void {
    const _currentOffsets = {}

    this.items.map(item => {
      const state = this.segmentState[item.value]

      _currentOffsets[item.value] = this.itemSizes$.value[item.value]?.offsets[state!] || 0
    })

    this.dom.write(() => {
      this.currentOffsets$.next(_currentOffsets)
      this.cdr.detectChanges()
    })
  }

  private get selectedItem(): SuperSegmentItem | undefined {
    return this.items.find(item => item.selected)
  }

  @HostListener('document:click', ['$event.target'])
  private onClick(target: Element): void {
    if (!Object.values(this.segmentState).includes(SuperSegmentState.OPTIONS)) return

    this.dom.read(() => {
      const clickedInside = this.elementRef.nativeElement.contains(target)

      if (clickedInside) return

      const { value, option } = this.value || {}

      if (!!value && !option) {
        // deselect current value if it is NO_VALUE
        if (value === SuperSegmentSpecialButton.NO_VALUE) {
          this.setValue({})
          return
        }

        this.updateState(this.value || {}, false)
      }
    })
  }
}
