import { AfterViewInit, Directive, HostBinding, inject } from '@angular/core'
import { IonContent } from '@ionic/angular'

@Directive({
  selector: 'ion-content[scrollable]',
})
export class ScrollableDirective implements AfterViewInit {
  private ionContent = inject(IonContent)
  @HostBinding('class.scrollable')
  public scrollable = false

  public ngAfterViewInit(): void {
    void this.checkIfScrollable()
  }

  public updateScrollable(): void {
    void this.checkIfScrollable()
  }

  private async checkIfScrollable(): Promise<void> {
    const scrollElement = await this.ionContent.getScrollElement()
    this.scrollable = scrollElement.scrollHeight > scrollElement.offsetHeight
  }
}
