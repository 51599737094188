import { DURATION, EASING, verticalSlideAnimation } from '@app/animations/vertical-slide'
import { di } from '@app/srv/di.service'
import { EventService } from '@app/srv/event.service'
import { AnimationController } from '@ionic/angular'
import { Animation } from '@ionic/core'

const OPACITY = 'opacity'
const TRANSFORM = 'transform'
const TRANSLATE_X = 'translateX'
const CENTER = '0%'
const OFF_OPACITY = 0.8
const OFF_RIGHT = '99.5%'
const OFF_LEFT = '-33%'

export function horizontalSlideAnimation(baseEl: HTMLElement, opts: any): Animation {
  const animationController = di.get(AnimationController)

  const enteringEl = opts.enteringEl
  const leavingEl = opts.leavingEl
  const backDirection = opts.direction === 'back'

  if (shouldItBeModalTransition(enteringEl.id, leavingEl.id)) {
    return verticalSlideAnimation(baseEl, opts)
  }

  const enteringContent = animationController
    .create()
    .addElement(enteringEl)
    .beforeRemoveClass('ion-page-invisible')
    .fromTo(
      TRANSFORM,
      `${TRANSLATE_X}(${backDirection ? OFF_LEFT : OFF_RIGHT})`,
      `${TRANSLATE_X}(${CENTER})`,
    )
    .fromTo(OPACITY, OFF_OPACITY, 1)

  const leavingContent = animationController
    .create()
    .addElement(leavingEl)
    .fromTo(
      TRANSFORM,
      `${TRANSLATE_X}(${CENTER})`,
      `${TRANSLATE_X}(${backDirection ? OFF_RIGHT : OFF_LEFT})`,
    )
    .fromTo(OPACITY, 1, OFF_OPACITY)

  const baseAnimation = animationController
    .create()
    .duration(DURATION)
    .easing(EASING)
    .addAnimation(enteringContent)
    .addAnimation(leavingContent)
    .onFinish(() => di.get(EventService).transitionDone$.next())
    .afterAddWrite(() => {
      if (!backDirection || baseAnimation.getDirection() !== 'normal') return

      // hide leaving element
      leavingEl.style.setProperty('display', 'none')
    })

  return baseAnimation
}

function shouldItBeModalTransition(enteringEl: string, leavingEl: string): boolean {
  const pages = [enteringEl, leavingEl]

  console.log('[transition] enteringEl', enteringEl, 'leavingEl', leavingEl)

  // Calibrate unless its going to Address
  if (pages.includes('CalibratePage') && !pages.includes('ProfileAddressPage')) return true

  // Thermometer unless its going to Delivery
  if (pages.includes('ThermometerPage') && !pages.includes('AddressPage')) return true

  return false
}
