<div uid="noTemps__content" class="noTemps__content">
  <h2>{{ 'aw-add-data-no-temps-title' | tr }}</h2>

  <p [innerHTML]="'aw-add-data-no-temps-body' | tmd"></p>
</div>

<ion-footer class="footer--shadow">
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button uid="no-temps-i-understand-button" color="primary" (click)="dismissModal()">
        <ion-label>{{ 'txt-understand' | tr }}</ion-label>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
