import { Component, Input, ViewChild } from '@angular/core'
import { SafeHtml } from '@angular/platform-browser'
import { BaseModal } from '@app/pages/base.modal'
import { IonContent } from '@ionic/angular'
import { ICON } from '@src/app/cnst/icons.cnst'

@Component({
  selector: 'page-info-modal',
  templateUrl: './info.modal.html',
  styleUrls: ['./info.modal.scss'],
  providers: [],
})
export class InfoModal extends BaseModal {
  className = 'InfoModal'

  @Input()
  public title?: string

  @Input()
  public body?: string

  @Input()
  public html?: SafeHtml

  @Input()
  public ctaTitle?: string

  @Input()
  public isLogoDisplayed?: boolean

  @Input()
  public infoBoxBody?: string

  @ViewChild(IonContent, { static: true })
  content!: IonContent

  public ICON = ICON
}
