import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { NCSecureLogoComponentModule } from '@app/cmp/nc-secure-logo/nc-secure-logo.component.module'
import { SuperSegmentComponentModule } from '@app/cmp/super-segment/super-segment.component.module'
import { SharedModule } from '@app/shared.module'
import { IonicModule } from '@ionic/angular'
import { SensitiveTrackersModal } from './sensitive-trackers.modal'

@NgModule({
  declarations: [SensitiveTrackersModal],
  imports: [
    IonicModule,
    CommonModule,
    SharedModule,
    NCSecureLogoComponentModule,
    SuperSegmentComponentModule,
    ReactiveFormsModule,
  ],
  exports: [SensitiveTrackersModal],
})
export class SensitiveTrackersModalModule {}
