import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { AddedTemperatureDisplayComponent } from '@app/cmp/added-temperature-display/added-temperature-display.component'
import { FertilityCircleComponent } from '@app/cmp/fertility-circle/fertility-circle.component'
import { LhImageComponent } from '@app/cmp/lh-image/lh-image.component'
import { SharedModule } from '@app/shared.module'
import { IonicModule } from '@ionic/angular'
import { HistoryRowComponent } from './history-row.component'

@NgModule({
  declarations: [HistoryRowComponent],
  imports: [
    IonicModule,
    SharedModule,
    CommonModule,
    FertilityCircleComponent,
    AddedTemperatureDisplayComponent,
    LhImageComponent,
  ],
  exports: [HistoryRowComponent],
})
export class HistoryRowComponentModule {}
