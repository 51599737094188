import { Injectable } from '@angular/core'
import { isNativeApp } from '@app/cnst/userDevice.cnst'
import { decorate, ErrorHandlerType, LoaderType } from '@app/decorators/decorators'
import { storageService } from '@app/srv/storage.service'
import { Browser } from '@capacitor/browser'
import { Clipboard } from '@capacitor/clipboard'
import { pHang } from '@naturalcycles/js-lib'
import { env } from '@src/environments/environment'
import { getState } from './store.service'

const ADMIN_TOKEN_KEY = 'nc_admin_token'

@Injectable({ providedIn: 'root' })
export class AdminService {
  /**
   * undefined means it wasn't loaded yet
   * null means "not an admin, no token"
   * string means "here's the token"
   */
  private _adminToken?: string | null

  getAdminToken(): string | undefined {
    if (this._adminToken === undefined) {
      this._adminToken = storageService.get(ADMIN_TOKEN_KEY) || null
    }

    return this._adminToken || undefined
  }

  setAdminToken(token: string | undefined): void {
    storageService.put(ADMIN_TOKEN_KEY, token || '')
    this._adminToken = token || null
  }

  isAdmin(): boolean {
    return !!getState().ui.admin
  }

  isTestUser(): boolean {
    return !!getState().account.testUser
  }

  onKeyPress(e: KeyboardEvent): void {
    // Ctrl+Shift+L
    if (e.ctrlKey && e.shiftKey && e.keyCode === 12) {
      void this.toggleAdmin()
    }
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  private async toggleAdmin(): Promise<void> {
    // this.storeService.dispatch('toggleAdminMode')
    const { admin } = getState().ui

    if (admin) {
      this.redirectToLogout()
    } else {
      this.redirectToLogin()
    }

    return await pHang()
  }

  redirectToLogin(): void {
    if (isNativeApp) {
      void this.openLoginInAppBrowser()
      return
    }

    // alert('Will log in')
    const currentHref = location.href
    const { loginHost, apiHost } = env

    // We send login request to `loginHost`, not to `apiHost`, because
    // Firebase only allows auth on whitelisted domains, which doesn't allow us to do "netlify for backend"
    location.href = `${loginHost}/login?apiHost=${apiHost}&return=${currentHref}`
  }

  redirectToLogout(): void {
    if (window.cordova) {
      this.setAdminToken(undefined)
      location.reload()
      return
    }

    // alert('Will log out')
    const currentHref = location.href
    const { loginHost, apiHost } = env
    location.href = `${loginHost}/login?logout=1&apiHost=${apiHost}&return=${currentHref}`
  }

  @decorate({
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  async openLoginInAppBrowser(): Promise<void> {
    const url = `${env.loginHost}/login`

    const token = await new Promise<string | undefined>(resolve => {
      void Browser.addListener('browserFinished', async () => {
        void Browser.removeAllListeners()

        const { value } = await Clipboard.read()
        await Clipboard.write({
          // eslint-disable-next-line id-blacklist
          string: '', // erases the token from the Clipboard
        })
        resolve(value)
      })

      void Browser.open({
        url,
        presentationStyle: 'fullscreen',
      })
    })

    if (!token) {
      throw new Error(
        `Failed to receive the token. Try again. Don't forget to click Copy before closing.`,
      )
    }

    this.setAdminToken(token)

    alert('auth successful, will reload the browser')
    location.reload()
  }
}
