import { Injectable } from '@angular/core'
import { select } from '@app/srv/store.service'
import { BehaviorSubject, combineLatestWith, Observable } from 'rxjs'

export enum TemperatureF {
  MIN = 95,
  MAX = 103.99,
}

export enum TemperatureC {
  MIN = 35,
  MAX = 40.99,
}

export interface TemperatureNumpad {
  selectedDigits?: number[]
  numpadDigits?: NumpadDigit[]
  temperature?: number
}

export interface NumpadDigit {
  value: number
  disabled: boolean
}

interface MinMax {
  min: number
  max: number
}

@Injectable({ providedIn: 'root' })
export class TemperatureNumpadService {
  public selectedDigits$ = new BehaviorSubject<number[]>([])
  public numpadDigits$ = new BehaviorSubject<NumpadDigit[]>([])
  public temperature$ = new BehaviorSubject<number | undefined>(undefined)

  @select(['account', 'fahrenheit'])
  public fahrenheit$!: Observable<boolean>

  public combined = this.fahrenheit$
    .pipe(combineLatestWith(this.selectedDigits$))
    .subscribe(([fahrenheit, digits]) => {
      this.expectedDigits = this.determineNumberOfDigits(fahrenheit, digits)
      this.temperatureRange = this.getTemperatureRange(fahrenheit, digits)
    })

  private temperatureRange: MinMax = this.getDefaultMinMax(false)
  private expectedDigits = 4

  // Press a digit and get back a new set of digits
  public updateNumpadDigitsByPressingDigit(digit: number): NumpadDigit[] {
    const selectedDigits = [...this.selectedDigits$.value]

    if (digit === -1) {
      // Delete one
      selectedDigits.pop()
    } else if (this.validate(digit)) {
      // Add digit
      selectedDigits.push(digit)
    }

    // Merge selected digits to a temperature
    this.mergeArrayToTemperature(selectedDigits, this.expectedDigits)
    this.selectedDigits$.next(selectedDigits)

    // Update numpad with new digits
    return this.updateNumpadDigits(selectedDigits)
  }

  private validate(digit: number): boolean {
    return !this.numpadDigits$.value.find(v => v.value === digit)?.disabled
  }

  public resetDigits(digits: number[] = []): NumpadDigit[] {
    this.mergeArrayToTemperature(digits, this.expectedDigits)

    this.selectedDigits$.next(digits)

    return this.updateNumpadDigits(digits)
  }

  private getTemperatureRange(fahrenheit: boolean, selectedDigits: number[]): MinMax {
    const { min, max } = this.getDefaultMinMax(fahrenheit)

    // Reset min and max values if there's no selected digits
    if (selectedDigits.length === 0) {
      return { min, max }
    }

    // Update allowed temperature range
    return this.calculateMinMax(selectedDigits, min, max)
  }

  // Update numpad digits depending on selected digits
  private updateNumpadDigits(digits: number[]): NumpadDigit[] {
    // Create digits 0-9
    const numpadDigits: NumpadDigit[] = []
    for (let i = 0; i < 10; i++) {
      numpadDigits[i] = {
        value: i === 9 ? 0 : i + 1,
        disabled: true,
      }
    }

    // Get the current min and max digits
    const { min, max }: MinMax = {
      min: Number(this.temperatureRange.min.toFixed(2).replace('.', '')[digits.length]),
      max: Number(this.temperatureRange.max.toFixed(2).replace('.', '')[digits.length]),
    }

    // Define all digits that should be enabled
    const list = {}
    for (let i = min; i <= max; i++) {
      list[i] = true
    }

    // Edge case in fahrenheit, starting with values of 100 and 90
    if (min > max) {
      for (let i = min; i <= 9; i++) {
        list[i] = true
      }
      for (let i = max; i >= 1; i--) {
        list[i] = true
      }
    }

    // Loop through digits and enable it if is excisting in list
    Object.keys(numpadDigits).forEach(i => {
      if (list[numpadDigits[i].value]) {
        numpadDigits[i].disabled = false
      }
    })

    this.numpadDigits$.next(numpadDigits)

    return numpadDigits
  }

  // Get default min and max temperature from cnst
  private getDefaultMinMax(isFahrenheit: boolean): MinMax {
    const min = isFahrenheit ? TemperatureF.MIN : TemperatureC.MIN
    const max = isFahrenheit ? TemperatureF.MAX : TemperatureC.MAX

    return { min, max }
  }

  // Determine if we should expect 4 or 5 digits
  private determineNumberOfDigits(isFahrenheit: boolean, digits: number[] = []): number {
    // Set 5 digits if temperature is more than 100
    if (isFahrenheit) {
      return digits[0]! > 1 ? 4 : 5
    }

    return 4
  }

  // Calculate possible min and max temperatures depending on an array of digits.
  private calculateMinMax(digits: number[], defaultMin: number, defaultMax: number): MinMax {
    let min = 0

    digits.forEach((value, i) => {
      min += value * 10 ** (this.expectedDigits - i)
    })

    const subtraction = 10 ** (this.expectedDigits - digits.length) * 10
    let max = Math.ceil((min + 10) / subtraction) * subtraction - 10

    min = Math.max(min / 1000, defaultMin)
    max = Math.min(max / 1000, defaultMax)

    return { min, max }
  }

  private mergeArrayToTemperature(digits: number[], expectedDigits: number): void {
    // Save new temperature if we have all expected digits
    if (digits.length === expectedDigits) {
      const temperature = parseInt(digits.join('')) / 100

      this.temperature$.next(temperature)

      return
    }

    this.temperature$.next(undefined)
  }
}
