import { Injectable } from '@angular/core'
import { NavigationParams } from '@app/cnst/nav.params.cnst'
import { PageGuard } from '@app/guards/page.guard'
import { getNavParams } from '@app/srv/nav.service'

@Injectable({
  providedIn: 'root',
})
export class MeasuringDevicePageGuard extends PageGuard {
  className = 'MeasuringDevicePageGuard'

  get requirements(): boolean[] {
    const flow = getNavParams()[NavigationParams.FLOW]

    const requirements = [
      !this.requiresAppConsent(),
      this.hasSession(),
      !!flow || !this.hasAccountCompleteDate(),
    ]

    return requirements
  }
}
