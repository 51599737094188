import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core'
import { _isObject, StringMap } from '@naturalcycles/js-lib'

type TreeLayerData = StringMap<any> | any[] | string

@Component({
  selector: 'app-tree-layer',
  templateUrl: './tree-layer.component.html',
  styleUrls: ['./tree-layer.component.scss'],
  standalone: true,
  imports: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeLayerComponent implements OnChanges {
  private cdr = inject(ChangeDetectorRef)
  @Input()
  public data?: TreeLayerData

  public isCollapsed: StringMap<boolean> = {}
  public isCollapsible: StringMap<boolean> = {}
  public objectKeys: string[] = []

  public ngOnChanges(changes: SimpleChanges): void {
    const data = changes['data']?.currentValue
    if (!data) return
    this.objectKeys = this.getObjectKeys(data)
    for (const key in data) {
      if (!data[key]) continue
      const value = data[key]
      const isCollapsible = _isObject(value) || (Array.isArray(value) && !!value.length)
      this.isCollapsed[key] = isCollapsible
      this.isCollapsible[key] = isCollapsible
    }
  }

  public getObjectKeys(obj: TreeLayerData): string[] {
    const collator = new Intl.Collator([], { numeric: true })
    return Object.keys(obj).sort((a, b) => collator.compare(a, b))
  }

  public toggleCollapse(event: Event, key: string): void {
    event.stopPropagation()
    if (!this.isCollapsible[key]) return
    this.isCollapsed[key] = !this.isCollapsed[key]
    this.cdr.detectChanges()
  }
}
