<!-- check https://ionicframework.com/docs/api/toolbar#searchbars-in-toolbars to add progress bar in toolbar. We could use same technique as in docs for the searchbar -->
<ion-toolbar mode="ios">
  <ion-buttons slot="start">
    <ion-back-button mode="md" />
  </ion-buttons>
  <ion-title>
    <div class="quizLogo">
      <ion-icon class="quizLogo__img" [src]="ICON.NC_LOGO" color="logo" />
    </div>
  </ion-title>
</ion-toolbar>
