/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
import { _mapValues } from '@naturalcycles/js-lib'
import {
  BirthControlMethodBreakpointOption,
  CervicalMucusConsistency,
  DataFlag,
  DataQuantity,
  HadSex,
  LANG,
  LHStatus,
  Libido,
  Mens,
  OvulationStatus,
  PregnancyTestStatus,
  Sleep,
  TestResult,
} from '@naturalcycles/shared'

export const ICON_PATH = './assets/img/icons/'

export enum ICON {
  NC_LOGO = 'nc-logo-full',
  NC_MONOGRAM = 'nc-logo-monogram',
  NC_LOGO_PREVENT = 'nc-logo-birth-control',
  NC_LOGO_PLAN = 'nc-logo-plan-pregnancy',
  NC_LOGO_PREGNANT = 'nc-logo-follow-pregnancy',
  NC_LOGO_RECOVERY = 'nc-logo-recovery',
  NC_LOGO_POSTPARTUM = 'nc-logo-postpartum',
  NC_LOGO_SECURE = 'nc-logo-secure',

  NC_GOAL_PREVENT = 'nc-goal-prevent',
  NC_GOAL_PLAN = 'nc-goal-plan',
  NC_GOAL_PREGNANT = 'nc-goal-pregnant',
  NC_GOAL_POSTPARTUM = 'nc-goal-postpartum',

  CHECK_NC = 'check_nc',
  CHECK = 'check',
  CHECK_OUTLINE = 'check_outline',
  CHECK_BOLD = 'check_bold',
  CHECK_AW = 'check_aw',
  CHECK_QUIZ = 'quiz-checkmark',
  SAFETY_CHECK = 'safety_check',
  SKIP = 'skip',
  PREG_TEST = 'pregnancy_test',
  PREG_TEST_POS = 'pregnancy_test_positive',
  PREG_TEST_NEG = 'pregnancy_test_negative',
  OVULATION = 'ovulation',
  CLOCK = 'clock',
  MENSTRUATION = 'bleeding-period',
  MENSTRUATION_LIGHT = 'bleeding-period-light',
  MENSTRUATION_MEDIUM = 'bleeding-period-medium',
  MENSTRUATION_HEAVY = 'bleeding-period-heavy',
  WITHDRAWAL_LIGHT = 'bleeding-withdrawal-light',
  WITHDRAWAL_MEDIUM = 'bleeding-withdrawal-medium',
  WITHDRAWAL_HEAVY = 'bleeding-withdrawal-heavy',
  MISCARRIAGE_LIGHT = 'bleeding-miscarriage-light',
  MISCARRIAGE_MEDIUM = 'bleeding-miscarriage-medium',
  MISCARRIAGE_HEAVY = 'bleeding-miscarriage-heavy',
  POSTPARTUM_LIGHT = 'bleeding-miscarriage-light',
  POSTPARTUM_MEDIUM = 'bleeding-miscarriage-medium',
  POSTPARTUM_HEAVY = 'bleeding-miscarriage-heavy',
  LH_POS = 'lh_positive',
  LH_NEG = 'lh_negative',
  LH_TEST = 'lh_test',
  ADD = 'add',
  QUIZ_ADD = 'quiz-purple-plus',
  ADD_SOLID = 'add-solid',
  SEX = 'sex',
  SEX_SAFE = 'sex_safe',
  SPOTTING = 'spotting',
  REMINDER_ON = 'reminder_on',
  REMINDER_OFF = 'reminder_off',
  TEMPERATURE = 'temperature',
  TEMPERATURE_EXCLUDED = 'temperature-excluded',
  GRAPH = 'newgraph',
  GRAPH_COMPARE = 'graph-compare',
  GRAPH_HOLISTIC = 'graph-holistic',
  WARNING = 'warning',
  WARNING_CIRCLE = 'warning-circle',
  STAR = 'star',
  CLOSE = 'close',
  ARROW_RIGHT = 'arrow-right',
  ARROW_LEFT = 'arrow-left',
  ARROW_UP = 'arrow-up',
  ARROW_DOWN = 'arrow-down',
  ARROW_LONG_RIGHT = 'arrow-long-right',
  ARROW_LONG_LEFT = 'arrow-long-left',
  ARROW_LONG_UP = 'arrow-long-up',
  ARROW_LONG_DOWN = 'arrow-long-down',
  UNDO = 'undo',
  TODAY = 'nav_today',
  CALENDAR = 'nav_calendar',
  MESSAGES = 'nav_messages',
  LEARN = 'nav_learn',
  MORE_VERTICAL = 'more-vertical',
  MORE_HORIZONTAL = 'more-horizontal',
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  TWITTER = 'twitter',
  STEPPER_MORE = 'stepper-add',
  STEPPER_LESS = 'stepper-remove',
  BACKSPACE = 'backspace-white',
  INSTRUCTIONS = 'instructions',
  MANUFACTURER = 'manufacturer',
  MEASURE_STREAKS_ARROW = 'measure-streaks-circle-arrow',
  TEMPERATURE_VARIATION_ARROW = 'temperature-variation-arrow',
  HELP_SYMBOL = 'help-symbol',
  INFO = 'info',
  SETTINGS = 'settings',
  THUMBS_UP = 'thumbs-up',
  THUMBS_DOWN = 'thumbs-down',
  EYE_ACTIVE = 'eye-active',
  EYE_INACTIVE = 'eye-inactive',
  HAMBURGER = 'hamburger',
  THERMOMETER = 'thermometer',
  T3 = 'thermometer_gen3',
  OURA = 'oura',
  OURA_BIG = 'oura-logo-spaced',
  APPLE_WATCH = 'apple-watch',
  APPLE_LOGO = 'apple',
  FLAG = 'flag',
  DELETE = 'delete',
  ZOOM_IN = 'zoom-in',
  ZOOM_OUT = 'zoom-out',
  NOTES = 'notes',
  LOGOUT = 'logout',
  MD = 'md',
  HISTORY = 'history',
  INSIGHTS = 'insights',
  CONCEPTION = 'conception',
  CYCLE_STATS_GREEN_DAYS = 'cycle-stats-green-days',
  CYCLE_LENGTH = 'cycle-length',
  PROFILE = 'profile',
  GALLERY = 'gallery',
  CAMERA = 'camera',
  TRUCK = 'delivery-truck',
  CARDS = 'cards',
  CLEARBLUE_LOGO = 'clearblue-logo',

  PREGNANT_BABY = 'pregnant_baby',
  PREGNANT_BELLY = 'pregnant_belly',
  PREGNANT_WEIGHT = 'pregnant_weight',
  PREGNANT_LENGTH = 'pregnant_length',

  DEVIATION_ALCOHOL = 'deviation-alcohol',
  DEVIATION_ALGO = 'deviation-algo',
  DEVIATION_SLEEP = 'deviation-sleep',
  DEVIATION_SICK = 'deviation-sick',

  MOOD_ANGRY = 'mood-angry',
  MOOD_ANXIOUS = 'mood-anxious',
  MOOD_CALM = 'mood-calm',
  MOOD_CONFIDENT = 'mood-confident',
  MOOD_ENERGETIC = 'mood-energetic',
  MOOD_EXCITED = 'mood-excited',
  MOOD_EXHAUSTED = 'mood-exhausted',
  MOOD_FORGETFUL = 'mood-forgetful',
  MOOD_HAPPY = 'mood-happy',
  MOOD_IRRITABLE = 'mood-irritable',
  MOOD_NUMB = 'mood-numb',
  MOOD_PMS = 'mood-pms',
  MOOD_SAD = 'mood-sad',
  MOOD_SENSITIVE = 'mood-sensitive',
  MOOD_SENSITIVE_SMELL = 'mood-sensitive-smell',
  MOOD_SICK = 'mood-sick',
  MOOD_STRESSED = 'mood-stressed',
  MOOD_SWINGS = 'mood-swings',
  MOOD_TIRED = 'mood-tired',
  MOOD_UNFOCUSED = 'mood-unfocused',
  MOOD_SELF_CRITICAL = 'mood-self-critical',
  MOOD_FEELING_GUILTY = 'mood-feeling-guilty',
  MOOD_OBSESSIVE_THOUGHTS = 'mood-obsessive-thoughts',
  MOOD_CONFUSED = 'mood-confused',

  CM_QUANTITY_NONE = 'cm_quantity_none',
  CM_QUANTITY_LIGHT = 'cm_quantity_light',
  CM_QUANTITY_MEDIUM = 'cm_quantity_medium',
  CM_QUANTITY_HEAVY = 'cm_quantity_heavy',

  CM_CONSISTENCY_STICKY = 'cm_consistency_sticky',
  CM_CONSISTENCY_CREAMY = 'cm_consistency_creamy',
  CM_CONSISTENCY_EGGWHITE = 'cm_consistency_eggwhite',
  CM_CONSISTENCY_WATERY = 'cm_consistency_watery',

  LIBIDO_LOW = 'libido_LOW',
  LIBIDO_MEDIUM = 'libido_MEDIUM',
  LIBIDO_HIGH = 'libido_HIGH',

  SLEEP_POOR = 'sleep_POOR',
  SLEEP_OKAY = 'sleep_OKAY',
  SLEEP_RESTFUL = 'sleep_RESTFUL',

  PAIN_BACKACHE = 'pain_backache',
  PAIN_CONTRACTIONS = 'pain_contractions',
  PAIN_CRAMPS = 'pain_cramps',
  PAIN_HEADACHE = 'pain_headache',
  PAIN_HEARTBURN = 'pain_heartburn',
  PAIN_OTHER = 'pain_other',
  PAIN_OVULATION = 'pain_ovulation',
  PAIN_PELVIC = 'pain_pelvic',
  PAIN_SORE_BREASTS = 'pain_sore_breasts',
  PAIN_SWELLING = 'pain_swelling',
  PAIN_CRAVINGS = 'pain-cravings',
  PAIN_NAUSEA = 'pain-nausea',
  PAIN_DIARRHEA = 'pain-diarrhea',
  PAIN_CONSTIPATION = 'pain-constipation',
  PAIN_BLOATING = 'pain-bloating',
  PAIN_MOVEMENTS = 'pain-movements',
  PAIN_VAGINAL = 'pain-vaginal',
  PAIN_HEMORRHOIDS = 'pain-hemorrhoids',
  PAIN_SORE_NIPPLES = 'pain-sore_nipples',
  PAIN_STITCHES = 'pain-stitches',

  SKIN_DRY = 'skin_dry',
  SKIN_OILY = 'skin_oily',
  SKIN_PUFFY = 'skin_puffy',
  SKIN_ACNE = 'skin_acne',
  SKIN_GLOWING = 'skin_glowing',
  SKIN_CHLOASMA = 'skin_chloasma',
  SKIN_OTHER = 'skin_other',
  SKIN_HAIR_LOSS = 'skin_hair_loss',
  SKIN_STRETCH_MARKS = 'skin_stretch_marks',

  SEX_OTHER = 'sex_other',

  MORE_EMERGENCY_IUD = 'more_emergency_iud',
  MORE_EMERGENCY_PILL = 'more_emergency_pill',

  COVID = 'covid',
  COVID_POS = 'covid_positive',
  COVID_NEG = 'covid_negative',

  ADJUSTED_TEMP = 'adjusted_temp',

  DIAMOND = 'diamond',

  BLUETOOTH = 'bluetooth',
  BLUETOOTH_CONNECTED = 'bluetooth-connected',
  BLUETOOTH_OFF = 'bluetooth-off',
  BATTERY = 'battery_full',
  BATTERY_ALERT = 'battery_alert',
  DEVICE_SETTINGS = 'device_settings',
  PENDING_ACTION = 'pending_action',
  BRIGHTNESS_LOW = 'brightness_low',
  BRIGHTNESS_HIGH = 'brightness_high',
  FIRMWARE = 'firmware',
  FIRMWARE_UPDATE = 'firmware_update',
  UPDATE = 'update',

  GOOGLE = 'google',

  // For health kit use apple-health.png
  // SVG doesn't work because of some Safari bug, making the heart black

  HEART_RATE = 'heart-rates',
  SLEEP_FOCUS = 'sleep-focus',
  WATCH = 'watch',
  WATCH_BOLD = 'watch-bold',
  WRIST_TEMPS = 'wrist-temps',
  INTEGRATION = 'integration',
  INTEGRATION_SUCCESSFUL = 'integration-successful',
  TROUBLESHOOTING = 'troubleshooting',

  // Apple Watch icons
  AW_SLEEP = 'aw-sleep-app',
  AW_FULL_SCHEDULE = 'aw-full-schedule',

  // BirthControl icons
  BC_CONDOM = 'bc-condom',
  BC_IUD_COPPER = 'bc-copperIUD',
  BC_IUD_HORMONAL = 'bc-hormonalIUD',
  BC_IMPLANT = 'bc-implant',
  BC_NONE = 'bc-none',
  BC_NUVARING = 'bc-nuvaRing',
  BC_OTHER = 'bc-other',
  BC_PATCH = 'bc-patch',
  BC_PILL = 'bc-pill',
  BC_PULLOUT = 'bc-pullOut',
  BC_SHOT = 'bc-shot',
}

_mapValues(ICON, (_k, v) => `${ICON_PATH}${v}.svg`, true)

export const ICON_BY_DATAFLAG: Record<DataFlag, ICON | undefined> = {
  [DataFlag.MOOD_HAPPY]: ICON.MOOD_HAPPY,
  [DataFlag.MOOD_CONFIDENT]: ICON.MOOD_CONFIDENT,
  [DataFlag.MOOD_CALM]: ICON.MOOD_CALM,
  [DataFlag.MOOD_ENERGETIC]: ICON.MOOD_ENERGETIC,
  [DataFlag.MOOD_EXCITED]: ICON.MOOD_EXCITED,
  [DataFlag.MOOD_PMS]: ICON.MOOD_PMS,
  [DataFlag.MOOD_SWINGS]: ICON.MOOD_SWINGS,
  [DataFlag.MOOD_IRRITABLE]: ICON.MOOD_IRRITABLE,
  [DataFlag.MOOD_ANXIOUS]: ICON.MOOD_ANXIOUS,
  [DataFlag.MOOD_STRESSED]: ICON.MOOD_STRESSED,
  [DataFlag.MOOD_TIRED]: ICON.MOOD_TIRED,
  [DataFlag.MOOD_SENSITIVE]: ICON.MOOD_SENSITIVE,
  [DataFlag.MOOD_NUMB]: ICON.MOOD_NUMB,
  [DataFlag.MOOD_SAD]: ICON.MOOD_SAD,
  [DataFlag.MOOD_ANGRY]: ICON.MOOD_ANGRY,
  [DataFlag.MOOD_FORGETFUL]: ICON.MOOD_FORGETFUL,
  [DataFlag.MOOD_SICK]: ICON.MOOD_SICK,
  [DataFlag.MOOD_SENSITIVE_TO_SMELL]: ICON.MOOD_SENSITIVE_SMELL,
  [DataFlag.MOOD_EXHAUSTED]: ICON.MOOD_EXHAUSTED,
  [DataFlag.MOOD_UNFOCUSED]: ICON.MOOD_UNFOCUSED,
  [DataFlag.MOOD_SELF_CRITICAL]: ICON.MOOD_SELF_CRITICAL,
  [DataFlag.MOOD_FEELING_GUILTY]: ICON.MOOD_FEELING_GUILTY,
  [DataFlag.MOOD_OBSESSIVE_THOUGHTS]: ICON.MOOD_OBSESSIVE_THOUGHTS,
  [DataFlag.MOOD_CONFUSED]: ICON.MOOD_CONFUSED,

  [DataFlag.PAIN_CRAMPS]: ICON.PAIN_CRAMPS,
  [DataFlag.PAIN_BACKACHE]: ICON.PAIN_BACKACHE,
  [DataFlag.PAIN_SORE_BREASTS]: ICON.PAIN_SORE_BREASTS,
  [DataFlag.PAIN_OVULATION]: ICON.PAIN_OVULATION,
  [DataFlag.PAIN_HEADACHE]: ICON.PAIN_HEADACHE,
  [DataFlag.PAIN_OTHER]: ICON.PAIN_OTHER,
  [DataFlag.PAIN_HEARTBURN]: ICON.PAIN_HEARTBURN,
  [DataFlag.PAIN_PELVIC]: ICON.PAIN_PELVIC,
  [DataFlag.PAIN_SWELLING]: ICON.PAIN_SWELLING,
  [DataFlag.PAIN_CONTRACTIONS]: ICON.PAIN_CONTRACTIONS,
  [DataFlag.PAIN_NAUSEOUS]: ICON.PAIN_NAUSEA,
  [DataFlag.PAIN_CRAVINGS]: ICON.PAIN_CRAVINGS,
  [DataFlag.PAIN_DIARRHEA]: ICON.PAIN_DIARRHEA,
  [DataFlag.PAIN_CONSTIPATION]: ICON.PAIN_CONSTIPATION,
  [DataFlag.PAIN_BLOATING]: ICON.PAIN_BLOATING,
  [DataFlag.PAIN_MOVEMENTS]: ICON.PAIN_MOVEMENTS,
  [DataFlag.PAIN_VAGINAL]: ICON.PAIN_VAGINAL,
  [DataFlag.PAIN_HEMORRHOIDS]: ICON.PAIN_HEMORRHOIDS,
  [DataFlag.PAIN_SORE_NIPPLES]: ICON.PAIN_SORE_NIPPLES,
  [DataFlag.PAIN_STITCHES]: ICON.PAIN_STITCHES,

  [DataFlag.SKIN_DRY]: ICON.SKIN_DRY,
  [DataFlag.SKIN_OILY]: ICON.SKIN_OILY,
  [DataFlag.SKIN_PUFFY]: ICON.SKIN_PUFFY,
  [DataFlag.SKIN_ACNE]: ICON.SKIN_ACNE,
  [DataFlag.SKIN_GLOWING]: ICON.SKIN_GLOWING,
  [DataFlag.SKIN_CHLOASMA]: ICON.SKIN_CHLOASMA,
  [DataFlag.SKIN_OTHER]: ICON.SKIN_OTHER,
  [DataFlag.SKIN_HAIR_LOSS]: ICON.SKIN_HAIR_LOSS,
  [DataFlag.SKIN_STRETCH_MARKS]: ICON.SKIN_STRETCH_MARKS,

  [DataFlag.MORE_EMERGENCY_PILL]: ICON.MORE_EMERGENCY_PILL,
  [DataFlag.MORE_EMERGENCY_IUD]: ICON.MORE_EMERGENCY_IUD,

  [DataFlag.DEVIATION_REASON_OTHER]: undefined,
  [DataFlag.DEVIATION_REASON_SLEEP]: ICON.DEVIATION_SLEEP,
  [DataFlag.DEVIATION_REASON_ALCOHOL]: ICON.DEVIATION_ALCOHOL,
  [DataFlag.DEVIATION_REASON_SICK]: ICON.DEVIATION_SICK,
  [DataFlag.DEVIATION_REASON_ALGO]: ICON.DEVIATION_ALGO,

  [DataFlag.COVID_DRY_COUGH]: ICON.COVID,
  [DataFlag.COVID_SHORT_BREATH]: ICON.COVID,
  [DataFlag.COVID_FATIGUE]: ICON.COVID,
  [DataFlag.COVID_SORE_THROAT]: ICON.COVID,
  [DataFlag.COVID_DIARRHEA]: ICON.COVID,
  [DataFlag.COVID_MUSCLE_PAIN]: ICON.COVID,
  [DataFlag.COVID_HEADACHE]: ICON.COVID,
  [DataFlag.COVID_LOSS_TASTE_SMELL]: ICON.COVID,

  [DataFlag.OURA_ADJUSTED_TEMPERATURE]: ICON.ADJUSTED_TEMP,
  [DataFlag.OURA_IRREGULAR_SLEEP]: ICON.DEVIATION_SLEEP,
  [DataFlag.OURA_INCOMPLETE_DATA]: undefined,
  [DataFlag.OURA_DEVIATION_ALGO]: ICON.DEVIATION_ALGO,
  [DataFlag.OURA_ALCOHOL]: ICON.DEVIATION_ALCOHOL,
  [DataFlag.OURA_SICK]: ICON.DEVIATION_SICK,
  [DataFlag.OURA_SHORT_SLEEP]: undefined,

  [DataFlag.SEX_MASTURBATION]: ICON.SEX_OTHER,
  [DataFlag.SEX_TOYS]: ICON.SEX_OTHER,
  [DataFlag.SEX_TOUCHING]: ICON.SEX_OTHER,
  [DataFlag.SEX_ANAL]: ICON.SEX_OTHER,
  [DataFlag.SEX_ORAL]: ICON.SEX_OTHER,

  [DataFlag.ORIGINAL_PERIOD_PREDICTION]: undefined, // something for samsung only
}

export const ICON_BY_MENS: Record<Mens, ICON> = {
  [Mens.MENSTRUATION]: ICON.MENSTRUATION,
  [Mens.SPOTTING]: ICON.SPOTTING,
  [Mens.WITHDRAWAL]: ICON.WITHDRAWAL_MEDIUM,
  [Mens.MISCARRIAGE_BLEEDING]: ICON.MISCARRIAGE_MEDIUM,
  [Mens.POSTPARTUM_BLEEDING]: ICON.POSTPARTUM_MEDIUM,
}

export const ICON_BY_MENS_QUANTITY: Record<DataQuantity, ICON | undefined> = {
  [DataQuantity.NONE]: undefined,
  [DataQuantity.LIGHT]: ICON.MENSTRUATION_LIGHT,
  [DataQuantity.MEDIUM]: ICON.MENSTRUATION_MEDIUM,
  [DataQuantity.HEAVY]: ICON.MENSTRUATION_HEAVY,
}

export const ICON_BY_WITHDRAWAL_QUANTITY: Record<DataQuantity, ICON | undefined> = {
  [DataQuantity.NONE]: undefined,
  [DataQuantity.LIGHT]: ICON.WITHDRAWAL_LIGHT,
  [DataQuantity.MEDIUM]: ICON.WITHDRAWAL_MEDIUM,
  [DataQuantity.HEAVY]: ICON.WITHDRAWAL_HEAVY,
}

export const ICON_BY_MISCARRIAGE_QUANTITY: Record<DataQuantity, ICON | undefined> = {
  [DataQuantity.NONE]: undefined,
  [DataQuantity.LIGHT]: ICON.MISCARRIAGE_LIGHT,
  [DataQuantity.MEDIUM]: ICON.MISCARRIAGE_MEDIUM,
  [DataQuantity.HEAVY]: ICON.MISCARRIAGE_HEAVY,
}

export const ICON_BY_POSTPARTUM_QUANTITY: Record<DataQuantity, ICON | undefined> = {
  [DataQuantity.NONE]: undefined,
  [DataQuantity.LIGHT]: ICON.POSTPARTUM_LIGHT,
  [DataQuantity.MEDIUM]: ICON.POSTPARTUM_MEDIUM,
  [DataQuantity.HEAVY]: ICON.POSTPARTUM_HEAVY,
}

export const MENS_QUANTITY_RECORD_BY_MENS: Record<
  Mens,
  Record<DataQuantity, ICON | undefined> | undefined
> = {
  [Mens.MENSTRUATION]: ICON_BY_MENS_QUANTITY,
  [Mens.SPOTTING]: undefined,
  [Mens.WITHDRAWAL]: ICON_BY_WITHDRAWAL_QUANTITY,
  [Mens.MISCARRIAGE_BLEEDING]: ICON_BY_MISCARRIAGE_QUANTITY,
  [Mens.POSTPARTUM_BLEEDING]: ICON_BY_POSTPARTUM_QUANTITY,
}

export const ICON_BY_CM_QUANTITY: Record<DataQuantity, ICON> = {
  [DataQuantity.NONE]: ICON.CM_QUANTITY_NONE,
  [DataQuantity.LIGHT]: ICON.CM_QUANTITY_LIGHT,
  [DataQuantity.MEDIUM]: ICON.CM_QUANTITY_MEDIUM,
  [DataQuantity.HEAVY]: ICON.CM_QUANTITY_HEAVY,
}

export const ICON_BY_CM_CONSISTENCY: Record<CervicalMucusConsistency, ICON> = {
  [CervicalMucusConsistency.CREAMY]: ICON.CM_CONSISTENCY_CREAMY,
  [CervicalMucusConsistency.EGGWHITE]: ICON.CM_CONSISTENCY_EGGWHITE,
  [CervicalMucusConsistency.STICKY]: ICON.CM_CONSISTENCY_STICKY,
  [CervicalMucusConsistency.WATERY]: ICON.CM_CONSISTENCY_WATERY,
}

export const ICON_BY_LIBIDO: Record<Libido, ICON> = {
  [Libido.LOW]: ICON.LIBIDO_LOW,
  [Libido.MEDIUM]: ICON.LIBIDO_MEDIUM,
  [Libido.HIGH]: ICON.LIBIDO_HIGH,
}

export const ICON_BY_SLEEP: Record<Sleep, ICON> = {
  [Sleep.POOR]: ICON.SLEEP_POOR,
  [Sleep.OKAY]: ICON.SLEEP_OKAY,
  [Sleep.RESTFUL]: ICON.SLEEP_RESTFUL,
}

export const ICON_BY_LH: Record<TestResult, ICON> = {
  [TestResult.YES]: ICON.LH_POS,
  [TestResult.NO]: ICON.LH_NEG,
}

export const ICON_BY_OVULATION_STATUS: Record<OvulationStatus, ICON | undefined> = {
  [OvulationStatus.OVU_DAY]: ICON.OVULATION,
  [OvulationStatus.OVU_CONFIRMED]: ICON.OVULATION,
  [OvulationStatus.OVU_PREDICTION]: ICON.OVULATION,
  [OvulationStatus.OVU_WAITING]: ICON.CLOCK,
  [OvulationStatus.OVU_NOT_CONFIRMED]: undefined,
  [OvulationStatus.ANOVULATORY]: undefined,
}

export const ICON_BY_LH_STATUS: Record<LHStatus, ICON> = {
  [LHStatus.LH_NEG_PRE_OVU_CONFIRMED]: ICON.LH_NEG,
  [LHStatus.LH_NEG_OVU_DAY]: ICON.LH_NEG,
  [LHStatus.LH_PREDICTION]: ICON.LH_TEST,
  [LHStatus.LH_POS_PRE_OVU_CONFIRMED]: ICON.LH_POS,
  [LHStatus.LH_POS_OVU_CONFIRMED]: ICON.LH_POS,
  [LHStatus.LH_POS_OVU_NOT_CONFIRMED]: ICON.LH_POS,
}

export const ICON_BY_PREG_TEST_STATUS: Record<PregnancyTestStatus, ICON> = {
  [PregnancyTestStatus.PREG_PREDICTION]: ICON.PREG_TEST,
  [PregnancyTestStatus.PREG_POSITIVE]: ICON.PREG_TEST_POS,
  [PregnancyTestStatus.PREG_NEGATIVE]: ICON.PREG_TEST_NEG,
}

export const ICON_BY_SEX: Record<HadSex, ICON | undefined> = {
  [HadSex.NO]: undefined,
  [HadSex.YES]: ICON.SEX,
  [HadSex.YES_PROTECTED]: ICON.SEX_SAFE,
}

export const ICON_BY_BC: Record<BirthControlMethodBreakpointOption, ICON> = {
  [BirthControlMethodBreakpointOption.condom]: ICON.BC_CONDOM,
  [BirthControlMethodBreakpointOption.copperIUD]: ICON.BC_IUD_COPPER,
  [BirthControlMethodBreakpointOption.hormonalIUD]: ICON.BC_IUD_HORMONAL,
  [BirthControlMethodBreakpointOption.implant]: ICON.BC_IMPLANT,
  [BirthControlMethodBreakpointOption.none]: ICON.BC_NONE,
  [BirthControlMethodBreakpointOption.nuvaRing]: ICON.BC_NUVARING,
  [BirthControlMethodBreakpointOption.other]: ICON.BC_OTHER,
  [BirthControlMethodBreakpointOption.patch]: ICON.BC_PATCH,
  [BirthControlMethodBreakpointOption.pill]: ICON.BC_PILL,
  [BirthControlMethodBreakpointOption.pullOut]: ICON.BC_PULLOUT,
  [BirthControlMethodBreakpointOption.shot]: ICON.BC_SHOT,
}

export const PMS_ICON_BY_LANG: Partial<Record<LANG, Partial<Record<ICON.MOOD_PMS, string>>>> = {
  [LANG.es_US]: {
    [ICON.MOOD_PMS]: `${ICON_PATH}mood-pms-es.svg`,
  },
  [LANG.pt_BR]: {
    [ICON.MOOD_PMS]: `${ICON_PATH}mood-pms-pt.svg`,
  },
}
