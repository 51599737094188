import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { ScrollFooterComponentModule } from '@app/cmp/scroll-footer/scroll-footer.component.module'
import { PipesModule } from '@app/pipes/pipes.module'
import { IonicModule } from '@ionic/angular'
import { InfoModal } from './info.modal'

@NgModule({
  declarations: [InfoModal],
  imports: [IonicModule, CommonModule, PipesModule, ScrollFooterComponentModule, RouterModule],
  exports: [InfoModal],
})
export class InfoModalModule {}
