import { Assignments, BackendResponseFM, ExperimentState } from '@naturalcycles/shared'
import { AnyAction, Reducer } from 'redux'

const initialState: ExperimentState = {
  id: '',
  assignments: {},
}

const actions = {
  replaceExperiment(
    _state: ExperimentState,
    action: { type: string; payload: ExperimentState },
  ): ExperimentState {
    return {
      ...action.payload,
    }
  },

  extendExperiment(
    state: ExperimentState,
    action: { type: string; payload: Assignments },
  ): ExperimentState {
    return {
      ...state,
      assignments: {
        ...state.assignments,
        ...action.payload,
      },
    }
  },

  onBackendResponse(
    state: ExperimentState,
    action: { type: string; payload: BackendResponseFM },
  ): ExperimentState {
    // replace!
    if (action.payload.experiment) {
      return {
        ...action.payload.experiment,
      }
    }

    return state
  },
}

export const experimentReducer: Reducer<ExperimentState> = (
  // biome-ignore lint/style/useDefaultParameterLast: ok
  state = initialState,
  action: AnyAction,
) => {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
