import { getState } from '@app/srv/store.service'
import { NavController } from '@ionic/angular'
import { ROUTES } from '../cnst/nav.cnst'
import { di } from '../srv/di.service'

/**
 * A "guard" that runs immediately after appinit.
 * Unlike other page guards that inherit PageGuard,
 * this is a normal function that navigates directly to the specified page.
 */
export function appInitGuard(): void {
  const {
    account: { completeDate, demoMode, lastPaymentGate },
    partnerAccount,
  } = getState()

  if (!partnerAccount && !completeDate && lastPaymentGate && !demoMode) {
    // If demo user exit demo mode on the web, they come to the flow here.
    // When "completeDate" becomes undefined during demo mode,
    // the account status needs to be evaluated by appInitGuard,
    // since HomePageGuard has already been evaluated.
    void di.get(NavController).navigateRoot(ROUTES.RegDonePage)
  }
}
