import { Injector, NgZone, Type } from '@angular/core'
import { StringMap } from '@naturalcycles/js-lib'
import { Observable, Subscription } from 'rxjs'

class DIService {
  MOCK_MAP = new Map<Type<any>, any>()
  INSTANCE_MAP: StringMap<any> = {}

  injector!: Injector
  ngZone!: NgZone

  get<T = any>(token: Type<T>): T {
    if (!this.injector) {
      // This warning is to help debugging di issues
      console.warn('di.get() is called before di.init()!')
      return undefined as any
    }

    return this.MOCK_MAP.get(token) || this.injector.get(token)
  }

  setInstance(name: string, instance: any): void {
    this.INSTANCE_MAP[name] = instance
  }

  getInstance<T = any>(name: string): T {
    return this.INSTANCE_MAP[name]
  }

  /**
   * Must be called as early as possible to make DI work
   */
  init(injector: Injector): void {
    // console.log('di.init()', !!injector) // uncomment to debug
    this.injector = injector
    this.ngZone = injector.get(NgZone)
  }
}

export const di = new DIService()

export function runOutsideAngular<T>(fn: (...args: any[]) => T): T {
  return di.ngZone.runOutsideAngular(fn)
}

export function runInsideAngular<T>(fn: (...args: any[]) => T): T {
  return di.ngZone.run(fn)
}

export function setTimeoutOutsideAngular(fn: (...args: any[]) => any, timeout = 0): number {
  return di.ngZone.runOutsideAngular(() => setTimeout(fn, timeout))
}

export function setIntervalOutsideAngular(fn: (...args: any[]) => any, timeout = 0): number {
  return di.ngZone.runOutsideAngular(() => setInterval(fn, timeout))
}

// Based on: https://netbasal.com/optimizing-angular-change-detection-triggered-by-dom-events-d2a3b2e11d87
export function outsideZone<T>(): (source: Observable<T>) => Observable<T> {
  return (source: Observable<T>) =>
    new Observable<T>(observer => {
      let sub: Subscription = undefined as any
      di.ngZone.runOutsideAngular(() => {
        sub = source.subscribe(observer)
      })

      return sub
    })
}
